.faq-page {
  padding: 130px 0 0px 0;
}
.faq-page-title h2 {
  font-size: 44px;
  letter-spacing: 1px;
  color: #ffffff;
  font-weight: 600;
  margin-bottom: 0;
  text-align: center;
  text-transform: capitalize;
}
.faq-page-topsection {
  /* background: url(../../assets/images/faq/faqBg.png) no-repeat; */
  background-size: 100vw 100%;
  width: 100%;
  /* height: 70vh; */
  display: flex;
  justify-content: center;
  align-items: center;
}
.faq-page-title {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 15px;
}

.faq-page-title p {
  font-size: 22px;
  max-width: 788px;
  width: 100%;
  text-align: center;
  line-height: 27px;
}
.faq-section {
  background: transparent;
  border-radius: 8px;
  max-width: 788px;
  width: 100%;
  margin: 0 auto;
}
.faq-section .accordion__button {
  background: #d38b26;
  color: #ffffff;
  border-radius: 8px;
  font-size: 24px;
  font-weight: 600;
  padding: 13px 18px;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.faq-section .accordion .accordion__item {
  background: #d38b26;
  /* border: 2px solid #d38b26; */
  margin-bottom: 30px;
  border-radius: 8px;
}
.faq-section .accordion .accordion__item .accordion__header {
  padding-bottom: 0;
}
.faq-section .accordion .accordion__item .accordion__header button {
  background: transparent;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
}

.faq-section .accordion .accordion__item .accordion__body {
  background: transparent;
  border-top: 2px solid #2f3038;
}
.faq-page-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
}
.faq-page-icon img {
  max-width: 150px;
  width: 100%;
}
.accordian-desc {
  padding: 30px 0;
  border-top: 2px solid #fff;
}
.faq-section p span {
  font-weight: 600;
  padding-left: 8px;
  display: inline;
}
.faq-section .accordion__panel {
  padding: 0 30px;
}
.faq-section .accordion__button:before {
  height: 15px;
  width: 15px;
  border-bottom: 3px solid currentColor;
  border-right: 3px solid currentColor;
}
