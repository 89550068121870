.checkout-page {
  padding: 185px 0px 100px;
}
.checkout-page input:focus,
input:active,
textarea:focus,
textarea:active {
  border-color: #ecaa2f !important;
}

.checkout-page textarea {
  border: 1px solid #083248 !important;
}
.payment-form__title input:not([type="radio"]) {
  border: 1px solid #292B39;
}

/*** address details popup *******/
.address-details-popup .address-list {
  padding: 20px 30px;
  background: #5959593d;
  border-radius: 5px;
  margin-bottom: 20px;
  position: relative;
  cursor: pointer;
}

.address-details-popup .adress-section {
  margin: 30px;
}
.address-details-popup .adress-section h2 {
  color: #fff;
  padding: 20px 0;
  margin: 0;
  font-size: 25px;
}

.address-details-popup .address-list h6 {
  margin-bottom: 6px;
  font-size: 20px;
  font-weight: 600;
}

.address-details-popup .address-details i {
  margin-right: 10px;
  font-size: 22px;
  color: #e89c31;
}
.address-details-popup .address-list.selected-address {
  background: #123a90a1;
}
.address-list.selected-address {
  background: #123a90a1;
}

.address-details-popup .address-list.selected-address .la-check {
  position: absolute;
  right: -16px;
  font-size: 24px;
  top: -13px;
  background: #ffb200;
  border-radius: 50%;
  padding: 5px;
  display: block;
}

.address-details-popup .address-list .la-check {
  display: none;
}
.payment-details.address .delivery-address {
  display: flex;
  justify-content: space-between;
}
.delivery-address {
  display: flex;
  justify-content: space-between;
}
.address-details {
  display: flex;
  align-items: center;
}

.address-details i {
  color: #b2c8ff;
  font-size: 25px;
  line-height: 1;
  padding-right: 10px;
}
.payment-details.address.add-address a {
  color: #f2ac4c;
  font-size: 22px;
  padding: 0px;
}

.payment-details.address.add-address a i {
  margin-right: 10px;
}
