.footer {
  position: relative;
  border-top: 1px solid #072536;
  padding: 0px 20px;
}

.copyright-text p {
  margin-bottom: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 15px;
  color: #e9e9e9;
  padding: 20px 0px 17px;
}
p.copy-right-text{
  font-size: 15px;
  line-height: 25px;
  max-width: 750px;
  margin: 0px auto;
  
}

.new-footer-section {
  background: #1B1C25;
}

.new-footer-section .select-Lang {
  height: 50px;
  border-radius: 11.111px;
  background: #2C3034;
  border: none;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.footer-text span {

    display: initial;
    color: #757786;
}

.footer-icon svg {
  /* margin-top: 15px; */
  height: 25px; 
  width: 25px;
  cursor: pointer;
}

.footer-pl {
  padding-left: 70px;
}

.right-copy-text {
  color: #757786;
}

@media screen and (max-width: 991px) {
  .footer-pl {
    padding-left: 16px;
    padding-top: 24px;
  }
}
