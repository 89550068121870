.how-to-play-topsection {
  /* background: url("../../assets/images/howtoplay/howToBg.png") no-repeat;
  background-size: 100vw 100%; */
}
.how-to-play-title {
  padding-top: 40px;
}
.how-to-play-title h2 {
  font-size: 54px;
  line-height: 50px;
  margin-top: 100px;
}
.how-to-play-title img {
  max-width: 150px;
  width: 100%;
  margin-bottom: 100px;
}
.how-to-play-topsection {
  margin-bottom: 77px;
}
.play-content-title {
  display: flex;
  justify-content: center;
  margin: 35px 0;
}
.play-content-title h3 {
  font-size: 56px;
  color: #db942f;
}
.howtoplay-card {
  padding: 20px;
  max-width: 900px;
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(95.04deg, #6840a1 0%, #451d7e 100%);
}
.howtoplay-card-wrapper {
  display: flex;
  flex-direction: column;
  gap: 50px;
  justify-content: center;
  align-items: center;
}
.howtoplay-card {
  padding: 20px;
  max-width: 900px;
  width: 100%;
  border-radius: 15px;
  background: linear-gradient(95.04deg, #6840a1 0%, #451d7e 100%);
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.howtoplay-card:nth-child(even) {
  flex-direction: row-reverse;
}
.howtoplay-card-left {
  width: 28%;
}
.howtoplay-image {
  width: 100%;
  height: 100%;
  background: #fff;
  padding: 120px;
  border-radius: 15px;
  box-shadow: 10px 10px 10px rgb(0 0 0 / 25%);
}
.howtoplay-card-right {
  width: 70%;
  padding-left: 18px;
}
.howtoplay-card-title {
  padding: 15px 0;
  border-bottom: 1px solid #fff;
}
.howtoplay-card-title h4 {
  font-size: 26px;
  color: #db942f;
}
.howtoplay-card-desc {
  padding: 10px 0;
}
.howtoplay-card-desc p {
  font-size: 18px;
  line-height: 26px;
  color: #fff;
}
.howtoplay-card-desc p span {
  font-weight: 600;
  display: inline;
  padding-left: 8px;
}
.play-video-wrapper {
  display: flex;
  justify-content: center;
  position: relative;
  min-height: 400px;
}
.howtoplay-video {
  position: absolute;
  top: -50px;
  border-radius: 30px;
}
.howtoplay-video-hide {
  position: absolute;
  top: -50px;
  border-radius: 30px;
}
.howtoplay-video-hide::before {
  content: "";
  position: absolute;
  background: rgba(74, 34, 131, 0.5);
  width: 100%;
  height: 98.5%;
  background-color: rgba(74, 34, 131, 0.5);
  border-radius: 30px;
}
.howtoplay-video video,
.howtoplay-video-hide video {
  max-width: 900px;
  width: 100%;
  border-radius: 30px;
}
.howtoplay-video-btn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
  cursor: pointer;
}
.howtoplay-video-btn img {
  max-width: 100px;
  width: 100%;
}
