.chat-button-toggle {
  width: 36px;
  height: 36px;
  bottom: 36px;
  border-radius: 30px;
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  padding: 0px;
}

.open-button {
  background-color: #555;
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 23px;
  right: 28px;
  width: 280px;
}

.chat-popup {
  position: fixed;
  left: 0px;
  bottom: 0px;
  top: 0;
  max-width: 300px;
  width: 100%;
  z-index: 9999;
  background-color: #061e2c;
  border-radius: 12px;
  border: 1px solid #0d2c3f;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-top-left-radius: 0px;
}

.chat-button-toggle svg {
  fill: #fff;
  width: 24px;
  height: 22px;
}

.chat-popup .card-header {
  background-color: #061e2c;
  border-bottom: 1px solid #0d2c3f;
}

.chat-header-left {
  display: flex;
  gap: 10px;
  align-items: center;
}

.chatboxarea {
  position: relative;
}

.chatboxarea .text-muted {
  position: absolute;
  padding: 9px 10px;
  top: 26px;
  transform: translateY(-50%);
  background: #21252900;
  right: 56px;
}

.paperplanebox {
  position: absolute;
  right: 7px;
  top: 27px;
  transform: translateY(-50%);
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
  padding: 9px 0;
  border-radius: 0 5px 5px 0;
  width: 49px;
  text-align: center;
  cursor: pointer;
}

.paperplanebox .fa-paper-plane {
  color: #fff;
}

.chatboxarea textarea {
  padding: 5px 0 5px 9px;
  min-height: 66px;
  border-radius: 5px 5px 5px 5px !important;
}

.chat-container .bg-body-tertiary,
.chat-container .bg-body-me {
  background-color: #01151f;
  /* width: 96%; */
}

/* ToggleComponent.css */
.toggle-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.toggle-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  margin-bottom: 10px;
  transition: background-color 0.3s ease;
}

.toggle-button:hover {
  background-color: #0056b3;
}

.chat-popup {
  opacity: 0;
  animation: slideInFromRight 0.5s ease forwards;
}

.chat-popup.open {
  opacity: 1;
  animation: slideInFromLeft 0.5s ease forwards;
}
.new-chatOpen .card-header {
  background: #181820;
  border-bottom: 1px solid #29292f;
}
.new-chatOpen .card-style {
  background: #1b1c25;
}

.new-chatOpen {
  left: unset;
  right: 0px;
  border: 1px solid #29292f;
}

.new-chatOpen .chat-container .bg-body-me {
  background: #13151d;
  /* line-break: auto; */
  /* text-wrap: wrap; */
  word-wrap: break-word;
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  line-break: auto;
}

.new-chatOpen .msg-divider:after,
.new-chatOpen .msg-divider:before {
  background: #29292f;
}

.new-chatOpen input:not([type="radio"]),
textarea {
  background: #1b1c25;
}

.new-chatOpen .chatboxarea {
  background: #181820;
}

.new-chatOpen #chat2 input::placeholder {
  color: #676778;
}

.new-chatOpen .chat-button-toggleafteropen {
  background: #363640;
}
.new-chatOpen {
  background: #1b1c25;
  opacity: 1;
  animation: slideInFromLeft1 0.5s ease forwards;
}

.new-chatOpen.open {
  opacity: 0;
  animation: slideInFromRight1 0.5s ease forwards;
}

@keyframes slideInFromRight {
  from {
    transform: translateX(0);
    opacity: 0;
  }

  to {
    transform: translateX(-100%);
    opacity: 1;
  }
}

@keyframes slideInFromLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideInFromLeft1 {
  from {
    transform: translateX(0%);
    opacity: 0;
  }

  to {
    transform: translateX(322%);
    opacity: 1;
  }
}
@keyframes slideInFromRight1 {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
