.register-form {
  max-width: 450px;
  margin: 0px auto;
  padding: 0px 20px;
  position: relative;
}

.register-form img {
  max-width: 250px;
  margin: 0px auto;
  display: block;
}
.select-dropdown option {
  background: #0b2838 !important;
  padding: 10px 20px !important;
  border: 1px solid rgba(255, 255, 255, 0.1) !important;
}
.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid #ff5917;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.register-form h1 {
  font-size: 30px;
  font-weight: 600;
  text-align: center;
  max-width: 400px;
  margin: 0px auto;
  color: #ddd;
  margin-bottom: 0.5rem;
}

.register-form p {
  text-align: center;
  color: #a9a9a9;
  font-size: 14px;
  max-width: 270px;
  margin: 0px auto;
}

.register-form h1 span {
  color: #f5d35c;
}

.register-form .rigister-tab {
  margin-top: 20px;
  margin-bottom: 0px;
}

.terms-link {
  color: #a9a9a9;
  text-decoration: underline;
}

.terms-link:hover {
  color: #f5d35c;
}

.dis-show .form-check {
  display: inline-block;
}

.dis-show p {
  font-size: 13px;
  color: #a9a9a9;
  margin: 0;
  text-align: left;
}

.dis-show {
  display: flex;
  align-items: flex-start;
}

.s-btn.btn.btn-primary {
  padding: 8px 40px;
  font-size: 15px;
  background: #a9f55c;
  border-radius: 3px;
  font-weight: 600;
  color: #1b1f22;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 15px;
}

.s-btn.btn.btn-primary:hover,
.s-btn.btn.btn-primary:focus,
.s-btn.btn.btn-primary:active {
  background: #a9f55c;
  box-shadow: 0 0 50px 0 hsl(0deg 0% 100% / 30%);
  border: none;
}

.register-form .form-group .form-control {
  height: auto;
  padding: 8px 15px;
  background: #192633;
  border: 1px solid #192633;
  border-radius: 4px;
  font-size: 14px;
  color: #a9a9a9;
}

.register-form .form-group .form-control::placeholder {
  color: #a9a9a9;
}

.register-form .form-group .form-control:focus {
  box-shadow: none;
  background: #192633;
}

.register-form input::-internal-autofill-selected {
  background: transparent !important;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:hover,
.form-control:-webkit-autofill:focus {
  border: 1px solid #192633;
  -webkit-text-fill-color: #ffffff;
  -webkit-box-shadow: 0 0 0px 1000px #192633 inset;
  transition: background-color 5000s ease-in-out 0s;
  outline: none;
}

.signup .form-label {
  font-size: 14px;
  color: #ddd;
  font-weight: 500;
  margin-bottom: 5px;
}

.already {
  text-align: center;
  padding-top: 20px;
  padding-bottom: 10px;
  font-size: 13px;
  color: #a9a9a9;
}

.signup {
  position: relative;
}

input[type="date"].form-control,
input[type="datetime-local"].form-control,
input[type="month"].form-control,
input[type="time"].form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
}

input[type="date"]:before {
  background: none;
  display: block;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  height: 20px;
  position: absolute;
  top: 8px;
  right: 15px;
  color: #a9a9a9;
  cursor: pointer;
  pointer-events: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.signup-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.signup-popup .modal-header .close:focus {
  outline: none;
}

.signup-popup .close {
  color: #a9a9a9;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
}

.signup-popup .close:hover {
  color: #efebeb;
}

.already {
  text-align: center;
  padding-top: 30px;
  font-size: 13px;
  color: #a9a9a9;
}

.signup-popup .modal-content {
  background: #1f2127;
  position: relative;
  background-size: cover;
  background-position: center;
}

.signup-popup .modal-body {
  padding-top: 0;
}

.custom-checkbox-section {
  display: grid;
  grid-template-columns: 24px auto;
  width: 100%;
  align-items: center;
  margin: 5px 0;
}
.custom-checkbox-label {
  margin-bottom: 0 !important;
  text-transform: none !important;
  font-size: 14px !important;
}

/***** new registrtion css ***********/
.registration-popup .account-form-area form input::placeholder {
  color: #c6c6c6;
  padding-bottom: 0px !important;
  font-weight: 300;
  font-size: 14px;
}
.registration-popup .account-form-area form input {
  color-scheme: dark;
}
.privacy-policy {
  margin-top: 15px;
}
.privacy-policy .form-group {
  display: flex;
}
.privacy-policy a {
  color: #e89c31;
}
.privacy-policy .form-check-input[type="checkbox"] {
  height: 20px;
  width: 20px;
  border: 1px solid #ffffff2d;
  border-radius: 4px;
  margin-right: 10px;
}
.privacy-policy .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgb(108 108 108 / 25%) !important;
}
.registration-popup .account-form-area form input {
  padding-bottom: 0px;
  color: white;
}
.registration-popup .form-control:-webkit-autofill,
.registration-popup .form-control:-webkit-autofill:hover,
.registration-popup .form-control:-webkit-autofill:focus {
  border: none;
  border-bottom: 1px solid #ffffff2d;
}
.registration-popup .steps-section {
  display: flex;
  justify-content: space-around;
  margin: 20px 0px;
}

.registration-popup .steps-section span {
  background: #47484e;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  line-height: 0px !important;
  font-size: 14px;
  cursor: not-allowed;
}

.registration-popup .step-one::before,
.registration-popup .step-two::before {
  content: "";
  position: absolute;
  height: 2px;
  width: 71%;
  background: #edeae5;
  right: -55px;
  top: 50%;
}

.registration-popup .step-one {
  position: relative;
  width: 33%;
  display: flex;
  justify-content: center;
}

.registration-popup .step-two {
  width: 33%;
  position: relative;
  display: flex;
  justify-content: center;
}

.registration-popup .step-three {
  position: relative;
  width: 33%;
  display: flex;
  justify-content: center;
}

.registration-popup .complete-step span i {
  color: #ffffff;
  font-size: 22px;
}

.registration-popup .complete-step span {
  background: #eb8a00;
  cursor: pointer;
}
.registration-popup .account-form-area form .cmn-btn {
  width: 100%;
  display: inline-block;
  padding: 5px 20px;
  height: 45px;
  font-size: 18px;
  font-weight: 300;
  text-transform: inherit;
  margin-top: 20px;
  border-radius: 50px !important;
}
.registration-popup .account-form-area form .cmn-btn.btn-style:hover span {
  z-index: 99 !important;
}
.registration-popup .account-form-area .password-msg {
  font-size: 15px;
  line-height: 18px;
  margin-top: 13px;
  font-weight: 300;
  color: #b87617;
}
.registration-popup .account-form-area form label {
  margin-bottom: 0 !important;
  text-transform: initial;
}
.account-form-wrapper label {
  display: none;
}
.custom-checkbox-section label,
.privacy-policy label {
  display: block !important;
}
.send-otp {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.send-otp button,
.send-otp button:hover,
.send-otp button:focus,
.send-otp .btn-primary:not(:disabled):not(.disabled):active {
  background-color: transparent;
  color: #b87617;
  border: 1px solid #b87617;
  padding: 5px 20px;
  border-radius: 50px;
  /* margin: 20px 0px; */
  outline: none;
  box-shadow: none;
  width: fit-content !important;
}
.send-otp.verify-otp {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.send-otp.verify-otp p {
  color: #b97718;
  cursor: pointer;
  text-decoration: underline;
}
.rc-anchor-light.rc-anchor-normal {
  border: 1px solid #50778c;
}

.rc-anchor-light {
  background: #0b2838 !important;
  color: white !important;
}
p.error-msg {
  font-size: 14px;
  color: #cb0606;
  text-transform: capitalize;
  text-align: left;
}
.span {
  display: inline-flex;
  margin-left: 25px;
}
.checkBox label {
  margin-left: 15px;
}
.checkBox {
  margin-top: 15px;
}
.registerpage .react-datepicker-popper {
  z-index: 999;
}
.registerpage .react-datepicker,
.global-date-picker .react-datepicker {
  border: 1px solid #052434;
  color: rgba(255, 255, 255, 0.7);
}
.registerpage .react-datepicker__header,
.global-date-picker .react-datepicker__header {
  background: #041d2a;
  color: rgba(255, 255, 255, 0.7);
}
.registerpage .react-datepicker__current-month,
.registerpage .react-datepicker-time__header,
.registerpage .react-datepicker-year-header,
.global-date-picker .react-datepicker__current-month,
.global-date-picker .react-datepicker-time__header,
.global-date-picker .react-datepicker-year-header {
  color: rgba(255, 255, 255, 0.7);
}
.registerpage .react-datepicker__day-name,
.registerpage .react-datepicker__day,
.registerpage .react-datepicker__time-name,
.global-date-picker .react-datepicker__day-name,
.global-date-picker .react-datepicker__day,
.global-date-picker .react-datepicker__time-name {
  color: rgba(255, 255, 255, 0.9);
}
.registerpage .react-datepicker__day--disabled,
.global-date-picker .react-datepicker__day--disabled {
  color: rgba(255, 255, 255, 0.4);
}
.registerpage .react-datepicker button {
  border: none !important;
}
.registerpage .react-datepicker select,
.global-date-picker .react-datepicker select {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  padding: 0px 10px;
  border: none;
  height: 35px;
}

.registerpage .react-datepicker select::-webkit-scrollbar,
.global-date-picker .react-datepicker select::-webkit-scrollbar {
  background-color: #041d2a;
  width: 6px;
}
.registerpage .react-datepicker select::-webkit-scrollbar-track,
.global-date-picker .react-datepicker select::-webkit-scrollbar-track {
  background-color: transparent;
}

.registerpage .react-datepicker select::-webkit-scrollbar-thumb,
.global-date-picker .react-datepicker select::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 3px solid #c5801e;
  background-color: #c5801e;
}

.registerpage select option,
.global-date-picker select option {
  color: #ffffff;
}
.registerpage .react-datepicker__month,
.global-date-picker .react-datepicker__month {
  background: #041f2d;
  padding: 5px;
  margin: 0px;
}
.registerpage
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.registerpage
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after,
.global-date-picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
.global-date-picker
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: #041f2d;
}
.registerpage
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.registerpage
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after,
.global-date-picker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::before,
.global-date-picker
  .react-datepicker-popper[data-placement^="top"]
  .react-datepicker__triangle::after {
  border-top-color: #041f2d;
}
.registerpage .react-datepicker__day--selected,
.registerpage .react-datepicker__day--keyboard-selected,
.registerpage .react-datepicker__day:hover,
.global-date-picker .react-datepicker__day--selected,
.global-date-picker .react-datepicker__day--keyboard-selected,
.global-date-picker .react-datepicker__day:hover {
  background: #eb8a00;
}
.registerpage .react-datepicker__day--disabled:hover,
.global-date-picker .react-datepicker__day--disabled:hover {
  background: transparent;
}
.phone-input .form-control {
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #ffffff2d !important;
  padding-top: 0px !important;
  padding-right: 0px !important;
  border-radius: 0 !important;
}
.phone-input .selected-flag {
  padding-left: 0px !important;
}
.phone-input .form-control:focus {
  border-color: #e1972e !important;
  box-shadow: none !important;
}
.phone-input .selected-flag .arrow {
  border-top: 5px solid #eee !important;
}
.phone-input .selected-flag:focus:before,
.react-tel-input .selected-flag.open:before {
  border: none !important;
  box-shadow: none !important;
}

.phone-input .country-list {
  background: #041d2a !important;
  color: rgba(255, 255, 255, 0.7) !important;
  overflow-x: hidden !important;
}

.phone-input .country-list::-webkit-scrollbar {
  background-color: #041d2a;
  width: 6px;
}
.phone-input .country-list::-webkit-scrollbar-track {
  background-color: transparent;
}

.phone-input .country-list::-webkit-scrollbar-thumb {
  border-radius: 5px;
  border: 3px solid #c5801e;
  background-color: #c5801e;
}
.phone-input .country-list .country .dial-code {
  color: #e9e9e9 !important;
}
.phone-input .country-list .search {
  background: #041d2a !important;
}
.phone-input .country-list .search-box {
  margin: 0px !important;
}
.phone-input .country-list .country:hover,
.phone-input .country-list .country.highlight {
  background-color: #0b2838 !important;
}
