.terms-page,
.privacy-page {
  padding: 200px 0 50px;
}
.terms-page h2,
.privacy-page h2 {
  padding-bottom: 40px;
}
.terms-page p,
.privacy-page p {
  padding-bottom: 20px;
  font-size: 16px;
}
.terms-page a,
.privacy-page a {
  padding-bottom: 20px;
}
