.payment-page{
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    text-align: center;
}
.payment-image{
    padding: 20px 0px;
}
.payment-image img{
    max-width: 120px;
    width: 100%;
}
.payment-title{
    padding: 30px 0px;
}
.payment-title h2{
    font-size: 30px;
    margin: 0;
    padding-bottom: 15px;
}
.payment-title h3{
    max-width: 630px;
    line-height: 30px;
    font-size: 18px;
    font-weight: 400;
    margin: 0px auto;
}
.payment-title a{
    color: #e89c31;
}
.payment-status-popup .modal-content{
    background: rgba(4,31,45)!important;
    border: 1px solid #052434;
    padding: 20px 35px;
}
.payment-status-popup .modal-header{
    justify-content: flex-end;
    border: none;
    padding: 0;
}
.payment-close-btn img{
    width: 18px;
    height: 18px;
    cursor: pointer;
}
.payment-status-content{
    text-align: center;
}


@media screen and (max-width: 767px) {
    .payment-close-btn img{
        width: 12px;
        height: 12px;
    }
    .payment-status-popup .modal-content{
        padding: 20px 15px;
      }
      .payment-image img{
        max-width: 50px;
      }
      .payment-title h2{
        font-size: 20px;
      }
      .payment-title h3{
        font-size: 16px;
      }
      .payment-title{
        padding: 20px 0px;
      }
}

