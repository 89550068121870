.contactus-page-title h2 {
  font-size: 100px;
}
.contactus-wrapper {
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 40px;
}
.contactus-form-body {
  /* display: flex;
  justify-content: space-between;
  padding: 30px 40px;
  background: #0b2838;
  border-radius: 30px;
  max-width: 788px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 40px; */
  display: flex;
  justify-content: space-between;
  padding: 0;
  background: #1b1c25;
  border-radius: 30px;
  max-width: 788px;
  width: 100%;
  flex-wrap: wrap;
  margin-top: 40px;
  border: 1px solid #21222c;
}
.contactus-form-left {
  width: 60%;
  background: rgb(11, 40, 56);
  background: linear-gradient(90deg, #232430 75%, #1b1c252d 97%);
  padding: 30px 40px;
  border-radius: 30px 0 0 30px;
}
.contactus-form-right {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px 40px;
}
.contactus-form-title {
  margin-bottom: 15px;
}
.contactus-form-title p {
  color: #ddd;
}
.contactus-form-title h3 {
  font-size: 36px;
  color: #fff;
}
.contactus-form .mb-3.form-group {
  margin-bottom: 25px !important;
}
.contactus-form label.form-label {
  color: #ddd;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 0px;
}
.contactus-form input.form-control {
  min-height: 38px;
  height: 100%;
  padding: 6px 0px;
  background: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #ffffff2d;
  color: #fff;
}
.contactus-form input.form-control::placeholder,
.contactus-form textarea.form-control {
  color: #a9a9a9;
}
.contactus-form textarea.form-control {
  background: transparent;
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #ffffff2d;
  /* min-height: 10px; */
  color: #fff;
  padding-left: 0px;
}
.contactus-right-wrapper {
  display: flex;
  flex-direction: column;
  gap: 14rem;
}
.contactus-details {
  display: flex;
  gap: 40px;
  padding-top: 40px;
  justify-content: center;
  flex-direction: column;
}
.contactus-info {
  display: flex;
  gap: 20px;
  align-items: center;
}
.contactus-info img {
  max-width: 36px;
  width: 100%;
}
.contactus-info span {
  font-size: 16px;
  font-weight: 600;
  color: #e9e9e9;
}
.contactus-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
}
.contactus-btn button.btn-style.btn.btn-primary {
  max-width: 190px;
  width: 100%;
  color: #fff;
}
.contactus-btn span {
  text-transform: uppercase;
}
.contactus-footer {
  max-width: 650px;
  margin: 0 auto;
  text-align: center;
  padding-top: 20px;
}
