.login-form {
  max-width: 450px;
  margin: 0px auto;
  padding: 0px 20px;
  position: relative;
}

.login-form img {
  max-width: 250px;
  margin: 0px auto;
  display: block;
}
.head-logo img {
  max-width: 100px;
  margin: 0px auto;
  display: block;
  border: 2px solid#018bcf;
  border-radius: 10px;
  padding: 5px;
  margin-bottom: 20px;
}

.login-form h1 {
  font-size: 30px;
  text-align: center;
  font-weight: 600;
  color: #ddd;
}

.login-form p {
  text-align: center;
  color: #a9a9a9;
  font-size: 14px;
}

.login-form h1 span {
  color: #f5d35c;
}

.login-box {
  padding-top: 15px;
  max-width: 400px;
  margin: 0px auto;
}

.login-box .form-group .form-control {
  height: auto;
  padding: 8px 15px;
  background: #192633;
  border: 1px solid #192633;
  border-radius: 4px;
  font-size: 14px;
  color: #a9a9a9;
}

.login-box .form-group .form-control::placeholder {
  color: #a9a9a9;
}

.login-box .form-group .form-control:focus {
  box-shadow: none;
  background: #192633;
}

.login-box input:-internal-autofill-selected {
  background: transparent !important;
}

.login-box .form-group {
  margin-bottom: 25px;
}

.l-btn.btn.btn-primary {
  padding: 8px 40px;
  font-size: 15px;
  background: #a9f55c;
  border-radius: 3px;
  font-weight: 600;
  color: #1b1f22;
  border: none;
  transition: all 0.5s;
  width: 100%;
  margin-top: 15px;
}

.l-btn.btn.btn-primary:hover,
.l-btn.btn.btn-primary:focus,
.l-btn.btn.btn-primary:active {
  background: #a9f55c;
  box-shadow: 0 0 50px 0 hsl(0deg 0% 100% / 30%);
  border: none;
}

.login-button {
  text-align: center;
}

.login-box p {
  text-align: center;
  padding-top: 20px;
  font-size: 13px;
  color: #a9a9a9;
  margin: 0;
}

.signup-link {
  color: #f5d35c;
  text-decoration: underline;
}

.signup-link:hover {
  color: #c5c5c5;
}

.forget-link {
  color: #f5d35c;
  font-size: 13px;
}

.forget-link:hover {
  color: #a9a9a9;
  text-decoration: none;
}

.forget-password {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.remember-me .form-check-label {
  font-weight: 400;
  font-size: 13px;
  color: #a9a9a9;
}

.social-login {
  text-align: center;
}

.social-login span {
  color: #afb4d0;
  display: block;
  padding: 10px 0px;
  font-size: 12px;
}

.social-btns {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.social-btns .btn {
  width: 48%;
  font-size: 14px;
}

.facebook-btn {
  background: #3b5998 !important;
  border-color: #3b5998 !important;
}

.twitch-btn {
  background: #4b367c !important;
  border-color: #4b367c !important;
}

.login {
  position: relative;
}

.login-popup .modal-header {
  border-bottom: none;
  padding-bottom: 0;
}

.login-popup .form-label {
  font-size: 14px;
  color: #ddd;
  font-weight: 500;
  margin-bottom: 5px;
}

.login-popup .modal-header .close:focus {
  outline: none;
}

.login-popup .close {
  color: #a9a9a9;
  text-shadow: none;
  font-weight: 400;
  font-size: 30px;
  cursor: pointer;
}

.login-popup .close:hover {
  color: #efebeb;
}

.modal-backdrop {
  background-color: transparent !important;
  opacity: 1 !important;
}

.login-popup .modal-body {
  padding-top: 0;
}

.login-popup .modal-content {
  position: relative;
  background: #1f2127;
  background-size: cover;
  background-position: center;
}

.forget-link,
.forget-link:focus,
.forget-link:hover {
  background: transparent !important;
  border: none !important;
  font-weight: 400 !important;
  font-size: 13px !important;
  color: #f5d35c !important;
  box-shadow: none !important;
  padding: 0 !important;
}

.forget-link.btn-primary:not(:disabled):not(.disabled).active:focus,
.forget-link.btn-primary:not(:disabled):not(.disabled):active:focus {
  box-shadow: none !important;
}
.login-popup input, .login-popup textarea{
  padding: 10px 20px;
  border: 1px solid #6472d9;
  width: 100%;
  background-color: transparent !important;
}
.login-popup select {
  padding: 10px 20px;
  width: 100%;
  cursor: pointer;
  background-color: transparent;
}
.login-popup textarea {
  min-height: 150px;
  resize: none;
  width: 100%;
}
.modal {
  background-color: rgba(0, 0, 0, 0.5) !important;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:active,
input:-webkit-autofill:focus {
    background-color: transparent !important;
    -webkit-box-shadow: 0 0 0 1000px #0b283800 inset !important;
    -webkit-text-fill-color: #ffffff !important;
    color: white !important;
}

.custom-checkbox-section {
  display: grid;
  grid-template-columns: 24px auto;
  width: 100%;
  align-items: center;
  margin: 5px 0;
}

.custom-checkbox-label {
  margin-bottom:0 !important;
  text-transform: none !important;
  font-size:14px !important;
}

.reset-password-form{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}
.boxi-logo{
  text-align: center;
}
.reset-password-form h1{
  font-size: 30px;
  padding: 30px 0px;
  text-align: center;
  margin: 0px;
}
.reset-password-box{
  max-width: 600px;
  width: 100%;
  background: rgba(4,31,45,.7)!important;
  border: 1px solid #052434;
  padding: 50px 40px;
  border-radius: 10px;
}
.reset-password-box .form-group {
  padding-bottom: 25px;
}
.reset-password-box .form-label{
  color: #e2e8ff;
}
.reset-password-box input{
  border: 1px solid #033754!important;
  color: hsla(0,0%,100%,.7) !important;
}
.reset-password-box input::placeholder{
  color: hsla(0,0%,100%,.7) !important;
}
