#chat2 .form-control {
    border-color: transparent;
    padding-right: 90px;
}

#chat2 .form-control:focus {
    border-color: transparent;
    box-shadow: inset 0px 0px 0px 1px transparent;
}

.msg-divider:after,
.msg-divider:before {
    content: "";
    flex: 1;
    height: 1px;
    background: #0d2c3f;
}

.msg-divider p {
    font-size: 12px;
}

p.msg-time {
    font-size: 12px;
    color: #a2aab7;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.card-style {
    background-color: #081f2d;
    border-radius: 15px;

}

.bg-body-tertiary {
    background-color: #6461618c;
}

.bg-body-me {
    background: #f4a21dcf;
}

.fa-smile,
.fa-paper-plane {
    color: #f39b1d;
}

.emoji-popup {
    position: absolute;
    right: 0px;
    bottom: 60px;
    background: #05344e;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 0px #262626;
    z-index: 99999;
    max-width: 330px;
    max-height: 200px;
    overflow: auto;
    width: 100%;
}

.chat-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: calc(100vh - 106px);
    padding: 16px;
}

.chat-container div {
    vertical-align: bottom;
}

.emoji-popup::-webkit-scrollbar {
    display: none;
}

.emoji-popup::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 16px rgba(0, 0, 0, 0.3);
    background-color: var(--light-grey);

}

.emoji-btn {
    cursor: pointer;
}

.chats::-webkit-scrollbar {
    width: 0px;
    background-color: #222530;
    display: none;
}

.chats::-webkit-scrollbar-track {
    margin-bottom: 7px !important;
}

.chats::-webkit-scrollbar-thumb {
    background: #f4a21dcf;
    border-radius: 50px;
}

.chats::-webkit-scrollbar-thumb:hover {
    background: #8ac4d0;
}

.chats::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

.emoji-popup::-webkit-scrollbar-thumb {
    background-color: #222530;
}

.emoji-popup span {
    display: inline-block;
    margin-bottom: 9px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
}

.emoji-popup-content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
    justify-content: space-between;
}

.post-page .filter-card .feed-comments .spinner-border {
    width: 18px;
    height: 18px;
}

.chat-button-toggleafteropen {
    border-radius: 8px;
    width: 32px;
    height: 32px;
    background: #083248;
    color: #fff;
    padding: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media screen and (max-width: 400px) {
    .emoji-popup {
        position: absolute;
        right: -22px;
    }
}
