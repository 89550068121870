/* My Profile Tabs */
.user-action-list li {
  font-size: 18px;
  text-transform: capitalize;
  padding: 12px 30px;
  display: block;
  background: transparent;
  border: none;
  color: #ececec;
}
.user-info-card__list li input {
  margin-right: 5px;
  border: 1px solid #98a8db;
}
.value {
  position: relative;
}
.value i.fa {
  position: absolute;
  color: #fff;
  transform: translate(-50%, -50%);
  right: 1%;
  top: 50%;
  cursor: pointer;
}
/* .value.show-password i.fa:before{
  content: "\f06e";
} */
.value input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
.user-info-card__list input:disabled {
  border: none !important;
}
.user-action-list li.react-tabs__tab--selected {
  color: #083248;
  background: #e89c31;
  border-radius: 5px;
}

.profile-update {
  padding: 10px 35px;
  font-size: 14px;
  font-weight: 600;
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 50px;
  text-transform: uppercase;
  border: none;
}

.profile-update i {
  color: #fff;
}
input[type="date"] {
  position: relative;
}
input[type="date"]:before {
  display: none !important;
}
/* input[type="date"]::-webkit-calendar-picker-indicator {
    color:#a9a9a9 !important;
    background:#083248 !important;
    border-radius:50%;
} */
.user-action-list li.react-tabs__tab:focus:after {
  content: none;
}

.myprofile-card,
.transaction-balance-wrapper,
.all-transaction,
.referral-transaction,
.referral-link-wrapper,
.referral-overview,
.upcoming-draw-wrapper {
  /* background: #083248 !important;
  border: 1px solid #083248 !important;
  box-shadow: 0px 0px 27px 0px #032435 !important; */
  /* background: #041f2d !important; */
  background: #1b1c25 !important;
  border: 1px solid #131313 !important;
  box-shadow: 0px 0px 27px 0px #09212d00 !important;
}
.myprofile-card.user-card {
  padding: 30px;
}
.draw-single-ticket::after,
.draw-single-ticket::before {
  /* background: #083248 !important; */
  background: #041f2d !important;
}
.all-transaction table tbody,
.referral-transaction table tbody {
  /* background: #143b4f !important; */
  background: transparent !important;
}

.user-info-card {
  /* background: #083248 !important; */
  /* background: #041f2d !important; */
  background: #1b1c25 !important;
  border: 1px solid #131313 !important;
}

.avatar-upload {
  max-width: 100px !important;
  max-height: 120px;
}

.favourites-boxes {
  padding-top: 18px;
  border-radius: 10px;
}
.user-card__name {
  font-size: 28px;
  text-transform: uppercase;
  margin-top: 10px;
}

.transaction-balance-wrapper img {
  width: 41px;
}

.copy-link {
  border: 1px solid #292b39 !important;
  background-color: #20212be6 !important;
}

.transaction-balance-wrapper .transaction-balance span,
.transaction-balance-wrapper .transaction-action-btn span,
.referral-crad__content span {
  color: #e9e9e9 !important;
}
.transaction-balance-wrapper .transaction-balance,
.transaction-balance-wrapper .transaction-action-btn,
.copy-link .copy-link-icon {
  background: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
}
.referral-crad {
  padding: 30px;
  background-color: #143b4f !important;
}
.draw-single-ticket .ticket-numbers-list.active li {
  background-image: -webkit-linear-gradient(
    7deg,
    #e89c31 0%,
    #ab6b10 100%
  ) !important;
  cursor: pointer;
  margin: 5px;
}

.draw-single-ticket .circle-divider {
  padding: 10px;
}

.draw-ticket-slider .slick-arrow {
  color: transparent !important;
  background: -webkit-linear-gradient(
    7deg,
    #e89c31 0%,
    #ab6b10 100%
  ) !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "Font Awesome 5 Free" !important;
  font-weight: 900;
}
.draw-ticket-slider .slick-slide {
  padding: 10px;
}
.slick-prev:before {
  content: "\f053" !important;
  color: #fff;
}
.slick-next:before {
  content: "\f054" !important;
  color: #fff;
}
.global-date-picker .react-datepicker__navigation-icon {
  top: 8px;
}

@media (min-width: 575px) {
  .draw-ticket-slider .slick-prev {
    left: 80% !important;
    z-index: 2;
  }
}
@media (max-width: 575px) {
  .draw-ticket-slider .slick-arrow {
    top: 105% !important;
    bottom: 0;
  }
  .draw-ticket-slider .button.slick-arrow.slick-prev {
    left: 10px;
  }
}
.copy-link-inner input[type="text"] {
  padding-right: 160px;
  padding-left: 200px;
  height: 60px;
  color: #00cca2;
  font-size: 16px;
}
.clipboard-copy {
  position: absolute;
  top: 4px;
  right: 5px;
  width: auto;
  color: #ffffff;
  padding: 10px 35px;
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 999px;
  -webkit-border-radius: 999px;
}

/* Add Address Page */
.shipping-cards-section {
  padding: 10px 0;
}
.shipping-card {
  padding: 15px 10px;
  /* background: #0a405d; */
  margin: 10px 0;
  border-radius: 10px;

  background-color: #041f2d !important;
  border: 1px solid #052434;
  box-shadow: rgb(0 0 0 / 25%) -1px -4px 5px 0px,
    rgb(0 0 0 / 22%) 0px 7px 6px 0px;
}

.shipping-card-body input:disabled,
.shipping-card-body select:disabled {
  border: none !important;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 0.01px;
}
.shipping-card-body input {
  margin-bottom: 10px;
  padding: 5px 10px !important;
  color: #ffffff !important;
  text-indent: 0.01px;
  border: 1px solid #ffffff2b !important;
  border-radius: 8px;
}
.shipping-card-body select {
  margin-bottom: 5px;
  padding: 5px 9px;
  color: #ffffff;
  text-indent: 0.01px;
  border: 1px solid #1e5675;
  border-radius: 8px;
}
.shipping-card-body select:focus {
  outline: none;
}
.shipping-addresss input[type="radio"] {
  margin-right: 10px;
}
.shipping-addresss {
  display: flex;
  align-items: center;
}
.shipping-card-header {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  position: relative;
}
.shipping-card-header span {
  font-size: 22px;
  color: #fff;
  font-weight: 600;
}
.shipping-card-body {
  text-align: center;
}
.shipping-btn1 {
  border: none !important;
  background: transparent !important;
  box-shadow: none !important;
  padding-right: 0;
}
.shipping-btn2 {
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%) !important;
  border: none !important;
  border-radius: 30px;
  padding: 8px 22px;
  font-size: 14px;
  text-transform: uppercase;
  margin: 10px 0;
}
.shipping-btn2:hover,
.shipping-btn2:focus,
.profile-update:focus,
.profile-update:hover {
  background-image: -webkit-linear-gradient(
    7deg,
    #f3a334 0%,
    #c98525 100%
  ) !important;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%) !important;
}
.shipping-btn2:disabled {
  filter: contrast(0.5);
}

@media (min-width: 991px) {
  .ship-col-padding-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .ship-col-padding-left-0 {
    padding-left: 0 !important;
  }
}

@media (max-width: 991px) {
  ul.user-action-list {
    white-space: nowrap;
    overflow-y: auto;
  }
  .user-action-list li {
    display: inline-block;
    width: fit-content;
    margin: 5px;
    padding: 5px 16px;
    margin-bottom: 20px;
  }
}

/*************** style for inventory card ***************/
.inventory__card .inv_card {
  padding: 4px 5px;
}
.inventory__card .battle-card {
  background: #041f2d;
  border: 1px solid #052434;
  min-height: 250px;
  height: 100%;
  position: relative;
  box-shadow: 0px 3px 1px 0px rgba(2, 24, 36, 0.185);
}
.inventory__page .contest-card__thumb {
  background: #041f2d;
  height: 160px;
}
.inventory__page .contest-card__thumb img {
  max-height: 150px;
  width: auto;
}

.inventory__card .inventory_content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
  z-index: 3;
  padding-top: 25px;
  padding-left: 15px;
  padding-right: 15px;
}

.inventory__card .date span {
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.inventory__card span.dt {
  color: #e89d31;
  font-weight: 600;
}

.inventory__card .price span {
  color: #e89d31;
  font-size: 16px !important;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.inventory__card p.pro_name {
  padding: 0 15px;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: 1px;
  position: relative;
  z-index: 3;
}
.inventory__card .inv_btn {
  display: flex;
  justify-content: center;
  padding: 30px 15px;
}

.inventory__card button.cmn-btn {
  padding: 7px 25px;
  font-size: 15px;
  font-weight: 600;
  text-transform: uppercase;
  box-shadow: none;
  position: relative;
  z-index: 4;
}
/*************** style for inventory card ***************/

/*********** inventory popup ************/
.inventory_radio_btn {
  padding: 20px 30px;
}
.inventory_radio_btn .radio_name {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.inventory_radio_btn input.inv_radio {
  width: 5%;
}
.inventory_radio_btn .radio_name label {
  margin: 0;
  margin-left: 20px;
  font-size: 18px;
  text-transform: capitalize;
  letter-spacing: 1px;
}

/******* order details popup ***********/

.order-details-popup .order-details-content {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 20px;
}

.order-details-popup .order-details-content .left-section {
  width: 40%;
}

.order-details-popup .order-details-content .right-section {
  width: 60%;
}

.order-details-popup .order-details-content .right-section h5 {
  font-size: 25px;
  line-height: 35px;
  font-weight: 500;
}

.order-details-popup .order-details-content .right-section p {
  font-size: 18px;
  font-weight: 300;
}

.order-details-popup .order-details-content .right-section h6 {
  margin-top: 5px;
  font-size: 20px;
  font-weight: 500;
}

.order-details-popup .order-details-content .right-section h6 span {
  font-weight: 200;
  margin-left: 8px;
  color: #e58c0f;
  font-size: inherit;
}
.order-details-popup .order-details-content .right-section p span {
  margin-left: 10px;
}
.order-details-popup .modal-content {
  background: #072b3f !important;
  border-radius: 20px;
}

.order-details-popup .quantity-nav {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  justify-content: space-between;
  align-items: center;
  padding: 7px 10px;
  max-width: 170px;
  margin-top: 30px;
}

.order-details-popup .quantity-nav .quantity-button {
  width: 38px;
  height: 38px;
  background-color: #083248;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #ffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.order-details-popup .quantity-nav .quantity-button.quantity-up {
  background-color: #e59a30;
  color: #fff;
}
.order-details-popup .modal-header {
  padding: 20px 25px;
  border-bottom: 1px solid #292c35 !important;
}
.address-details-popup .modal-header {
  padding-bottom: 0;
  align-items: flex-start;
}
.address-details-popup .modal-header p {
  font-size: 16px;
}
/* .address-details-popup .close-img {
  position: absolute;
  right: 13px;
  top: 12px;
}
.address-details-popup .close-img img {
  width: 16px;
  height: 16px !important;
} */
.order-details-popup .close {
  display: none;
}
.order-details-popup .close-img img {
  height: 20px;
  cursor: pointer;
}
.order-details-popup .left-section img {
  width: 100%;
  max-width: 250px;
  height: 200px;
  object-fit: contain;
}
.order-details-popup .quantity h6 {
  margin-top: 10px !important;
}
.order-details-popup .modal-body {
  max-height: 500px;
  height: auto;
  overflow: auto;
  /* margin: 20px 0px !important; */
  margin: 0;
}
.order-details-popup .modal-body::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

.order-details-popup .modal-body::-webkit-scrollbar-track {
  background: #1a3645;
  border-radius: 20px;
}

.order-details-popup .modal-body::-webkit-scrollbar-thumb {
  background: rgb(6 30 44);
  border-radius: 20px;
}

.no-inventory {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 50px 0px;
}

.inventory-section .top-inventory-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 0px 20px;
  flex-wrap: wrap;
}

.top-inventory-list p {
  font-size: 15px;
}

.top-inventory-list p span {
  margin-left: 10px;
  font-size: 15px;
  color: #e89c31;
}

.top-inventory-list button {
  background: #e89c3100 !important;
  border: 1px solid #e89c31 !important;
  border-radius: 50px !important;
  padding: 5px 20px !important;
  color: #e89c31 !important;
}

.inventory-section .contest-search-form button {
  padding: 0px;
  background: transparent;
  position: absolute;
  right: 16px;
  top: 14px;
  font-weight: 300;
  color: #ffffff;
}

.inventory-section .battle-card input,
.inventory-section .battle-card input:focus {
  position: absolute;
  top: 10px;
  z-index: 99;
  height: 22px;
  width: 22px !important;
  right: 12px;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: 1px solid #d48c26;
  padding: 0px;
  min-height: 20px !important;
  border-radius: 4px !important;
}
.inventory-section .battle-card {
  position: relative;
}

.inventory-section input:checked::after {
  content: "";
  display: block;
  position: absolute;
  top: -1px;
  right: 6px;
  width: 7px;
  height: 15px;
  border: solid #dc922a;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.inventory-section .inv_btn {
  justify-content: center;
  padding: 12px 10px;
}

.inventory-section .inv_btn button {
  width: 48%;
  margin-bottom: 0px;
  padding: 5px 5px !important;
  font-size: 11px;
  font-weight: 300;
  margin-right: 10px;
}

.inventory-section .inv_btn button:last-child {
  background: transparent;
  border: 1px solid #db912a;
  color: #e0962d;
  margin-right: 0;
}
.inventory-section .contest-filter-wrapper,
.inventory-section .filter-tabs-section {
  padding: 0px !important;
  margin: 0px !important;
}
.inventory-section .inventory__card .battle-card:before {
  display: none;
}
.inventory-section .inventory__card .inventory_content {
  flex-wrap: wrap;
  flex-direction: column;
  min-height: 100px;
  align-items: center;
  justify-content: flex-start;
  padding-top: 15px;
}

.inventory-section .inventory__card .inventory_content p {
  padding: 0px;
  text-align: center;
  font-size: 13px;
  color: lightgrey;
}
.inventory-section .inventory__card .inventory_content p.brand_name {
  font-size: 20px !important;
  font-weight: 600;
  color: white;
}
.inventory-section .load-more {
  border-top: 1px dashed #072b3f !important;
  margin-top: 20px;
}
.inventory-section .inventory__card .battle-card {
  background: linear-gradient(rgb(8, 43, 61) 0%, rgba(128, 38, 40, 0.35) 100%);
  border-bottom-color: rgba(128, 38, 40, 0.565);
  border-radius: 8px;
}
.inventory-section .load-more button {
  padding: 15px;
}
.inventory-section .inv_btn.inv-no-sell {
  justify-content: center;
}
.inventory-section .inventory__card .battle-card label.radio-card {
  width: 100%;
  margin-bottom: 0px;
}
.inventory-section .battle-card.selected-inventroy {
  /* border: 1px solid #af6e12 !important; */
  border: 1px solid #c4c2bf !important;
}
.inventory-section .select-all input {
  height: 22px;
  min-height: 15px;
  width: 22px !important;
  position: relative;
  appearance: none;
  border: 1px solid #e89c31;
  width: 100%;
  padding: 0;
  border-radius: 5px;
  background: transparent;
}
.inventory-section .select-all input:focus {
  box-shadow: unset;
}
.inventory-section .form-check-input:checked[type="checkbox"] {
  background-image: unset;
}

.inventory-section .form-check {
  display: flex;
  align-items: center;
}

.inventory-section .form-check label {
  margin-left: 14px;
  font-size: 20px;
  margin-top: 5px;
  color: white;
  margin-bottom: 0;
}

.inventory-section .select-all {
  padding: 0px 20px;
}
.user-profile-section .inventory-section .contest-card__thumb {
  background-color: transparent !important;
  padding-bottom: 10px;
}
.user-profile-section .inventory-section .contest-card__thumb img {
  width: 100%;
  height: 135px;
  object-fit: contain;
}

.cancel-btn {
  background: #1a3645 !important;
  padding: 15px 35px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 30px;
  transition: background-size 0.3s;
  color: #ffffff;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
}
.cancel-btn:hover,
.cancel-btn:focus,
.cancel-btn:active {
  opacity: 0.9;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  outline: none;
  border: none;
}
.account-form-area form .form-group + .form-group {
  position: relative;
}
.eye-icon {
  position: absolute;
  margin-top: -5px;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
}
.account-form-wrapper .eye-icon {
  position: absolute;
  top: 0 !important;
  right: 5px;
  transform: translateY(0) !important;
}

.fairness-info-popup .modal_header {
  border-radius: 20px 20px 0 0;
}
.fairness-info-popup .account-form-area {
  border-radius: 0 0 20px 20px !important;
}
.inventory-section .inv_btn button span {
  font-size: 11px !important;
  padding: 5px 0px !important;
}
.inventroy-policy {
  margin-top: 25px;
}

.inventroy-policy input {
  height: 25px !important;
  width: 25px !important;
  padding: 0px !important;
  position: relative !important;
}
.inventroy-policy .form-check-input[type="checkbox"] {
  min-height: 10px;
  max-height: 20px !important;
  width: 20px !important;
  height: 20px !important;
  padding: 10px !important;
  min-height: unset;
}
.inventroy-policy .form-check-input[type="checkbox"]:focus,
.inventroy-policy .form-check-input[type="checkbox"]:hover,
.inventroy-policy .form-check-input[type="checkbox"]:focus-within {
  box-shadow: none !important;
  outline: 0;
}

.inventroy-policy label.form-check-label {
  padding-left: 20px;
}

.inventroy-policy .form-check {
  display: flex;
  align-items: flex-start;
}
.address-list select * {
  background: #072b3f !important;
  padding: 10px !important;
  /* font-size: 20px !important; */
}
h6.info-section {
  text-align: left;
  padding: 12px 47px;
  color: #e6930a;
  font-size: 20px !important;
  text-decoration: underline;
}

h6.info-section span {
  font-weight: 400;
  font-size: 18px;
  margin-left: 10px;
}
/* *************************************************************** */
/* ********************** Media Query **************************** */
/* *************************************************************** */
.shipping-card-body p {
  text-align: left;
  margin-bottom: 20px;
  position: absolute;
}
@media (max-width: 991px) {
  .shipping-card-body p {
    text-align: left;
    margin-bottom: 20px;
    position: absolute;
  }
  .order-details-popup .order-details-content .right-section p {
    font-size: 16px;
  }
  .order-details-popup .order-details-content .right-section h5 {
    font-size: 23px;
  }
  .order-details-popup .order-details-content .right-section h6 {
    font-size: 18px;
  }
  .order-details-popup .left-section img {
    width: 100%;
    max-width: 150px;
  }
}
@media (max-width: 767px) {
  .user-card__name {
    font-size: 24px;
    text-transform: uppercase;
    margin-top: 20px;
  }
  .user-action-list li {
    font-size: 16px;
  }
  .user-action-card {
    margin-top: 0;
  }
  .shipping-card-header span {
    font-size: 18px;
  }
  .order-details-popup .order-details-content .right-section p {
    font-size: 14px;
  }
  .order-details-popup .order-details-content .right-section h5 {
    font-size: 20px;
  }
  .order-details-popup .order-details-content .right-section h6 {
    font-size: 14px;
    margin: 0 !important;
  }
  .order-details-popup .left-section img {
    width: 100%;
    max-width: 100px;
    height: 120px;
  }
  .order-details-popup .order-details-content .right-section p span {
    font-size: inherit;
  }
  .order-details-popup .modal-content {
    border-radius: 12px;
  }
  .myprofile-card.user-card {
    padding: 15px;
  }
}
