/***************************************************** Media Query *********************************************/
.scroll-btn {
  position: fixed;
  width: 36px;
  cursor: pointer;
  height: 36px;
  bottom: 36px;
  border-radius: 30px;
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
  right: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.scroll-btn i {
  font-size: 24px;
  color: white;
}

@media screen and (min-width: 1440px) {
  .roulette-placeholder::before {
    background-size: 100%;
  }

  .roulette-placeholder-box img {
    height: 436px;
    width: 436px;
    max-height: 501px;
    max-width: 501px;
    margin: 0 auto;
  }
}

@media screen and (max-width: 1440px) {
  .game-page .roulette-placeholder-box {
    margin-left: 0px;
  }

  .roulette-placeholder-box img {
    margin-left: 0px;
  }

  .placeholder-section {
    height: 400px;
    width: 390px;
    background-size: 100%;
    background-position: center;
  }

  .roulette-placeholder-box img {
    height: 450px;
    width: 445px;
  }

  /* .update-home-page:before{
  max-width: 1300px;
  max-height: 800px;
  right: -27%;
  top: -6%;
} */
}

@media screen and (max-width: 1366px) {
  .roulette-placeholder-box img {
    /* margin-left: 10px; */
  }

  .placeholder-section {
    height: 402px;
    width: 395px;
  }

  .placeholder-section::before {
    height: 574px;
    width: 599px;
  }

  /* .placeholder-section {
    background-size: 80%;
  } */
  .placeholder-section {
    margin-top: 0;
  }

  .game-page .roulette-placeholder-box {
    margin: 0;
  }

  /* .roulette-placeholder-box img {
    max-width: 372px;
    max-height: 280px;
  } */
  .game-page .container {
    max-width: 1098px;
    padding: 0;
  }

  .roulette_section:before {
    /* background: url(./assets/images/game/game-bg-1366.png); */
    background: url(./assets/images/game/single-updated-game-bg-1366.png);
    width: 1366px;
    height: 592px;
  }

  .roulette_section {
    min-height: 290px;
  }

  .roulette_wrapper {
    height: 190px;
  }

  .roulette_container .game-bet-box {
    width: 156px;
    height: 190px;
  }

  .roulette-placeholder {
    width: 600px;
    height: 496px;
    /* margin-top: -420px; */
  }

  .roulette_section.roulette-bg-horizontal:before {
    /* background: url(./assets/images/game/game-bg-horizontal.png); */
    background: url(./assets/images/game/updated-game-bg-1366.png);
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--top {
    top: 51%;
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--bottom {
    top: 51%;
  }

  .contest-card__price {
    font-size: 24px;
  }

  .battles-page .date-price {
    max-width: 30%;
  }

  /* .copy-right-text.text-lg-left {
    font-size: 16px;
  } */

  .inventory__card .inv_card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .ticket-wrapper__body .single-row .action-btns {
    padding: 20px 0 0;
  }

  .inventory-section .contest-filter-wrapper .col-lg-3.col-sm-6.mb-30 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  /* .update-home-page:before {
    max-width: 1300px;
    max-height: 800px;
    right: -28%;
    top: -7%;
} */
}
@media screen and (max-width: 1200px) {
  .battle-slider-section {
    max-width: 560px !important;
  }
}

@media screen and (max-width: 767px) {
  .battle-slider-section {
    max-width: 200px !important;
  }
}

@media screen and (max-width: 1024px) {
  .placeholder-section::before {
    height: 511px;
    width: 560px;
  }

  /* .roulette-placeholder::before {
    background-size: 60%;
  } */
  .game-page {
    padding: 200px 0px 100px;
  }

  .game-page .container {
    max-width: 818px;
  }

  .roulette_section:before {
    /* background: url(./assets/images/game/game-bg-1024.png); */
    background: url(./assets/images/game/single-updated-game-bg-1020.png);
    width: 1020px;
    height: 442px;
  }

  .roulette_section {
    min-height: 220px;
  }

  .roulette_container {
    padding: 0px 0px;
  }

  .roulette-indicator {
    height: 20px;
  }

  .roulette-indicator--bottom {
    bottom: -5px;
  }

  .roulette_container .game-bet-box:before {
    width: 50px;
    height: 50px;
  }

  .roulette_wrapper {
    height: 160px;
  }

  .roulette-wrapper img {
    max-height: 76px;
  }

  .roulette-container-shadow--left,
  .roulette-container-shadow--right {
    height: 100%;
    width: 150px;
  }

  .item-info h5 {
    font-size: 14px;
  }

  .roulette_container .game-bet-box {
    width: 119px;
    height: 160px;
    padding: 5px;
  }

  .roulette_container .game-bet-box.dark-box:nth-of-type(even) {
    border-bottom: 2px solid #104a68;
  }

  .roulette_container .game-bet-box.dark-box:nth-of-type(odd) {
    border-bottom: 2px solid #083248;
  }

  .roulette-container-shadow--left {
    left: -1px;
  }

  .roulette-container-shadow--right {
    right: -1px;
  }

  /* .roulette-wrapper:before {
    display: none;
  } */

  .roulette-placeholder {
    width: 500px;
    height: 413px;
    margin-top: -285px;
  }

  .roulette-placeholder-box img {
    max-width: 420px;
    max-height: 400px;
  }

  .win-grid-bix img {
    max-width: 200px;
    max-height: 160px;
  }

  .win-grid-bix {
    width: 500px;
    height: 413px;
  }

  .win-btn .btn,
  .win-btn .btn:hover,
  .win-btn .btn:focus,
  .win-btn .btn-primary:not(:disabled):not(.disabled):active:focus {
    min-width: 160px;
    height: 45px;
    font-size: 14px;
  }

  .win-grid h3 {
    padding-bottom: 20px;
    font-size: 26px;
  }

  .contest-description .col-lg-9,
  .contest-description .col-lg-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contest-details-content .contest-name {
    font-size: 28px !important;
  }

  .contest-details-content p {
    font-size: 14px;
  }

  .contest-details-content .contest-details-meta span {
    color: #ec1379;
  }

  .contest-details-content .contest-details-meta span {
    font-size: 14px;
  }

  .contest-details-meta .col-md-9,
  .contest-details-meta .col-md-3 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contest-details-meta .col-md-3 .text-right {
    text-align: left !important;
  }

  .top-item-box {
    margin-top: 60px;
  }

  .top-item-box .col-6 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .roulette_section.roulette-bg-horizontal:before {
    /* background: url(./assets/images/game/game-bg-horizontal-1024.png); */
    background: url(./assets/images/game/updated-game-bg-1020.png);
    background-size: 75% !important;
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--top {
    top: 54%;
    left: -18px;
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--bottom {
    top: 54%;
    right: -18px;
  }

  .battle-slider-section {
    max-width: 333px !important;
  }

  .inventory-section .contest-filter-wrapper .col-lg-3.col-sm-6.mb-30 {
    margin-bottom: 10px;
  }

  .roulette-wrapper:before {
    background-size: 80%;
    background-position: center;
    background-repeat: no-repeat;
  }

  /* .update-home-page:before{
    max-width: 1024px;
    max-height: 800px;
    right: -31%;
    top: -3%;
  } */
  /************************** FAQ Section *****************************/
  /* .faq-page-topsection {
    height: 85vh;
  } */

  /* .faq-page-title h2 {
    font-size: 90px;
  } */

  /* .faq-page-title p {
    font-size: 32px;
  } */

  /************************** Contact us Section *****************************/
  .contactus-form-left {
    width: 100%;
  }

  .contactus-form-right {
    width: 100%;
  }

  .contactus-right-wrapper {
    flex-direction: row;
    gap: unset;
    justify-content: space-between;
    width: 100%;
  }

  .contactus-details {
    flex-direction: column;
    gap: 16px;
    justify-content: space-around;
  }

  /************************** Hoe to play Section *****************************/
  .how-to-play-title h2 {
    font-size: 80px;
  }

  .play-video-wrapper {
    min-height: 250px;
  }

  .howtoplay-video,
  .howtoplay-video-hide {
    top: -64px;
  }

  .play-content-title {
    margin: 60px 0 40px 0;
  }

  .play-content-title h3 {
    font-size: 42px;
  }

  .howtoplay-card-wrapper {
    gap: 40px;
  }

  .howtoplay-card-left {
    width: 37%;
  }

  .howtoplay-card-right {
    width: 60%;
  }

  .howtoplay-card-title {
    padding: 10px 0;
  }

  .howtoplay-card-title h4 {
    font-size: 20px;
  }

  .howtoplay-card-desc p {
    font-size: 16px;
  }

  /****************************** Privacy page ****************************/
  .terms-page strong,
  .privacy-page strong {
    font-size: 18px;
  }
}

@media (max-width: 991px) {
  .inventory__card .inv_card {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  /* .update-home-page:before {
    max-width: 630px;
    max-height: 400px;
    right: -17%;
    top: -1%;
} */
  .contest-cart__right {
    padding: 20px;
  }

  /************************** FAQ Section *****************************/
  /* .faq-page-topsection {
    height: 50vh;
  } */

  .faq-page-icon img {
    max-width: 300px;
  }

  /************************** Hoe to play Section *****************************/
  .how-to-play-title h2 {
    font-size: 62px;
  }

  .how-to-play-title img {
    max-width: 260px;
    width: 100%;
  }

  .how-to-play-title {
    padding: 60px 0;
    gap: 0;
  }
}

@media screen and (max-width: 768px) {
  /* .roulette_section.roulette-bg-horizontal {
    background: #041f2d;
  } */
  .game-page .container {
    max-width: 740px;
  }

  .two-roulette .roulette {
    top: -60px;
  }

  .roulette-wrapper:before {
    background-size: 70%;
    transform: rotate(90deg);
  }

  .roulette_section:before {
    /* background: url(./assets/images/game/game-bg-768.png); */
    background: url(./assets/images/game/single-updated-game-bg-900.png);
    width: 768px;
    height: 400px;
  }

  .roulette_container .game-bet-box {
    width: 105px;
    height: 150px;
  }

  .roulette_section {
    min-height: 203px;
    padding: 15px 10px;
  }

  .roulette_wrapper {
    height: 145px;
  }

  .roulette-placeholder-box img {
    max-width: 430px;
    max-height: 450px;
    /* margin-left: 10px; */
  }

  .roulette-placeholder {
    width: 100%;
    height: 370px;
    margin-top: -250px;
  }

  .placeholder-section::before {
    height: 574px;
    width: 599px;
  }

  .win-grid-bix {
    width: 450px;
    height: 370px;
  }

  .win-grid-bix img {
    max-width: 170px;
    max-height: 150px;
  }

  .roulette_section.roulette-bg-horizontal:before {
    /* background: url(./assets/images/game/game-bg-horizontal-768.png); */
    background: url(./assets/images/game/updated-game-bg-900.png);
    background-size: 56% !important;
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--top {
    top: 54%;
    left: -18px;
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--bottom {
    top: 54%;
    right: -18px;
  }

  /******* checkout page ************/
  .checkout-area .col-lg-5,
  .checkout-area .col-lg-7 {
    padding: 0 !important;
  }

  .contest-card__footer {
    padding: 10px 30px 20px;
  }

  .contest-card__price {
    font-size: 20px;
  }

  .contest-card__content .right p {
    font-size: 16px;
  }

  .contest-card__thumb {
    height: 220px;
  }

  .contest-card__thumb .contest-num {
    width: 60px;
    height: 60px;
    /* left: 15%; */
    bottom: -30px;
  }

  .contest-cart__right .quantity {
    height: 50px;
  }

  /* *****Battles Page ******** */
  .battles-page .filter-header {
    padding: 15px 0 22px;
  }

  .battles-page .date-price {
    max-width: 40%;
  }

  .battles-page {
    padding: 100px 0 50px;
  }

  img.fly-img {
    left: 0;
  }

  .cmn-btn {
    padding: 13px 25px;
  }

  .inventory__card .inv_card {
    flex: 0 0 50%;
    max-width: 50%;
  }

  /* .update-home-page:before{
    right: -22%;
    max-width: 630px;
    max-height: 400px;
  } */
  /************************** FAQ Section *****************************/
  /* .faq-page-topsection {
    height: 65vh;
  } */
  /* .faq-page-title h2 {
    font-size: 80px;
  } */

  /* .faq-page-title p {
    font-size: 26px;
    line-height: 40px;
  } */

  .faq-page-icon img {
    max-width: 250px;
  }

  .faq-page-icon {
    padding: 30px 0;
  }

  .scroll-btn {
    width: 30px;
    height: 30px;
    border-radius: 100%;
  }

  .scroll-btn i {
    font-size: 20px;
  }

  .referral-crad {
    padding: 15px;
  }

  .referral-link-wrapper {
    padding: 15px;
  }
}

@media screen and (max-width: 767px) {
  .hero.style--two .hero__content p {
    font-size: 16px;
    line-height: 22px;
  }

  .roulette_section.roulette-bg-horizontal {
    margin-top: 70px;
  }

  .roulette_section.roulette-bg-horizontal .roulette_container:before,
  .roulette_section.roulette-bg-horizontal .roulette_container:after {
    content: unset;
  }

  .roulette_section.roulette-bg-horizontal:before {
    background: url(./assets/images/game/updated-game-bg-600.png);
    width: 100%;
    height: 500px;
    background-size: 100% !important;
  }

  .contest-details-grid {
    padding: 10px 15px;
  }

  .contest-details-grid .col-md-3 {
    width: 40%;
    padding-bottom: 30px;
  }

  .item-box-col .col-md-6 {
    width: 50%;
  }

  .top-item-box .col-6 {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }

  .contest-sidebar__cart .icon-item-thumb img {
    max-height: 100px;
  }

  .game-page .roulette-wrapper .container {
    max-width: 100%;
    padding: 0;
  }

  .roulette_container .game-bet-box {
    width: 153px;
    height: 200px;
  }

  .roulette-placeholder {
    margin-left: 0;
    margin-top: -230px;
  }

  .roulette_section:before {
    background: url(./assets/images/bg/bg-mbl.png);
    width: 100%;
    height: 500px;
  }

  .roulette_section {
    position: relative;
  }

  .roulette_container:before,
  .roulette_container:after {
    position: absolute;
    width: 2px;
    height: 250px;
    background: #e79c31;
    content: "";
    top: 50%;
    transform: translate(-50%, -50%);
    bottom: 0;
    z-index: 9999;
  }

  .roulette_container:before {
    left: 3px;
  }

  .roulette_container:after {
    right: 0px;
  }

  .roulette_section {
    padding: 0;
    margin-top: 45px;
  }

  .roulette_wrapper {
    height: 200px;
  }

  /* .roulette_section{
    border: 10px solid #083045;
  }
  */
  .roulette-indicator--bottom,
  .roulette-indicator svg {
    display: none;
  }

  .roulette-indicator--top {
    background: url(./assets/images/game/indicator.png);
    width: 30px;
    height: 42px;
    display: block;
    background-size: contain;
    transform: translate(-50%, -50%) rotate(180deg);
    background-repeat: no-repeat;
    top: 0px;
  }

  .two-roulette .roulette_wrapper,
  .three-roulette .roulette_wrapper,
  .four-roulette .roulette_wrapper {
    border: none;
  }

  /* .two-roulette,
  .three-roulette,
  .four-roulette {
    border-top: 8px solid #083248;
    border-bottom: 8px solid #083248;
  } */
  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--top {
    top: 50%;
    left: -8px;
    transform: translateY(-50%) rotate(90deg);
  }

  .roulette-bg-horizontal .roulette-indicator.roulette-indicator--bottom {
    top: 50%;
    right: -8px;
    transform: translateY(-50%) rotate(-90deg);
    background: url(./assets/images/game/indicator.png);
    width: 30px;
    height: 42px;
    display: block;
    background-size: contain;
    background-repeat: no-repeat;
  }

  .roulette-bg-horizontal .roulette_wrapper {
    height: 220px;
  }

  .game-page .spin-button .btn {
    font-size: 22px;
    max-width: 90%;
    width: 100%;
  }

  .game-page .spin-button {
    padding: 0px 15px;
  }

  /******* responsive for game popup-2 and 3 ******/
  .game-page .win_grid_container h2 {
    padding-top: 60px;
    font-size: 27px;
  }

  .game-page .win_grid_container {
    padding-bottom: 0;
  }

  .game-page .win-grid-container-two .box__containers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .game-page .win-grid-container-two .win-grid-bix {
    width: 250px;
    height: 205px;
  }

  .game-page .win-grid-container-two .win-grid-bix img {
    max-width: 120px;
  }

  .game-page .win-grid-container-two .win__btn {
    padding: 20px 5px;
  }

  .game-page .win-grid-container-three {
    max-width: 370px;
    width: 100%;
  }

  .game-page .win-grid-container-three .win__btn {
    padding-bottom: 30px;
  }

  .game-page .win-grid-container-three .win-grid-bix {
    width: 150px;
    height: 110px;
  }

  .game-page .win-grid-container-three .win-grid-bix img {
    max-width: 70px;
  }

  .game-page .win-grid-container-three .box__name h3 {
    padding-bottom: 0;
    font-size: 17px;
    font-weight: 500;
    margin-top: 15px;
    margin-right: 10px;
  }

  .game-page .win-grid-container-three .img_name_btn .quick_sells {
    height: 55px;
    min-width: 108px;
    font-size: 13px;
  }

  .quick-sell span {
    line-height: 1.3;
    font-size: 13px;
  }

  .fairness-link .check {
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .game-page .roulette-placeholder-box {
    margin-left: 0px;
  }

  .placeholder-section {
    background-repeat: no-repeat;
    margin-bottom: 0px;
    position: relative;
    height: 445px;
    width: 408px;
    background-size: 85%;
    background-position: center;
  }

  .placeholder-section::before {
    content: "";
    position: absolute;
    height: 470px;
    width: 450px;
    z-index: -1;
    top: 50%;
    background-size: cover;
    background-position: center;
    /* opacity: 0; */
  }

  .game-page .stripe-details {
    flex-wrap: wrap;
  }

  .hot-keys-section {
    max-width: 300px;
    margin: 0px auto;
    min-height: 65px;
    display: none;
  }

  .case-quantity .game-type {
    min-height: 65px;
    padding: 8px;
  }

  .case-quantity {
    margin: 0px auto;
  }

  .fairness-link {
    font-size: 14px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .fairness-link a i {
    width: 25px;
    height: 25px;
    text-align: center;
    line-height: 25px;
  }

  .fairness-link .fa-box {
    font-size: 12px;
  }

  .last-live-drops-section {
    padding: 20px 0;
  }

  .faq-page {
    padding: 120px 0px 40px 0px;
  }

  .faq-page-title h2 {
    font-size: 56px;
  }

  .faq-section .accordion__button {
    padding: 15px 10px;
  }

  .ticket-wrapper__body .cart-items .item-img {
    padding-bottom: 10px;
  }

  .item-quantity-btn {
    margin: 0;
  }

  .cart-page {
    padding: 120px 0px 100px;
  }

  .cart-wrapper {
    padding-top: 40px;
  }

  /* .update-home-page:before {
    max-width: 500px;
    max-height: 739px;
    top: 0%;
  } */
  .reset-password-form h1 {
    font-size: 24px;
    padding-bottom: 24px;
  }

  .reset-password-box {
    padding: 20px 10px;
  }

  /******************* Checkout page ******************/
  .checkout-page {
    padding-bottom: 0px;
  }

  .game-page {
    padding: 200px 0px 50px;
  }

  .win-close-icon {
    right: 0;
  }

  .win-close-icon img {
    width: 20px;
  }

  /************************** Contact us Section *****************************/
  .contactus-wrapper {
    padding-top: 10px;
    padding-bottom: 0px;
  }

  .contact-page-title h2 {
    font-size: 36px;
  }

  .contactus-form-title h3 {
    font-size: 28px;
  }

  .contactus-form-body {
    padding: 0px;
    border-radius: 20px;
  }

  .contactus-form-title {
    margin-bottom: 20px;
  }

  .contactus-form .mb-3.form-group {
    margin-bottom: 15px !important;
  }

  .contactus-form label.form-label {
    margin-bottom: 10px;
  }

  .contactus-details {
    flex-direction: column;
    gap: 10px;
    justify-content: unset;
  }

  .contactus-info {
    gap: 10px;
  }

  .contactus-info img {
    max-width: 24px;
  }

  .contactus-info span {
    font-size: 14px;
  }

  /************************** how-to-play Section *****************************/

  .how-to-play-title h2 {
    font-size: 52px;
  }

  .play-video-wrapper {
    min-height: 220px;
  }

  .play-content-title {
    margin: 0 0 40px 0;
  }

  .howtoplay-card-left,
  .howtoplay-card-right {
    width: 100%;
  }

  .howtoplay-video,
  .howtoplay-video-hide,
  .howtoplay-video-hide::before,
  .howtoplay-video video,
  .howtoplay-video-hide video {
    border-radius: 15px;
  }

  .howtoplay-video-btn img {
    max-width: 60px;
  }

  .howtoplay-video-hide::before {
    height: 96%;
  }

  /************************* Privacy page *******************************/
  .terms-page,
  .privacy-page {
    padding: 150px 0 0;
  }

  .terms-page p,
  .privacy-page p {
    font-size: 14px;
  }

  .terms-page strong,
  .privacy-page strong {
    font-size: 16px;
  }
}

/******* responsive for game popup-2 and 3 ******/

@media screen and (max-width: 480px) {
  .roulette_section.roulette-bg-horizontal,
  .roulette_section {
    margin-top: 0;
  }

  .roulette_section:before {
    height: 350px;
  }

  .roulette_container:before,
  .roulette_container:after {
    height: 175px;
  }

  .roulette_wrapper {
    height: 145px;
  }

  .roulette_container .game-bet-box {
    height: 145px;
    width: 160px;
  }

  .roulette-placeholder-box img {
    /* margin-left: 20px; */
  }

  .cmn-btn {
    padding: 10px 25px;
  }

  .roulette-wrapper:before {
    background-size: 50%;
  }

  .roulette-placeholder::before {
    background-size: 50%;
    transform: rotate(90deg);
    margin-left: 0px;
    margin-top: 75px;
  }

  .win-grid-container {
    max-width: 98%;
  }

  .contest-details-grid .col-md-3 {
    width: 80%;
    margin: auto;
    padding: 0 20px 10px;
  }

  .top-item-box .col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .game-page .contest-card__thumb {
    height: 142px !important;
    padding: 10px 15px;
  }

  .contest-sidebar__cart {
    padding: 0 !important;
    background: transparent !important;
  }

  .roulette-container-shadow--left,
  .roulette-container-shadow--right {
    height: 100%;
    width: 120px;
  }

  .roulette-placeholder {
    /* width: 350px;
      height: 290px;
      margin-top: -260px; */
    width: 100%;
    max-width: 100%;
    height: 375px;
    /* margin-top: -310px; */
  }

  .two-roulette .roulette_wrapper,
  .three-roulette .roulette_wrapper,
  .four-roulette .roulette_wrapper {
    width: 100px;
  }

  .spin-horiz {
    flex-wrap: wrap;
  }

  .roulette-placeholder-box img {
    max-width: 428px;
    max-height: 365px;
    width: 100%;
    object-fit: contain;
  }

  .roulette-placeholder-box img.light-glow {
    max-width: 250px;
  }

  .roulette-bg-horizontal .roulette_wrapper {
    height: 170px;
  }

  .roulette_section.roulette-bg-horizontal .roulette {
    top: -40px;
  }

  .animate-inventory.toggle-cart img {
    animation: flyinventory 0.5s linear;
    /* animation-fill-mode: forwards; */
    opacity: 0;
  }

  @keyframes flyinventory {
    0% {
      transform: translate3d(0px, 0px, 16px) scale(1);
      opacity: 1;
    }

    50% {
      transform: translate3d(20px, -280px, 16px) scale(0.5);
      opacity: 1;
    }

    100% {
      transform: translate3d(52px, -499px, 16px) scale(0.2);
      opacity: 1;
    }
  }

  .unboxing-hero-section {
    padding: 120px 0 !important;
  }

  .unboxing-page .icon-item2__icon img,
  .recent-item:hover .icon-item-thumb img.final-img,
  .recent-item:hover .icon-item-thumb-img img.final-img {
    height: 120px;
    width: 120px;
  }

  /* .icon-item2__icon img {
    height: auto;
    width: 40px !important;
  } */

  .contest-sidebar__cart .icon-item-thumb img {
    height: 120px;
    width: 120px;
  }

  .contest-card__content {
    padding: 30px 20px 10px;
  }

  .unboxing-page .contest-card__footer {
    padding: 10px 30px;
  }

  .game-page .item-card__content {
    padding: 10px 15px;
  }

  .game-page .spin-button .btn {
    font-size: 18px;
  }

  .game-page h5.contest-card__name,
  .item-price {
    font-size: 16px;
  }

  .item-price {
    margin-top: 10px;
  }

  .contest-sidebar__cart .recent-item {
    min-height: 152px;
    justify-content: space-around;
  }

  .header__top .right .language {
    margin-top: 5px;
  }

  .header__top .right .amount__btn {
    margin-left: 28px;
  }
  .loggedin {
    margin-left: 0px;
  }

  .unboxing-page .max-option .col-lg-6.col-sm-6 {
    width: 50%;
    padding-right: 0;
    padding-left: 15px;
  }

  .max-option input[type="number"] {
    width: 100%;
  }

  .product-details-page .contest-cart {
    padding: 20px;
  }

  .contest-cart__right {
    margin-bottom: 40px;
  }

  .contest-cart__right .contest-name {
    font-size: 22px;
    margin-bottom: 15px;
  }

  .contest-cart__right p {
    font-size: 16px;
    line-height: 1.2;
  }

  .contest-cart__right .contest-num,
  .contest-cart__right .ticket-price {
    margin: 10px 0;
  }

  .contest-cart__right .ticket-price .amount {
    font-size: 25px;
  }

  .contest-cart__right .caption {
    padding: 0;
    margin-bottom: 10px;
    margin-right: 0;
  }

  .no-product h5 {
    max-width: 300px;
    width: 100%;
    margin: 0px auto;
  }

  .contest-cart__right .quantity {
    height: 41px;
    width: 150px;
  }

  .product-details-page .contest-cart__right .quantity-nav .quantity-button {
    width: 26px;
    height: 26px;
    font-size: 20px;
  }

  .product-details-page .contest-cart__right .quantity input {
    border: 0 !important;
    text-align: left;
    padding-left: 70px;
    font-size: 20px;
    height: 40px;
    width: 150px;
  }

  /* .unboxing-page .filter-tablist {
    padding: 10px 0;
  } */

  .cmn-btn {
    width: fit-content;
  }

  .battles-page .date-price {
    max-width: 100%;
  }

  .inventory__card .inv_card {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .inventory-section .contest-filter-wrapper .col-lg-3.col-sm-6.mb-30 {
    flex: 0 0 50%;
    max-width: 50%;
    margin-bottom: 10px;
  }

  .inventory-section
    .contest-filter-wrapper
    .col-lg-3.col-sm-6.mb-30:last-child {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .inventory-section .top-inventory-list {
    display: grid;
    grid-template-columns: 48% 48%;
    grid-gap: 10px;
  }

  .top-inventory-list p {
    display: flex;
    line-height: 1;
    align-items: center;
    padding: 5px 10px;
  }

  .top-inventory-list button {
    padding: 5px !important;
  }

  .thanks-popup .modal-body h2 {
    font-size: 28px;
  }

  .thanks-popup .modal-body h4 {
    font-size: 18px;
  }

  .top-inventory-list button {
    padding: 5px !important;
  }

  .roulette-wrapper:before {
    margin-top: -175px;
  }

  .registration-popup .account-form-area {
    padding: 20px 10px;
    padding-bottom: 10px;
  }

  .registration-popup .account-form-area .title {
    font-size: 22px;
    max-width: 335px;
    margin: 0px auto;
    margin-bottom: 20px;
  }

  .registration-popup .account-form-area .close-btn {
    top: 12px;
    right: 10px;
    width: 20px;
    height: 20px;
    font-size: 12px;
  }

  .registration-popup .account-form-area .account-form-wrapper {
    padding: 15px;
  }

  .registration-popup .account-form-area .account-form-area .divider {
    margin-top: 0px;
  }

  .registration-popup .account-form-area .divider span {
    font-size: 14px;
  }

  .registration-popup .account-form-area .social-link-list {
    margin-top: 5px;
  }

  .account-form-area .social-link-list li a,
  .account-form-area button .my-facebook-button-class.metro,
  .account-form-wrapper ul.social-link-list button,
  .account-form-area button {
    width: 40px;
    height: 40px;
    font-size: 16px;
  }

  .fairnessBtn button {
    width: fit-content;
  }

  .fairness-info-popup .verify-form {
    margin: 10px;
  }

  .send-otp {
    column-gap: 20px;
  }

  .privacy-policy .form-check-input[type="checkbox"] {
    width: 15px;
    height: 15px;
  }

  .privacy-policy .account-form-area form label {
    font-size: 14px;
  }

  /************************** FAQ Section *****************************/
  /* .faq-page-topsection {
    height: 40vh;
  } */

  .faq-page-title p {
    font-size: 20px;
    line-height: 28px;
  }

  .faq-page-icon img {
    max-width: 150px;
  }

  .faq-page-icon {
    padding: 25px 0;
  }

  .faq-section .accordion__button {
    font-size: 18px;
  }

  /************************** Contact us Section *****************************/
  .contactus-right-wrapper {
    flex-direction: column-reverse;
    gap: 15px;
  }

  .contactus-details {
    /* flex-direction: row;
    justify-content: space-between; */
    gap: 12px;
    width: 100%;
  }

  .contactus-btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .contactus-btn button.btn-style.btn.btn-primary {
    max-width: unset;
    width: 100%;
    color: #fff;
  }

  /************************** Hoe to play Section *****************************/
  .how-to-play-title h2 {
    font-size: 36px;
    line-height: 38px;
  }

  .how-to-play-title img {
    max-width: 160px;
  }

  .play-video-wrapper {
    min-height: 170px;
  }

  .howtoplay-video,
  .howtoplay-video-hide {
    top: -48px;
  }

  .play-content-title h3 {
    font-size: 36px;
  }

  .hero.style--two .hero__content p {
    /* max-width: 240px; */
    margin: 0px auto;
    padding: 0px 10px;
  }

  .contactus-form-left {
    padding: 20px 16px;
    border-radius: 20px 0 0 20px;
  }
  .contactus-form-right {
    padding: 20px 16px;
  }
}

@media screen and (max-width: 440px) {
  .roulette_container .game-bet-box {
    width: 135px;
  }
}

@media screen and (max-width: 376px) {
  .roulette-wrapper:before {
    margin-top: -150px;
  }

  .placeholder-section {
    height: 415px;
    width: 389px;
    background-size: 82%;
  }

  .placeholder-section::before {
    height: 382px;
    width: 390px;
  }

  .roulette-placeholder::before {
    background-size: 50%;
    margin-top: 40px;
  }

  .roulette-placeholder {
    max-width: 100%;
    height: 270px;
    /* margin-top: -230px; */
    margin-left: 0;
    background-position-x: 3px;
  }

  .roulette-placeholder-box img {
    max-width: 383px;
    max-height: 260px;
    width: 100%;
    object-fit: contain;
  }

  .win-grid-bix {
    width: 340px;
    height: 275px;
  }

  .win-btn {
    margin-top: -20px;
  }

  .win-btn .btn,
  .win-btn .btn:hover,
  .win-btn .btn:focus,
  .win-btn .btn-primary:not(:disabled):not(.disabled):active:focus {
    min-width: 150px;
    height: 40px;
    font-size: 14px;
    margin: 0px 2px;
  }

  .win-grid h3 {
    font-size: 24px;
  }

  .win-btn h5 {
    font-size: 14px;
  }

  /**** boxing page ********/
  .unboxing-header.unboxing_header h2.section-title {
    font-size: 25px;
  }

  .unboxing-page li.filter-tablist-links .icon-thumb2 img {
    height: 55px;
    width: 100%;
    object-fit: contain;
  }

  .roulette_container .game-bet-box {
    width: 125px;
  }

  .item-box-col .col-md-6 {
    width: 100%;
  }

  .top-item-box .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .contest-sidebar__cart .recent-item {
    min-height: 152px;
    justify-content: space-around;
  }

  .header__top .right .total__amount,
  .header__top .right .language button {
    font-size: 12px;
    padding: 4px 10px;
  }

  .header__top span.total__amount img {
    margin-right: 7px;
  }

  .header__top .right .amount__btn img {
    height: 30px;
  }

  .header__top .right .amount__btn {
    margin-left: 10px;
  }

  .loggedin {
    margin-left: 0px;
  }

  .content-block .title {
    font-size: 22px;
  }

  .addtocart_page {
    min-width: 280px;
    max-width: 100%;
  }

  .header__top .right {
    justify-content: center;
  }

  .product-box .buy_add_btn button {
    margin: auto;
  }

  .quick-sell span,
  .roll-btn {
    font-size: 12px !important;
  }

  .win-btn .btn {
    min-width: 120px;
  }

  .game-page .win-grid-container-three .box__name h3 {
    font-size: 10px;
    margin-right: 5px;
  }

  .thanks-popup .modal-body h2 {
    font-size: 24px;
  }

  /************************** FAQ Section *****************************/
  /* .faq-page-topsection {
    height: 30vh;
  } */
  .faq-page-title p {
    font-size: 16px;
    line-height: 21px;
  }

  .howtoplay-video,
  .howtoplay-video-hide {
    top: -20px;
  }
}

@media screen and (max-width: 320px) {
  .roulette_container .game-bet-box {
    width: 105px;
  }

  .fairness-info-popup .nav-tabs .nav-link,
  .fairness-info-popup .nav-tabs .nav-link.active {
    padding: 9px;
  }

  .roulette-bg-horizontal .roulette_wrapper {
    height: 80px;
  }

  .roulette_wrapper {
    height: 125px;
  }

  .roulette_container .game-bet-box {
    height: 124px;
  }

  .roulette_section:before {
    height: 290px;
  }

  .roulette_container:before,
  .roulette_container:after {
    height: 150px;
  }
}

/***************************** Media query for landscape screen *****************************/

@media screen and (max-width: 1024px) and (orientation: landscape) {
  .roulette-wrapper:before {
    background-size: 20%;
  }

  .roulette_section {
    min-height: 210px;
  }

  .roulette-placeholder {
    width: 250px;
    height: 250px;
    margin-top: -280px;
  }

  .roulette-placeholder::before {
    background-size: 42%;
    margin-top: 55px;
  }

  .placeholder-section {
    height: 180px;
    width: 180px;
    margin-top: -10px;
  }

  .placeholder-section::before {
    height: 300px;
    width: 300px;
  }

  .roulette-placeholder-box img {
    max-width: 200px;
    max-height: 200px;
  }

  .roulette-placeholder::after {
    background-size: 60%;
  }

  .stripe-details-section {
    padding-top: 0;
    margin-top: -33px;
  }

  .game-page .stripe-details {
    max-width: 410px;
  }

  .hot-keys-section {
    min-height: 60px;
  }

  .stripe-details .content p {
    font-size: 10px;
  }

  .case-quantity {
    min-height: 60px;
    max-width: 190px;
  }

  .case-quantity .game-type {
    padding: 3px 8px;
    max-width: 200px;
    min-height: 60px;
  }

  .case-quantity h6 {
    font-size: 10px;
  }

  .game-page .spin-button .btn {
    max-width: 383px;
    font-size: 14px;
    padding: 8px 20px;
  }

  .fairness-link a {
    font-size: 12px;
  }

  .fairness-link a i {
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
  }

  .fairness-link .fa-box {
    font-size: 12px;
  }

  .stripe-details-section.hide {
    opacity: 0;
  }

  .spin-button.wow.animate__animated.animate__slideInUp.hide {
    opacity: 0;
  }

  .win-grid-bix {
    width: 360px;
    height: 280px;
  }

  .win-btn {
    margin-top: -15px;
  }

  .win-grid h3 {
    font-size: 22px;
  }
}

@media screen and (max-width: 767px) and (orientation: landscape) {
  .roulette-placeholder {
    margin: 0 auto;
    margin-top: -296px;
  }

  .placeholder-section::before {
    height: 260px;
    width: 260px;
  }

  .stripe-details-section {
    margin-top: -50px;
  }

  .case-quantity {
    min-height: 55px;
  }

  .case-quantity .game-type {
    min-height: 56px;
  }

  .case-quantity h6 {
    font-size: 10px;
  }

  .case-quantity .game-type span {
    font-size: 16px;
  }

  .game-page .spin-button .btn {
    max-width: 300px;
    font-size: 12px;
    margin-top: 6px;
  }

  .fairness-link {
    padding-top: 5px;
  }
}
