/* .rtl .ar-row {
    flex-direction: row-reverse;
}
.rtl .ar-left {
    justify-content: flex-end;
}
.rtl .header__top .right {
    display: flex;
    justify-content: flex-start;
} */

/******* Start style for home page *********/

.rtl .hero__car {
  height: 725px;
  overflow: hidden;
  position: absolute;
  left: 0;
  top: 10%;
  width: 1150px;
}
.rtl .hero__car img {
  left: 40%;
  max-width: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.rtl .ar-hero {
  flex-direction: row-reverse;
}
.rtl .hero__title {
  text-align: right;
  direction: rtl;
}
.rtl .hero__content p {
  text-align: right;
  direction: rtl;
}
.rtl .hero__btn {
  margin-top: 50px;
  display: flex;
  flex-direction: row-reverse;
}
.rtl .hero__btn .video-btn {
  margin-right: 50px;
}
.rtl .section-header {
  direction: rtl;
}
.rtl .ar-boxes-row {
  direction: rtl;
}
.rtl .contest-card__content {
  text-align: right;
  direction: rtl;
}
.rtl .details-btn i {
  font-size: 18px;
  margin-left: 5px;
}
.rtl .contest-card__content .left {
  width: 50%;
  padding-right: 0;
  text-align: right;
}

.rtl .contest-card__content .right {
  width: 50%;
  padding-left: 0;
  text-align: left;
}
.rtl .ar-card {
  direction: rtl;
}
.rtl .ar-section {
  text-align: left !important;
}
.rtl .section-header {
  direction: rtl;
  text-align: right;
}
.rtl .support-card {
  direction: rtl;
  text-align: right;
}
.rtl .support-card__content {
  padding-right: 30px;
}
/******* End style for home page *********/

/******* Start style for profile page *********/
.rtl .ar-profile-row {
  direction: rtl;
}
.rtl .user-action-card .myprofile-card {
  text-align: right;
}
.rtl .user-info-card__list li {
  text-align: right;
}
.rtl .user-info-card__list li .caption {
  text-align: unset;
  padding-right: 20px;
}
.rtl .user-info-card__list li .value {
  padding-right: 0px;
}
.rtl .user-info-card__list li .caption::after {
  position: absolute;
  content: ":";
  left: 17px;
  top: 2px;
  text-align: left;
}
.rtl ul.user-action-list {
  text-align: right;
}
.rtl .ar-trans {
  margin-right: 1.5rem !important;
}
.rtl .ar-trans-left {
  text-align: right;
}
.rtl h3.title {
  text-align: right;
}
.rtl .all-transaction table th:first-child {
  text-align: center;
}
.rtl .referral-transaction table th:first-child {
  text-align: center;
}
.rtl .referral-crad__content {
  text-align: right;
  padding-right: 15px;
}
.rtl .contest-num {
  flex-direction: column;
  text-align: center;
}
.rtl .contest-card__meta li:first-child {
  width: 30%;
  border-left: 1px solid rgba(255, 255, 255, 0.15);
}
.rtl .contest-card__meta li:first-child {
  width: 30%;
  border-right: unset;
}
.rtl .contest-card__meta li p {
  margin-right: 8px;
}
.rtl .all-transaction__header .date-range i {
  left: 30px;
}
.rtl .ar-fav-title {
  text-align: left !important;
}
.rtl i.las.la-angle-down.ml-2 {
  margin-right: 0.5rem;
}
.rtl .draw-single-ticket__header {
  direction: rtl;
}
.rtl .contest-card__meta li i {
  padding-left: 8px;
}

.rtl .copy-link .copy-link-icon {
  right: 5px;
  left: unset;
}
.rtl .clipboard-copy {
  left: 5px;
  right: unset;
}
.rtl .copy-link .label {
  right: 75px;
  left: unset;
}
.rtl .share-buttons {
  text-align: right;
  margin-top: 10px;
  margin-right: 10px;
}
.rtl .email-share {
  margin-left: 15px;
}
.rtl .social-txt {
  margin-right: 5px;
}
/******* End style for profile page *********/

/******* Start style for login and Reg page *********/
.rtl .ar-login-title {
  text-align: center !important;
}
.rtl .account-form-wrapper {
  direction: rtl;
}
.rtl .form-group {
  text-align: right;
}
.rtl .custom-checkbox-label {
  text-align: right;
}
.rtl .ar-reg-title {
  text-align: center !important;
}
.rtl .ar--link.link {
  margin-right: 8px;
}
/******* End style for login and Reg page *********/

/******* Start style for OpenBox Page *********/
.rtl .unboxing-page .ar-heading {
  text-align: center !important;
}
.rtl .page-list {
  justify-content: flex-end;
}
.rtl ul.filter-tablist {
  direction: rtl;
}
.rtl .list li.option {
  text-align: right !important;
}
.rtl .contest-filter-wrapper {
  direction: rtl;
}
.rtl .contest-search-form button {
  position: absolute;
  top: 10px;
  left: -235px;
  color: #a2aae4;
  background: transparent;
  font-size: 20px;
}
.rtl .ar-open-boxes {
  direction: rtl;
}
/* a.ar-trans.transaction-action-btn.ml-4 {
    margin-left: 0 !important;
} */
/******* End style for OpenBox Page *********/

/******* Start MediaQuery for home page *********/
@media (max-width: 1700px) {
  .rtl .hero__car img {
    max-width: 50%;
    left: 35%;
  }
}

@media (max-width: 991px) {
  .rtl .hero__title {
    text-align: center;
  }
  .rtl .hero__content p {
    text-align: center;
  }
  .rtl .hero__car {
    bottom: 0;
    height: 475px;
    top: auto;
  }
  .rtl .section-header {
    direction: rtl;
    text-align: center;
  }
  .rtl .hero__btn {
    margin-top: 50px;
    flex-direction: row-reverse;
    justify-content: center !important;
  }
  .rtl .hero__car img {
    max-width: 40%;
    right: 65%;
    left: 38%;
  }
  .rtl ul.navbar-nav {
    text-align: right;
  }
  .rtl .header .nav-right {
    margin-left: 30px;
    display: flex;
    justify-content: flex-end;
  }
  .rtl .contest-search-form button {
    position: absolute;
    top: 11px;
    left: -300px;
  }
}

@media (max-width: 767px) {
  .rtl .header__top .right {
    justify-content: center;
  }
  .rtl .hero__title {
    text-align: center;
    font-size: 56px;
  }
  .rtl .hero__content p {
    text-align: center;
  }
  .rtl .hero__btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .rtl .hero__btn {
    display: inline-grid;
    display: -moz-inline-grid;
    display: -ms-inline-grid;
  }
  .rtl .hero__car {
    bottom: 0;
    height: 475px;
    top: auto;
  }
  .rtl .hero__car img {
    top: 60%;
    left: 17% !important;
    max-width: 25% !important;
  }
  .rtl .section-header {
    text-align: center !important;
  }
  .rtl .support-card__content {
    padding-left: 30px;
  }
  .rtl .ar-btn {
    padding: 6px 10px !important;
    width: 100%;
  }
  .rtl ul.navbar-nav {
    text-align: right;
  }
  .rtl .header .nav-right {
    margin-right: 0;
    margin-left: 65px;
  }
  .rtl .contest-search-form button {
    position: absolute;
    top: 11px;
    left: -285px;
  }
  .rtl h3.title {
    text-align: center;
  }
  .rlt a.ar-trans.transaction-action-btn.ml-4 {
    margin-left: 0 !important;
  }
  .rtl button.react-share__ShareButton.email-share {
    margin-bottom: 13px;
  }
  .rtl .referral-crad__content {
    padding-right: 0;
    text-align: center;
  }
  .trl .referral-crad__content {
    text-align: center !important;
  }
  .rtl h3.ar-fav-title.title {
    text-align: center !important;
  }
  .rtl .ar-profile-row .slick-prev {
    left: 10px;
  }
  .rtl .user-profile-section .share-buttons {
    text-align: right;
  }
}

/******* End MediaQuery for home page *********/
