.mt-10 {
  margin-top: 10px;
}

.mt-20 {
  margin-top: 20px;
}

.unboxing-hero-section {
  /* background-image: -webkit-linear-gradient(-90deg,
      #031b28 0%,
      #092230 100%) !important; */
  background-image: none !important;
  padding: 114px 0 !important;
}

.product-details-page .contest-cart__right .progressbar {
  background-color: #083248;
}

/* .unboxing-hero-section:before {
    content: "";
    background: url(../../assets/images/game/pattern.png);
    position: absolute;
    top: 0%;
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
    opacity: 0.1;
  } */

.contest-details-grid {
  /* background: #041f2d;0 */
  background: #1b1c25;
  border-radius: 12px;
  padding: 20px 20px 25px;
  border: 1px solid #131313;
}

.unboxing-header {
  background-color: #041f2d;
}

.page-list {
  cursor: pointer;
}

/* 
  .unboxing-main-section {
    padding-left: 10px;
    padding-right: 10px;
  } */

/* Filter Tabs */

.filter-tabs-section {
  padding: 0 0 40px;
  text-align: center;
}

.mt-minus-70 {
  margin-top: -70px;
}

.pt-50 {
  padding-top: 50px;
}

.products-ctaegory .filter-tablist {
  list-style-type: none;
  overflow-y: auto;
  white-space: nowrap;
  padding-top: 20px;
  /* display: flex; */
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  padding-bottom: 10px;
  display: grid;
  grid-template-rows: auto auto;
  grid-auto-flow: column;
  column-gap: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.products-ctaegory li.filter-tablist-links {
  cursor: pointer;
  /* 
    padding: 10px 30px;
      color: #ffffff;
      background: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
      background: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
      background: linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
      box-shadow: -1.113px 7.922px 16px 0px rgba(143, 61, 245, 0.63);
      border: none !important;
      margin: 5px 10px; */
  margin: 5px;
  display: inline-block;
  /* width: calc(100% / 6); */
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  width: 100%;
}

li.filter-tablist-links div.category {
  background-color: transparent;
  border-color: transparent !important;
  /* border-bottom: 4px solid transparent; */
  display: block;
  padding: 0.5rem;
  /* border: 1px solid transparent; */
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  display: flex;
  align-items: center;
  background: rgb(168, 245, 92);
  background: linear-gradient(
    90deg,
    rgba(168, 245, 92, 0) 0%,
    rgba(18, 95, 135, 1) 0%,
    rgba(8, 50, 72, 1) 51%,
    rgba(18, 95, 135, 1) 100%
  );

  border-radius: 30px;
  justify-content: center;
  font-size: 16px;
  text-transform: uppercase;
}

.icon-thumb {
  width: 50px;
  height: 40px;
  margin-right: 10px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.icon-thumb img {
  width: auto;
  height: 35px;
}

.icon-thumb2 {
  margin-bottom: 0.5em;
  float: left;
  vertical-align: bottom;
  display: none;
}
.icon-name2 {
  padding: 34px 0 0 8px;
  display: block;
}
@media (max-width: 575px) {
  li.filter-tablist-links {
    padding: 12px 5px;
    font-size: 16px;
    width: 50%;
    cursor: pointer;
  }
}

li.filter-tablist-links.react-tabs__tab--selected,
li.filter-tablist-links:focus,
li.filter-tablist-links:hover {
  background: transparent !important;
  box-shadow: none;
  /* border-bottom: 4px solid #e2972e !important; */
  color: #fff;
}

li.filter-tab-home.react-tabs__tab--selected,
li.filter-tab-home:focus {
  border-bottom: 4px solid #e2972e !important;
  background: #051823 !important;
  padding: 12px;
  border-radius: 9px;
  margin: 0 9px;
  vertical-align: top;
}

li.filter-tablist-links.react-tabs__tab--selected .category,
li.filter-tablist-links:focus .category,
li.filter-tablist-links:hover .category {
  background: -webkit-linear-gradient(
    7deg,
    #462b04 0%,
    #ab6b10 100%
  ) !important;
}

.contest-filter-wrapper {
  justify-content: space-between;
  padding-bottom: 40px;
}

.contest-filter-wrapper .nice-select,
.contest-filter-wrapper .order-dropdown-list {
  background-color: transparent;
  cursor: pointer;
  border-color: #292b39;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  padding-left: 20px;
  padding-right: 15px;
  border: 1px solid #292b39 !important;
  box-sizing: border-box;
  clear: both;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  min-height: 50px;
  line-height: 50px;
}

.contest-filter-wrapper .nice-select::after,
.contest-filter-wrapper .order-dropdown-list:after {
  content: "\f107";
  font-family: FontAwesome;
  width: 0;
  height: 0;
  top: -3px;
  border-bottom: 1px solid #6e6bad;
  border-right: 1px solid #6e6bad;
  right: 28px;
  font-size: 23px;
  position: absolute;
}

.nice-select.open::after,
.order-dropdown-list:after {
  content: "\f106";
}

.contest-filter-wrapper .nice-select span,
.contest-filter-wrapper .nice-select li,
.contest-filter-wrapper .order-dropdown-list span,
.contest-filter-wrapper .order-dropdown-list li {
  font-size: 14px;
}

.contest-filter-wrapper .nice-select .current,
.contest-filter-wrapper .order-dropdown-list .current {
  color: #ffffff;
}

.nice-select.open ul.list,
.order-dropdown-list.open ul.list {
  opacity: 1;
  pointer-events: auto;
  width: 100%;
  -webkit-transform: scale(1) translateY(0) !important;
  -ms-transform: scale(1) translateY(0) !important;
  transform: scale(1) translateY(0) !important;
  z-index: 9999;
}

.contest-filter-wrapper .nice-select .list,
.contest-filter-wrapper .order-dropdown-list .list {
  /* background: linear-gradient(7deg, #e89c31 0%, #ab6b10 100%); */
  background: #22232d;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  border-radius: 5px;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.contest-filter-wrapper .nice-select .list .option,
.contest-filter-wrapper .order-dropdown-list .list .option {
  color: #fafafa;
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
}

.contest-filter-wrapper .nice-select .list .option.selected,
.contest-filter-wrapper .nice-select .list .option:hover,
.contest-filter-wrapper .order-dropdown-list .list .option.selected,
.contest-filter-wrapper .order-dropdown-list .list .option:hover {
  background-color: #e1972d;
}

/* Range Input */
.amount-wrapper {
  position: relative;
}

.range {
  -webkit-appearance: none;
  vertical-align: middle;
  padding: 0;
  background: none;
  border: none !important;
  padding: 0 !important;
  margin-top: -10px;
}

.range::-webkit-slider-runnable-track {
  background-color: #083248;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: transparent;
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower {
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-fill-upper {
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-tooltip {
  display: none;
  /* display and visibility only */
}

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #e89c31;
  height: 18px;
  width: 18px;
  margin-top: -7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}

.range::-webkit-slider-thumb:before {
  content: "\f053";
}

.range::-webkit-slider-thumb:after {
  content: "\f054";
}

.range[disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #d7dbdd;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range::-ms-thumb {
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  border: none;
}

.range:active::-ms-thumb {
  border: none;
}

.initial {
  border: none;
  color: #ededed;
  font-size: 14px;
  position: absolute;
  z-index: 999;
  left: 24px;
  top: 28px;
}

.output {
  border: none;
  color: #ededed;
  font-size: 14px;
  position: absolute;
  z-index: 999;
  right: 28px;
  top: 29px;
}

.range:focus {
  border-color: transparent !important;
}

.contest-search-form {
  position: relative;
}

.contest-filter-wrapper .nice-select,
input[type="number"] {
  border: 1px solid #292b39 !important;
  border-radius: 999px !important;
}

.contest-search-form input {
  cursor: pointer;
  width: 100%;
  border-radius: 999px !important;
  -webkit-border-radius: 999px !important;
  -moz-border-radius: 999px !important;
  -ms-border-radius: 999px !important;
  -o-border-radius: 999px !important;
  border-color: #292b39 !important;
  font-size: 14px;
}

.unboxing-page .contest-search-form button {
  position: absolute;
  top: 10px;
  right: 16px;
  color: #ffffff;
  background: transparent;
  font-size: 20px;
}

.unboxing-page .contest-filter-wrapper input::placeholder {
  color: #fff !important;
}

.unboxing-page .contest-filter-wrapper .order-dropdown-list.open {
  border-color: #e1972e !important;
}

.unboxing-page .contest-filter-wrapper .nice-select.open {
  border-color: #e1972e !important;
}

/* Scrollbar CSS*/
.filter-tablist::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.filter-tablist::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.filter-tablist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
  background-color: #c2d5d1;
}

@media (max-width: 786px) {
  .filter-tabs {
    margin-top: -50px !important;
  }
}

.icon-item2__content p,
.icon-item2__content .title {
  color: #f1f1f1;
}

.details-btn {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

.details-btn i {
  font-size: 18px;
  margin-right: 5px;
}

/* Box Details Page */
.contest-card {
  cursor: pointer;
}

.last-live-drops-section {
  padding: 50px 0;
}

.last-live-drops-section .slick-slide {
  padding: 0px 5px;
  float: none;
  display: inline-block !important;
}

.recent-item {
  padding: 10px;
  display: flex !important;
  flex-wrap: wrap;
  text-align: left;
  flex-direction: column-reverse;
  background: #083248;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  /* background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #2d1e2c); */
  border-bottom: 2px solid #ffffff2d;
}

.last-live-drops-section .slick-slide img {
  display: block;
  height: 110px;
  width: auto;
  object-fit: contain;
}

/* .last-live-drops-section .slick-slide:nth-of-type(odd) .recent-item {
    background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #031b28);
    border-bottom: 4px solid #083248;
  }
  
  .last-live-drops-section .slick-slide:nth-of-type(even) .recent-item {
    background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #083248);
    border-bottom: 4px solid #104a68;
  } */

.contest-sidebar__cart .recent-item {
  background: #082b3d;
  background-image: linear-gradient(180deg, rgb(3 27 40), #083248);
  border-bottom: 2px solid #062434;
}

.icon-item-thumb {
  justify-content: center;
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
  position: relative;
}

.icon-item-thumb-img {
  justify-content: center;
  display: inline-flex;
  align-content: center;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}

.contest-sidebar__cart .icon-item-thumb img {
  max-height: 50px;
  /* position: absolute; */
  transform: translate(-50%, -50%);
  /* left: 50%;
    top: 50%; */
}

.icon-item-thumb-img img {
  transform: translate(-50%, -50%);
}

.icon-item-thumb img.initial-img,
.recent-item:hover {
  transform: translate(0);
  opacity: 1;
  transition: opacity 0.3s, transform 0.3s;
}

.recent-item:hover .icon-item-thumb img.initial-img {
  transform: translate(120%);
  opacity: 0;
}

.icon-item-thumb img.final-img,
.icon-item-thumb-img img.final-img {
  transition: opacity 0.3s, transform 0.3s;
  transform: translate(120%);
  opacity: 0;
  position: absolute;
}

.recent-item:hover .icon-item-thumb img.final-img,
.recent-item:hover .icon-item-thumb-img img.final-img {
  transform: translate(0);
  opacity: 1;
}

.icon-item-thumb:hover img {
  /* opacity: 0.8; */
  /* -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 0.8s;
    animation-duration: 0.8s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both; */
}

.recent-item-content {
  width: 100%;
  text-align: right;
  display: inline-flex;
  justify-content: space-between;
}

.recent-item-content {
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.recent-item-content p {
  color: #f1f1f1;
  font-size: 12px;
}

.yellow {
  color: #e79a32;
}

.green {
  color: #337d3e !important;
}

.gray {
  color: #62686b !important;
}

.icon-item,
.contest-sidebar__cart,
.contest-details__nav-slider .single-slide,
.contest-sidebar__cart,
.contest-details__thumb-slider {
  background: #1b1c25 !important;
  border-radius: 10px !important;
  border: 1px solid #131313;
}

.contest-details-content .contest-details-meta span {
  color: #e89c31 !important;
  font-size: 16px;
  margin-bottom: 5px;
}

.contest-details-content p {
  font-size: 16px;
  color: #d3d3d3;
}

.contest-sidebar .progressbar,
.contest-sidebar .quantity input {
  background-color: #04669a !important;
}

.detail-main-img-section {
  display: inline-flex;
  align-items: center;
}

.detail-main-img {
  padding: 5px;
  background-color: #1f202a;
  border-radius: 10px;
}

.item-card {
  background: #031b28;
}

.item-card__content {
  padding: 10px 20px 20px 20px;

  display: flex;
  justify-content: space-between;

  background: rgb(4 31 45);
  /* width: 100%;
      bottom: 0;
      position: absolute; */
  border-radius: 0 0 10px 10px;
  border: 1px solid #052434;
}

.item-card__content .contest-card__name h5 {
  font-size: 18px;
}

.item-card__thumb {
  border-radius: 10px 10px 0 0;
}

.item-price {
  font-size: 18px;
  font-weight: 600;
  color: #e89c31;
  line-height: 1;
  margin-top: 15px;
}

.item-category {
  font-size: 16px;
}

/* 1.2 Text Blink */
.blinks {
  transition: 1s all;
  animation: blink 2s linear infinite;
  -webkit-animation: blink 2s linear infinite;
}

.language .btn {
  font-size: 13px;
}

.header__top .right .language {
  background: #083248;
  margin-left: 35px;
  border-radius: 30px;
}

.icon-item-thumb img.final-img {
  opacity: 0;
  visibility: hidden;
  height: 0px;
  display: none;
}

.recent-item:hover .icon-item-thumb img.final-img,
.recent-item:hover .icon-item-thumb-img img.final-img {
  height: 110px;
  visibility: visible;
  display: block;
  width: auto;
  object-fit: contain;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}

.unboxing-page .contest-filter-wrapper .nice-select ul.list {
  height: 300px;
  overflow: auto;
  padding: 10px 0px;
  z-index: 99;
}

.unboxing-page .contest-filter-wrapper .nice-select ul.list::-webkit-scrollbar {
  width: 5px;
  border-radius: 20px;
}

.unboxing-page
  .contest-filter-wrapper
  .nice-select
  ul.list::-webkit-scrollbar-track {
  background: #e89c31;
  border-radius: 20px;
}

.unboxing-page
  .contest-filter-wrapper
  .nice-select
  ul.list::-webkit-scrollbar-thumb {
  background: #031b28;
  border-radius: 20px;
}
.desc-contest {
  position: relative;
}
.boxbottom-text,
.boxbottom-text span {
  color: #fff;
  text-transform: uppercase;
  font-size: 18px;
}

@media (max-width: 992px) {
}

@media (max-width: 992px) and (min-width: 425px) {
  .contest-sidebar__cart .col-6 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .contest-sidebar__cart .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 991px) {
  /* .content-block {
      padding-top: 40px;
    } */
  .unboxing-hero-section {
    padding: 150px 0 !important;
  }

  .unboxing-page li.filter-tablist-links div {
    background-color: transparent;
    /* border-color: transparent !important; */
    /* border-bottom: 4px solid transparent; */
    display: block;
    padding: 0.6rem 1rem;
    /* border: 1px solid transparent; */
    /* border-top-left-radius: 0.25rem; */
    /* border-top-right-radius: 0.25rem; */
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: 16px;
  }

  .filter-tabs-section {
    padding: 0;
  }
}

@media screen and (max-width: 767px) {
  .max-option input[type="number"] {
    margin-bottom: 20px;
    width: 345px;
  }
}

@media screen and (max-width: 575px) {
  .unboxing-page li.filter-tablist-links div {
    padding: 0.4rem 1rem;
  }
}
