.cart-page {
  padding: 185px 0px 100px;
}
.checkout-wrapper {
  border-radius: 10px;
  border: 1px solid #052434;
}
.subscribe-area input:focus,
input:active,
textarea:focus,
textarea:active {
  border-color: #ecaa2f !important;
}
.ticket-wrapper__body .cart-items {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.ticket-wrapper__body .single-row .items {
  display: -ms-flexbox;
  display: flex;
  margin: -3px;
  justify-content: center;
}
.item-img img {
  width: 64px;
  height: 64px;
}
.item-count {
  display: flex;
  align-items: center;
  padding: 3px 15px 0 15px;
  background: #083248;
  border-radius: 10px;
}
.item-desc {
  margin-left: 10px;
}
.item-quantity-btn {
  margin-right: 15px;
}
.item-desc p {
  color: #a9a9a9;
}
