@import "https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";

body {
  font-family: "Biko" !important;
  margin: 0;
  padding: 0;
  color: #ddd !important;
  background: #031b28 !important;
  font-weight: normal;
}

.rtl * {
  font-family: "Hanimation" !important;
}

@font-face {
  font-family: "Biko";
  src: url(./assets/fonts/Biko_Regular.otf);
  font-weight: normal;
}

@font-face {
  font-family: "Biko";
  src: url(./assets/fonts/Biko_Bold.otf);
  font-weight: 600;
}

@font-face {
  font-family: "Biko";
  src: url(./assets/fonts/Biko_Black.otf);
  font-weight: bold;
}
/****************** Arabic Font ********************/

@font-face {
  font-family: "Hanimation";
  src: url(./assets/fonts/hanimation_light.ttf);
  font-weight: 400;
}

@font-face {
  font-family: "Hanimation";
  src: url(./assets/fonts/hanimation_medium.ttf);
  font-weight: 600;
}

@font-face {
  font-family: "Hanimation";
  src: url(./assets/fonts/hanimation_regular.ttf);
  font-weight: 500;
}

@font-face {
  font-family: "Hanimation";
  src: url(./assets/fonts/hanimation_bold.ttf);
  font-weight: bold;
}
.btn-style::before {
  content: "";
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: linear-gradient(97.3deg, #9c5e07 0%, #ab6b10 100%) !important;
  transition: transform 0.5s !important;
  transform-origin: right !important;
  transform: scaleX(0) !important;
  z-index: 0 !important;
  border-radius: inherit !important;
  border-radius: 50px !important;
}
.btn-style {
  background: transparent !important;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%) !important;
  position: relative !important;
  /* border-radius: 50px !important; */
  border-radius: 10px !important;
  background-image: none;
  overflow: hidden !important;
}
.btn-style:hover::before {
  transform: scaleX(1) !important;
  transform-origin: left !important;
  transition: transform 0.5s;
  border-radius: 10px !important;
  z-index: 9 !important;
}
.btn-style:hover {
  color: #fff !important;
}
.btn-style span {
  z-index: 99 !important;
  position: relative !important;
  color: #d1d1d1;
  text-transform: uppercase;
}
.btn-style {
  background-image: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%) !important;
  border: 1px solid #c5801e !important;
  height: 42px !important;
  font-size: 13px !important;
  padding: 5px 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btn-style-transparent {
  background: transparent !important;
}
.modal {
  z-index: 99999999 !important;
}
.Toastify__toast-container {
  z-index: 999999999 !important;
  font-size: 14px;
}
.Toastify__toast-container > div {
  font-family: "Biko" !important;
}
.ml-auto {
  margin-left: auto !important;
}
.bg-new-all {
  position: relative;
}
.bg-new-all:before {
  content: "";
  /* background: url("../src/assets/images/newhome/bg-home-page.jpg"); */
  width: 100%;
  background-size: cover;
  position: absolute;
  height: 100%;
  top: 0;
  right: 0;
  z-index: -1;
  background-attachment: fixed;
}

.new-bg-all:before{
  background:#13151D;
  background-attachment: unset;
}
.disabledbtn {
  cursor: not-allowed;
  pointer-events: none;
}


@media (min-width: 1400px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      max-width: 1200px !important;
  }
}
