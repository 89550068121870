.loader {
  height: 100vh;
  width: 100%;
  position: fixed;
  bottom: 0px;
  right: 0px;
  z-index: 99999;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader::before {
    content: "";
    position: absolute;
    height: 100vh;
    width: 100%;
    background: #000000;
}
.loader .spinner-grow{
    height: 50px;
    width: 50px;
    color:  #f06723 !important;
}
.table-loader {
  text-align: center;
  padding: 25px 0px;
}
.table-loader .spinner-border{
  color: #f06723!important;
}