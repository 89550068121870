/***** battle cancel popup *******/
.battle-cancel h3 {
  font-weight: 400 !important;
  font-size: 20px;
  padding-top: 25px;
}
.battle-cancel button {
  margin: 10px;
}
.battle-cancel button:last-child {
  margin-right: 10px !important;
  color: #e0962d;
}
.battle-cancel button:last-child:hover {
  color: white;
}

.balance-popup p {
  font-size: 24px;
  margin-bottom: 8px;
}

.balance-popup p span {
  font-weight: 600;
  font-size: 24px;
  margin: 0px 10px;
  color: #e1972d;
}

.balance-popup .popup__buttons {
  margin: 30px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.balance-popup .popup__buttons button {
  margin: 0px 10px;
  min-width: 120px;
}

.balance-popup h3.ar-reg-title.text-center.pop_head p {
  font-size: 26px;
}
.balance-popup .account-form-area p {
  font-size: 22px;
}
/* ************************************************** */
/* ****************  Deposit popup ************ */
/* ************************************************** */

.deposit-model .account-form-area {
  /* background-color: transparent !important; */
  border-radius: 0;
  height: 54vh !important;
  overflow: hidden;
  overflow-y: auto !important;
  padding: 0 !important;
}
.deposit-select-method {
  border-top: 2px solid #20212d;
}

.deposit-popup-heading {
  padding-bottom: 15px;
}
.deposit-popup-heading.bonus-text {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.deposit-popup-heading.bonus-text h3 {
  font-size: 20px;
  line-height: 1.2;
}
.bonus-money {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 12px;
  line-height: 1.2;
  color: #e89c31;
}
.bonus-money i {
  font-size: 10px;
}

.deposit-select-heading {
  display: flex;
  padding: 20px 0;
}

.deposit-select-heading span {
  color: #e89c31;
  padding-right: 5px;
  text-transform: uppercase;
}

.deposit-select-box {
  /* display: flex;
  align-items: center;
  justify-content: center; */
  /* background: linear-gradient(
    90deg,
    rgba(168, 245, 92, 0) 0%,
    rgba(18, 95, 135, 1) 0%,
    rgba(8, 50, 72, 1) 51%,
    rgba(18, 95, 135, 1) 100%
  ); */
  padding: 30px 0;
  text-align: center;
  border-radius: 0.3rem;
  background: #1b1c25;
  transition: all 0.3s ease;
}
.deposit-select-box:hover {
  background: #21222d;
}
.deposit-box {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  cursor: pointer;
}

.deposit-select-box img {
  max-width: 100px;
  width: 100%;
  height: 50px;
  object-fit: contain;
}

.deposit-select-box span {
  /* padding-left: 10px; */
  display: block;
  text-transform: capitalize;
}
.deposit-payment-history {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
}

.deposit-payment-history span {
  color: #a9a9a9;
  font-size: 12px !important;
  max-width: 498px;
  width: 100%;
}

.deposit-payment {
  display: flex;
  justify-content: center;
  align-items: center;
}

.deposit-payment i {
  font-size: 30px;
  padding-right: 5px;
}
.deposit-popup-page {
  display: flex;
  flex-wrap: wrap;
}

.deposit-popup-left {
  width: 70%;
  background: #13141a;
  padding: 20px;
  /* background: url("../../assets/images//newhome//bg-home-page.jpg"); */
  /* background-size: cover; */
}

.deposit-popup-right {
  width: 30%;
  padding: 20px;
  background: #1b1c25;
}

.deposit-popup-right-heading {
  display: flex;
  align-items: center;
}

.deposit-popup-right-heading i {
  font-size: 24px;
  padding-right: 10px;
  color: #b2c8ff;
}
.deposit-popup-right-text {
  padding: 20px 0;
}
.deposit-payment p:hover {
  color: #e89c31;
}
.deposit-popup-right-content .deposit-popup-right-text {
  padding: 0px;
}
.badges-img {
  text-align: center;
}
.badges-img img {
  height: 19.5vh;
  object-fit: contain;
}
.deposit-popup-right-content ul {
  padding: 20px 0px;
}

/* *************  Deposit popup end ************* */
/* ************************************************** */
/* ****************  payment popup ************ */
/* ************************************************** */
.payment-select-heading {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  flex-wrap: wrap;
}
.payment-select-heading span {
  color: #e89c31;
  padding-right: 5px;
}
.select-method {
  display: flex;
}
.select-method p {
  text-transform: uppercase;
}
.add-promo p {
  text-transform: uppercase;
}
.add-promo p:hover {
  color: #e89c31;
}

.payment-select-box {
  background: #1b1c25;
  text-align: center;
  padding: 15px;
  position: relative;
}
.payment-select-box:hover {
  background: #262833;
}
.payment-btn {
  position: relative;
  width: fit-content;
  margin-top: 20px;
  cursor: pointer;
  min-width: 10%;
  padding: 3px 10px;
}
.payment-btn:before {
  -webkit-clip-path: polygon(
    15% 0,
    85% 0,
    100% 50%,
    100% 50%,
    85% 100%,
    15% 100%,
    0 50%,
    0 50%
  );
  clip-path: polygon(
    15% 0,
    85% 0,
    100% 50%,
    100% 50%,
    85% 100%,
    15% 100%,
    0 50%,
    0 50%
  );
  background: #e89c31;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}
.payment-input {
  position: relative;
}
.payment-input p {
  position: absolute;
  top: 11px;
  left: 15px;
}
.payment-input p.text-danger {
  font-size: 12px;
  top: 50px;
}

.payment-input i {
  position: absolute;
  left: 1px;
  top: 1px;
  background: #072d41;
  font-size: 24px;
  padding: 12px;
  border-radius: 8px;
}

.payment-option {
  padding: 20px 0;
}
.payment-input input:not([type="radio"]) {
  padding-left: 60px;
}
.payment-input input:not([type="radio"])::placeholder {
  color: #a9a9a9 !important;
}
.payment-input span {
  font-size: 14px;
  color: #a9a9a9;
}
span.recieve-bonus-money {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 12px;
}
.payment-btn-option {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.payment-btn-option .btn-style {
  margin-top: 25px;
  cursor: pointer;
}
.payment-btn-text {
  padding-left: 50px;
}
.payment-btn span {
  position: relative;
  font-size: 12px;
  width: 100%;
  text-align: center;
  padding: 8px;
}

.back-method {
  display: flex;
  align-items: center;
  padding: 0 20px;
}

.back-method i {
  background: #262833;
  margin-right: 9px;
  border-radius: 50px;
  height: 35px;
  width: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.deposit-amount-show {
  background: #072d41;
  padding: 10px;
  border-left: 2px solid #e89c31;
}

/* *******Thanks******* */
.thanks-popup .modal-content {
  background: #072b3f !important;
  border-radius: 20px;
}
.thanks-popup .modal-header {
  border: 0;
  justify-content: flex-end;
}
.thanks-popup .modal-body {
  border: 0;
  padding: 1rem 1rem;
}
.thanks-popup .modal-body .thanks-popup-content {
  padding: 1rem 1rem 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.thanks-popup .modal-body .mail-img {
  width: 100px;
  margin: auto;
}
.thanks-popup .modal-body h2 {
  color: #fff;
  padding: 20px 0 0;
  margin: 0;
  font-weight: 500;
  font-size: 32px;
}
.thanks-popup .modal-body h4 {
  color: #fff;
  margin: 0;
  font-weight: 500;
}

.thanks-popup .modal-header img {
  height: 20px;
  cursor: pointer;
}
.inventroy-btn {
  display: flex !important;
  justify-content: center;
  margin-top: 35px !important;
}
.inventroy-btn button {
  min-width: 120px !important;
}

/* *******Thanks page******* */
.thanks-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
}
.thanks-image img {
  max-width: 200px;
  width: 100%;
}
.thanks-title {
  padding: 30px 0px;
}
.thanks-popup-content a {
  font-weight: 600;
}
.how-works {
  padding-top: 40px;
}

/* ************************************************** */
/* **************** Media Query  ************ */
/* ************************************************** */
@media screen and (max-width: 1084px) {
  .deposit-select-box {
    flex-wrap: wrap;
  }
}
@media screen and (max-width: 991px) {
  .account-form-area h3.ar-reg-title.text-center.pop_head p {
    font-size: 26px;
  }
  .balance-popup .account-form-area p {
    font-size: 25px !important;
    font-weight: 600;
  }
  .deposit-model .deposit-box {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    cursor: pointer;
  }
  .deposit-popup-left {
    width: 50%;
  }
  .deposit-popup-right {
    width: 50%;
  }
  .deposit-payment-history {
    flex-wrap: wrap;
  }
  .deposit-payment-history span {
    font-size: 14px;
  }
  .deposite-popup .modal-dialog {
    max-width: 90vw;
    margin: 0px auto;
  }
}
@media screen and (max-width: 768px) {
  .deposit-model .deposit-box {
    gap: 5px;
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .payment-btn-text {
    padding: 0;
    margin-top: 10px;
  }
  .payment-btn-option {
    justify-content: center;
  }
  .deposit-popup-right-heading {
    flex-wrap: wrap;
    justify-content: center;
  }
  .deposit-payment-history {
    flex-wrap: wrap;
  }

  .deposit-popup-left,
  .deposit-popup-right {
    width: 100%;
    padding: 15px;
  }
  .deposit-select-box span {
    padding-left: 0;
  }

  .deposit-select-box {
    flex-wrap: wrap;
    padding: 15px 0;
  }

  .back-method h3 {
    font-size: 18px;
  }

  .deposit-popup-heading h3 {
    font-size: 18px;
  }

  .deposit-amount-show p {
    font-size: 12px !important;
  }

  .select-method span {
    font-size: 14px;
  }

  .select-method p {
    font-size: 14px !important;
  }

  .add-promo p {
    font-size: 14px !important;
  }
  .deposit-popup-right-heading h4 {
    font-size: 18px;
  }
  .deposit-popup-right-text li {
    font-size: 14px;
  }
  .deposit-payment-history span {
    font-size: 14px;
    padding-top: 10px;
  }
  .deposit-payment p {
    font-size: 14px;
  }

  .deposit-payment-history {
    flex-wrap: wrap !important;
  }
  .payment-btn-option {
    flex-wrap: wrap;
    justify-content: center;
  }
  .deposit-model .modal_header {
    min-height: 50px !important;
  }
  .deposit-model button.close-btn {
    min-height: 36px !important;
  }
  .deposit-model .case_add h4 {
    margin-top: 10px !important;
  }
  .deposit-select-heading {
    padding: 10px 0;
  }
  .deposit-model .account-form-area {
    height: 75vh !important;
  }
  .payment-select-box {
    padding: 20px 15px;
  }
}
@media screen and (max-width: 767px) {
  .balance-popup .account-form-area p {
    font-size: 22px !important;
  }
  .account-form-area .popup__buttons {
    margin-bottom: 30px !important;
  }
}
@media screen and (max-width: 480px) {
  .deposit-popup-right-heading {
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  .deposit-payment {
    flex-wrap: wrap;
  }
  .deposit-popup-heading h3 {
    font-size: 14px;
  }
  .select-method span {
    font-size: 12px;
  }

  .select-method p {
    font-size: 12px !important;
  }

  .add-promo p {
    font-size: 12px !important;
  }
  .payment-btn-text {
    padding-left: 0;
  }
  .back-method {
    padding-top: 10px;
  }
  .back-method {
    padding: 10px 5px 0px 5px !important;
  }
  .back-method i {
    width: 30px;
    height: 30px;
  }
  .payment-btn-option {
    justify-content: center;
  }
  .payment-btn-text p {
    padding-top: 10px;
    font-size: 14px !important;
    line-height: 1.3;
  }
  .deposit-payment-history span {
    line-height: 1.3;
    padding-bottom: 10px;
    font-size: 10px !important;
  }
  .deposit-model button.close-btn {
    min-height: 30px !important;
  }
  .payment-select-box,
  .payment-select-box .deposite-money {
    font-size: 14px;
  }
  .bonus-money {
    top: 5px;
    right: 5px;
  }
  .bonus-money i {
    font-size: 8px;
  }
  .bonus-level-heading h3 {
    font-size: 14px !important;
  }
}

.free-st-popup .modal-body {
  background-color: #1b1c25;
  padding: 25px;
  border-radius: 4px;
}
.free-st-popup .modal-body form {
  padding: 25px 25px 25px 25px;
}
.free-st-popup .modal-body .payment-header {
  padding: 20px 25px;
  font-weight: 600;
  font-size: 20px;
  border-bottom: #ffffff2d 1px solid;
}
.free-st-popup .modal-body .paymentCrossIcon {
  position: absolute;
  top: 18px;
  right: 18px;
  border: 1px solid #ffffff4d;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.free-st-popup .modal-body .select-banner-option .form-group {
  margin-bottom: 16px !important;
}
.free-st-popup .modal-body .select-banner-option .form-group .form-label {
  font-size: 16px !important;
  font-weight: 400 !important;
  margin-bottom: 6px !important;
  color: dddddd !important;
}
.free-st-popup .modal-body .select-banner-option .form-group .form-control {
  border: 1px solid #292b39 !important;
  -webkit-border-radius: 8px !important;
}
.free-st-popup
  .modal-body
  .select-banner-option
  .form-group
  .form-control::placeholder {
  color: #d8d8d84e !important;
}
.free-st-popup .modal-body .popupBtn {
  padding-top: 6px;
}
.free-st-popup .modal-body .popupBtn button {
  border-radius: 8px !important;
  cursor: pointer;
  background-image: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%) !important;
  border: 1px solid #c5801e !important;
  height: 40px !important;
  font-size: 13px !important;
  padding: 5px 20px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600 !important;
  text-transform: uppercase;
  color: #ffffff !important;
}
