.game-page {
  padding: 250px 0px 100px;
  position: relative;
  overflow: hidden;
  /* background: #072B3F; */
}

/* .game-page:before {
  content: "";
  background: url(../../assets/images/game/pattern.png);
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  opacity: 0.1;
} */
/* .container {
  max-width: 1360px;
} */

.roulette-wrapper {
  width: 100%;
  /* z-index: 9; */
  position: relative;
}

.roulette-wrapper:before {
  content: "";
  background: url(../../assets/images/game/3.png);
  position: absolute;
  top: 50%;
  height: 1200px;
  width: 1920px;
  transform: translate(-50%, -50%);
  left: 50%;
  margin-top: -130px;
  animation: round 9s infinite;
}

@keyframes round {
  0% {
    transform: translate(-50%, -50%) scale(0.7);
  }

  50% {
    transform: translate(-50%, -50%) scale(1);
  }

  100% {
    transform: translate(-50%, -50%) scale(0.7);
  }
}

.roulette-wrapper .container {
  position: relative;
  max-width: 1320px;
}

/* .roulette-wrapper:before {
  content: "";
  background: url(../../assets/images/game/light.png);
  position: absolute;
  width: 968px;
  height: 800px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: block;
  opacity: 0.5;
} */
.roulette-wrapper img {
  width: 100%;
  margin: 0px auto;
  max-width: 150px;
  display: block;
  position: relative;
  max-height: 114px;
  object-fit: contain;
}

.roulette {
  transition: transform ease 0.35s;
  display: -ms-flexbox;
  display: flex;
  will-change: transform;
  position: absolute;
  left: 0;
}

.roulette .item {
  display: inline-block;
  vertical-align: middle;
  height: auto;
  border: none !important;
  background-image: linear-gradient(to right, #1a16221a, #1a16221a);
  margin: 0px 0px;
}

.roulette_wrapper {
  overflow: hidden;
  position: relative;
  height: 220px;
  width: 100%;
}

.roulette_marker {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #87ceff;
  transform: translateX(-50%);
  top: 0;
  bottom: 0;
  display: none;
}

.roulette_container .game-bet-box {
  background: transparent !important;
  padding: 10px;
  border-radius: 0px;
  width: 185px;
  height: 220px;
  margin: 0px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  color: #000;
  cursor: pointer;
  transition: all 0.5s;
}

.roulette_container .game-bet-box:before {
  position: absolute;
  content: "";
  top: 4%;
  left: 50%;
  transform: translate(-50%, -4%);
  width: 120px;
  height: 120px;
  display: block;
  background: url(../../assets/images/game/shape/o.png);
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
  opacity: 0.1;
}

.roulette_container .game-bet-box:hover:before {
  opacity: 1;
  transform: translate(-50%, -2%) scale(1.1);
}

.roulette_container .game-bet-box.cream-box {
  background: #e89c31;
}

.roulette_container .game-bet-box.gray-box {
  background: #d8d8d8;
}

.roulette_container .game-bet-box.dark-box {
  background: #031c2a;
}

.roulette_container .game-bet-box.dark-box:nth-of-type(odd) {
  background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #031b28);
  border-bottom: 4px solid #083248;
}

.roulette_container .game-bet-box.dark-box:nth-of-type(even) {
  background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #083248);
  border-bottom: 4px solid #104a68;
}

.item-info {
  width: 100%;
  text-align: center;
}

.item-info h5 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.5px;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
}

.item-info span {
  display: block;
  font-size: 12px;
  font-weight: 100;
  letter-spacing: 1px;
}

.bet-box.gray {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #d8d8d8;
  border-radius: 5px;
  margin-right: 10px;
}

.bet-box.green {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #298854;
  border-radius: 5px;
  margin-right: 10px;
}

.bet-box.cream {
  width: 20px;
  height: 20px;
  display: inline-block;
  background: #d99f69;
  border-radius: 5px;
  margin-right: 10px;
}

.roulette_container .game-bet-box h4 {
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  color: #fff;
}

.roulette_container .game-bet-box.gray-box h4 {
  color: #000;
}

.button_wrapper {
  margin-top: 30px;
}

.roulette_section {
  min-height: 330px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  /* overflow: hidden; */
  padding: 0;
  position: relative;
  filter: drop-shadow(0px 20px 40px #000);
  transition: all 0.8s;
}

.roulette_section:before {
  content: "";
  /* background: url(../../assets/images/game/game-bg.png); */
  background: url(../../assets/images/game/single-updated-game-bg.png);
  position: absolute;
  display: block;
  background-repeat: no-repeat !important;
  height: 701px;
  width: 1618px;
  bottom: 0;
  background-size: cover !important;
  background-position: center !important;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.roulette_container {
  position: relative;
  width: 100%;
  padding: 20px 0px;
}

.roulette_logo {
  width: 100px;
  padding: 2px 10px;
  cursor: pointer;
}

.roulette_logo img {
  width: 100%;
}

.roulette_group_type {
  width: 100px;
  padding: 2px 10px;
  text-align: center;
}

.roulette_group_type h5 {
  text-transform: uppercase;
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}

.roulette_group_type span {
  text-transform: uppercase;
  font-weight: 700;
  font-size: 14px;
  color: #191e24;
  line-height: 1;
}

.spin-button {
  text-align: center;
}

.spin-button .btn {
  max-width: 275px;
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  padding: 8px 20px;
  background: #8c0e0f;
  border-color: #8c0e0f;
  border-radius: 30px;
  margin-top: 10px;
  letter-spacing: 1px;
}

/****************** animation button *************/
.animated-button {
  transform: translate(0%, 0%);
  overflow: hidden;
}

.animated-button::before {
  content: "";
  -webkit-transition: 0.2s opacity ease-in-out;
  transition: 0.2s opacity ease-in-out;
}

.animated-button:hover::before {
  opacity: 0.2;
}

.animated-button span {
  position: absolute;
}

.animated-button span:nth-child(1) {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(
    linear,
    right top,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(#f50406)
  );
  background: linear-gradient(to left, rgba(8, 20, 43, 0), #f50406);
  -webkit-animation: 2s animateTop linear infinite;
  animation: 2s animateTop linear infinite;
}

@-webkit-keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

@keyframes animateTop {
  0% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
}

.animated-button span:nth-child(2) {
  top: 0px;
  right: 0px;
  height: 100%;
  width: 3px;
  background: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(rgba(8, 20, 43, 0)),
    to(#f50406)
  );
  background: linear-gradient(to top, rgba(8, 20, 43, 0), #f50406);
  -webkit-animation: 2s animateRight linear -1s infinite;
  animation: 2s animateRight linear -1s infinite;
}

@-webkit-keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

@keyframes animateRight {
  0% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }

  100% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }
}

.animated-button span:nth-child(3) {
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 3px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(rgba(8, 20, 43, 0)),
    to(#f50406)
  );
  background: linear-gradient(to right, rgba(8, 20, 43, 0), #f50406);
  -webkit-animation: 2s animateBottom linear infinite;
  animation: 2s animateBottom linear infinite;
}

@-webkit-keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes animateBottom {
  0% {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }

  100% {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

.animated-button span:nth-child(4) {
  top: 0px;
  left: 0px;
  height: 100%;
  width: 3px;
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(rgba(8, 20, 43, 0)),
    to(#f50406)
  );
  background: linear-gradient(to bottom, rgba(8, 20, 43, 0), #f50406);
  -webkit-animation: 2s animateLeft linear -1s infinite;
  animation: 2s animateLeft linear -1s infinite;
}

@-webkit-keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

@keyframes animateLeft {
  0% {
    -webkit-transform: translateY(-100%);
    transform: translateY(-100%);
  }

  100% {
    -webkit-transform: translateY(100%);
    transform: translateY(100%);
  }
}

/****************** animation button *************/

.spin-button .btn:hover,
.spin-button .btn:focus,
.spin-button .btn-primary:not(:disabled):not(.disabled):active:focus {
  background: #8c0e0f;
  border-color: #8c0e0f;
  box-shadow: none;
}

.roulette-container-line--bottom {
  bottom: 0;
}

.roulette-container-line--top {
  top: 0;
}

.roulette-container-line--bottom,
.roulette-container-line--top {
  position: absolute;
  left: 0;
  background-color: #d99f69;
  right: 0;
  height: 2px;
  width: 100%;
  transition: background-color 0.4s ease-in;
}

.roulette-container-line--mask {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(to right, #191c24 3%, transparent, #191c24 97%);
}

.roulette-container-shadow--left,
.roulette-container-shadow--right {
  z-index: 1000;
  position: absolute;
  top: 50%;
  bottom: 0;
  background: linear-gradient(
    90deg,
    rgba(3, 27, 40, 0.804359243697479) 0%,
    rgba(3, 27, 40, 0.8015581232492998) 25%,
    rgba(3, 27, 40, 0.8015581232492998) 50%,
    rgba(3, 27, 40, 0.6839110644257703) 75%,
    rgba(3, 27, 40, 0) 100%
  );
  height: 85%;
  width: 250px;
  opacity: 0.8;
  transform: translateY(-50%);
}

.roulette-container-shadow--left {
  left: 0;
}

.roulette-container-shadow--right {
  right: 0px;
  transform: rotate(180deg) translateY(50%);
}

.roulette-indicator {
  position: absolute;
  height: 30px;
  width: auto;
  left: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 15;
  display: none !important;
}

.roulette-indicator--top {
  transform: translate(-50%, -50%) rotate(90deg);
  top: -12px;
}

.roulette-indicator svg {
  height: 100%;
  width: auto;
}

.roulette-indicator--bottom {
  transform: translate(-50%, 50%) rotate(-90deg);
  bottom: 5px;
}

.rolling {
  position: absolute;
  z-index: 9999;
  left: 50%;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 30px 50px;
  color: #d99f69;
}

.disable-roulette:before {
  content: "";
  position: absolute;
  background: #191c24bf;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999;
}

.rolling span {
  display: block;
}

.rolling h2 {
  font-family: "Poppins", sans-serif !important;
  font-weight: 700;
}

.rolling h2 span {
  font-family: "Poppins", sans-serif !important;
  font-weight: 500;
  font-size: 20px;
  display: inline-block;
}

/********************* Winner Animation ***************/

.win-grid-container {
  position: fixed;
  z-index: 999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0px 0px 50px;
  border-radius: 20px;
  background: #041f2d;
  transition: all 1s;
}

.win-grid-bix {
  display: flex;
  align-items: center;
  height: 100%;
  background: url(../../assets/images/game/win-bg.png);
  width: 600px;
  height: 496px;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  transition: all 0.5s;
}

.win-grid-bix img {
  max-width: 300px;
  transition: all 0.5s;
  animation-fill-mode: inherit;
  position: relative;
  z-index: 9;
  max-height: 275px;
  /* cursor: -moz-zoom-in;
  cursor: -webkit-zoom-in; */
  cursor: pointer;
  /* cursor: url("../../assets/images/icon/search.png"), auto; */
}

.win-grid-bix .middle {
  transition: 0.5s ease;
  opacity: 0;
  position: absolute;
  top: 51%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.win-grid-bix:hover .middle {
  opacity: 1;
}

.win-grid-bix:hover img {
  opacity: 0.1;
}

.win-grid-bix .la-search {
  font-size: 40px;
  background: #083d5a;
  border-radius: 5px;
  padding: 3px;
  opacity: 0.8;
}

.win-grid-bix .sm_search .la-search {
  font-size: 25px;
}

.win-grid-bix img:hover {
  filter: drop-shadow(4px 8px 12px black);
  transform: rotate(-1deg) scale(1.1);
}

.body-fade {
  overflow: hidden;
}

.body-fade:before {
  content: "";
  background: rgba(3, 27, 40, 0.9);
  /* background: rgb(3 27 40 / 85%) url(../../assets/images/game/roulette-bg.jpg); */
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 9999;
  opacity: 0.9;
}

/* .win-grid:before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  height: 300px;
  display: block;
  background: url(../../assets/images/game/shape.png);
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.5s;
  opacity: 0.2;
}

.win-grid:hover:before {
  transform: rotate(1deg) translate(-50%, -50%);
  filter: drop-shadow(4px 8px 12px black);
} */

.win-btn {
  width: 100%;
  text-align: center;
  margin-top: -50px;
  position: relative;
}

.win-btn button.roll-btn {
  background: transparent;
  border: 2px solid #fff;
}

.win-btn .btn,
.win-btn .btn:hover,
.win-btn .btn:focus,
.win-btn .btn-primary:not(:disabled):not(.disabled):active:focus {
  margin: 0px 5px;
  min-width: 200px;
  background: #08283a;
  border: 2px solid #08283a;
  height: 50px;
  font-size: 16px;
  font-weight: 600;
  border-radius: 30px;
  transition: all 0.5s;
  box-shadow: none;
}

.win-btn .btn.quick-sell,
.win-btn .btn.quick-sell:hover,
.win-btn .btn.quick-sell:focus,
.win-btn
  .quick-sell.btn-primary:first-child:not(:disabled):not(
    .disabled
  ):active:focus {
  background: transparent;
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
}

.win-grid h3 {
  text-align: center;
  padding-bottom: 30px;
  margin-bottom: 0px;
  font-size: 28px;
  text-transform: capitalize;
}

.win-close-icon {
  position: absolute;
  right: 30px;
  top: 30px;
  cursor: pointer;
  z-index: 9;
  background: #031b28;
  padding: 5px;
  border-radius: 5px;
}

.win-close-icon img {
  width: 25px;
  opacity: 0.8;
  cursor: pointer;
}

.win-close-icon img:hover {
  opacity: 1;
}

.win-btn h5 {
  margin: 0;
  font-size: 15px;
  padding-top: 14px;
  font-weight: 100;
  color: #47697c;
  transition: all 0.5s;
  cursor: pointer;
}

.win-btn h5:hover {
  color: #fff;
}

/********************* Game Type  ********************/

.game-type {
  max-width: 275px;
  width: 100%;
  font-weight: 400;
  font-size: 16px;
  padding: 8px 20px;
  background: #1b1c25;
  border: 1px solid #292d39;
  margin: 0px auto;
  margin-top: 35px;
  clip-path: polygon(
    8% 0%,
    92% 0%,
    100% 50%,
    100% 50%,
    92% 100%,
    8% 100%,
    0 50%,
    0 50%
  );
}

.game-type ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.game-type ul li {
  width: 25%;
  text-align: center;
  position: relative;
  color: #47697c;
  cursor: pointer;
  transition: all 0.8s;
}

.game-type ul li span {
  color: #a0a0a0;
  display: block;
  cursor: pointer;
}

.game-type ul li.active span {
  color: #fff;
}

.game-type ul li span {
  position: relative;
}

.game-type ul li.active:before {
  content: "";
  background: #ffffff2d;
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  clip-path: polygon(
    15% 0%,
    85% 0%,
    100% 50%,
    100% 50%,
    85% 100%,
    15% 100%,
    0 50%,
    0 50%
  );
}

/********************* Animations ********************/

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px #ee00630a;
  }
}

/**************************************** Two Roulette *************************************/

.roulette_section.roulette-bg-horizontal:before {
  content: "";
  /* background: url(../../assets/images/game/game-bg-horizontal.png); */
  background: url(../../assets/images/game/updated-game-bg.png);
  position: absolute;
  right: 0;
  display: block;
  height: 701px;
  width: 1516px;
  bottom: 0;
  background-size: 100%;
  background-repeat: no-repeat !important;
  background-position: center !important;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.two-roulette {
  position: relative;
  width: 100%;
}

.two-roulette-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.two-roulette .roulette-indicator.roulette-indicator--top {
  top: 53%;
  left: -34px;
  transform: translateY(-50%);
}

.two-roulette .roulette-indicator.roulette-indicator--bottom {
  top: 53%;
  right: -34px;
  transform: translateY(-50%) rotate(180deg);
  left: inherit;
}

.two-roulette .roulette_marker {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(-50%);
  top: 0px;
  bottom: 0;
}

/* .two-roulette .item-info {
  display: none;
} */

.two-roulette .roulette_container .game-bet-box:before {
  display: none;
}

.two-roulette .roulette_container .game-bet-box {
  /* background-image: none !important; */
  border-bottom: none !important;
  /* background: transparent !important; */
}

.two-roulette .roulette_container {
  width: 50%;
}

.two-roulette .roulette_wrapper {
  width: 100px;
  margin: 0px auto;
}

.two-roulette .roulette {
  display: block;
  top: -15px;
}

.two-roulette .roulette_container .game-bet-box {
  padding: 5px;
  width: 100px;
  height: 80px;
  margin: 10px 0;
  border-radius: 5px;
}

/*** New css for two roulette ***/
.two-roulette .item-info {
  margin-top: 5px;
  padding: 2px 0;
}

.two-roulette .item-info h5 {
  font-size: 14px;
}

.two-roulette .item-info .role_price {
  font-size: 10px;
  margin-top: 0px;
  line-height: 15px;
}

.two-roulette .item-info {
  position: absolute;
  bottom: -15px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 0%);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  background: -webkit-linear-gradient(
    90deg,
    transparent 18%,
    rgb(4 59 89 / 59%) 82%
  );
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* display: none; */
}

/*** New css for two roulette ***/

/**************************************** Three Roulette *************************************/

.three-roulette {
  position: relative;
  width: 100%;
}

.three-roulette-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.three-roulette .roulette-indicator.roulette-indicator--top {
  top: 53%;
  left: -34px;
  transform: translateY(-50%);
}

.three-roulette .roulette-indicator.roulette-indicator--bottom {
  top: 53%;
  right: -34px;
  transform: translateY(-50%) rotate(180deg);
  left: inherit;
}

.three-roulette .roulette_marker1 {
  position: absolute;
  left: 33%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(-33%);
  top: 0px;
  bottom: 0;
  display: none;
}

.three-roulette .roulette_marker2 {
  position: absolute;
  right: 33%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(33%);
  top: 0px;
  bottom: 0;
  display: none;
}

/* .three-roulette .item-info {
  display: none;
} */

.three-roulette .roulette_container .game-bet-box:before {
  display: none;
}

.three-roulette .roulette_container .game-bet-box {
  /* background-image: none !important; */
  border-bottom: none !important;
  /* background: transparent !important; */
}

.three-roulette .roulette_container {
  width: 33.33%;
}

.three-roulette .roulette_wrapper {
  width: 100px;
  margin: 0px auto;
}

.three-roulette .roulette {
  display: block;
  top: -15px;
}

.three-roulette .roulette_container .game-bet-box {
  padding: 5px;
  width: 100px;
  height: 80px;
  margin: 10px 0;
  border-radius: 5px;
}

/*** New css for three roulette ***/
.three-roulette .item-info {
  margin-top: 5px;
  padding: 2px 0;
}

.three-roulette .item-info h5 {
  font-size: 14px;
}

.three-roulette .item-info .role_price {
  font-size: 14px;
  margin-top: -7px;
}

.three-roulette .item-info {
  position: absolute;
  bottom: -15px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 0%);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  background: -webkit-linear-gradient(
    90deg,
    transparent 18%,
    rgb(4 59 89 / 59%) 82%
  );
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* display: none; */
}

/*** New css for three roulette ***/
/**************************************** Four Roulette *************************************/

.four-roulette {
  position: relative;
  width: 100%;
}

.four-roulette-content {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.four-roulette .roulette-indicator.roulette-indicator--top {
  top: 53%;
  left: -34px;
  transform: translateY(-50%);
}

.four-roulette .roulette-indicator.roulette-indicator--bottom {
  top: 53%;
  right: -34px;
  transform: translateY(-50%) rotate(180deg);
  left: inherit;
}

.four-roulette .roulette_marker1 {
  position: absolute;
  left: 25%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(-25%);
  top: 0px;
  bottom: 0;
  display: none;
}

.four-roulette .roulette_marker2 {
  position: absolute;
  right: 25%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(25);
  top: 0px;
  bottom: 0;
  display: none;
}

.four-roulette .roulette_marker3 {
  position: absolute;
  left: 50%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(-50%);
  top: 0px;
  bottom: 0;
  display: none;
}

/* .four-roulette .item-info {
  display: none;
} */

.four-roulette .roulette_container .game-bet-box:before {
  display: none;
}

.four-roulette .roulette_container .game-bet-box {
  /* background-image: none !important; */
  border-bottom: none !important;
  /* background: transparent !important; */
}

.four-roulette .roulette_container {
  width: 25%;
}

/* .four-roulette .roulette_wrapper {
  width: 174px;
  margin: 0px auto;
} */
.four-roulette .roulette_wrapper {
  width: 260px;
  margin: 0px auto;
}
.four-roulette .roulette {
  display: block;
  top: -15px;
}

.four-roulette .roulette_container .game-bet-box {
  padding: 5px;
  width: 100px;
  height: 80px;
  margin: 10px auto 10px auto;
  border-radius: 5px;
}

/*** New css for three roulette ***/
.four-roulette .item-info {
  margin-top: 5px;
  padding: 2px 0;
}

.four-roulette .item-info h5 {
  font-size: 14px;
}

.four-roulette .item-info .role_price {
  font-size: 14px;
  margin-top: -7px;
}

.four-roulette .item-info {
  position: absolute;
  bottom: -15px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 0%);
  backdrop-filter: blur(4.9px);
  -webkit-backdrop-filter: blur(4.9px);
  background: -webkit-linear-gradient(
    90deg,
    transparent 18%,
    rgb(4 59 89 / 59%) 82%
  );
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  /* display: none; */
}

/*** New css for three roulette ***/

/**************************************** Five Roulette *************************************/
.five-roulette .roulette_container {
  width: 20%;
}

.five-roulette .line_marker1 {
  left: 20%;
}

.five-roulette .line_marker2 {
  right: 20%;
}

.five-roulette .line_marker3 {
  left: 60%;
}

.five-roulette .roulette_marker4 {
  position: absolute;
  left: 40%;
  z-index: 1;
  width: 2px;
  height: 100%;
  background: #032030;
  transform: translateX(-50%);
  top: 0px;
  bottom: 0;
}

.five-roulette .default-placeholder .small-placeholder {
  width: unset;
  display: block;
}

/********** Placeholder ***************/

.roulette-placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background: url(../../assets/images/game/3.png); */
  width: 650px;
  height: 537px;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0px auto;
  margin-top: -446px;
  /* position: absolute; */
}

.roulette-placeholder::before {
  content: "";
  position: absolute;
  height: 1200px;
  width: 1920px;
  background: url(../../assets/images/game/3.png);
  /* animation: imgRotate 35s linear infinite; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: 75%;
  margin-top: 140px;
  animation: ripple 9s infinite;
  /* animation-delay: 5s; */
}

@keyframes ripple {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.6);
  }
}

.roulette-placeholder::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(../../assets/images/game/mob-bg.png);
  z-index: -1;
  left: 0;
  background-position: center;
  background-size: 85%;
  background-repeat: no-repeat;
}

@keyframes imgRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.placeholder-section {
  background: url(../../assets/images/game/circle2.gif);
  background-repeat: no-repeat;
  margin-bottom: 0px;
  position: relative;
  height: 400px;
  width: 400px;
  background-size: 100%;
  background-position: center center;
}

@keyframes playX {
  from {
    background-position-x: 0px;
  }

  to {
    background-position-x: -2225px;
  }
}

@keyframes playY {
  from {
    background-position-y: 0px;
  }

  to {
    background-position-y: -1338px;
  }
}

/* @keyframes playX {
  from {
    background-position-x: 0;
  }
  to {
    background-position-x: -100%;
  }
} */
/* @keyframes playY {
  from {
    background-position-y: 0;
  }
  to {
    background-position-y: -100%;

  }
} */

.roulette-placeholder-box img {
  max-width: 420px;
  transition: all 0.5s;
  animation-fill-mode: inherit;
  z-index: 9;
  max-height: 400px;
  /* margin-top: -60px; */
  /* margin-left: 35px; */
}

.game-page .roulette-placeholder-box img {
  animation: image_zoom 3s infinite;
}

.game-page .roulette-placeholder-box {
  /* margin-left: -30px; */
}

@keyframes image_zoom {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.6);
  }
}

@keyframes ripple {
  0% {
    transform: scale(0.6);
  }

  50% {
    transform: scale(1);
  }

  100% {
    transform: scale(0.6);
  }
}

.game-page .roulette-placeholder-box {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
  /* margin-top: 10px; */
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.game-page .roulette-placeholder-box .light-glow {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  animation: imgRotate 35s linear infinite;
}

@keyframes imgRotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.roulette-placeholder-box img:hover {
  filter: drop-shadow(4px 8px 12px black);
  transform: rotate(-1deg) scale(1.1);
}

.roulette_section.hide {
  opacity: 0;
}

/************** Small Placeholder *************/

.small-placeholder {
  display: none !important;
}

.small-placeholder img {
  animation: image_zoom 3s ease-out infinite;
}

@keyframes image_zoom {
  0% {
    transform: scale(0.9, 0.9);
  }

  50% {
    transform: scale(1, 1);
  }

  100% {
    transform: scale(0.9, 0.9);
  }
}

.default-placeholder .small-placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  display: block !important;
  z-index: 9999999999;
  text-align: center;
}

.disables {
  pointer-events: none;
  opacity: 0.4;
}

.default-placeholder .roulette {
  opacity: 0;
}

.winner-horizontal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  width: 100%;
  text-align: center;
}

.roulette-bg-horizontal .roulette.light {
  opacity: 0;
}

.winner-horizontal img {
  max-width: 80px;
  width: 100%;
}

.winner-horizontal h5 {
  padding-top: 10px;
  font-weight: 100;
  font-size: 15px;
}

.spin-horiz {
  justify-content: space-around;
  display: flex;
  align-items: center;
}

.fairness-link {
  padding-top: 10px;
}

.fairness-link a i {
  margin-right: 10px;
  background: #ffffff2d;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 28px;
  border-radius: 30px;
}

.fairness-link a {
  color: #c5c5c5;
  transition: all 0.5s;
  display: inline-block;
  margin-right: 10px;
}

.fairness-link a:hover {
  color: #fff;
}

.fairness-link .fa-box {
  font-size: 15px;
}

.fairness-link span {
  margin: 0 15px;
  cursor: pointer;
  color: #e1e1e1;
  background: #ffffff2d;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 30px;
  transition: all 0.5s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.fairness-link span i {
  font-size: 22px;
  margin-top: 2px !important;
}

.fairness-link .fa-volume-mute,
.fa-volume-up {
  margin-top: 7px;
}

.hot-keys-section .fairness-link span {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin-top: 12px;
}

.hot-keys-section .fairness-link span i {
  font-size: 17px;
  margin-top: 0px !important;
}

.hot-keys-section .fairness-link .fa-volume-mute,
.fa-volume-up {
  margin-top: 0px;
}

.fairness-link span:hover {
  color: #fff;
}

.game-page .item-card__content {
  background: transparent;
  border: none;
}

.game-page .contest-card__thumb {
  background-image: none;
  background-color: transparent;
}

/********** Start Style for Game popup 2 roll ***********/
.game-page .win-grid-container-two {
  display: flex;
  flex-direction: column;
}

.game-page .box__containers {
  display: flex;
  flex-direction: row;
}

.game-page .win-grid-container-two .win-grid-bix img {
  max-width: 140px;
  transition: all 0.5s;
  animation-fill-mode: inherit;
  position: relative;
  z-index: 9;
  max-height: 275px;
}

.game-page .win_grid_container h2 {
  text-align: center;
  padding-top: 50px;
  font-size: 35px;
}

.game-page .win-grid-container-two .win-grid-bix {
  width: 300px;
  height: 250px;
}

.game-page .win-grid-container-two .win-btn h3 {
  text-align: center;
  padding-bottom: 10px !important;
  margin-bottom: 0px;
  font-size: 22px;
  text-transform: capitalize;
}

.game-page .win-grid-container-two .win-btn {
  width: 100%;
  text-align: center;
  margin-top: -20px;
  position: relative;
}

.game-page .win-grid-container-two .win__btn {
  margin-top: 30px;
  padding-bottom: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-top: 40px;
  margin: 0px auto;
  align-items: center;
  width: 40%;
}

.game-page .win-grid-container-two .win__btn .quick_sell {
  margin-bottom: 15px;
}

.game-page .win-grid-container-two button.quick-sell.quick___sell {
  min-width: 173px;
}

/********** End Style for Game popup 2 roll ***********/

/********** Start Style for Game popup 3 and 4 roll ***********/
.game-page .win-grid-container-three .win-grid-bix img {
  max-width: 80px;
}

.game-page .win-grid-container-three .win-grid-bix {
  width: 160px;
  height: 130px;
  background: none;
}

.game-page .win-grid-container-three .img_name_btn {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

/* .game-page .win-grid-container-three .win-grid h3 {
  text-align: center;
  padding-bottom: 0;
  margin-bottom: 0px;
  font-size: 28px;
  text-transform: capitalize;
} */
.game-page .win-grid-container-three .img_name_btn .quick_sells {
  height: 45px;
}

.game-page .win-grid-container-three .win-btn {
  width: unset;
  text-align: center;
  margin-top: 10px;
  position: relative;
}

.game-page .win-grid-container-three .box__name h3 {
  padding-bottom: 0;
  font-size: 22px;
  font-weight: 500;
  margin-top: 20px;
  margin-right: 30px;
}

.game-page .win-grid-container-three .win__btn {
  margin-top: 10px;
}

/********** End Style for Game popup 3 roll ***********/

/************ Start Style for game img, btn, tab ***********/
.game-page .spin-button .btn {
  transition: 0.5s;
  border-radius: 0;
}

.game-page .spin-button .btn:hover {
  background-color: #9e0505;
}

.game-page .contest-card {
  transition: all 0.5s;
}

.game-page .contest-card:hover {
  transform: scaleY(1.05);
}

.game-page .game-type {
  max-width: 270px;
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  padding: 11px 25px;
  margin-top: 0px;
  display: flex;
  align-items: center;
  min-height: 90px;
}

.game-page .game-type span {
  font-size: 20px;
  color: #e9e9e9;
  line-height: 1.7;
  margin: 0;
}

.game-page .spin-button .btn {
  max-width: 444px;
  width: 100%;
  font-weight: 600;
  font-size: 30px;
  padding: 8px 20px;
  margin-top: 10px;
}

.game-page h5.contest-card__name {
  font-size: 18px;
  text-transform: capitalize;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  overflow: hidden;
  -webkit-box-orient: vertical;
}

.game-page .item-info .role_price {
  color: #e79933;
  font-weight: 600;
  font-size: 14px;
}

.game-page .item-info h5 {
  text-transform: capitalize;
}

.game-page p.time_min {
  line-height: 0.8;
  font-size: 14px;
  font-weight: 600;
  color: #e79933;
  text-align: left;
}

.game-page .time_min span {
  font-size: 12px;
  font-weight: 400;
}

.game-page .recent-item-content {
  margin-bottom: 5px;
}

.game-page .contest-details-content p {
  text-transform: capitalize;
}

.spin-button button {
  margin: 0px 10px;
  text-transform: uppercase;
}

/************ End Style for game img, btn, tab  ***********/

/******* style for game 2-3-4 light glow animation *****/
/* .game-page img.light-glow-2-3-4 {
  animation: imgRotate 35s linear infinite;
  position: absolute;
  right: 0;
  top: -15px;
  bottom: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  max-width: 150px;
  max-height: 180px;
}
.game-page .two-roulette .roulette_wrapper, .three-roulette .roulette_wrapper, .four-roulette .roulette_wrapper {
  width: 150px;
  margin: 0px auto;
}

@media screen and (max-width: 1024px) {
  .game-page img.light-glow-2-3-4 {
    top: -10px;
    max-width: 150px;
    max-height: 100px;
  }
} */
/******* style for game 2-3-4 light glow animation *****/

/* fairness popup */
.fairmodalbady {
  color: hsla(0, 0%, 100%, 0.7);
}

.fairness-code {
  border: 1px dashed #3e395f;
  color: #bbbbc0;
  display: inline-block;
  padding: 10px;
  margin: 16px 0;
}

.fair-title-arrow-left {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  display: inline-block;
  position: relative;
  top: -3px;
}

.fair-title-arrow-left:before {
  box-shadow: 0 0 10px 2px rgb(255 71 0 / 90%);
  content: "";
  position: absolute;
}

.fair-title-arrow-left {
  border-left: 4px solid #e89b32;
  box-shadow: 0 0 10px 2px rgb(255 71 0 / 20%);
  position: absolute;
  left: 0;
  top: 10px;
}

.varify-modal {
  margin: 10px 0 25px;
}

.fairmodalbody-verify {
  color: hsla(0, 0%, 100%, 0.7);
}

span.dd-list__item-text {
  color: hsla(0, 0%, 100%, 0.7);
}

ul.varify-modal li {
  position: relative;
  padding-left: 20px;
}

.watch-battle-page .three-roulette .roulette_wrapper,
.watch-battle-page .two-roulette .roulette_wrapper {
  width: 200px;
}

.last-live-drops-section .icon-item-thumb {
  width: 100%;
  text-align: center;
}

#coin {
  height: 120px;
  width: 120px;
  position: relative;
  margin: 0;
  transform-style: preserve-3d;
}

.coin-flip {
  position: absolute;
  top: 30%;
  left: 46%;
  /* background: red; */
  z-index: 99999999;
  text-align: center;
}

.added-flip-cion {
  position: relative;
}

#coin img {
  width: 120px;
}

.tails {
  transform: rotateX(180deg);
}

.heads,
.tails {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

@keyframes flip-heads {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(2520deg);
  }
}

@keyframes flip-tails {
  0% {
    transform: rotateX(0);
  }

  100% {
    transform: rotateX(2340deg);
  }
}

button.try-btn.animated-button.btn.btn-primary {
  background: transparent;
  border: none;
  color: #ffffff;
  max-width: -moz-fit-content;
  max-width: fit-content;
  height: auto;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 22px;
}

button.try-btn.animated-button img {
  height: 25px;
  width: fit-content;
  object-fit: cover;
  max-width: fit-content;
  margin: 0;
  margin-right: 15px;
}

.game-page .spin-button .animated-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.game-page .spin-button .animated-button img {
  height: 25px;
  width: fit-content;
  object-fit: cover;
  max-width: fit-content;
  margin: 0;
  margin-right: 15px;
}
.game-page .spin-button .animated-button img.roll-rocket {
  margin-left: 10px;
  margin-right: 0;
  height: 35px;
  max-width: 35px;
  width: 100%;
  object-fit: unset;
}

.roll-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

/* .roulette-wrapper{
  background: url(../../assets/images/game/1.png);
  background-position: top;
  background-repeat: no-repeat;
  background-size: 100%;
} */
.placeholder-section::before {
  content: "";
  position: absolute;
  background: url(../../assets//images/game/spiral.png);
  height: 574px;
  width: 700px;
  z-index: -1;
  top: 50%;
  background-size: cover;
  background-position: center;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: bubble 20s infinite;
  /* opacity: 0; */
}

@keyframes bubble {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
    opacity: 0.8;
  }

  50% {
    transform: translate(-50%, -50%) rotate(180deg);
    opacity: 1;
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
    opacity: 0.8;
  }
}

.shake {
  background-color: #2bd993;
  color: #fff;
  font-size: 24px;
  margin: 30px 0 0;
  padding: 20px;
  text-transform: uppercase;
}

.shake:hover {
  animation: shake 150ms 2 linear;
  -moz-animation: shake 150ms 2 linear;
  -webkit-animation: shake 150ms 2 linear;
  -o-animation: shake 150ms 2 linear;
}

@keyframes shake {
  0% {
    transform: translate(3px, 0);
  }

  50% {
    transform: translate(-3px, 0);
  }

  100% {
    transform: translate(0, 0);
  }
}

@-moz-keyframes shake {
  0% {
    -moz-transform: translate(3px, 0);
  }

  50% {
    -moz-transform: translate(-3px, 0);
  }

  100% {
    -moz-transform: translate(0, 0);
  }
}

@-webkit-keyframes shake {
  0% {
    -webkit-transform: translate(3px, 0);
  }

  50% {
    -webkit-transform: translate(-3px, 0);
  }

  100% {
    -webkit-transform: translate(0, 0);
  }
}

@-ms-keyframes shake {
  0% {
    -ms-transform: translate(3px, 0);
  }

  50% {
    -ms-transform: translate(-3px, 0);
  }

  100% {
    -ms-transform: translate(0, 0);
  }
}

@-o-keyframes shake {
  0% {
    -o-transform: translate(3px, 0);
  }

  50% {
    -o-transform: translate(-3px, 0);
  }

  100% {
    -o-transform: translate(0, 0);
  }
}

/*******input range *********/
.inputRange-slider {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.inputRange-slider .input-range {
  max-width: 95%;
}

.inputRange-slider .input-range__label--min,
.inputRange-slider .input-range__label--max {
  display: none;
}

.inputRange-slider .input-range__label-container {
  margin-top: -10px;
  font-family: "Biko";
  font-size: 14px;
}

.inputRange-slider .input-range__label--value {
  position: absolute;
  top: 10px;
}

.inputRange-slider .input-range__slider {
  background: #e89c31;
  border: 1px solid #e89c31;
}

.inputRange-slider .input-range__track--active {
  background: #e89c31;
}

.inputRange-slider span.input-range__label-container {
  background: #e89c31;
  min-width: 30px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px !important;
  padding: 0px 5px;
  margin-top: 0px !important;
}

.slider-label {
  -webkit-appearance: none;
  appearance: none;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 1rem;
  outline: none;
  position: absolute;
  top: 50%;
  transition: box-shadow 0.3s ease-out, -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out;
  transition: transform 0.3s ease-out, box-shadow 0.3s ease-out,
    -webkit-transform 0.3s ease-out;
  width: 1rem;
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d);
  border: 1px solid #e89c31;
  margin-top: -3px;
}

.hot-keys-section {
  width: -moz-fit-content;
  width: fit-content;
  display: flex;
  align-items: center;
  background: #1b1c25;
  padding: 0px;
  border-radius: 5px;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
  border: 1px solid #292b39;
}

.hot-keys-section .content {
  display: flex;
  height: 100%;
  justify-content: space-between;
  margin: 0px 8px !important;
}

.game-page .stripe-details {
  max-width: 444px;
}

.case-quantity {
  width: 100%;
  max-width: 190px;
  background: transparent;
  margin-left: 10px;
  padding: 0;
  min-height: 90px;
  display: flex;
  align-items: center;
}

.case-quantity h6 {
  font-size: 13px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.7;
  margin: 0px;
  text-transform: uppercase;
}

.case-quantity .game-type {
  border-radius: 5px;
  clip-path: none;
  display: block;
  padding: 15px 8px 11px 8px;
  max-width: 200px;
  min-height: 80px;
}

.case-quantity .game-type span {
  font-size: 18px;
}

/* @media screen and (max-width:767px){
  .game-type ul li {
    display: none;
}

.game-type ul li:first-child {
    display: block;
}

.game-type ul {
    justify-content: center;
}
} */
