.mt-10 {
  margin-top: 10px;
}
.mt-20 {
  margin-top: 20px;
}
.unboxing-hero-section {
  background-image: -webkit-linear-gradient(
    -90deg,
    #031b28 0%,
    #092230 100%
  ) !important;
  padding: 200px 0 !important;
}
.case-list-page {
  padding-top: 150px;
}

/* .unboxing-hero-section:before {
  content: "";
  background: url(../../assets/images/game/pattern.png);
  position: absolute;
  top: 0%;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  opacity: 0.1;
} */

.contest-details-grid {
  background: #1b1c25;
  border-radius: 12px;
  padding: 20px 20px 25px;
  border: 1px solid #131313;
}

.unboxing-header {
  background-color: #041f2d;
}
.page-list {
  cursor: pointer;
}
.unboxing-page-content .unboxing-header {
  background-color: rgba(4, 31, 45, 0) !important;
}
.unboxing-page-content .contest-card__thumb {
  background: linear-gradient(
    310deg,
    #1b1c25 0%,
    #242530 25%,
    #1b1c25 50%,
    #1b1c25 75%,
    #292b39 100%
  ) !important;
}
.unboxing-page-content .contest-card__content {
  border-top: 1px solid transparent !important;
  background: #04202e00 !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  padding-top: 40px;
  gap: 5px;
}
.unboxing-page-content .contest-card__footer {
  background: #04202e00 !important;
  padding-top: 0;
}
.unboxing-page-content .contest-card__name {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.36px;
  text-align: left;
}
.unboxing-page-content .contest-card__price {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.36px;
  text-align: left;
}

/* Filter Tabs */

.filter-tabs-section {
  padding: 0 0 40px;
  text-align: center;
}
.mt-minus-70 {
  margin-top: -70px;
}

.pt-50 {
  padding-top: 50px;
}

.filter-tablist {
  list-style-type: none;
  overflow: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 5px;
  background: #292d39;
  width: fit-content;
  margin: 0px auto;
  margin-bottom: 80px;
  border-radius: 24px;
}
.filter-tablist .react-tabs__tab {
  margin: 0px;
  border: none;
  bottom: 0px;
  min-width: 140px;
}
.shopbox .filter-tablist {
  list-style-type: none !important;
  overflow: auto;
  overflow-y: hidden !important;
  white-space: nowrap !important;
  padding: 5px !important;
  background: #05344e;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0px auto;
  margin-bottom: 80px !important;
  border-radius: 24px;
  display: block !important;
}
.shopbox .filter-tablist li {
  margin: 0px !important;
  border: none !important;
  bottom: 0px !important;
  width: auto !important;
  padding: 0;
  min-width: 100px;
}
.shopbox .filter-tablist li .category {
  font-weight: 400;
  background: #031b2800;
}
.shopbox .filter-tablist li:hover .category {
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
}
.shopbox .filter-tablist li.react-tabs__tab--selected .category {
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
}
.contest-description .overlay-view:before {
  content: "";
  top: 0;
}
.contest-details-meta div {
  width: 100%;
}
.filter-tablist .react-tabs__tab--selected {
  border-radius: 20px;
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d);
  color: #fff;
}
.filter-tablist .react-tabs__tab:focus:after {
  display: none;
}
li.filter-tablist-links {
  cursor: pointer;
  /* 
  padding: 10px 30px;
    color: #ffffff;
    background: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
    background: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
    background: linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
    box-shadow: -1.113px 7.922px 16px 0px rgba(143, 61, 245, 0.63);
    border: none !important;
    margin: 5px 10px; */
  display: inline-block;
  width: calc(100% / 4);
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  text-align: center;
  padding: 12px;
  border-radius: 9px;
  margin: 0 9px;
  vertical-align: top;
}
.allproductbox .contest-card__content {
  flex-direction: row;
}
/* li.filter-tablist-links div {
  background-color: transparent;
  border-color: transparent !important;
  border-bottom: 4px solid transparent;
  display: block;
  padding: 0.5rem 1rem;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
} */
/* .icon-thumb {
  width: 100px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
} */
.filter-tabs-section-filter {
  overflow: scroll;
  display: flex;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
@media (max-width: 575px) {
  li.filter-tablist-links {
    padding: 12px 25px;
    font-size: 16px;
    width: 50%;
  }
}

li.filter-tablist-links.react-tabs__tab--selected,
li.filter-tablist-links:focus {
  background: transparent !important;
  box-shadow: none;
  /* border-bottom: 4px solid #e2972e !important; */
  color: #fff;
}

.contest-filter-wrapper {
  justify-content: space-between;
  padding-bottom: 40px;
}

.contest-filter-wrapper .nice-select,
.contest-filter-wrapper .order-dropdown-list {
  background-color: #22232d !important;
  cursor: pointer;
  /* border-color: #271c48; */
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  padding-left: 20px;
  padding-right: 15px;
  border: 1px solid #292b39 !important;
  box-sizing: border-box;
  clear: both;
  display: block;
  float: left;
  font-size: 14px;
  font-weight: normal;
  height: 42px;
  position: relative;
  text-align: left !important;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
  width: 100%;
  min-height: 50px;
  line-height: 50px;
}
.contest-filter-wrapper .nice-select::after,
.contest-filter-wrapper .order-dropdown-list:after {
  content: "\f107";
  font-family: FontAwesome;
  width: 0;
  height: 0;
  top: -3px;
  border-bottom: 1px solid #6e6bad;
  border-right: 1px solid #6e6bad;
  right: 28px;
  font-size: 23px;
  position: absolute;
}

.nice-select.open::after,
.order-dropdown-list:after {
  content: "\f106";
}

.contest-filter-wrapper .nice-select span,
.contest-filter-wrapper .nice-select li,
.contest-filter-wrapper .order-dropdown-list span,
.contest-filter-wrapper .order-dropdown-list li {
  font-size: 14px;
}

.contest-filter-wrapper .nice-select .current,
.contest-filter-wrapper .order-dropdown-list .current {
  color: #ffffff;
}
.nice-select.open ul.list,
.order-dropdown-list.open ul.list {
  opacity: 1;
  pointer-events: auto;
  width: 100%;
  -webkit-transform: scale(1) translateY(0) !important;
  -ms-transform: scale(1) translateY(0) !important;
  transform: scale(1) translateY(0) !important;
  z-index: 9999;
}
.contest-filter-wrapper .nice-select .list,
.contest-filter-wrapper .order-dropdown-list .list {
  /* background: linear-gradient(7deg, #e89c31 0%, #ab6b10 100%); */
  background: #083248;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 0;
  pointer-events: none;
  position: absolute;
  top: 100%;
  border-radius: 5px;
  left: 0;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  z-index: 9;
}

.contest-filter-wrapper .nice-select .list .option,
.contest-filter-wrapper .order-dropdown-list .list .option {
  color: #fafafa;
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
}

.contest-filter-wrapper .nice-select .list .option.selected,
.contest-filter-wrapper
  .nice-select
  .list
  .option:hover
  .contest-filter-wrapper
  .order-dropdown-list
  .list
  .option.selected,
.contest-filter-wrapper .order-dropdown-list .list .option:hover {
  background-color: #e1972d;
}

/* Range Input */
.amount-wrapper {
  position: relative;
}
.range {
  -webkit-appearance: none;
  vertical-align: middle;
  outline: none;
  background: none;
  border: none !important;
  padding: 0 !important;
  margin-top: -10px;
}

.range::-webkit-slider-runnable-track {
  background-color: #083248;
  height: 6px;
  border-radius: 3px;
  border: 1px solid transparent;
}

.range[disabled]::-webkit-slider-runnable-track {
  border: 1px solid #d7dbdd;
  background-color: transparent;
  opacity: 0.4;
}

.range::-moz-range-track {
  background-color: #d7dbdd;
  height: 6px;
  border-radius: 3px;
  border: none;
}

.range::-ms-track {
  color: transparent;
  border: none;
  background: none;
  height: 6px;
}

.range::-ms-fill-lower {
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-fill-upper {
  background-color: #d7dbdd;
  border-radius: 3px;
}

.range::-ms-tooltip {
  display: none; /* display and visibility only */
}

.range::-moz-range-thumb {
  border-radius: 20px;
  height: 18px;
  width: 18px;
  border: none;
  background: none;
  background-color: #606670;
}

.range:active::-moz-range-thumb {
  outline: none;
}

.range::-webkit-slider-thumb {
  -webkit-appearance: none !important;
  border-radius: 100%;
  background-color: #e89c31;
  height: 18px;
  width: 18px;
  margin-top: -7px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.range::-webkit-slider-thumb:before {
  content: "\f053";
}
.range::-webkit-slider-thumb:after {
  content: "\f054";
}

.range[disabled]::-webkit-slider-thumb {
  background-color: transparent;
  border: 1px solid #d7dbdd;
}

.range:active::-webkit-slider-thumb {
  outline: none;
}

.range::-ms-thumb {
  border-radius: 100%;
  background-color: #606670;
  height: 18px;
  width: 18px;
  border: none;
}

.range:active::-ms-thumb {
  border: none;
}

.initial {
  border: none;
  color: #ededed;
  font-size: 14px;
  position: absolute;
  z-index: 999;
  left: 24px;
  top: 28px;
}
.output {
  border: none;
  color: #ededed;
  font-size: 14px;
  position: absolute;
  z-index: 999;
  right: 28px;
  top: 29px;
}
.range:focus {
  border-color: transparent !important;
}

.contest-search-form {
  position: relative;
}

.contest-search-form input {
  cursor: pointer;
  width: 100%;
  border-radius: 999px !important;
  -webkit-border-radius: 999px !important;
  -moz-border-radius: 999px !important;
  -ms-border-radius: 999px !important;
  -o-border-radius: 999px !important;
  border-color: #292b39 !important;
  font-size: 14px;
}

.unboxing-page .contest-search-form button {
  position: absolute;
  top: 10px;
  right: 16px;
  color: #ffffff;
  background: transparent;
  font-size: 20px;
}
.unboxing-page .contest-filter-wrapper input::placeholder {
  color: #fff !important;
}
.unboxing-page .contest-filter-wrapper .order-dropdown-list.open {
  border-color: #c0464d !important;
}
.unboxing-page .contest-filter-wrapper .nice-select.open {
  border-color: #c0464d !important;
}
/* Scrollbar CSS*/
.filter-tablist::-webkit-scrollbar {
  width: 3px;
  height: 3px;
}

.filter-tablist::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0);
  border-radius: 10px;
}

.filter-tablist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgb(0 0 0 / 50%);
  background-color: #c2d5d1;
}
@media (max-width: 786px) {
  .filter-tabs {
    margin-top: -50px !important;
  }
}

.icon-item2__content p,
.icon-item2__content .title {
  color: #f1f1f1;
}
.details-btn {
  width: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.details-btn i {
  font-size: 18px;
  margin-right: 5px;
}

/* Box Details Page */
.contest-card {
  cursor: pointer;
}
.last-live-drops-section {
  padding: 50px 0;
}
.last-live-drops-section .slick-slide {
  padding: 10px;
}
.recent-item {
  padding: 10px;
  display: flex !important;
  flex-wrap: wrap;
  text-align: left;
  flex-direction: column-reverse;
  background: #083248;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.5s;
  /* background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #2d1e2c); */
  border-bottom: 2px solid #083248;
}

/* .last-live-drops-section .slick-slide:nth-of-type(odd) .recent-item {
  background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #031b28);
  border-bottom: 4px solid #083248;
}

.last-live-drops-section .slick-slide:nth-of-type(even) .recent-item {
  background-image: linear-gradient(180deg, rgba(45, 30, 44, 0), #083248);
  border-bottom: 4px solid #104a68;
} */

.contest-sidebar__cart .recent-item {
  background: #082b3d;
  background-image: linear-gradient(180deg, rgb(3 27 40), #083248);
  border-bottom: 2px solid #062434;
}

.icon-item-thumb {
  justify-content: center;
  display: inline-flex;
  align-content: center;
}
.icon-item-img-section {
  width: 100%;
}
.contest-sidebar__cart .icon-item-thumb img {
  max-height: 50px;
}

.icon-item-thumb:hover img {
  /* opacity: 0.8; */
  /* -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 0.8s;
  animation-duration: 0.8s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both; */
}
.recent-item-content {
  width: 100%;
  text-align: right;
  display: inline-flex;
  justify-content: space-between;
}
.recent-item-content {
  width: 100%;
  text-align: right;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.recent-item-content p {
  color: #f1f1f1;
  font-size: 12px;
}
.yellow {
  color: #e79a32;
}
.green {
  color: #337d3e !important;
}
.gray {
  color: #62686b !important;
}

.icon-item,
.contest-sidebar__cart,
.contest-details__nav-slider .single-slide,
.contest-sidebar__cart,
.contest-details__thumb-slider {
  background: #041f2d !important;
  border-radius: 10px !important;
  border: 1px solid #052434;
}

.contest-details-content .contest-details-meta span {
  color: #e89c31 !important;
  font-size: 16px;
  margin-bottom: 5px;
}

.contest-details-content p {
  font-size: 16px;
  color: #d3d3d3;
}
.contest-sidebar .progressbar,
.contest-sidebar .quantity input {
  background-color: #04669a !important;
}

.detail-main-img-section {
  display: inline-flex;
  align-items: center;
}
.detail-main-img {
  padding: 5px;
  background-color: #041f2d;
  border-radius: 10px;
}

.item-card {
  background: #031b28;
}
.item-card__content {
  padding: 10px 20px 20px 20px;
  display: flex;
  justify-content: space-between;

  background: rgb(4 31 45);
  /* width: 100%;
    bottom: 0;
    position: absolute; */
  border-radius: 0 0 10px 10px;
  border: 1px solid #052434;
}
.item-card__content .contest-card__name h5 {
  font-size: 18px;
}
.item-card__thumb {
  border-radius: 10px 10px 0 0;
}
.item-price {
  font-size: 18px;
  font-weight: 600;
  color: #e89c31;
  line-height: 1;
  margin-top: 15px;
}
.item-category {
  font-size: 16px;
}

/* 1.2 Text Blink */
.blinks {
  transition: 1s all;
  animation: blink 2s linear infinite;
  -webkit-animation: blink 2s linear infinite;
}

.language .btn {
  font-size: 13px;
}

.header__top .right .language {
  background: #083248;
  margin-left: 35px;
  border-radius: 30px;
}
.contest-details-content .contest-card {
  background: linear-gradient(rgb(4, 31, 45) 50%, rgba(255, 174, 0, 0.25) 100%);
  border-bottom: 2px solid rgba(255, 174, 0, 0.565);
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@-webkit-keyframes blink {
  50% {
    opacity: 0;
  }
}

@media (max-width: 992px) {
  /* .content-block {
    padding-top: 40px;
  } */
  .unboxing-hero-section {
    padding: 150px 0 !important;
  }
}
@media (max-width: 992px) and (min-width: 425px) {
  .contest-sidebar__cart .col-6 {
    flex: 0 0 33.3%;
    max-width: 33.3%;
  }
}

@media (max-width: 1199px) and (min-width: 992px) {
  .contest-sidebar__cart .col-6 {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

/**** battle animation *********/
.small-placeholder {
  font-size: 22px;
  font-weight: 600;
}
.small-placeholder.loss-battle {
  color: #ee0065;
}
.small-placeholder p {
  font-weight: 400;
  font-family: fantasy;
  letter-spacing: 1px;
}
.small-placeholder.winner-battle .battle-text {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  margin-top: 5px;
  top: 50%;
}
/* .watch-battle-page .default-placeholder .small-placeholder.winner-battle img {
  max-height: 170px;
  max-width: 230px;
  filter: drop-shadow(0px 0px 0px #098f11);
} */
.watch-battle-page .default-placeholder .small-placeholder.winner-battle img {
  max-height: max-content;
  max-width: 329px;
  filter: drop-shadow(0px 0px 0px #098f11);
  margin: -11px auto;
  display: block;
  float: none;
  width: 100%;
}
.winner-battle-image {
  position: relative;
}
.small-placeholder.winner-battle h6 {
  font-weight: 500;
  font-size: 20px;
  color: #e89c31;
}
.lose-battle .battle-text h6 {
  color: #ff1d66;
}
.lose-battle .battle-text h6 {
  animation: neon1 1.5s ease-in-out infinite alternate;
  height: calc(100% - 0px);
  width: calc(100% - 0px);
  transition: all 0.4s;
}
@keyframes neon1 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.5);
  }
}
.small-placeholder.winner-battle,
.small-placeholder.lose-battle {
  position: initial !important;
}
.default-placeholder .small-placeholder.lose-battle {
  height: 100%;
  display: flex !important;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.default-placeholder .watch-battle-page .cancle_btn {
  display: flex !important;
}
.game-page.hide-game-content h1 {
  text-align: center;
  padding: 70px 0px;
}
.unboxing-page .cmn-btn.btn--sm {
  width: 70%;
  margin: 0 auto;
  display: block;
}

/**** winning popup css*********/
.winner-popup {
  position: fixed;
  z-index: 99999;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  background: transparent;
  transition: all 1s;
  width: 100%;
  height: 100%;
}

.winner-popup img.left-img {
  /* position: absolute;
  left: -650px;
  top: auto;
  right: auto;
  bottom: -585px;
  z-index: 99999;
  width: 80%;
  max-width: none;
  transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) rotate(118deg);
  opacity: 1;
  transform-style: preserve-3d;
  max-height: inherit;
  animation: animate2 1.5s ease-in-out;
  animation-fill-mode: forwards; */
  position: fixed;
  left: 0px;
  top: auto;
  right: auto;
  bottom: -4%;
  z-index: 99999;
  width: 50%;
  max-width: none;
  max-height: inherit;
  opacity: 1;
  transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) rotate(0deg);
  transform-style: preserve-3d;
  animation: animate2 0.5s ease-in-out;
  animation-fill-mode: forwards;
  transition: all ease 0.5s;
}
.winner-popup img.right-img {
  /* position: absolute;
  left: -650px;
  top: auto;
  right: auto;
  bottom: -585px;
  z-index: 99999;
  width: 80%;
  max-width: none;
  left: auto;
  top: auto;
  right: -628px;
  bottom: -577px;
  width: 78%;
  transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  opacity: 1;
  transform-style: preserve-3d;
  max-height: inherit;
  animation: animate 1.5s ease-in-out;
  animation-fill-mode: forwards; */
  position: fixed;
  right: 0px;
  top: auto;
  left: auto;
  bottom: -4%;
  z-index: 99999;
  width: 50%;
  max-width: none;
  opacity: 1;
  max-height: inherit;
  transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
    rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d;
  animation: animate 0.5s ease-in-out;
  animation-fill-mode: forwards;
  transition: all ease 0.5s;
}
.animted-images {
  position: relative;
  height: 100%;
  width: 100%;
}

@keyframes animate {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
      rotateY(0deg) rotateZ(-40deg) skew(0deg, 0deg);
  }
  50% {
    transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
      rotateY(0deg) rotateZ(-20deg) skew(0deg, 0deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  }
}

@keyframes animate2 {
  0% {
    transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
      rotateY(0deg) rotateZ(40deg) skew(0deg, 0deg) rotate(0deg);
  }
  50% {
    transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
      rotateY(0deg) rotateZ(20deg) skew(0deg, 0deg) rotate(0deg);
  }
  100% {
    transform: translate3d(0px, 0px, 0px) scale3d(1.2, 1.2, 1.2) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) rotate(0deg);
  }
}
.win-grid-container {
  z-index: 9999999 !important;
  background: transparent !important;
}
@media screen and (max-width: 1440px) {
  .winner-popup img.right-img,
  .winner-popup img.left-img {
    bottom: 3%;
  }
}
@media screen and (max-width: 1024px) {
  .winner-popup img.right-img,
  .winner-popup img.left-img {
    top: 40%;
  }
}
@media screen and (max-width: 767px) {
  .contest-filter-wrapper {
    padding-bottom: 20px;
  }
  .filter-tablist {
    margin-bottom: 40px;
  }
  .filter-tablist .react-tabs__tab {
    margin: 0px;
    border: none;
    bottom: 0px;
    min-width: 100px;
  }
  .winner-popup img.left-img,
  .winner-popup img.right-img {
    width: 55%;
    transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
      rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) rotate(0deg);
  }

  @keyframes animate {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
        rotateY(0deg) rotateZ(-40deg) skew(0deg, 0deg);
    }
    50% {
      transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
        rotateY(0deg) rotateZ(-20deg) skew(0deg, 0deg);
    }
    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
    }
  }

  @keyframes animate2 {
    0% {
      transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
        rotateY(0deg) rotateZ(40deg) skew(0deg, 0deg) rotate(0deg);
    }
    50% {
      transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
        rotateY(0deg) rotateZ(20deg) skew(0deg, 0deg) rotate(0deg);
    }
    100% {
      transform: translate3d(0px, 0px, 0px) scale3d(1.5, 1.5, 1.5) rotateX(0deg)
        rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) rotate(0deg);
    }
  }
}

@media screen and (max-width: 480px) {
  .filter-tablist .react-tabs__tab {
    bottom: 0px;
    min-width: auto;
    padding: 5px 25px;
  }
}
@media screen and (max-width: 375px) {
  .filter-tablist .react-tabs__tab {
    min-width: auto;
    padding: 2px 15px;
  }
}
