.widthdraw-deposit-page {
	padding: 250px 0px 100px;
	width: 50%;
	margin: 0px auto;
}
.widthdraw-deposit-page .tab-content {
	background: -webkit-linear-gradient(7deg, #041f2d 0%, #052333 100%);
	padding: 40px 35px;
	min-height: 400px;
	border: 1px solid #092d3f;
	border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
}

.widthdraw-deposit-page label {
	font-size: 14px;
	color: #ddd;
	font-weight: 500;
	margin-bottom: 5px;
}
.widthdraw-deposit-page input,
.widthdraw-deposit-page .form-control {
	height: auto;
    padding: 8px 15px;
    background: #031b28;
    border: 1px solid #073148;
    border-radius: 4px;
    font-size: 14px;
    color: #a9a9a9;
}
.widthdraw-deposit-page input::placeholder {
	color: #a9a9a9;
}

.widthdraw-deposit-page input:focus {
	box-shadow: none;
    background: #031b28;
    border: 1px solid #071a46;
}
.widthdraw-deposit-page .form-group {
	width: 48%;
}

/* .widthdraw-deposit-page .user-deposit-box{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
} */
.widthdraw-deposit-page .deposit-btn {
	display: flex;
	justify-content: flex-end;
	margin: 30px 0px 0px;
}
.widthdraw-deposit-page .text-danger {
	font-size: 12px;
}
.widthdraw-deposit-page .nav-tabs {
    border-bottom: none;
    border-top: 1px solid #092d3f;
    border-right: 1px solid #092d3f;
    border-left: 1px solid #092d3f;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
}

.widthdraw-deposit-page .nav-link {
    background: #041f2d;
    width: 50%;
    text-align: center;
    font-weight: 600;
    color: white;
    border: none;
}
.widthdraw-deposit-page .nav-link:first-child {
	border-top-right-radius: 0px;
}
.widthdraw-deposit-page .nav-link:last-child {
	border-top-left-radius: 0px;
}
.widthdraw-deposit-page .nav-link.active {
	border-bottom: 2px solid #e89b32 !important;
    background: #041f2d;
    border: none;
    color: white;
}
.widthdraw-deposit-page .nav-link:hover {
	border: none;
}
.bar-code-secion {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	padding-top: 20px;
}
.bar-code-secion img {
	height: 150px;
	margin-bottom: 10px;
}
.assets-section {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 20px 0px;
	/* border: 1px solid #011c4d; */
	border-left: none;
	border-right: none;
	margin: 20px 0px;
}
.assets-section .asstes-left img {
	height: 30px;
}
.user-deposit {
	width: 100%;
}

.assets-section img:last-child {
	filter: invert(1);
	height: 26px;
	cursor: pointer;
}

.asstes-left {
	display: flex;
	align-items: center;
}

.asstes-left .bitxoin-heading p {
	margin-bottom: 0px;
	color: #c7c7c7;
	font-size: 12px;
}

.asstes-left .bitxoin-heading {
	margin-left: 15px;
}

.asstes-left .bitxoin-heading h6 {
	color: white;
	margin-bottom: 0px;
	font-family: 'Averta-Semibold';
}
.btc-address-section {
	border-top: none;
	padding-top: 0px;
	margin-bottom: 0px;
}
.btc-address-section i {
	color: white;
	cursor: pointer;
}
.user-deposit-box {
	padding: 30px 0px 0px;
	/* border: 1px solid #011c4d; */
	border-bottom: none;
}
.bitcoin-info {
	display: flex;
	color: white;
	font-size: 12px;
	margin-top: 12px;
}

.bitcoin-info i {
	margin-right: 10px;
	font-size: 18px;
}
.bitcoin-balance {
	display: flex;
	justify-content: space-between;
	color: white;
	font-size: 12px;
	margin-top: 10px;
}
.user-withdraw-page {
	width: 100%;
}

.withdraw-balance .withdraw-amount {
	text-align: center;
}

.withdraw-balance .withdraw-amount i {
	font-size: 22px;
	color: white;
	margin-right: 8px;
	position: absolute;
	margin-left: -16px;
	top: 10px;
}

.withdraw-balance .withdraw-amount {
	color: white;
	font-size: 40px;
	display: contents;
	font-weight: 700;
	position: relative;
}
.withdraw-balance p {
	color: white;
	font-size: 12px;
	margin-top: 15px;
	text-align: center;
}
.withdraw-balance {
	padding: 20px 0px;
}
/* .withdraw-list.pay-with {
    display: flex;
    justify-content: flex-start;
    align-items: center;
} */

.withdraw-list.pay-with .withdraw-field {
	width: 100%;
	display: flex;
	justify-content: space-between;
}

.withdraw-list p {
	margin-bottom: 3px;
}

.select-blockchain {
	padding: 10px 20px;
	background: #ffffff14;
	border-radius: 5px;
	cursor: pointer;
}

.withdraw-list.pay-with .withdraw-field img {
	filter: invert(1);
	height: 26px;
	cursor: pointer;
}
.withdraw-list {
	color: white;
	margin-bottom: 0px;
	font-size: 13px;
	font-weight: 500;
	padding: 15px 0px;
}
/* .withdraw-details {
    border: 1px solid #07255b;
}
.withdraw-list{
    border-bottom: 1px solid #07255b;
} */
.withdraw-list.pay-with {
	border-bottom: none;
}
.withdraw-list.pay-with .withdraw-field > div img {
	margin-right: 10px;
	filter: inherit;
}

.widthdraw-deposit-page .deposit-btn {
	width: 100%;
	margin: 40px auto;
}

.widthdraw-deposit-page .deposit-btn button {
	width: 100%;
}
.assets-section.btc-address-section .asstes-left .bitxoin-heading {
	margin-left: 0;
}

/********** assets popup **********/
.assets-popup .assets-section {
	margin: 5px 0px;
	cursor: pointer;
	padding: 20px 10px;
	transition: all 0.2s;
}

.assets-popup .assets-section .asstes-left.assets-right h6 {
	font-weight: 500 !important;
	font-size: 12px;
}
.assets-popup .modal-title.h4 {
	color: white;
	font-size: 18px;
	font-weight: 500;
}
.assets-popup .assets-listing-section {
	margin-top: 30px;
}
.assets-popup .assets-section .asstes-left img {
	height: 30px;
	border-radius: 50%;
}
.assets-popup .assets-section:hover {
	background: #b1b9c017;
	border-radius: 6px;
}
.info-popup .assets-listing-section p {
	color: white;
	font-size: 14px;
	margin: 30px 10px;
}
.info-popup .modal-title.h4 {
	color: white;
	font-size: 18px;
	font-weight: 500;
	padding-left: 10px;
}
.info-popup .edit-button button {
	max-width: 120px;
	width: 100%;
	margin-top: 20px;
}
.tick {
	filter: initial !important;
}
.withdraw-amount input {
	width: 50% !important;
	max-width: 200px;
	text-align: right;
	background: none;
	border: none;
	font-size: 40px;
	font-weight: 600;
	color: white;
}
.withdraw-amount input:focus,
.withdraw-amount input:hover {
	outline: none;
	background: none;
	border: none;
	box-shadow: none;
}
.widthdraw-deposit-page  .user-deposit-box {
    padding: 0;
    /* border: 1px solid #011c4d; */
    border-bottom: none;
}
.widthdraw-deposit-page .save-btn {
    background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
    /* box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%); */
    border: none;
}
@media screen and (max-width: 991px) {
	.widthdraw-deposit-page{
		width: 75%;
	}
}
@media screen and (max-width: 767px) {
	.widthdraw-deposit-page{
		width: 100%;
	}
}
