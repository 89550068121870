.new-home-page .testimonial-area.bg_img {
  background: url("../../assets/images/elements/testimonial-single.jpg");
  background-size: cover;
  background-position: center;
}
.hero.style--two.bg_img {
  /* background: url(../../assets/images/elements/hero-2-bg.jpg); */
  width: 100%;
  background-size: cover;
}
.testimonial-slider button.slick-arrow {
  display: none !important;
}
.home-card-btn a {
  width: 70%;
  margin: 0 auto;
  display: block;
  text-align: center;
}

.bgwelcome {
  position: absolute;
  top: 0%;
  width: 100%;
  height: 580px;
}

.bgwelcome img {
  height: 100%;
}
.new-home-page .hero.style--two {
  padding-bottom: 100px;
}

.new-home-page .hero.style--two .hero-box-main {
  top: 0%;
}

.update-home-page .hero.style--two .hero__title {
  text-transform: uppercase;
}
.update-home-page {
  position: relative;
  overflow-x: hidden;
}
.home-page-light {
  position: relative;
  padding-top: 150px;
}
.premium-card-btn a {
  width: 100%;
}

.highlightedText {
  background: linear-gradient(90deg, #f06723 0%, #f4a21d 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-page-light:before {
  content: "";
  position: absolute;
  /* background: url("../../assets/images/newhome/home_bg_light.png") no-repeat; */
  max-width: 1488px;
  width: 100%;
  height: 100%;
  /* z-index: -1; */
  background-position: bottom;
  transform: translate(-50%, -50%) rotate(3deg);
  top: 30%;
  left: 86%;
}
.update-home-page .hero {
  padding: 0;
}
/* .home-live-drops .last-live-drops-section{
  padding-top: 0px;
} */
.home-live-drops .last-live-drops-section {
  padding: 80px 0px 0px;
}
/* -----------new css------------ */
.hero-box-main img {
  max-width: 78%;
}

.live-drop-items img {
  margin: auto;
  height: 80px !important;
}

.live_drop_bg {
  position: absolute;
  right: 12px;
  top: 4px;
}

.live-drop-items {
  position: relative;
  background: #171a25;
  border-radius: 12px;
  padding: 10px 15px;
  height: 113px;
}

.live-drop-items .rarity-shadow {
  background: #7aff70;
  max-width: 40px;
  width: 100%;
  height: 40px;
  border-radius: 100%;
  filter: blur(32.400001525878906px);
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: 35px;
}
.custom-card-cover {
  position: relative;
}
.custom-card-cover img {
  max-width: 193px;
  width: 100%;
  height: 154px;
  text-align: center;
  margin: 0 auto;
  transform: translateX(0);
  transition: opacity 0.3s, transform 0.3s;
}

.live-drop-items:hover img.initial-img {
  transform: translateX(125%);
  opacity: 0;
  transition: opacity 0.3s, transform 0.3s;
}

.custom-card-cover img.final-img {
  opacity: 0;
  visibility: hidden;
  height: 0px;
  position: absolute;
  max-width: 193px;
  width: 100%;
  height: 154px;
}

.live-drop-items:hover img.final-img,
.live-drop-items:hover img.final-img {
  height: 90px;
  visibility: visible;
  width: 100%;
  object-fit: contain;
  opacity: 1;
  transform: translate(0%);
  top: 6px;
  margin: auto;
}

.live-drop-content {
  position: absolute;
  bottom: 5px;
}

.live-drop-content p,
.live-drop-content span {
  font-size: 14px;
  line-height: 10px;
}

.new-content-block .title {
  font-size: 18px;
}

.contest-premium {
  padding: 20px 10px;
}

.premium-card__name {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-align: center;
  padding: 12px 0px;
}

.premium-card-btn a {
  border-radius: 12px;
}
.premium-card-btn a span {
  color: black;
  line-height: normal;
}
.contest-premium .contest-num {
  max-width: 90px;
  width: 100%;
  height: 25px;
  line-height: 25px;
  text-align: center;
  display: flex;
  border-radius: 50px;
  text-transform: capitalize;
  align-items: center;
  justify-content: center;
}

.contest-premium .contest-num h4 {
  font-size: 14px;
  text-transform: uppercase;
}
.mystery-cardbox-header p {
  font-size: 33.254px;
  font-weight: 700;
  line-height: 16px;
}
.view-box p {
  color: #f4a21d;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.contest-premium-img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 180px;
}
.contest-premium-img a img {
  max-height: 140px;
}

.contest-premium:hover img {
  animation-name: zoomIn;
  animation-duration: 1.5s;
  animation-fill-mode: both;
}

@keyframes zoomIn {
  0% {
    opacity: 0.2;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  50% {
    opacity: 1;
  }
}

@media screen and (min-width: 2560px) {
  .home-page-light:before {
    transform: translate(-50%, -50%) rotate(-6deg);
  }
}
@media screen and (max-width: 1440px) {
  .home-page-light::before {
    transform: translate(-50%, -50%) rotate(0deg);
    top: 27%;
    left: 86%;
  }
}
@media screen and (max-width: 1366px) {
  .home-page-light::before {
    top: 20%;
    left: 89%;
  }
}
@media screen and (max-width: 1024px) {
  .home-page-light::before {
    top: 30%;
    left: 90%;
  }
  .home-page-light {
    padding-top: 180px;
  }
}

@media screen and (max-width: 991px) {
  .home-page-light {
    padding-top: 0px;
  }
  .home-page-light::before {
    top: 0%;
    left: 100%;
    width: 803px;
  }
  .new-home-page .hero.style--two {
    padding-bottom: 40px;
  }
}
@media screen and (max-width: 767px) {
  .home-page-light::before {
    content: unset;
  }
  .contest-card-premium {
    max-width: 50% !important;
  }
  .mystery-cardbox-header p {
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }
}

@media screen and (max-width: 480px) {
  .mystery-cardbox-header p {
    font-size: 20px;
    font-weight: 600;
    line-height: 20px;
  }
}

@media screen and (max-width: 320px) {
  .contest-card-premium {
    max-width: 100%;
  }
}
