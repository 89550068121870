.product-details-page .inner-hero-section {
  /* background-image: -webkit-linear-gradient(
    -90deg,
    #031b28 0%,
    #092230 100%
  ) !important; */
  background-image: none !important;
}
/* .product-hero-section {
    background-image: -webkit-linear-gradient( -90deg, #031b28 0%, #092230 100% ) !important;
} */
.product-details-page .contest-cart__left .single-slide {
  height: 500px;
  background-image: linear-gradient(
    #282932e0 50%,
    rgba(31, 38, 38, 0.503) 100%
  );
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 25px;
  margin: 0px 10px;
}
.product-details-page .contest-cart__left .single-slide img {
  height: 450px;
  width: 100%;
  object-fit: contain;
}
.product-details-page .contest-cart__left .slick-dots {
  position: absolute;
  bottom: -150px;
  display: flex !important;
  width: 100%;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
}
.product-details-page .contest-cart__left .slick-dots li {
  position: relative;
  display: inline-block;
  width: 117px;
  height: 120px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
  justify-content: center;
  align-items: center;
}
.product-details-page .contest-cart__left .slick-dots li a {
  height: 120px;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-image: linear-gradient(
    rgb(7 43 63) 50%,
    rgba(128, 38, 40, 0.25) 100%
  );
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  text-align: center;
}
.product-details-page .social-links li a:hover, .product-details-page .social-links li span  {
  background-color: #e1962d;
  box-shadow: none;
}
.product-details-page .contest-description .nav-tabs .nav-item .cmn-btn.active {
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
}
.product-details-page .contest-description .nav-tabs .nav-item .cmn-btn.active {
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
}
.product-details-page .contest-description .nav-tabs .nav-item .cmn-btn {
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: none;
}
.product-details-page .contest-cart__right .quantity-nav {
  height: 50px !important;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.product-details-page .contest-cart__right .quantity-nav .quantity-button {
  width: 32px;
  height: 32px;
  background-color: #ffffff2d;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #ffff;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}
.product-details-page
  .contest-cart__right
  .quantity-nav
  .quantity-button.quantity-up {
  background-color: #e59a30;
  color: #ffff;
}
.product-details-page .contest-cart {
  padding: 40px 30px 40px 30px;
  display: flex;
  flex-wrap: wrap;
  /* background-color: #dfe5e8; */
  background-color:#1b1c25;
  margin-bottom: 66px;
}
.product-details-page .contest-cart {
 border-radius: 10px;
  border-top: 1px solid #292b39;
  border-left: 1px solid #292b39;
  border-right: 1px solid #292b39;
}
.product-details-page .clock-wrapper {
  background-color: #083248;
  padding: 15px 30px;
  border-radius: 25px 25px 0 0;
  -webkit-border-radius: 25px 25px 0 0;
  -moz-border-radius: 25px 25px 0 0;
  -ms-border-radius: 25px 25px 0 0;
  -o-border-radius: 25px 25px 0 0;
  text-align: center;
}
.product-details-page .contest-cart__right .contest-num span {
  color: #e79933;
}
.product-details-page .contest-cart__right .quantity input {
  padding-left: 0;
}
.con-in-box{
  font-weight: 600;
  font-size: 22px !important;
  color: #de942c !important;
}
.no-product h5{
  font-weight: 500;
  text-align: center;
}

.product-box .buy_add_btn {
  margin-bottom: 20px !important;
}

.product-box .buy_add_btn button {
  margin-right: 25px;
}

.add_to_cart {
  position: relative;
}

img.fly-img {
  position: absolute;
  z-index: 9;
  max-height: 442px;
  max-width: 464px;
  height: 100%;
  width: 100%;
  top: 65px;
  left: 117px;
  opacity: 0;
}

img.fly-to-cart {
  position: absolute;
  z-index: 9999;
  width: 100%;
  opacity: 0;
  animation: fly 1s linear forwards;
}

@keyframes fly {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform-origin: 100% 50%;
    transform: rotate(90deg);
    transform: translateY(-49%, 100%);
  }
}
.product-details-page .contest-cart__right .buy_add_btn button {
  max-width: 248px;
  width: 100%;
  /* margin: 0 auto; */
}
@media screen and (max-width: 1366px) {
  .product-details-page .contest-cart__left .single-slide img {
    width: 400px;
  }
  .product-details-page .contest-cart__left .single-slide {
    height: 450px;
  }
}
@media screen and (max-width: 1024px) {
  .product-details-page .contest-cart__left .single-slide img{
    height: 370px;
  }
}

@media (max-width: 991px) {
  .product-details-page .inner-hero-section {
    padding-top: 150px;
  }
  .product-details-page .contest-cart {
    padding: 30px 30px 100px 30px;
  }
  .product-details-page .contest-cart__left .single-slide img {
    width: 300px;
    height: 270px;
  }
  .product-details-page .contest-cart__left .single-slide {
    height: 100%;
  }
  .contest-cart__left {
    width: 100%;
    padding-right: 0;
    height: 320px;
  }
  img.fly-img {
    position: absolute;
    z-index: 9;
    max-height: 230px;
    max-width: 350px;
    height: 100%;
    width: 100%;
    top: 65px;
    left: 200px;
    opacity: 0;
  }
  .product-details-page .contest-cart__left .slick-dots li img {
    max-width: 85px;
  }
  .add_to_cart img {
    max-width: 250px;
  }
}

@media (max-width: 767px) {
  .product-details-page .inner-hero-section{
    padding-top: 100px;
  }
  .product-details-page .contest-cart__left .single-slide {
    height: 290px;
    width: 100%;
    margin: 0px auto;
  }
  .product-details-page .contest-cart__left .slick-dots li a {
    height: 100px;
  }
  .product-btn {
    justify-content: center;
  }
  .product-details-page .contest-cart__right .select-quantity {
    display: block;
    text-align: center;
    justify-content: center;
    width: 100%;
  }
  .contest-cart__right .quantity {
    margin: 0 auto;
  }
  .contest-cart__right .caption {
    margin: 0;
    padding-bottom: 20px;
  }
  .product-box .buy_add_btn button {
    margin: 0 auto;
  }
  .product-details-page .contest-cart {
    padding: 30px 30px 60px 30px;
  }
  .product-details-page .contest-cart__left .slick-dots {
    overflow: scroll;
  }
  .product-details-page .contest-cart__left .single-slide img {
    width: 100%;
    height: 230px;
  }
}

@media screen and (max-width: 480px) {
  .product-details-page .contest-cart__left .single-slide {
    width: 100%;
    height: auto;
    margin: 0;
  }
}
