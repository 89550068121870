.battles-page {
  padding: 185px 0px 100px;
  position: relative;
}

.battles-section {
  border-bottom: 1px solid #ffffff2d;
}

/******** Start Style for battles top header ********/
.battles-page .battles-top-header {
  padding: 30px 0;
  padding-bottom: 0;
}

.battles-page .header-content span {
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.battles-page .header-content .fairness {
  cursor: pointer;
  font-size: 11px;
  font-weight: 500;
  margin-left: 20px;
  color: #e89d31;
  text-transform: uppercase;
}

.battles-page .header-content .la-dice {
  margin-left: 20px;
  font-size: 28px;
}

/******** End Style for battles top header ********/

/******** Start Style for battles title arrow ********/
.battles-page .section-title {
  font-size: 21px;
  font-weight: 900;
  letter-spacing: 3px;
  margin: 0;
  text-transform: uppercase;
  text-align: center;
}

.section-title-arrow-left {
  border-left: 4px solid #e89b32;
}

.section-title-arrow-left,
.section-title-arrow-right {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  display: inline-block;
  position: relative;
}

.section-title-arrow-left:before {
  left: -3px;
}

.section-title-arrow-left:before,
.section-title-arrow-right:before {
  box-shadow: 0 0 10px 2px rgb(255 71 0 / 90%);
  content: "";
  position: absolute;
}

.section-title-arrow-left:after {
  background: linear-gradient(270deg, #e89b32, rgba(227, 66, 5, 0));
  right: 4px;
}

.section-title-arrow-left:after,
.section-title-arrow-right:after {
  content: "";
  height: 2px;
  opacity: 0.19;
  position: absolute;
  top: -1px;
  width: 44px;
}

.section-title-text {
  display: inline-block;
  line-height: 27px;
  margin: 0 20px;
  min-height: 27px;
  position: relative;
  text-align: center;
  font-size: 22px;
  letter-spacing: 1px;
  font-weight: 600;
  text-transform: uppercase;
}

.section-title-arrow-right {
  border-right: 4px solid #e89b32;
}

.section-title-arrow-left,
.section-title-arrow-right {
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  display: inline-block;
  position: relative;
  top: -5px;
}

.section-title-arrow-right:before {
  left: 3px;
}

.section-title-arrow-left:before,
.section-title-arrow-right:before {
  box-shadow: 0 0 10px 2px rgb(255 71 0 / 90%);
  content: "";
  position: absolute;
}

.section-title-arrow-right:after {
  background: linear-gradient(90deg, #e89b32, rgba(227, 66, 5, 0));
  left: 4px;
}

.section-title-arrow-left:after,
.section-title-arrow-right:after {
  content: "";
  height: 2px;
  opacity: 0.19;
  position: absolute;
  top: -1px;
  width: 44px;
}

/******** End Style for battles title arrow ********/

/******** Start Style for battles filter ********/
.battles-page .filter-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 22px 0;
}

.battles-page .date-price {
  max-width: 28%;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 28%;
  justify-content: space-between;
}

.battles-page .date-price .date {
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 0;
  /* opacity: .5; */
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.battles-page .date-price .switch {
  margin-bottom: 0;
}

.battles-page .date-price .price {
  margin-left: -10px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 600;
  opacity: 0.5;
  margin-bottom: 0;
  transition: all 0.35s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.battles-page .battles-history {
  display: flex;
  justify-content: flex-end;
}

/******** End Style for battles filter ********/

/******** Start Style for battles button ********/
.battles-history .cmn-btn {
  padding: 10px 20px;
  font-size: 14px !important;
}

.battles-btn {
  position: relative;
  max-width: 170px;
  width: 100%;
  padding: 10px 20px;
  text-decoration: none;
  text-align: center;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  font-family: sans-serif;
  cursor: pointer;
  letter-spacing: 1px;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.1);
  box-shadow: 0 2px 3px rgba(0, 0, 0.2);
}

.battles-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 50%;
  height: 100%;
  background: rgba(255, 255, 255, 0.1);
}

.battles-btn:after {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg,
      transparent,
      rgba(255, 255, 255, 0.4),
      transparent);
  transition: 0.5s;
  transition-delay: 0.5s;
}

.battles-btn:hover:after {
  left: 100%;
}

.battles-btn span {
  position: absolute;
  display: block;
  transition: 0.5s ease;
}

.battles-btn span:nth-child(1) {
  top: 0;
  left: 0;
  width: 0;
  height: 1px;
  background: #e79a32;
}

.battles-btn:hover span:nth-child(1) {
  width: 100%;
  transform: translateX(100%);
}

.battles-btn span:nth-child(3) {
  bottom: 0;
  right: 0;
  width: 0;
  height: 1px;
  background: #e79a32;
}

.battles-btn:hover span:nth-child(3) {
  width: 100%;
  transform: translateX(-100%);
}

.battles-btn span:nth-child(2) {
  top: 0;
  left: 0;
  width: 1px;
  height: 0;
  background: #e79a32;
}

.battles-btn:hover span:nth-child(2) {
  height: 100%;
  transform: translateY(100%);
}

.battles-btn span:nth-child(4) {
  bottom: 0;
  right: 0;
  width: 1px;
  height: 0;
  background: #e79a32;
}

.battles-btn:hover span:nth-child(4) {
  height: 100%;
  transform: translateY(-100%);
}

.header-cerate-btn {
height: 45px;
width: 100%;
padding: 20px 20px !important;
border-radius: 12px !important;
font-size: 16px !important;
color: black !important;
}


/******** End Style for battles button ********/

/******** Start Style for battles tables ********/
.battles-page .table thead th {
  vertical-align: bottom;
  color: #e89d31;
  font-size: 13px;
  text-transform: uppercase;
  border-top: none;
  font-weight: 500;
  letter-spacing: 1px;
  padding: 0 10px 40px;
  text-align: center;
  border-bottom: none;
}

.battles-page .table tbody td {
  background: #1b1c25;
  border-top: none;
  border-bottom: 6px solid #ffffff2b;
  border-right: 2px solid #ffffff2b;
  color: #c3c3c3;
  padding: 10px;
}

.circle {
  width: 60px;
  height: 60px;
  background: #031b28;
  border-radius: 100%;
}

/******** End Style for battles tables ********/

/********** battle table css *******/
.battle-table tr td:first-child {
  width: 10%;
}

.battle-table tr td:last-child {
  width: 15%;
}

.new-battle-table tr td:nth-child(2) {
  text-align: left;
  width: 45% !important;
}

.battle-table tr td:nth-child(2) {
  text-align: left;
  width: 50%;
}

.battle-table tr td {
  vertical-align: middle;
  text-align: center;
  padding: 0;
}

.battle-table tr td svg {
  height: 60px;
  fill: #ffffff2b;
}
.new-battle-table .watch-player-btn {
  border-radius: 7.821px;
}

.new-battle-table .see-player-btn {
  background: #252530 !important;
}

.new-battle-table .watch-player-btn svg {
  width: 22.347px;
  height: 22.347px;
}

.battle-table .cirlce-section {
  position: relative;
  width: fit-content;
  margin: 0px auto;
}

.battle-table .cirlce-section span {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;
}

.battle-table .circle-active svg {
  stroke: rgb(227 152 46) !important;
  stroke-width: 13;
}

.battle-img img {
  height: 65px;
}

.battle-img {
  /* width: fit-content; */
  position: relative;
  padding: 5px 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  width: 170px;
  margin-right: 1px;
}

.battle-img:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: linear-gradient(rgb(255 255 255 / 2%) 20%,
      rgb(214 141 39 / 45%));
  top: 0px;
  border-bottom: 3px solid #e2972e;
  bottom: 0px;
  margin-top: -1px;
}

.battle-img span {
  position: relative;
  margin-bottom: -2px;
  margin-top: 10px;
  text-align: center;
}

.battle-slider {
  display: -webkit-inline-box;
}

td.circle-active p {
  color: #e1972d;
  margin-bottom: 0px;
  text-shadow: 0 2px 13px #e1972d;
  font-size: 14px;
}

.battle-slider-section {
  max-width: 665px !important;
  width: 100%;
  overflow: auto;
}

.battle-slider-section::-webkit-scrollbar {
  height: 5px;
  border-radius: 20px;
}

.battle-slider-section::-webkit-scrollbar-track {
  background: #031b28;
}

.battle-slider-section::-webkit-scrollbar-thumb {
  background: #083248;
}

.new-battle-slider-section::-webkit-scrollbar-thumb {
  background: #1d1d27 !important;
}

.new-battle-slider-section::-webkit-scrollbar-track {
  background: #252530 !important;
}
.new-battle-slider-section .table-responsive::-webkit-scrollbar-track {
  /* background: #083248; */
  background: #252530 !important;
}

.new-battle-slider-section .table-responsive::-webkit-scrollbar-thumb {
  /* background: #022031; */
  background: #1d1d27 !important;
}



.battles-page .table-responsive::-webkit-scrollbar {
  height: 5px;
}

.battles-page .table-responsive::-webkit-scrollbar-track {
  background: #083248;
}

.battles-page .table-responsive::-webkit-scrollbar-thumb {
  background: #022031;
}

.battle-table .cmn-btn {
  padding: 10px 25px;
  font-size: 14px;
}

.battle-table .players-img img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  /* border: 1px solid #de942b; */
  object-fit: cover;
  margin: 5px -4px;
  border: 2px solid #041f2d;
}

.battle-table .players-section h6 {
  font-weight: 500;
  color: #e3982e;
}

.battles-page .table tbody tr:hover td {
  background-color: #222229 !important;
}

/****** watch battle page **********/
.watch-battle-page {
  padding: 185px 0px 100px;
  position: relative;
}

.players-length {
  display: flex;
  justify-content: space-between;
  margin-top: 35px;
  margin-left: 30px;
  margin-right: 30px;
  position: relative;
}

.watch-battle-page .battles-top-header .section-title {
  text-align: center;
  line-height: 0;
  margin-bottom: 20px;
}

.players-length.three-palyers .players {
  width: calc(100% - 5px);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
  padding: 26px 0px;
  background: #083248;
}

.players-length.three-palyers .players:last-child {
  margin-right: 0px;
}

.players-length .players img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15px;
}

.players.player-one.first-player:before {
  content: "";
  height: 100%;
  left: -30px;
  position: absolute;
  top: -2px;
  transform: skewY(42deg) translateY(-11px);
  width: 30px;
  background: #083248;
}

.players.player-one.last-player::after {
  content: "";
  height: 100%;
  position: absolute;
  right: -30px;
  top: -2px;
  transform: skewY(-42deg) translateY(-11px);
  width: 30px;
  background: #083248;
}

.players-battle-details {
  border: 4px solid #083248;
  border-top: none;
  padding-top: 32px;
  margin-top: -32px;
  background: #031b28;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.players-battle-details .row {
  margin: 0 30px 0 30px;
}

.players-battle-details .col-sm-3,
.players-battle-details .col-sm-4 {
  padding: 0;
  border-right: 1px solid #073148;
}

.players-battle-details .col-sm-3:last-child,
.players-battle-details .col-sm-4:last-child,
.players-battle-details .col-sm-6:last-child {
  border-right: none;
}

.players-battle-details .col-sm-6 {
  border-right: 1px solid #073148;
}

.all-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.all-details .details-section {
  width: calc(100% - 5px);
  margin-right: 2px;
  justify-content: center;
  display: flex;
}

.all-details .details-section .left-details-section,
.all-details .details-section .right-details-section {
  width: 50%;
  text-align: center;
  border-bottom: 3px solid #083248;
  /* border-left: 1px solid #073148; */
  padding: 15px 0px;
  min-height: 185px;
}

.players-battle-details .col-sm-3 .all-details::before {
  content: "";
  background: #083248;
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: -30px;
}

.players-battle-details .col-sm-3 .all-details::after {
  content: "";
  background: #083248;
  width: 40px;
  height: 3px;
  position: absolute;
  bottom: 0;
  right: -32px;
}

.players-battle-details .col-sm-3 .all-details:last-child::after,
.players-battle-details .col-sm-3 .all-details:last-child::before {
  display: none;
}

.players-battle-details .col-sm-4 .all-details::before {
  content: "";
  background: #083248;
  width: 30px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: -30px;
}

.players-battle-details .col-sm-4 .all-details::after {
  content: "";
  background: #083248;
  width: 40px;
  height: 3px;
  position: absolute;
  bottom: 0;
  right: -32px;
}

.players-battle-details .col-sm-4 .all-details:last-child::after,
.players-battle-details .col-sm-4 .all-details:last-child::before {
  display: none;
}

.players-battle-details .col-sm-6 .all-details::before {
  content: "";
  background: #083248;
  width: 60px;
  height: 3px;
  position: absolute;
  bottom: 0;
  left: -48px;
}

.players-battle-details .col-sm-6 .all-details::after {
  content: "";
  background: #083248;
  width: 60px;
  height: 3px;
  position: absolute;
  bottom: 0;
  right: -50px;
}

.players-battle-details .col-sm-6 .all-details:last-child::after,
.players-battle-details .col-sm-6 .all-details:last-child::before {
  display: none;
}

.all-details:last-child .details-section .left-details-section,
.all-details:last-child .details-section .right-details-section {
  border-bottom: none;
}

.all-details .details-section .left-details-section {
  border-right: 1px solid #08314680;
}

.details-section img {
  height: 60px;
}

.details-section h5 {
  font-size: 14px;
  margin: 20px 0px 5px 0px;
}

.details-section p {
  font-size: 12px;
  margin-bottom: -5px;
  color: #adadad;
}

.details-section h6 {
  font-size: 14px;
  color: #de942c;
}

.filter-header .slider:before {
  position: absolute;
  content: "";
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  transition: 0.4s;
}

.watch-battle-page .no-of-plays svg {
  height: 28px;
  fill: #083248;
}

.watch-battle-page .no-of-plays span {
  position: absolute;
  transform: translate(-50%, -50%);
  left: 12px;
  top: 15px;
  z-index: 999;
  color: #df952c;
  font-weight: 600;
  font-size: 13px;
}

.watch-battle-page .no-of-plays {
  position: relative;
  margin-right: 5px;
}

.watch-battle-page .no-of-plays-section {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.watch-battle-page .no-of-plays.next-plays svg {
  height: 40px;
  margin-top: -6px;
  fill: #f2ac4c;
}

/********** all battles **********/
.all-battles-listing .date-price {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  max-width: 100%;
}

.all-battles-listing label.date {
  margin-right: 15px;
}

.all-battles-listing label.switch {
  margin-right: 25px;
}

.all-battles-listing .battle-box {
  min-height: 231px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 0;
  border: 1px dashed #ffffff2d;
  background: #1B1C25;
  cursor: pointer;
}

.all-battles-listing .cirlce-section {
  margin: 0 auto;
  margin: 10px;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.all-battles-listing .cirlce-section svg {
  fill: #ffffff2d;
  height: 60px;
}

.all-battles-listing .cirlce-section span {
  left: 50%;
  position: absolute;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.all-battles-listing .add-case {
  display: flex;
  flex-direction: column;
}

.all-battles-listing .battle-box h6 {
  font-size: 16px;
}

.watch-battle-page .default-placeholder .small-placeholder img {
  max-height: 114px;
  max-width: 150px;
}

/************* style for battle header *********/
.battle__header {
  padding-top: 20px;
}

.battle__header .main_battle_section {
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 7%);
  display: flex;
  height: 67px;
  margin: 15px 25px 0;
  position: relative;
}

.battle__header .totol_cost_1 {
  margin-right: -10px;
}

.battle__header .totol_cost {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 55%;
}

.battle__header .totol_cost_1:before {
  transform: skew(45deg);
}

.battle__header .totol_cost:before {
  background: #2C3034;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.battle__header .totol_cost_1:after {
  left: 20%;
  transform: skew(45deg);
}

.battle__header .totol_cost:after {
  background: #1B1C25;
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 23%);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 60%;
  z-index: 0;
}

.battle__header .battle_cost {
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.83px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}

.battle__header .price-highlight {
  color: #e89c30;
  font-size: 16px !important;
  font-weight: 700;
  letter-spacing: 0;
  position: relative;
  text-shadow: 0 0 10px rgb(232 157 49 / 85%);
  white-space: nowrap;
}

.battle__header .totol_cost_2 {
  margin-left: -10px;
}

.battle__header .totol_cost {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  position: relative;
  width: 55%;
}

.battle__header .totol_cost_2:before {
  transform: skew(-45deg);
}

.battle__header .totol_cost:before {
  background: #2C3034;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  z-index: -1;
}

.battle__header .totol_cost_2:after {
  right: 20%;
  transform: skew(-45deg);
}

.battle__header .totol_cost:after {
  background: #1B1C25;
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 23%);
  content: "";
  height: 100%;
  position: absolute;
  top: 0;
  width: 60%;
  z-index: 0;
}

.battle__header .battle_cost {
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.83px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}

.battle__header .battle_cost {
  color: #ffffff;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 1.83px;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  z-index: 1;
}

.battle__header .battle_round {
  top: 25px;
  color: #ffffff;
  font-size: 10px;
  font-weight: 800;
  left: 0;
  letter-spacing: 1.41px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
}

.battle__header .main_diamond {
  top: -55px;
  color: #ffffff;
  left: 0;
  letter-spacing: 1.41px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #e89a33;
  margin: 0 auto;
  transform: rotate(45deg);
  box-shadow: 1px 0px 15px 11px rgb(225 150 45 / 22%);
}

.battle__header .diamond {
  position: absolute;
  top: 3px;
  left: 3px;
  background: #e89b32;
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.battle__header .diamond span {
  position: relative;
  top: 0px;
  left: 2px;
  font-size: 18px;
  font-weight: 600;
  transform: rotate(-45deg);
}

.battle__header .battle_cost span b {
  font-size: 13px;
  font-weight: 600;
}

.battle__header .battle_cost span {
  font-size: 13px;
}

.watch-battle-btn {
  display: inline-block;
}

/************* style for battle header *********/

/************** style for battle card ************/
.battle-card {
  background: #041f2d;
  border: 1px solid #052434;
  min-height: 231px;
  height: 100%;
  position: relative;
}

.battle-card:before {
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.02) 20%,
      rgba(214, 141, 39, 0.45));
  border-bottom: 3px solid #e2972e;
  bottom: 0;
  content: "";
  height: 100%;
  margin-top: -1px;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
}

.battle-card .contest-card__thumb {
  background: #041f2d;
  height: 140px;
  position: relative;
  border-radius: 8px 8px 0 0;
}

.battle-card .contest-card__thumb .la-times {
  position: absolute;
  top: 7px;
  right: 7px;
  cursor: pointer;
  z-index: 2;
  color: #ffffff;
}

.battle-card .action-icon {
  position: absolute;
  top: 8px !important;
  left: 8px !important;
  width: 20px !important;
  height: 20px !important;
}

.battle-card .action-icon .fa-heart {
  font-size: 13px;
  position: relative;
  left: 0px;
  top: -4px;
}

.battle-card .contest-card__thumb img {
  max-height: 100px;
}

.battle-card .increment-price {
  position: relative;
  z-index: 3;
}

.battle-card .increment {
  background: #e89d31;
  height: 36px;
  margin: 11px auto 0;
  position: relative;
  width: 100px;
  margin: -6px auto;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.battle-card .price p {
  color: #e9e9f2;
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.battle-card .price span {
  color: #e89d31;
  font-size: 13px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.battle-card .increment .text-center {
  text-align: center !important;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.battle-card .increment:before {
  border-bottom: 19px solid transparent;
  border-top: 18px solid transparent;
  content: "";
  position: absolute;
  top: 0;
  border-right: 12px solid #e89d31;
  left: -12px;
}

.battle-card .increment:after {
  border-bottom: 18px solid transparent;
  border-top: 18px solid transparent;
  content: "";
  position: absolute;
  top: 0;
  border-left: 9px solid #e89d31;
  right: -9px;
}

.battle-card .la-plus-circle {
  cursor: pointer;
}

.battle-card .la-plus-circle:hover {
  color: #073148;
}

.battle-card .la-minus-circle {
  cursor: pointer;
}

.battle-card .la-minus-circle:hover {
  color: #073148;
}

/********* style for player tab *********/
.player-tab {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.player-tab .game-type {
  max-width: 320px;
  width: 100%;
  font-weight: 400;
  margin-top: 5px;
  padding: 10px 14px;
  background: #22232d;
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 23%);
}

.player-tab .player span {
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 600;
  color: #e89a33;
}

.player-tab .checkbox {
  position: relative;
  margin-top: 20px;
}

.player-tab .checkbox input[type="checkbox"] {
  display: none;
  position: absolute;
}

.player-tab .inbox_label {
  color: rgb(255, 255, 255);
  font-size: 10px;
  font-weight: 400;
  text-transform: uppercase;
  vertical-align: middle;
}

.player-tab .checkbox-label {
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  padding-left: 25px;
  margin-bottom: 0;
  -webkit-user-select: none;
  user-select: none;
}

.player-tab .checkbox input[type="checkbox"]:checked+label:before {
  background: url("../../../src/assets/images/icon/checkIcon.svg") no-repeat center bottom 2px,
    linear-gradient(to right top, #e89a33, #ffa150);
  border-width: 0;
  box-shadow: 0 0 17px rgb(255 93 37 / 45%);
}

.player-tab .checkbox-label:before {
  border: 1px solid #e89a33;
  border-radius: 3px;
  content: "";
  height: 14px;
  left: 0;
  position: absolute;
  top: 4px;
  width: 14px;
}

.player-tab .create_battle_btn {
  margin-top: 25px;
}

.player-tab .create_battle_btn .cmn-btn {
  padding: 10px 25px;
  font-size: 18px;
  font-weight: 600;
}

/********* style for player tab *********/

/********* style for battle popup *********/
.boxadd-model-page .modal_header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #1B1C25;
  position: relative;
  min-height: 60px;
  height: 100%;
  z-index: 5;
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 20%);
}

.boxadd-model-page .search {
  box-shadow: 0 22px 94px 0 rgb(0 0 0 / 49%);
  height: 100%;
  width: 30%;
}

.boxadd-model-page input[type="search"] {
  border: 0;
  color: #ffffff;
  display: block;
  min-height: 60px;
  height: 100%;
  outline: 0;
  padding: 0 32px;
  width: 100%;
}

.boxadd-model-page input::placeholder {
  color: #fff !important;
}

.boxadd-model-page .case_add h4 {
  color: #ffffff;
  margin: 0;
  padding: 0 50px;
  text-transform: uppercase;
  white-space: nowrap;
  font-size: 20px;
  letter-spacing: 1px;
  font-weight: 600;
}

.boxadd-model-page span.case {
  color: #e29a33;
  font-size: 17px !important;
  font-weight: 600;
  letter-spacing: 0;
  position: relative;
  text-shadow: 0 0 10px #e29a33d6;
  white-space: nowrap;
  cursor: help;
  margin-left: 10px;
}

.boxadd-model-page .close_pup_btn {
  position: absolute;
  top: 8px;
  right: 8px;
}

.boxadd-model-page button.close-btn {
  min-width: 45px;
  width: 100%;
  min-height: 45px;
  height: 100%;
  background: #1B1C25;
}

.boxadd-model-page button.close-btn .la-times {
  font-size: 25px;
  color: #fff;
}

.boxadd-model-page .account-form-area {
  background-color: #081e2a;
  border-radius: 0;
  height: 60vh;
  padding-top: 6px;
  overflow: hidden;
  overflow-y: scroll;
}

.boxadd-model-page .modal_footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #072d41;
  position: relative;
  min-height: 60px;
  height: 100%;
  z-index: 5;
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 20%);
}

.boxadd-model-page .account-form-area::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

.boxadd-model-page .account-form-area::-webkit-scrollbar-track {
  background-color: #13141a;
}

.boxadd-model-page .account-form-area::-webkit-scrollbar-track:hover {
  background-color: #18191e;
}

.boxadd-model-page .account-form-area::-webkit-scrollbar-thumb {
  background-color: #070707;
  border-radius: 16px;
  border: 5px solid #1B1C25;
}

.boxadd-model-page .account-form-area::-webkit-scrollbar-thumb:hover {
  background-color: #131313;
}

.boxadd-model-page .account-form-area::-webkit-scrollbar-button {
  display: none;
}

.boxadd-model-page .done_btn .cmn-btn {
  padding: 8px 50px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.all_battle_card .contest-card {
  background-color: #041f2d;
  border-radius: 0;
}

.all_battle_card .battle_card {
  background: #041f2d;
  border: 1px solid #052434 !important;
  min-height: 231px;
  height: 100%;
  position: relative;
  border-radius: none;
  z-index: 1;
  background-image: none !important;
  border: none;
  box-shadow: 0px 0px 27px 0px #032435;
}

.all_battle_card .battle_card:before {
  background-image: linear-gradient(hsla(0, 0%, 100%, 0.02) 20%,
      rgba(214, 141, 39, 0.45));
  border-bottom: 3px solid #e2972e;
  bottom: 0;
  content: "";
  height: 100%;
  margin-top: -1px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
}

.all_battle_card .battle_card .contest-card__thumb {
  background: #041f2d;
  height: 140px;
}

.all_battle_card .battle_card .contest-card__thumb img {
  max-height: 100px;
}

.all_battle_card .battle_card .contest-card__content {
  padding: 10px 15px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background: #041f2d;
  border-top: none;
}

.all_battle_card .battle_card .contest-card__name {
  font-size: 13px;
  text-align: left;
  text-transform: uppercase;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

.all_battle_card .battle_card .contest-card__price {
  font-size: 13px;
  font-weight: 600;
  color: #e89c31;
  line-height: 1.4;
}

.all_battle_card .battle_card .add_box {
  padding: 20px 30px;
  position: relative;
  z-index: 2;
}

/******** Start Style for battle history page *********/
.battle-history-page {
  padding: 10px 0;
}

.battle-history-page .la-arrow-left {
  margin-right: 7px;
  cursor: pointer;
  font-size: 18px;
}

.history-table {
  margin-top: 40px;
}

/******** End Style for battle history page *********/

/******** Start Style for battle win popup *********/
.modal .modal-content {
  border: none;
}

.battle-win-popup .modal-body {
  padding: 0;
  max-width: 745px;
  width: 100%;
  margin: 0 auto;
}

.battle-win-popup .account-form-area {
  background-color: #081e2a;
  border-radius: 0;
  height: 40vh;
  padding-top: 6px;
  overflow: hidden;
  overflow-y: scroll;
}

.battle-win-popup .modal_header {
  height: 120px !important;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background: #072d41;
  position: relative;
  min-height: 60px;
  height: 100%;
  z-index: 5;
  box-shadow: 0 22px 54px 0 rgb(0 0 0 / 20%);
}

.battle-win-popup .case_add h5 {
  font-size: 18px;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  white-space: normal;
  text-align: center;
}

.battle-win-popup .case_add p {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 12px;
}

.battle-win-popup .case_add p span {
  color: #e89c30;
  font-size: 16px !important;
  font-weight: 600;
  letter-spacing: 1px;
  text-shadow: 0 0 10px rgb(232 157 49 / 85%);
  white-space: nowrap;
}

.battle-win-popup .user-win-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 50px 0 60px;
  min-height: 116px;
}

.battle-win-popup .user-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.battle-win-popup .user-img {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  border: 5px solid #e89a33;
  padding: 0px;
  overflow: hidden;
  margin: 0 15px;
  box-shadow: 3px 4px 16px 5px rgb(225 150 45 / 22%);
}

.battle-win-popup .user-img img {
  width: 100%;
  height: auto;
  border-radius: 100%;
  object-fit: contain;
}

.battle-win-popup .user-content p {
  font-size: 12px !important;
  text-transform: uppercase;
  font-weight: 600;
  margin-top: 20px;
}

.battle-win-popup .list_btn {
  display: flex;
  justify-content: center;
}

.battle-win-popup .cmn-btn {
  padding: 11px 30px;
  font-size: 16px;
  font-weight: 600;
}

.battle-win-popup .main_diamond {
  position: relative;
  top: 90px;
  color: #ffffff;
  left: 0;
  letter-spacing: 1.41px;
  position: absolute;
  right: 0;
  text-align: center;
  text-transform: uppercase;
  z-index: 0;
  width: 40px;
  height: 40px;
  border: 2px solid #e89a33;
  margin: 0 auto;
  transform: rotate(45deg);
  box-shadow: 1px 0px 12px 4px rgb(225 150 45 / 22%);
}

.battle-win-popup .diamond {
  position: absolute;
  top: 3px;
  left: 3px;
  background: #e89b32;
  width: 30px;
  height: 30px;
  margin: 0 auto;
}

.battle-win-popup .diamond span {
  position: relative;
  top: 2px;
  left: 2px;
  font-size: 17px;
  font-weight: 600;
  transform: rotate(-45deg);
}

.battle-win-popup .right-user {
  display: flex;
  flex-direction: row;
  margin-left: 35px;
}

.battle-win-popup .left-user {
  display: flex;
  flex-direction: row;
  margin-right: 30px;
}

/******** End Style for battle win popup *********/

/******** Start Style for FairnessGuaranteed Popup *********/
.fairness-info-popup .table_info {
  border-spacing: 0;
  margin-bottom: 50px;
  margin-top: 40px;
  width: 100%;
  margin-left: 4px;
}

.fairness-info-popup .table__info {
  table-layout: fixed;
}

.table_head {
  text-align: left;
  text-transform: uppercase;
}

.table__info thead th:first-child {
  min-width: 100px;
  width: 14%;
}

.table_row {
  color: #e89b32;
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 1px;
  padding: 0 10px 30px;
}

.table__info td {
  font-size: 12px;
  height: auto !important;
}

td.table_data {
  font-size: 12px !important;
  height: auto !important;
  padding: 12px;
  background: #082432d9;
  margin: 10px 0;
  border-bottom: 5px #081f2a solid;
  border-right: 5px #081f2a solid;
}

td.table_data .genrate-seeds {
  cursor: pointer;
  padding-top: 5px;
  font-size: 13px;
}

td.table_data .genrate-seeds i {
  margin-right: 10px;
}

.fairness-info-popup .nav-tabs .nav-link.active {
  color: #ffb200;
  padding: 10px 19px;
  background-color: #082d41;
}

.fairness-info-popup .nav-tabs .nav-link {
  border: none;
  padding: 10px 19px;
  color: #fff;
}

.fairness-info-popup .nav-tabs {
  border-bottom: 1px solid #082d41;
}

.fairness-info-popup a#uncontrolled-tab-example-tab-info {
  text-align: center;
}

.fairness-info-popup .table_data span a {
  font-size: 12px;
  letter-spacing: 1px;
}

.fairness-info-popup .verify-form {
  margin: 20px 35px;
}

.fairness-info-popup .user-info-card__list li span {
  /* width: 100%;
  font-size: 15px; */
}

.fairness-info-popup .user-info-card__list li input {
  margin-right: 5px;
  border: 1px solid #082d41;
  background-color: #041721 !important;
  border-radius: 50px;
}

.fairness-info-popup span.info_label {
  font-size: 13px !important;
  margin-left: 20px;
}

.fairness-info-popup .fairness-formula pre {
  font-size: 13px !important;
  width: 100%;
  margin-left: 5px;
  color: white;
  white-space: pre-line;
  border: 1px solid #082d41;
  background-color: #041721 !important;
  padding: 10px 20px;
}

.fairness-info-popup .verify-form li {
  margin: 18px 0px;
}

.fairness-info-popup .roll-text {
  margin-top: 50px;
}

.fairness-info-popup .roll-text p {
  font-size: 13px;
  font-weight: 300;
  text-transform: uppercase;
  letter-spacing: 1px;
  text-align: center;
}

.fairness-info-popup span.rand-text {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 5px;
}

.fairness-info-popup span.value {
  color: #e89c30;
  text-shadow: 0 0 10px rgb(232 157 49 / 85%);
  font-size: 21px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding: 0 5px;
}

.odds_range thead th:first-child {
  min-width: 100px;
  width: 60%;
}

/******** End Style for FairnessGuaranteed Popup *********/

/******** Start Style for Battle Board Button *********/
.watch-battle-page .cancle_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.watch-battle-page .cancle_btn .cmn-btn {
  padding: 5px 6px;
  font-size: 11px;
  font-weight: 600;
  margin-top: 20px;
  white-space: nowrap;
  display: block;
  margin: 0px auto 0px auto;
}

.watch-battle-page .quit_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.watch-battle-page .quit_btn .cmn-btn {
  padding: 5px 25px;
  font-size: 11px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
  margin: 20px auto 0px;
}

.watch-battle-page .join_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.watch-battle-page .join_btn .cmn-btn {
  padding: 5px 25px;
  font-size: 11px;
  font-weight: 600;
  margin-top: 20px;
  display: block;
  margin: 20px auto 0px;
}

.watch-battle-page .login_btn {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.watch-battle-page .login_btn .cmn-btn {
  padding: 5px 25px;
  font-size: 11px;
  font-weight: 600;
  margin-top: 20px;
}

/******** End Style for Battle Board Button *********/

/****** watch battle game **********/
/* .watch-battle-page .two-roulette .roulette_wrapper {
  min-width: 100px;
  margin: 0px auto;
  width: 100%;
} */

.players.player-one.first-player.lose-player:before {
  background: linear-gradient(rgb(236 0 57 / 0%), rgb(255 30 78 / 44%));
  top: -6px;
}

.players.player-one.last-player.lose-player::after {
  background: linear-gradient(rgb(236 0 57 / 0%), rgb(255 30 78 / 44%));
  top: -6px;
}

.players.player-one.first-player.win-player:before {
  background: linear-gradient(rgb(88 255 48 / 0%), #06964e5e);
  top: -6px;
}

.players.player-one.last-player.win-player::after {
  background: linear-gradient(rgb(88 255 48 / 0%), #06964e5e);
  top: -6px;
}

.players.player-one.last-player.lose-player,
.players.player-one.last-player.win-player {
  border-bottom-right-radius: 5px;
}

.players.player-one.first-player.lose-player,
.players.player-one.first-player.win-player {
  border-bottom-left-radius: 5px;
}

.players.player-one.lose-player {
  background: linear-gradient(rgb(236 0 57 / 0%), rgb(255 30 78 / 44%));
  border-bottom: 4px solid #bf0b55;
}

.players.player-one.win-player {
  background: linear-gradient(rgb(88 255 48 / 0%), #06964e5e);
  border-bottom: 4px solid #0e7a1c;
}

.verify-form .left-section img {
  height: 200px;
  width: 100%;
  object-fit: contain;
}

.verify-form .left-section {
  width: 50%;
}

.verify-form .right-section {
  width: 50%;
}

.verify-form .order-details-content {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.verify-form .right-section h6 {
  margin: 10px 0px;
}

.verify-form .right-section h6 span {
  font-weight: 400;
  margin-left: 20px;
  color: #b87617;
}

.no-result {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0px;
}

.new-battle-table  {
  /* border-radius: 13.408px 13.408px;
background: #1D1D27; */
}

.new-battle-table tbody{
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.new-battle-table  tr td:first-child {
  width: 10%;
  border-radius: 13.408px 0px 0px 13.408px;
  border-bottom: none;
  background: #1D1D27 !important;
}

.new-battle-table tr td:last-child{
  border-radius: 0px 13.408px 13.408px 0px ;
  border: none;
}

.new-battle-table .battle-slider-section {
width: 550px;
}
.new-battle-table .battle-img {
  width: 138px;
}
.new-battle-table .new-battle-img:before {
  background-image:none;
  border: none;
}

.new-battle-table .new-battle-img {
  padding: 15px 15px;
}

.new-battle-table tbody td {
  background: #151722 !important;
  border: none !important;
}
.new-battle-table .players-img {
  display: flex;
  gap: 2px;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.battles-page-box {
  padding: 50px 0px 50px;
}

.new-battle-table .players-img img {
  margin:0px;
  border-radius: 5.587px;
  background-color: #252530;
}
.battle-players-cost {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  max-width: 120px;
  width: 100%;
  height: 63px;
  padding: 5px;
  border-radius: 13.408px;
  background: #252530;
}
.battle-players-cost p{
  line-height: 20px;
}
.battle-players-button {
display: flex;
flex-direction: column;
gap: 10px;
}

.new-create-battle-page {
padding: 110px 0px 20px;
}

.new-create-battle-page .last-live-drops-section {
padding: 0px;
}
.new-header-battle{
  background: #1B1C25;
    border-radius: 12px;
    padding: 10px 20px;
}

.new-header-battle select {
  background: #383841;
    padding: 5px 10px;
    height: 40px;
    width: 40px;
    border-radius: 6px;
    width: 120px;
    border: none;
}

.header-number-btn {
  background: #383841;
  padding: 5px 10px;
  height: 40px;
  width: 40px;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* new battle card design */

.new-battle-card {
  background: #22232F;
  border: 1px solid #22232f;
  border-radius: 6px;
  min-height: 240px;
}

.new-battle-card:before {
  background-image:unset;
  border:none;
}

.new-battle-card .contest-card__thumb {
  background: #22232f;
}

.new-battle-card .increment {
  background: #2F313F;
  border-radius: 12px;

}

.battle-card .increment {
  width: 110px;
}

.battle-card .increment span{
height: 100%;
text-align: center;
display: flex;
align-items: center;
justify-content: center;
}
.battle-card .increment span:nth-child(2) {
  background-color: #0B0B0F;
  padding: 0px 10px;
  width: 40px;
}
.new-battle-card .increment:after {
  border: none;
}
.new-battle-card .increment:before {
  border: none;
}

.new-create-battle-page .battle-box {
  border: 1px dashed #22232f;
  background: #0b0b0f;
  min-height: 240px;
}

/****** media query css **********/
@media screen and (max-width: 991px) {
  .table__info thead th:first-child {
    min-width: 100px;
    width: 30%;
  }

  .battles-page .date-price {
    max-width: 40%;
    width: 100%;
  }

  .battles-page .filter-header {
    flex-wrap: nowrap;
  }

  .battles-page .header-content {
    margin-bottom: 20px;
  }

  .all-battles-listing .battle-box {
    margin: 0 10px;
  }
}

@media screen and (max-width: 767px) {
  .battle__header .battle_cost {
    margin-top: 10px;
    margin-left: 20px;
    font-size: 12px;
  }

  .battles-page .filter-header {
    flex-wrap: wrap;
  }

  .battle__header .price-highlight {
    font-size: 12px !important;
  }

  .battle__header .battle_cost span {
    font-size: 12px;
  }

  .battle__header .totol_cost:before {
    background: #2C3034;
    ;
  }

  .battle__header .totol_cost:after {
    background: #1B1C25;
  }

  .battle-card {
    margin: 0 20px;
  }

  .all-battles-listing .battle-box {
    width: unset;
    margin: 0 20px;
  }

  .battles-history .cmn-btn {
    padding: 10px 20px;
    margin: 20px auto;
  }

  .table__info thead th:first-child {
    min-width: 100px;
    width: 30%;
  }

  .players-length .players img {
    height: 30px;
    width: 30px;
    margin-right: 0px;
    margin-bottom: 5px;
  }

  .players-length.three-palyers .players {
    margin-right: 0px;
    flex-direction: column;
    text-align: center;
  }

  .all-details .details-section .left-details-section,
  .all-details .details-section .right-details-section {
    width: 100%;
  }

  .all-details .details-section {
    flex-direction: column;
  }

  .details-section img {
    height: 40px;
  }

  .details-section h5 {
    font-size: 12px;
    margin: 20px 0px 5px 0px;
  }

  .details-section p {
    font-size: 12px;
  }

  .details-section h6 {
    font-size: 12px;
  }

  .players-length.three-palyers .players {
    padding: 15px 0px;
  }

  .players-length:before {
    top: 3px;
    transform: skewY(42deg) translateY(-11px);
    width: 20px;
    left: -20px;
  }

  .players-length::after {
    right: -20px;
    top: 3px;
    transform: skewY(-42deg) translateY(-11px);
    width: 20px;
  }

  .players-length {
    margin-left: 20px;
    margin-right: 20px;
  }

  /****** battles page **********/
  /* .battles-page .table tbody td{
  white-space: nowrap;
} */
  .battle-slider-section {
    max-width: 200px !important;
  }

  .battle-img {
    width: 95px;
  }

  .battle-img img {
    height: 45px;
  }

  .battle-img span,
  .battle-table .cirlce-section span {
    font-size: 12px;
  }

  .battle-table tr td svg {
    height: 45px;
  }

  .battle-table .cmn-btn {
    padding: 10px 12px;
    white-space: nowrap;
    font-size: 12px;
  }

  .battles-page .table thead th {
    padding: 0 10px 10px;
  }

  .battles-page .table tbody td {
    padding: 0px 15px;
  }

  .battles-page .date-price {
    width: 100%;
    max-width: 100%;
  }

  .battles-page,
  .watch-battle-page {
    padding: 110px 0px 100px;
  }

  /******* Resp style for addboxPop **********/
  .boxadd-model-page .modal_header {
    display: flex;
    flex-direction: column;
  }

  .boxadd-model-page .search {
    width: 100%;
  }

  .boxadd-model-page input[type="search"] {
    min-height: 50px;
    height: 100%;
  }

  /* .search {
    position: relative;
  }

  .search .la-search {
    position: absolute;
    top: 18px;
    right: 18px;
    font-size: 25px;
  } */

  .boxadd-model-page .case_add h4 {
    padding: 7px 50px;
    font-size: 16px;
    margin-top: 15px;
  }

  .boxadd-model-page button.close-btn {
    min-width: 30px;
    min-height: 30px;
    border-radius: 3px;
    line-height: 20px;
  }

  .boxadd-model-page .close_pup_btn {
    top: -35px;
    right: 0;
  }

  .boxadd-model-page button.close-btn .la-times {
    font-size: 20px;
  }

  .battles-page .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .all-battles-listing .date-price {
    justify-content: center;
    margin: 25px 0;
  }

  .battle__header .battle_round {
    display: none;
  }

  .fairness-info-popup .text-danger {
    font-size: 20px;
    margin-top: 20px;
  }

  .fairness-info-popup td.table_data {
    word-break: break-word;
  }

  .fairness-info-popup span.section-title-text {
    margin-top: 20px;
  }

  .fairness-info-popup .fairmodalbady {
    font-size: 14px;
  }

  .fairness-info-popup .nav-tabs .nav-link {
    font-size: 14px;
  }
}

.bot-counter{
  text-transform: uppercase;
  font-size: 25px;
  font-weight: 600;
  color: #e89c31;
}