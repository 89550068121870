  .box-popup-sections {
    position: fixed;
    top: 0;
    right: 0;
    max-width:  450px;
    width: 100%;
    z-index: 99999;
}
.box-popup-sections .item-detail-page {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    max-width: 450px;
    width: 100%;
    height: 100vh;
    background-color: #1b1c25;
    box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024, 0 9px 46px 8px #0000001f;
    border: 1px solid #292b39;
    transition: all ease .5s;
  }
  .box-popup-sections .item-contents {
    padding-top: 20px;
    padding-left: 25px;
    padding-right: 10px;
    height: 100%;
    overflow: hidden;
    overflow-y: scroll;
  }
  .box-popup-sections .title-btn {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .box-popup-sections .title {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    color: #e89c31;
  }
  .box-popup-sections .img-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
  }
  /* .img-content hr{
      border-bottom: 1px solid red;
    } */
    .box-popup-sections .box-img img {
    max-width: 220px;
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .box-popup-sections .prodcut-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border-bottom: 1px solid #ffffff2d;
  }
  .box-popup-sections .prodcut-content h4 {
    /* font-size: 28px; */
    color: #ffffff;
    margin-top: 30px;
    margin-bottom: 20px;
    text-transform: capitalize;
  }
  .box-popup-sections .prodcut-content .price {
    color: #e89c31;
  }
  .box-popup-sections .title p {
    color: #e89c31;
}
  .box-popup-sections .product-desp p {
    color: #ffffff;
    font-size: 16px;
    line-height: 1.2;
    /* font-weight: 200 !important; */
    margin-top: 25px;
  }
  .box-popup-sections .product-desp .brand {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 25px;
}
.box-popup-sections .product-desp  .category {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
  .box-popup-sections .product-desp h6 {
    color: #e89c31;
    text-transform: capitalize;
  }
  .box-popup-sections .product-desp span {
    text-align: left;
    text-transform: capitalize;
  }
  .box-popup-sections .title-btn i.fa.fa-times {
    font-size: 25px;
    color: #e89c31;
    cursor: pointer;
}
.box-popup-sections .outcome {
  margin-top: 40px;
}
.box-popup-sections .outcome h6 {
  color: #e89c31;
}
.box-popup-sections .slide-box {
  margin-right: 2px;
}
.box-popup-sections .slick-prev {
  left: -17px;
}
.box-popup-sections .slide-box h6 {
  color: #e89c31;
  margin-top: 25px;
  margin-bottom: 10px;
}
.box-popup-sections .slick-list {
  margin-bottom: 30px;
}
.box-popup-sections .recent-item {
  margin: 0px 5px;
}
.box-popup-sections .slick-next {
  right: -17px;
}

 
.box-popup-sections .item-contents::-webkit-scrollbar {
  background-color: #1b1c25;
  width: 16px;
}
.box-popup-sections .item-contents::-webkit-scrollbar-track {
  background-color: #1b1c25;
}
.box-popup-sections .item-contents::-webkit-scrollbar-track:hover {
 background-color: #1e1f28;
}
.box-popup-sections .item-contents::-webkit-scrollbar-thumb {
  background-color: #ffffff1d;
  border-radius: 16px;
  border: 5px solid #1b1c25;
}
.box-popup-sections .item-contents::-webkit-scrollbar-thumb:hover {
  background-color: #ffffff2d;
}
.box-popup-sections .item-contents::-webkit-scrollbar-button {
  display: none;
}
.hide-popup .item-detail-page {
  right: -450px;
}
.show-popup .item-detail-page {
  right: 0px;
}
.box-popup-btn{
  display: flex;
  justify-content: center;
}

/********************** Over Blur Effect********/


.overlay-view {
  position: relative;
}

.overlay-view:before {
  content: "";
  position: absolute;
  top: -100px;
  bottom: 0;
  left: 12px;
  right: 12px;
  z-index: 9;
  border-radius: 10px;
  background: rgba(35,40,60,.08);
  -webkit-backdrop-filter: blur(12px);
  backdrop-filter: blur(12px);
}

.overlay-text {
  font-size: 24px;
  left: 50%;
  z-index: 9;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  max-width: 380px;
  margin: 0px auto;
  padding: 50px 20px;
  position: absolute;
  transform: translateX(-50%);
  text-shadow: 1px 1px 5px #000;
}