.stripe-details-section {
    padding-top: 45px;
}

.stripe-details {
    position: relative;
    /* z-index: 99; */
    max-width: 600px;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0px 0px;
    /* background: rgb(4 31 45); */
    border-radius: 5px;
    border: none;
}
/* .stripe-details .content {
    margin-top: 3px;
} */
.stripe-details .content {
    margin: 0 13px;
}
.stripe-details .content .arrow_con {
    display: flex;
    flex-direction: row;
}
.stripe-details .content .arrow_con i.las.la-arrow-right {
    font-size: 23px;
    font-weight: 900;
    margin-left: 10px;
    color: #ffffff;
}
.stripe-details .content p {
    text-transform: uppercase;
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    color: #ffffff;
    display: grid;
}

.stripe-details .content kbd {
    font-weight: 300;
    font-size: 13px;
    letter-spacing: 1px;
    text-transform: uppercase;
    background: #292b39;
    border: 1px solid #363742;
    cursor: pointer;
}

@media (max-width: 767) {
    .stripe-details .content p {
        font-size: 12px;
    }
}

/* @media (max-width: 991px) {
    .stripe-details {
        padding: 10px;
    }
    .key {
        margin-top: -25px;
    }
    .stripe-details .content .arrow_con i.las.la-arrow-right {
        display: none;
    }
    .stripe-details .num {
        margin-top: -20px;
        margin-left: 10px;
    }
} */

/* @media (max-width: 767px) {
    .stripe-details-section {
        padding-top: 30px;
        padding-right: 10px;
        padding-left: 10px;
    }
    .stripe-details .num {
        margin-top: 0;
        margin-left: 0;
    }
    .stripe-details {
        align-items: center;
        padding: 5px  10px;
        flex-wrap: wrap;
    }
    .stripe-details .content p {
        text-transform: uppercase;
        font-size: 9px;
    }
    .stripe-details .content .arrow_con i.las.la-arrow-right {
        font-size: 18px;
        font-weight: 900;
        margin-left: 3px;
        color: #ffffff;
        margin-top: -2px;
        display: block;
    }
    .stripe-details .content kbd {
        font-weight: 300;
        font-size: 8px;
        letter-spacing: 1px;
        margin-right: 0px;
        line-height: 3;
    }
} */
