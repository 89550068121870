/*
  ************************ CSS table of content ************************

  1)  reset css
  2)  global css
  3)  header
  4)  hero
  5)  inner-hero-section
  6)  about section
  7)  next-draw-section
  8)  how to play section
  9)  contest section
  10) contest slider section
  11) contest feature section
  12) winner section
  13) winner details section
  14) latest winner section
  15) overview section
  16) features section
  17) team section
  18) testimonial section
  19) support section
  20) counter section
  21) contest-details-section
  22) lottery-details
  23) cart section
  24) checkout section
  25) affiliate single section
  26) how it work section
  27) affiliate partner section
  28) top affiliate section
  29) video section
  30) buy ticket section
  31) client section
  32) faq section
  33) blog section
  34) blog single section
  35) contact section
  36) login
  37) user section
  38) error-section
  39) sidebar
  40) footer section

  **************************************************************** */
/* reset css start */

html {
  scroll-behavior: smooth;
}

body {
  padding: 0;
  margin: 0;
  font-weight: 400;
  position: relative;
  background-color: #0f0232;
}

img {
  max-width: 100%;
  height: auto;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style: none;
}

button {
  cursor: pointer;
}

*:focus {
  outline: none;
}

button {
  border: none;
}

button:focus {
  outline: none;
}

input:not([type="radio"]),
textarea {
  padding: 10px 20px;
  border: 1px solid #292b39;
  width: 100%;
  background-color: #22232d;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  color: #e9e9e9;
}

input:not([type="radio"])::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #9aace5;
}

input:not([type="radio"])::-moz-placeholder,
textarea::-moz-placeholder {
  color: #9aace5;
}

input:not([type="radio"]):-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #9aace5;
}

input:not([type="radio"]):-moz-placeholder,
textarea:-moz-placeholder {
  color: #9aace5;
}

select {
  padding: 10px 20px;
  width: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  cursor: pointer;
  color: #e9e9e9;
  background-color: transparent;
  height: 50px;
  border-radius: 4px;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}

textarea {
  min-height: 150px;
  resize: none;
  width: 100%;
}

span {
  display: inline-block;
}

a:hover {
  color: #ffb200;
}

.text-danger {
  color: #ffb200 !important;
}

.site-logo img {
  width: 180px;
}
.footer-section .site-logo img {
  width: 130px;
}
/* global css strat */
.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

.mt-50 {
  margin-top: 50px;
}

.mt-80 {
  margin-top: 80px;
}

.mb-50 {
  margin-bottom: 50px;
}

.mb-none-30 {
  margin-bottom: -30px !important;
}

.mb-none-50 {
  margin-bottom: -50px !important;
}
.pt-55 {
  padding-top: 55px;
}

.pt-90 {
  padding-top: 90px;
}

.pb-90 {
  padding-bottom: 90px;
}
.pb-75 {
  padding-bottom: 70px;
}
.pt-120 {
  padding-top: 120px;
}

.pb-120 {
  padding-bottom: 120px;
}

.pt-180 {
  padding-top: 180px;
}

.pb-180 {
  padding-bottom: 180px;
}

.pt-150 {
  padding-top: 150px;
}

.pb-150 {
  padding-bottom: 150px;
}

.mt-minus-50 {
  margin-top: -50px;
}

.mt-minus-100 {
  margin-top: -100px;
}

.mt-minus-150 {
  margin-top: -150px;
}

.mt-minus-270 {
  margin-top: -270px;
}

.mt-minus-300 {
  margin-top: -300px;
}

.mt-100 {
  margin-top: 100px;
}

.bg_img {
  background-size: cover;
  background-position: center;
}

.bg--one {
  background-color: #0f0232 !important;
}

.bg--two {
  background-color: #02014a !important;
}

.curve-shape {
  position: relative;
  z-index: 9;
}

.curve-shape::after {
  position: absolute;
  content: "";
  top: -85px;
  left: 0;
  width: 100%;
  height: 150px;
  background-color: #0f0232;
  -webkit-clip-path: ellipse(55% 75% at 50% 75%);
  clip-path: ellipse(55% 75% at 50% 75%);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  z-index: -1;
}

.slice-shape-two {
  clip-path: polygon(50% 10%, 100% 0, 100% 100%, 0 100%, 0 0);
}

.overlay-one {
  position: relative;
  z-index: 9;
}

.overlay-one::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #02014a;
  opacity: 0.95;
  z-index: -1;
}

.slice-shape {
  clip-path: polygon(0 0, 100% 8%, 100% 100%, 0% 100%);
}

select option {
  background-color: #02014a;
}

label {
  color: #ffffff;
  margin-bottom: 13px;
}

input {
  min-height: 50px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.nice-select {
  width: 100%;
  min-height: 50px;
  line-height: 50px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.nice-select .list {
  width: 100%;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.15);
}

.section-bg {
  background-color: #0f0232;
}

.section-bg-two {
  background-color: #02014a;
}

.section-header {
  margin-bottom: 15px;
  margin-top: -8px;
}
.unboxing-header .section-header {
  padding-bottom: 28px;
  padding-top: 14px;
  margin: 0;
}

.section-header p {
  margin-top: 10px;
  font-size: 18px;
  color: #f1f3ff;
}

.section-sub-title {
  font-size: 24px;
  font-family: "Biko";
  color: #fff;
  margin-bottom: 13px;
}
.section-title {
  font-size: 44px;
  text-transform: capitalize;
}

.section-title.style--two {
  text-transform: unset;
}

.border-radius-100 {
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
}

.hover--effect-1 {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.hover--effect-1:hover {
  -webkit-transform: translateY(-5px) scale(1.05);
  -ms-transform: translateY(-5px) scale(1.05);
  transform: translateY(-5px) scale(1.05);
}

.has-link {
  position: relative;
}

.item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.video-button {
  width: 105px;
  height: 105px;
  color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
  background-color: #ffb200;
  text-align: center;
  line-height: 125px;
  animation: pulse 2000ms linear infinite;
  -webkit-animation: pulse 2000ms linear infinite;
  -moz-animation: pulse 2000ms linear infinite;
}

.video-button::before,
.video-button::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 74px;
  background-color: #ffb200;
  opacity: 0.15;
  z-index: -10;
}

.video-button::before {
  z-index: -10;
  animation: inner-ripple 2000ms linear infinite;
  -webkit-animation: inner-ripple 2000ms linear infinite;
  -moz-animation: inner-ripple 2000ms linear infinite;
}

.video-button::after {
  z-index: -10;
  animation: outer-ripple 2000ms linear infinite;
  -webkit-animation: outer-ripple 2000ms linear infinite;
  -moz-animation: outer-ripple 2000ms linear infinite;
}

.video-button i {
  margin-left: 10px;
  font-size: 36px;
}

@-webkit-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@-moz-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@-ms-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  80% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@-webkit-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-moz-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-ms-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  30% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.d-pagination {
  margin-top: 50px;
}

.d-pagination .pagination {
  margin: -5px;
}

.d-pagination .pagination li {
  margin: 5px 5px;
}

.d-pagination .pagination li.active a {
  background-color: #ffb200;
  color: #ffffff;
}

.d-pagination .pagination li.active a:hover {
  background-color: #ffb200;
  color: #ffffff;
}

.d-pagination .pagination li a {
  border-radius: 50% !important;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  -ms-border-radius: 50% !important;
  -o-border-radius: 50% !important;
  color: #e9e9e9;
  font-weight: 500;
  width: 45px;
  height: 45px;
  border: 1px solid #e5e5e5;
  text-align: center;
  line-height: 28px;
}

.d-pagination .pagination li a:hover {
  color: #ffb200;
  background-color: transparent;
  border-color: #ffb200;
}

.cmn-list {
  margin-top: 20px;
}

.cmn-list li + li {
  margin-top: 15px;
}

.cmn-list li {
  position: relative;
  padding-left: 40px;
}

.cmn-list li::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  width: 20px;
  height: 10px;
  border-left: 1px solid #ffb200;
  border-bottom: 1px solid #ffb200;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.cmn-list-2 {
  margin-top: 20px;
}

.cmn-list-2 li + li {
  margin-top: 10px;
}

.cmn-list-2 li {
  position: relative;
  padding-left: 45px;
}

.cmn-list-2 li:nth-last-of-type(3n + 3)::before {
  border-color: #a22546;
}

.cmn-list-2 li:nth-last-of-type(3n + 2)::before {
  border-color: #f7a139;
}

.cmn-list-2 li:nth-last-of-type(3n + 1)::before {
  border-color: #3097d1;
}

.cmn-list-2 li::before {
  position: absolute;
  content: "";
  top: 5px;
  left: 0;
  width: 20px;
  height: 10px;
  border-left: 1px solid red;
  border-bottom: 1px solid red;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

.number-list--style {
  list-style-type: decimal;
  padding-left: 15px;
}

.number-list--style li span {
  font-weight: 500;
  color: #ffb200;
}

.pagination {
  margin-top: 50px;
}

.pagination li + li {
  margin-left: 10px;
}

.pagination li.active a {
  background-color: #ffb200;
  border-color: #ffb200;
  color: #ffffff;
}

.pagination li a {
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 3px !important;
  -webkit-border-radius: 3px !important;
  -moz-border-radius: 3px !important;
  -ms-border-radius: 3px !important;
  -o-border-radius: 3px !important;
  padding: 12px 25px;
  background-color: transparent;
  color: #e9e9e9;
  font-weight: 700;
}

.pagination li a:hover {
  background-color: #ffb200;
  border-color: #ffb200;
  color: #ffffff;
}

.cmn-accordion .card + .card {
  margin-top: 20px;
}

.cmn-accordion .card {
  border: none;
  background-color: #683df5;
  padding-left: 30px;
  padding-right: 30px;
  border-radius: 20px !important;
  -webkit-border-radius: 20px !important;
  -moz-border-radius: 20px !important;
  -ms-border-radius: 20px !important;
  -o-border-radius: 20px !important;
}

.cmn-accordion .card-header {
  background-color: #683df5;
  padding: 0;
  margin-bottom: 0 !important;
  border: none;
}

.cmn-accordion .card-header .btn-link {
  padding: 25px 0;
  display: block;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  position: relative;
  text-align: left;
  padding-right: 20px;
  font-size: 22px;
  color: #ffffff;
}

.cmn-accordion .card-header .btn-link.collapsed::after {
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.cmn-accordion .card-header .btn-link::after {
  position: absolute;
  content: "\f107";
  right: 0;
  top: 25px;
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #918fde;
  font-size: 20px;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.cmn-accordion .card-header .btn-link:focus,
.cmn-accordion .card-header .btn-link:hover {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}

.cmn-accordion .card-body {
  padding: 30px 0;
  border-top: 1px solid #9a80e5;
}

.cmn-tabs {
  border: none;
}

.cmn-tabs li a {
  padding: 14px 35px;
  font-size: 18px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 999px !important;
  -webkit-border-radius: 999px !important;
  -moz-border-radius: 999px !important;
  -ms-border-radius: 999px !important;
  -o-border-radius: 999px !important;
  background-image: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
  background-image: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
  /*background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);*/
  box-shadow: -1.113px 7.922px 16px 0px rgba(143, 61, 245, 0.63);
  border: none !important;
  text-transform: capitalize;
}

.cmn-tabs li a.active {
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
  color: #ffffff !important;
}

blockquote p {
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
}

input:focus,
textarea:focus,
.nice-select.open {
  border-color: #ffb200;
}

.page-breadcrumb {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: 15px;
}

.page-breadcrumb li {
  color: #ffffff;
  text-transform: capitalize;
}

.page-breadcrumb li::after {
  content: "-";
  color: #ffffff;
  margin: 0 5px;
}

.page-breadcrumb li:first-child::before {
  content: "\f102";
  font-family: "Flaticon";
  color: #ef428c;
  margin-right: 6px;
}

.page-breadcrumb li:last-child::after {
  display: none;
}

.page-breadcrumb li a {
  color: #ffffff;
  text-transform: capitalize;
}

@-webkit-keyframes customBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  75% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-moz-keyframes customBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  75% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@-ms-keyframes customBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  75% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes customBounce {
  0% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  25% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  50% {
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    transform: translateY(-20px);
  }

  75% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }

  100% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
}

.scroll-to-top {
  height: 60px;
  width: 60px;
  position: fixed;
  bottom: 5%;
  right: 5%;
  display: none;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
  border-radius: 50%;
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  background-image: linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  line-height: 77px;
  box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.25);
}

.scroll-to-top .scroll-icon {
  font-size: 36px;
  color: #ffffff;
  display: inline-block;
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  z-index: 999;
  overflow: hidden;
}

.preloader svg {
  width: 100%;
  height: 100%;
  visibility: hidden;
}

/* theme-switcher css start */
.theme-switcher {
  position: fixed;
  top: 20%;
  right: -190px;
  z-index: 99999;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.theme-switcher.active {
  right: 0;
}

.theme-switcher__icon {
  width: 45px;
  height: 45px;
  background-color: #ffffff;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
}

.theme-switcher__icon i {
  animation: spin 3s infinite linear;
}

.theme-switcher__body {
  padding: 20px;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 8px 0 0 8px;
  -webkit-border-radius: 8px 0 0 8px;
  -moz-border-radius: 8px 0 0 8px;
  -ms-border-radius: 8px 0 0 8px;
  -o-border-radius: 8px 0 0 8px;
}

.theme-switcher__body .single {
  width: 150px;
  height: 100px;
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.theme-switcher__body .single.dark {
  background-color: #000000;
}

.theme-switcher__body .single.light {
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
}

.theme-switcher__body .single.active {
  position: relative;
}

.theme-switcher__body .single.active::after {
  position: absolute;
  top: -8px;
  right: -6px;
  width: 25px;
  height: 25px;
  background-color: #5039fb;
  font-size: 14px;
  text-align: center;
  line-height: 25px;
  color: #ffffff;
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.theme-switcher__body a {
  width: 100%;
  height: 100%;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@-moz-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@-ms-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
}

/* theme-switcher css end */
h1 {
  font-size: 62px;
}

h2 {
  font-size: 40px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 22px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Biko";
  color: #ffffff;
  font-weight: 600;
  margin: 0;
  line-height: 1.3;
}

h1 > a,
h2 > a,
h3 > a,
h4 > a,
h5 > a,
h6 > a {
  font-family: "Biko";
  color: #ffffff;
  font-weight: 600;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  line-height: 1.3;
}

p,
li,
span {
  font-size: 16px;
  color: #e9e9e9;
  line-height: 1.7;
  margin: 0;
}

p {
  font-size: 18px;
}

a {
  text-decoration: none;
  display: inline-block;
  font-family: "Biko";
  color: #ffffff;
}

a:hover {
  text-decoration: none !important;
}

/* button css start */
.cmn-btn {
  /* display: inline-block; */
  padding: 15px 35px;
  font-size: 18px;
  font-weight: 600;
  text-transform: capitalize;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  /* background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  */
  background-image: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
  /* box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35); */
  box-shadow: 0px 17px 40px 0px rgb(2 24 36);
  -webkit-transition: background-size 0.3s;
  -o-transition: background-size 0.3s;
  transition: background-size 0.3s;
  color: #ffffff;
  width: fit-content;
}

.cmn-btn:hover {
  background-size: 300%;
  color: #ffffff;
}

.cmn-btn.style--two {
  /*background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);*/
  background-image: -moz-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);

  box-shadow: -1.113px 7.922px 16px 0px rgba(143, 61, 245, 0.63);
}

.cmn-btn.style--three {
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  /* background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%); */
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}

.cmn-btn.btn--sm {
  padding: 10px 22px;
  font-size: 14px;
}
.add_box .cmn-btn.btn--sm {
  width: 100%;
  height: 45px !important;
  border-radius: 50px;
}
.filter-tabs-section .contest-card__footer .btn-style span {
  z-index: 99 !important;
  position: relative !important;
  line-height: 1.9;
}

.btn-border {
  padding: 12px 35px;
  font-size: 16px;
  font-weight: 600;
  text-transform: capitalize;
  color: #ab6b10;
  background: #fff;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  /* border: 1px solid rgba(255, 255, 255, 0.25); */
  cursor: pointer;
  /* background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%); */
}

.btn-border:hover {
  background-size: 300%;
  color: #ab6b10;
}
.btn-grp {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  margin-left: -10px;
  margin-right: -10px;
}

.btn-grp *[class*="btn"] {
  margin: 5px 10px;
  align-items: center;
}

.btn-grp *[class*="btn"].d-flex {
  padding: 8px 35px;
}

*[class*="btn"] .btn-sm {
  padding: 6px 20px;
}

/* form css start */
.form-control:focus {
  background-color: transparent;
  box-shadow: none;
  border-color: #c0464d;
}

input:focus,
input:active,
textarea:focus,
textarea:active {
  border-color: #e1972e !important;
}

.custom-checkbox {
  padding-left: 30px;
  position: relative;
  overflow: hidden;
}

.custom-checkbox label {
  font-size: 14px !important;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-transform: capitalize !important;
}

.custom-checkbox .checkbox {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 0;
  top: 5px;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  /*background-image: -ms-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);*/
  background-image: -moz-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
}

.custom-checkbox .checkbox::before {
  position: absolute;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #ffffff;
  font-size: 8px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) scale(1.1, 1.1);
  -ms-transform: translate(-50%, -50%) scale(1.1, 1.1);
  transform: translate(-50%, -50%) scale(1.1, 1.1);
  opacity: 0;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.custom-checkbox input[type="checkbox"] {
  position: absolute;
  left: -100px;
  width: auto;
}

.custom-checkbox input[type="checkbox"]:checked + label ~ .checkbox {
  /*background-image: -ms-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);*/
  background-image: -moz-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
}

.custom-checkbox input[type="checkbox"]:checked + label ~ .checkbox::before {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1, 1);
  -ms-transform: translate(-50%, -50%) scale(1, 1);
  transform: translate(-50%, -50%) scale(1, 1);
  -webkit-transition-delay: 0.3s;
  transition-delay: 0.3s;
}

/* keyframes css start */
@-webkit-keyframes rifaRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-moz-keyframes rifaRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-ms-keyframes rifaRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@keyframes rifaRounded {
  0% {
    -webkit-transform: translate(-50%, -50%) rotate(0deg);
    -ms-transform: translate(-50%, -50%) rotate(0deg);
    transform: translate(-50%, -50%) rotate(0deg);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) rotate(360deg);
    -ms-transform: translate(-50%, -50%) rotate(360deg);
    transform: translate(-50%, -50%) rotate(360deg);
  }
}

@-webkit-keyframes rifaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
  }

  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}

@-moz-keyframes rifaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
  }

  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}

@-ms-keyframes rifaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
  }

  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}

@keyframes rifaPulse {
  0% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }

  25% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  50% {
    -webkit-transform: translate(-50%, -50%) scale(1.1);
    -ms-transform: translate(-50%, -50%) scale(1.1);
    transform: translate(-50%, -50%) scale(1.1);
  }

  75% {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }

  100% {
    -webkit-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
  }
}

@-webkit-keyframes rifaLight {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-moz-keyframes rifaLight {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-ms-keyframes rifaLight {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@keyframes rifaLight {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.6;
  }

  50% {
    opacity: 1;
  }

  75% {
    opacity: 0.6;
  }

  100% {
    opacity: 1;
  }
}

@-webkit-keyframes colorFlow {
  0% {
    background-size: 100%;
    opacity: 1;
  }

  25% {
    background-size: 150%;
    opacity: 0.5;
  }

  50% {
    background-size: 300%;
    opacity: 0.9;
  }

  75% {
    background-size: 150%;
    opacity: 0.5;
  }

  100% {
    background-size: 100%;
    opacity: 1;
  }
}

@-moz-keyframes colorFlow {
  0% {
    background-size: 100%;
    opacity: 1;
  }

  25% {
    background-size: 150%;
    opacity: 0.5;
  }

  50% {
    background-size: 300%;
    opacity: 0.9;
  }

  75% {
    background-size: 150%;
    opacity: 0.5;
  }

  100% {
    background-size: 100%;
    opacity: 1;
  }
}

@-ms-keyframes colorFlow {
  0% {
    background-size: 100%;
    opacity: 1;
  }

  25% {
    background-size: 150%;
    opacity: 0.5;
  }

  50% {
    background-size: 300%;
    opacity: 0.9;
  }

  75% {
    background-size: 150%;
    opacity: 0.5;
  }

  100% {
    background-size: 100%;
    opacity: 1;
  }
}

@keyframes colorFlow {
  0% {
    background-size: 100%;
    opacity: 1;
  }

  25% {
    background-size: 150%;
    opacity: 0.5;
  }

  50% {
    background-size: 300%;
    opacity: 0.9;
  }

  75% {
    background-size: 150%;
    opacity: 0.5;
  }

  100% {
    background-size: 100%;
    opacity: 1;
  }
}

/* header start */
.header {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 999;
  transition: all 0.3s;
}

.header .menu-fixed {
  position: fixed;
  width: 100%;
  z-index: 99;
  background: #072b3f;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.header.menu-fixed .header__top {
  display: none;
}

.header.menu-fixed .header__bottom {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);
  /* background-color: #070b28; */
  background-color: #083248;
  padding: 5px 0;
}

.header.menu-fixed .header__bottom .main-menu li a {
  padding: 30px 15px 30px 0;
}

.header.menu-fixed .header__bottom .main-menu li .sub-menu li a {
  padding: 8px 20px;
}

.header__top {
  /* padding: 10px 0; */
  /* border-bottom: 1px solid #072536; */
}

.header__top .left a {
  font-size: 16px;
  padding-right: 20px;
}

.header__top .left .language {
  margin-left: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.header__top .left .language i {
  color: #ffffff;
  font-size: 18px;
  margin-right: 5px;
}

.header__top .left .language .nice-select {
  width: auto;
  background-color: transparent;
  padding-left: 0;
  padding-right: 10px;
  min-height: 40px;
  line-height: 47px;
  border: none;
}

.header__top .left .language .nice-select::after {
  right: 0;
  margin-top: -2px;
}

.header__top .left .language .nice-select .list {
  background-color: transparent;
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);

  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  width: 75px;
}

.header__top .left .language .nice-select .option {
  background-color: transparent;
  padding-left: 10px;
  padding-right: 10px;
  color: #ffffff;
}

.header__top .right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
}
.header__top .right button {
  padding: 5px 10px !important;
}

.header__top .right .product__cart {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.header__top .right .total__amount {
  background-color: #083248;
  padding: 5px 18px;
  color: #ffffff;
  font-size: 12px;
  border-radius: 30px;
}

.header__top .right .amount__btn {
  background-color: transparent;
  margin-left: 30px;
  position: relative;
  padding-top: 7px;
}

.header__top .right .amount__btn i {
  color: #ffffff;
  font-size: 30px;
}
.header__top .right button i {
  font-size: 20px;
  z-index: 999 !important;
}

.header__top .right .amount__btn .cart__num {
  position: absolute;
  bottom: -2px;
  right: -14px;
  min-width: 22px;
  min-height: 22px;
  font-size: 10px;
  line-height: 22px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  background-image: -ms-linear-gradient(135deg, #e89c31 0%, #e89c31 100%);
  background-image: -moz-linear-gradient(135deg, #e89c31 0%, #e89c31 100%);
  background-image: -webkit-linear-gradient(135deg, #e89c31 0%, #e89c31 100%);

  box-shadow: 0px 19px 56px 0px rgba(0, 0, 0, 0.2);
  color: #ffffff;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.header__top .right .user__btn {
  margin-left: 38px;
  width: 40px;
  height: 40px;
  background-color: #e89c31;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  text-align: center;
  line-height: 48px;
}

.header__top .right .user__btn:hover {
  color: #fff;
}

.header__top .right .user__btn i {
  font-size: 24px;
}

.header__bottom {
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.header .main-menu li {
  position: relative;
}

.header .main-menu li.menu_has_children {
  position: relative;
}

.header .main-menu li.menu_has_children.open .sub-menu {
  display: block;
}

.header .main-menu li.menu_has_children > a::before {
  position: absolute;
  content: "\f107";
  font-family: "Line Awesome Free";
  font-size: 16px;
  font-weight: 900;
  top: 0;
  right: 0;
  color: #e9e9e9;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  display: none;
}

.header .main-menu li.menu_has_children:hover > a::before {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  color: #ffb200;
}

.header .main-menu li a {
  padding: 15px 0px;
  font-size: 18px;
  color: #e7e7f4;
  position: relative;
  display: inline-block;
  transition: all 0.5s;
  line-height: 1.5;
  display: flex;
  align-items: center;
  gap: 7px;
}
.header .main-menu li a p {
  line-height: 18px;
  padding-top: 3px;
  padding-right: 5px;
}
.header .main-menu li a:after {
  background: #e89c31;
  content: "";
  position: absolute;
  top: 80%;
  left: 5px;
  display: block;
  height: 2px;
  transition: width 0.5s;
  width: 0;
  border-radius: 6px;
}
.header .main-menu li a:hover:after {
  content: "";
  background: #e89c31;
  width: 100%;
}
.header .main-menu li a:hover,
.header .main-menu li a:focus {
  /* color: #e89c31; */
  color: #e7e7f4;
}

.header .main-menu li .sub-menu {
  position: absolute;
  width: 220px;
  top: 105%;
  left: -20px;
  z-index: 999;
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);

  padding: 10px 0;
  -webkit-box-shadow: 0px 18px 54px -8px rgba(0, 0, 0, 0.15);
  box-shadow: 0px 18px 54px -8px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  opacity: 0;
  visibility: hidden;
}

.header .main-menu li .sub-menu li a {
  padding: 8px 20px;
  display: block;
  color: #ffffff;
  font-size: 14px;
}

.header .main-menu li .sub-menu li a:hover {
  background-color: rgba(38, 174, 97, 0.05);
  color: #ffffff;
  text-decoration: underline;
}

.header .main-menu li .sub-menu li + li {
  margin-left: 0;
}

.header .main-menu li:hover .sub-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.header .main-menu li + li {
  margin-left: 20px;
}

.header .nav-right {
  margin-left: 30px;
}
.header .nav-right i {
  margin-right: 5px;
}
.header .menu-fixed .sticky-header-btn i {
  margin-right: 5px;
}

.header .nav-right .cmn-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.header .nav-right .cmn-btn i {
  margin-right: 6px;
  font-size: 22px;
  color: #ffb200;
}

.navbar-toggler {
  padding: 0;
  border: none;
}

.navbar-toggler:focus {
  outline: none;
}

.nav-toggle {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 22px;
  width: 22px;
}

.nav-toggle .bar {
  height: 3px;
  width: 100%;
  background-color: rgb(255, 255, 255);
  transition: all 100ms ease-in-out;
}

.nav-toggle:hover {
  cursor: pointer;
}

.x:nth-of-type(1) {
  transition: all 100ms ease-in-out;
  transform: rotate(45deg);
  transform-origin: top left;
  width: 28px;
}

.x:nth-of-type(2) {
  transition: all 100ms ease-in-out;
  transform-origin: center;
  width: 0;
}

.x:nth-of-type(3) {
  transition: all 100ms ease-in-out;
  transform: rotate(-45deg);
  transform-origin: bottom left;
  width: 28px;
}

.menu-toggle {
  margin: 10px 0;
  position: relative;
  display: block;
  width: 35px;
  height: 20px;
  cursor: pointer;
  background: transparent;
  border-top: 2px solid;
  border-bottom: 2px solid;
  color: #000000;
  font-size: 0;
  -webkit-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.menu-toggle:before,
.menu-toggle:after {
  content: "";
  display: block;
  width: 100%;
  height: 2px;
  position: absolute;
  top: 50%;
  left: 50%;
  background: currentColor;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  transition: -webkit-transform 0.25s ease-in-out;
  -webkit-transition: -webkit-transform 0.25s ease-in-out;
  -o-transition: -webkit-transform 0.25s ease-in-out;
  transition: transform 0.25s ease-in-out;
  -moz-transition: -webkit-transform 0.25s ease-in-out;
  -ms-transition: -webkit-transform 0.25s ease-in-out;
}

span.is-active {
  border-color: transparent;
}
.navbar-toggler:focus {
  box-shadow: none;
}

span.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

span.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

span.menu-toggle:hover {
  color: #000000;
}

span.is-active {
  border-color: transparent;
}

span.is-active:before {
  -webkit-transform: translate(-50%, -50%) rotate(45deg);
  -ms-transform: translate(-50%, -50%) rotate(45deg);
  transform: translate(-50%, -50%) rotate(45deg);
}

span.is-active:after {
  -webkit-transform: translate(-50%, -50%) rotate(-45deg);
  -ms-transform: translate(-50%, -50%) rotate(-45deg);
  transform: translate(-50%, -50%) rotate(-45deg);
}

/* hero css start */
.hero {
  position: relative;
  padding-top: 303px;
  padding-bottom: 175px;
  /*background-image: -ms-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
  /* background-image: -moz-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  background-image: -webkit-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
  /* background-image: -webkit-linear-gradient(90deg, #E89C31 0%, #083248 100%); */
  z-index: 1;
  overflow: hidden;
}

.hero__shape {
  position: absolute;
  left: 0;
  bottom: -5px;
}

.hero__element {
  position: absolute;
  bottom: -75px;
  left: -24px;
  width: 62%;
  z-index: -1;
}

.hero__element img {
  width: 100%;
}

.hero__content p {
  color: #ffffff;
  font-size: 24px;
  padding-top: 25px;
}

.hero__subtitle {
  font-size: 28px;
  font-weight: 600;
  text-transform: uppercase;
  color: #ffb200;
  margin-bottom: 10px;
}

@media (max-width: 1350px) {
  .hero__subtitle {
    font-size: 24px;
  }
  .hero__title {
    font-size: 102px;
  }
}

.hero__title {
  font-size: 80px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
}

.hero__btn {
  margin-top: 30px;
  display: flex;
}
/* .hero__btn .btn-style {
  height: 60px !important;
  max-width: 260px;
  width: 100%;
} */
.hero__btn .btn-style {
  height: 50px !important;
  max-width: 180px;
  width: 100%;
}
/* .hero__btn .btn-style span {
  font-size: 30px;
} */
.hero__btn .btn-style span {
  font-size: 24px;
}
.hero__btn .btn-style span:not(:first-child) {
  font-family: "Hanimation";
  margin-top: -5px;
  padding-left: 3px;
}
/* .hero__btn img {
  max-width: 50px;
  margin-top: -20px;
} */

.hero__btn img {
  max-width: 47px;
  margin-top: -22px;
  margin-left: 16px;
  margin-right: -15px;
}
.hero__btn .video-btn {
  margin-left: 50px;
}

.hero__thumb {
  display: none;
}

.hero__car {
  width: 1150px;
  height: 725px;
  position: absolute;
  right: 0;
  top: 10%;
  overflow: hidden;
}

.hero__car img {
  position: absolute;
  top: 50%;
  left: 55%;
  max-width: 65%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@media (max-width: 1700px) {
  .hero__car img {
    max-width: 50%;
    left: 65%;
  }
}

@media (max-width: 1350px) {
  .hero__car img {
    max-width: 50%;
    left: 70%;
  }
}

.hero__car .car-ray {
  animation: rifaRounded 20s infinite linear;
  z-index: -2;
}

.hero__car .car-light {
  animation: rifaLight 2s infinite linear;
  z-index: -1;
}

.hero__car .car-star {
  animation: rifaLight 2s infinite linear;
  z-index: -1;
}

.hero .hero__shape-left {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.hero .hero__shape-left img {
  height: 100%;
  width: 100%;
}

.hero.style--two {
  padding-bottom: 365px;
  overflow: hidden;
}

.hero.style--two .hero__title {
  font-size: 110px;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
}

.update-home-page .hero.style--two .hero__title {
  /* font-size: 60px; */
  font-size: 54px;
  font-weight: 600;
  line-height: 1.2;
  text-transform: unset;
  max-width: 575px;
}
.update-home-page .hero.style--two .hero__title span {
  /* font-size: 72px; */
  font-size: 48px;
  text-transform: uppercase;
  line-height: 1.1;
  color: #ffffff;
  /* font-family: "Hanimation"; */
}
.update-home-page .hero.style--two .hero__title img {
  max-width: 85px;
  margin-top: -20px;
}
.update-home-page .hero.style--two .hero__title img:not(:first-child) {
  margin-left: 15px;
}

.update-home-page .hero.style--two .hero__title span img {
  margin-top: -35px;
  margin-left: 15px !important;
}

@media (max-width: 1750px) {
  .hero.style--two .hero__title {
    font-size: 76px;
  }
}

@media (max-width: 1650px) {
  .hero.style--two .hero__content p {
    font-size: 22px;
  }
}

.hero.style--two .hero__shape {
  bottom: -2px;
  left: 0;
  z-index: 1;
  width: 100%;
}
.hero.style--two .hero__shape img {
  width: 100%;
}

.hero.style--two *[class*="hero-e"] {
  position: absolute;
}

.hero.style--two .hero-e1 {
  left: 0;
  bottom: 0;
}

.hero.style--two .hero-e2 {
  top: 0;
  right: 0;
}

.hero.style--two .hero-e3 {
  top: 23%;
  left: 14%;
  opacity: 0.25;
}

.hero.style--two .hero-e4 {
  top: 28%;
  left: 42%;
}

.hero.style--two .hero-e5 {
  top: 15%;
  left: 28%;
  opacity: 0.2;
}

@media (max-width: 1450px) {
  .hero.style--two .hero-car {
    height: 475px;
    right: -85px;
    top: 25%;
  }
}

.hero.style--two .hero-car div[class*="el"] {
  position: absolute;
}

@media (max-width: 1450px) {
  .hero.style--two .hero-car div[class*="el"] img {
    width: 75%;
  }
}

.hero.style--two .hero-car .el-car {
  left: 0;
  bottom: 5%;
}

@media (max-width: 1350px) {
  .hero.style--two .hero-car .el-car {
    text-align: center;
  }
}

.hero.style--two .hero-car .el-girl {
  bottom: 0;
  right: 5%;
}

@media (max-width: 1350px) {
  .hero.style--two .hero-car .el-girl {
    right: 3%;
  }
}

.hero.style--two .hero-car .el-star {
  top: 10%;
  left: 40%;
}

.hero.style--two .hero-car .el-star-2 {
  top: 25%;
  left: 25%;
}

.hero.style--two .hero-car-main {
  position: absolute;
  top: 20%;
  right: 0;
  text-align: right;
  width: 40%;
}
.update-home-page .hero.style--two {
  padding-bottom: 200px;
}
.update-home-page .hero.style--two .hero-box-main {
  top: -42%;
  right: 0%;
  width: 100%;
  position: absolute;
  text-align: right;
}
.update-counter-section {
  margin-top: 0px !important;
  padding-bottom: 46px;
  padding-top: 25px;
}
.counter-grid {
  align-items: center;
  justify-content: center;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 30px;
}
.counter-box {
  width: 100%;
  background: -webkit-linear-gradient(7deg, #f06723, #f4a21d) !important;
  border-radius: 5px;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.5s; /* Animation */
}
.counter-box:hover {
  transform: scale(1.05);
  background-image: -webkit-linear-gradient(
    7deg,
    #e89c31 0%,
    #ab6b10 100%
  ) !important;
}
.counter-grid .counter-item__content span {
  font-size: 40px;
}
.counter-grid .counter-item__content p {
  color: #ffffff;
}
.bg-seprator {
  width: 100%;
  text-align: center;
  position: relative;
}
.bg-seprator img {
  max-width: 100px;
  width: 100%;
  height: 100px;
  object-fit: cover;
}
.bg-seprator:before {
  content: "";
  position: absolute;
  width: 28%;
  height: 2px;
  top: 50%;
  background: #d18e2d;
  left: -4%;
  display: none;
}
.home-live-drops .slick-track {
  /* width: 100% !important; */
  /* text-align: center; */
}

.bg-seprator:after {
  content: "";
  position: absolute;
  width: 28%;
  height: 2px;
  top: 50%;
  background: #d18e2d;
  right: -4%;
  display: none;
}

/* .mystery-box-header .bg-seprator:before {
  content: "";
  position: absolute;
  width: 28%;
  height: 3px;
  top: 50%;
  background: #d18e2d;
  left: 0%;
}
.mystery-box-header .bg-seprator:after {
  content: "";
  position: absolute;
  width: 28%;
  height: 3px;
  top: 50%;
  background: #d18e2d;
  right: 0%;
} */
.mystery-box-header {
  margin-bottom: 0px;
  padding-bottom: 40px;
}
.mystery-box-header .section-title {
  color: #f28020;
}
.mystery-box-header .section-sub-title {
  color: #ffffff;
  margin: 0px;
}

@media (max-width: 1450px) {
  .hero.style--two .hero-car-main {
    top: 25%;
  }
}

@media (max-width: 1650px) {
  .hero.style--two .hero-car-main img {
    width: 100%;
  }
}

@media (max-width: 1450px) {
  .hero.style--two .hero-car-main img {
    width: 100%;
  }
}

.hero.style--three {
  background-color: transparent;
  background-image: none;
  padding-bottom: 405px;
}

.hero.style--three .hero__circle,
.hero.style--three .hero__obj,
.hero.style--three .hero__car-left,
.hero.style--three .hero__bike,
.hero.style--three .hero__car-right {
  position: absolute;
}

.hero.style--three .hero__circle {
  top: -27px;
}

.hero.style--three .hero__obj {
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
}

.hero.style--three .hero__bike {
  bottom: 0;
  left: 40%;
}

.hero.style--three .hero__bike img {
  width: 90%;
}

@media (max-width: 1650px) {
  .hero.style--three .hero__bike img {
    width: 70%;
  }
}

@media (max-width: 1450px) {
  .hero.style--three .hero__bike img {
    width: 60%;
  }
}

.hero.style--three .hero__car-left {
  bottom: 2%;
  left: 1%;
  text-align: left;
}

@media (max-width: 1650px) {
  .hero.style--three .hero__car-left img {
    width: 80%;
  }
}

@media (max-width: 1450px) {
  .hero.style--three .hero__car-left img {
    width: 70%;
  }
}

.hero.style--three .hero__car-right {
  bottom: 6%;
  right: 3%;
  text-align: right;
}

@media (max-width: 1650px) {
  .hero.style--three .hero__car-right img {
    width: 80%;
  }
}

@media (max-width: 1450px) {
  .hero.style--three .hero__car-right img {
    width: 60%;
  }
}

.video-btn {
  width: 37px;
  height: 37px;
  color: #ffffff;
  text-align: center;
  line-height: 40px;
  /*background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);*/
  /* background-image: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
  background-image: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%); */
  background-image: -moz-linear-gradient(45deg, #495643 0%, #eeb137 100%);
  background-image: -webkit-linear-gradient(45deg, #495643 0%, #eeb137 100%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  position: relative;
  z-index: 1;
}
.video-btn:hover {
  color: #fff;
}

.video-btn::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 57px;
  height: 54px;
  /*background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);*/
  /* background-image: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%); */
  /* background-image: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%); */
  background-image: -moz-linear-gradient(45deg, #4e5943 0%, #ca9e3a 100%);
  background-image: -webkit-linear-gradient(45deg, #4e5943 0%, #ca9e3a 100%);

  opacity: 0.6;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 27% 33% 15% 48%/21% 47% 35% 51%;
  -webkit-border-radius: 27% 33% 15% 48%/21% 47% 35% 51%;
  -moz-border-radius: 27% 33% 15% 48%/21% 47% 35% 51%;
  -ms-border-radius: 27% 33% 15% 48%/21% 47% 35% 51%;
  -o-border-radius: 27% 33% 15% 48%/21% 47% 35% 51%;
  animation: flowRubber 5s infinite linear;
  animation-delay: 0.5s;
}

.video-btn::after {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 73px;
  height: 74px;
  /*background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);*/
  /* background-image: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
  background-image: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%); */
  background-image: -ms-linear-gradient(45deg, #84773e 0%, #e4ac38 100%);
  background-image: -moz-linear-gradient(45deg, #84773e 0%, #e4ac38 100%);
  background-image: -webkit-linear-gradient(45deg, #84773e 0%, #e4ac38 100%);
  opacity: 0.4;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: -1;
  border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  animation: flowRubber 5s infinite linear;
}

@-webkit-keyframes flowRubber {
  0% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }

  25% {
    border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -webkit-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -moz-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -ms-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -o-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
  }

  50% {
    border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -webkit-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -moz-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -ms-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -o-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
  }

  75% {
    border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -webkit-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -moz-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -ms-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -o-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
  }

  100% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }
}

@-moz-keyframes flowRubber {
  0% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }

  25% {
    border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -webkit-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -moz-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -ms-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -o-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
  }

  50% {
    border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -webkit-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -moz-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -ms-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -o-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
  }

  75% {
    border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -webkit-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -moz-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -ms-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -o-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
  }

  100% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }
}

@-ms-keyframes flowRubber {
  0% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }

  25% {
    border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -webkit-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -moz-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -ms-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -o-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
  }

  50% {
    border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -webkit-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -moz-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -ms-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -o-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
  }

  75% {
    border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -webkit-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -moz-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -ms-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -o-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
  }

  100% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }
}

@keyframes flowRubber {
  0% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }

  25% {
    border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -webkit-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -moz-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -ms-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
    -o-border-radius: 84% 43% 59% 45%/62% 38% 67% 49%;
  }

  50% {
    border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -webkit-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -moz-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -ms-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
    -o-border-radius: 69% 70% 59% 86%/80% 77% 67% 49%;
  }

  75% {
    border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -webkit-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -moz-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -ms-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
    -o-border-radius: 35% 70% 24% 94%/64% 61% 67% 49%;
  }

  100% {
    border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -webkit-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -moz-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -ms-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
    -o-border-radius: 20% 38% 25% 53%/12% 47% 35% 51%;
  }
}

/* inner-hero-section css start */
.inner-hero-section {
  padding-top: 230px;
  padding-bottom: 375px;
  background-image: -moz-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  background-image: -webkit-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  /*background-image: -ms-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
  position: relative;
  overflow: hidden;
}

.inner-hero-section .bg-shape {
  position: absolute;
  left: 0;
  bottom: -5px;
}

.inner-hero-section .inner-page-content {
  text-align: center;
}

.inner-hero-section .inner-page-content .title {
  font-size: 60px;
  font-weight: 700;
  text-transform: uppercase;
}

.inner-hero-section .inner-page-content p {
  color: #e4e3fc;
}

.inner-hero-section.style--two {
  padding-bottom: 150px;
}

.inner-hero-section.style--three {
  padding-bottom: 190px;
}

.inner-hero-section.style--four {
  padding-bottom: 250px;
}

.inner-hero-section.style--five {
  padding-bottom: 150px;
}

.inner-hero-section.style--six {
  padding-bottom: 350px;
}

.page-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -15px;
}

.page-list li {
  padding: 5px 15px;
  position: relative;
  text-transform: capitalize;
  color: #ffffff;
}

.page-list li:first-child::before {
  display: none;
}

.page-list li::before {
  position: absolute;
  content: "\f30b";
  font-family: "Line Awesome Free";
  font-weight: 900;
  color: #ffffff;
  left: -8px;
}

.page-list li a {
  text-transform: capitalize;
}

/* about section css start */
.about-wrapper {
  padding: 120px 50px 200px 50px;
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.about-wrapper__header {
  text-align: center;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 20px;
}

.about-wrapper__title-top {
  font-size: 24px;
  font-weight: 600;
  color: #ffb200;
  margin-bottom: 10px;
}

.about-wrapper__title {
  font-size: 60px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
}

.about-wrapper__content p + p {
  margin-top: 20px;
}

/* next-draw-section css start */
.next-draw-area {
  padding: 50px 50px;
  background-color: #0f0233;
  border: 1px solid rgba(92, 82, 172, 0.4);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.next-draw-area .left {
  width: 26%;
}

.next-draw-area .left .title {
  font-size: 40px;
}

.next-draw-area .middle {
  width: 43%;
}

.next-draw-area .middle p {
  color: #e89c31;
  font-size: 24px;
  text-align: center;
}

.next-draw-area .middle .clock2 {
  justify-content: center;
  margin: 0;
}

.next-draw-area .middle .clock2 div {
  width: 25%;
  margin: 0;
  position: relative;
  justify-content: center;
}

.next-draw-area .middle .clock2 div:last-child::after {
  display: none;
}

.next-draw-area .middle .clock2 div::after {
  position: absolute;
  content: "";
  top: 9px;
  right: 0;
  width: 3px;
  height: 35px;
  background-color: #444781;
}

.next-draw-area .middle .clock2 span,
.next-draw-area .middle .clock2 p {
  font-size: 36px;
  font-weight: 500;
  color: #ffffff;
}

.next-draw-area .right {
  width: 16%;
  text-align: right;
}

/* how to play section css start */
.play-elements {
  position: absolute;
  top: 0;
  right: 0;
  text-align: right;
}

.play-card {
  padding: 30px 25px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.play-card:hover {
  -webkit-transform: scale(1.05, 1.05);
  -ms-transform: scale(1.05, 1.05);
  transform: scale(1.05, 1.05);
}

.play-card:hover .play-card__icon::after {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
}

.play-card__icon {
  position: relative;
  width: 100px;
  height: 100px;
  text-align: center;
  line-height: 100px;
  margin: 0 auto;
  background-color: #352882;
  box-shadow: 0px 13px 21px 0px rgb(8 50 72 / 33%),
    inset 0px 0px 15px 5px #083248;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 1;
}

.play-card__icon::after {
  position: absolute;
  content: "";
  top: -15px;
  left: -15px;
  width: 130px;
  height: 130px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.play-card__icon .play-card__number {
  position: absolute;
  bottom: -6px;
  right: -21px;
  width: 40px;
  height: 40px;
  background-image: -moz-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  /*background-image: -ms-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);*/
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  text-align: center;
  line-height: 44px;
  font-size: 22px;
  color: #ffffff;
}

.play-card__content {
  text-align: center;
  margin-top: 50px;
}

.play-card__content p {
  margin-top: 10px;
  font-size: 16px;
}

.play-card__title {
  text-transform: uppercase;
}

/* contest section css start */
.bg-el {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
}

.bg-el img {
  width: 100%;
  height: 100%;
}

.nav-tabs:not(.winner-tab-nav) li {
  margin: 5px 15px;
}

.contest-card {
  background-color: #e89c31;
  border-radius: 10px;
  position: relative;
  z-index: 1;
  background-image: -webkit-linear-gradient(28deg, #e89c31 0%, #ab6b10 100%);
  /* border: 1px solid #083248; */
  /* box-shadow: 0px 0px 27px 0px #032435; */
  transition: transform 0.2s;
}

.contest-card:hover .contest-card__thumb img {
  -webkit-animation-name: zoomIn;
  animation-name: zoomIn;
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.contest-card .item-link {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.contest-card__thumb {
  position: relative;
  height: 260px;
  padding: 15px;
  background-color: #cdf5f6;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  background-image: -webkit-linear-gradient(90deg, #082a3c 0%, #083248 100%);
}

.contest-card__thumb img {
  max-height: 200px;
}

.game-page .contest-card__thumb img {
  max-height: 148px;
  height: 100%;
}
.game-page .contest-card__thumb {
  height: 148x;
}

.contest-card__thumb .action-icon {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  background-image: -ms-linear-gradient(
    86deg,
    #e4992f 0%,
    #e1962d 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #e4992f 0%,
    #e1962d 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #e4992f 0%,
    #e1962d 44%,
    #fbb936 100%
  );

  box-shadow: 0px 6px 5px 0px rgb(225 150 45 / 17%);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.unboxing-page .action-icon i.far.fa-heart {
  position: relative;
  top: 3px;
  right: 0px;
}

.contest-card__thumb .action-icon:hover {
  color: #ffffff;
}

.contest-card__thumb .contest-num {
  position: absolute;
  bottom: -40px;
  left: 50%;
  width: 85px;
  height: 85px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  background-image: -moz-linear-gradient(7deg, #e2972e 0%, #c88220 100%);
  background-image: -webkit-linear-gradient(7deg, #e2972e 0%, #c88220 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.contest-card__thumb .contest-num span {
  color: #ffffff;
  text-transform: capitalize;
  font-size: 12px;
}

.contest-card__thumb .contest-num .number {
  text-transform: uppercase;
  font-size: 18px;
  text-align: center;
}

.contest-card__content {
  padding: 30px 30px 20px 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  background: #041f2d;
  border-top: 1px solid #052434;
}

.contest-card__content .left {
  width: 48%;
  padding-right: 25px;
  text-align: left;
}

.contest-card__content .right {
  width: 48%;
  padding-left: 15px;
  text-align: right;
}

.contest-card__content .right p {
  text-transform: capitalize;
}

.contest-card__price {
  font-size: 28px;
  font-weight: 600;
  color: #e89c31;
  line-height: 1.4;
}

.contest-card__footer {
  padding: 30px;
  /* border-top: 1px solid rgba(255, 255, 255, 0.07); */
  background: #041f2d;
}

.contest-card__meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.contest-card__meta li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.contest-card__meta li:first-child {
  width: 30%;
  border-right: 1px solid rgba(255, 255, 255, 0.15);
}

.contest-card__meta li:last-child {
  width: 60%;
}

.contest-card__meta li i {
  font-size: 24px;
  margin-right: 8px;
}

.contest-card__meta li span {
  font-size: 18px;
  color: #ffb200;
  line-height: 1.2;
}

.contest-card__meta li p {
  font-size: 14px;
  margin-left: 5px;
}

@-webkit-keyframes zoomIn {
  from {
    opacity: 0.2;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@-moz-keyframes zoomIn {
  from {
    opacity: 0.2;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@-ms-keyframes zoomIn {
  from {
    opacity: 0.2;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

@keyframes zoomIn {
  from {
    opacity: 0.2;
    -webkit-transform: scale3d(0.3, 0.3, 0.3);
    transform: scale3d(0.3, 0.3, 0.3);
  }

  50% {
    opacity: 1;
  }
}

.contest-wrapper__header {
  background-color: #5a4bcc;
}

.contest-wrapper__title {
  font-size: 60px;
  font-weight: 700;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
  margin-bottom: 30px;
}

.contest-wrapper .contest-filter-wrapper .nice-select {
  background-color: transparent;
  border-color: #292b39;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  padding-left: 20px;
  padding-right: 15px;
}

.contest-wrapper .contest-filter-wrapper .nice-select::after {
  width: 10px;
  height: 10px;
  border-bottom: 1px solid #6e6bad;
  border-right: 1px solid #6e6bad;
  right: 18px;
  margin-top: -7px;
}

.contest-wrapper .contest-filter-wrapper .nice-select span,
.contest-wrapper .contest-filter-wrapper .nice-select li {
  font-size: 14px;
}

.contest-wrapper .contest-filter-wrapper .nice-select .current {
  color: #ffffff;
}

.contest-wrapper .contest-filter-wrapper .nice-select .list {
  background-color: #5a4bcc;
}

.contest-wrapper .contest-filter-wrapper .nice-select .list .option {
  color: #fafafa;
}

.contest-wrapper .contest-filter-wrapper .nice-select .list .option.selected,
.contest-wrapper .contest-filter-wrapper .nice-select .list .option:hover {
  background-color: #4838c6;
}

.contest-wrapper .contest-search-form {
  position: relative;
}

.contest-wrapper .contest-search-form input {
  width: 100%;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  border-color: #292b39;
  font-size: 14px;
}

.contest-wrapper .contest-search-form input::-webkit-input-placeholder {
  color: #6775a8;
}

.contest-wrapper .contest-search-form input::-moz-placeholder {
  color: #6775a8;
}

.contest-wrapper .contest-search-form input:-ms-input-placeholder {
  color: #6775a8;
}

.contest-wrapper .contest-search-form input:-moz-placeholder {
  color: #6775a8;
}

.contest-wrapper .contest-search-form button {
  position: absolute;
  top: 13px;
  right: 16px;
  color: #a2aae4;
  background: transparent;
  font-size: 20px;
}

.contest-wrapper .rang-slider .caption {
  color: #ffffff;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  text-align: center;
  margin-bottom: 12px;
}

.contest-wrapper .rang-slider .ui-widget.ui-widget-content {
  background-color: #5a4bcc;
  border: none;
  height: 4px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.contest-wrapper .rang-slider .ui-state-default,
.contest-wrapper .rang-slider .ui-widget-content .ui-state-default {
  width: 24px;
  height: 24px;
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border: none;
  top: -10px;
  cursor: pointer;
}

.contest-wrapper .rang-slider .ui-state-default::before,
.contest-wrapper .rang-slider .ui-widget-content .ui-state-default::before {
  position: absolute;
  content: "\f104";
  color: #ffffff;
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 15px;
  left: 0px;
}

.contest-wrapper .rang-slider .ui-state-default::after,
.contest-wrapper .rang-slider .ui-widget-content .ui-state-default::after {
  position: absolute;
  content: "\f105";
  color: #ffffff;
  font-family: "Line Awesome Free";
  font-weight: 900;
  font-size: 15px;
  right: 1px;
}

.contest-wrapper .rang-slider .ui-widget-header {
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}

.contest-wrapper .rang-slider .amount-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 5px;
}

.contest-wrapper .rang-slider .amount-wrapper .min-amount {
  color: #ffffff;
  font-size: 16px;
}

.contest-wrapper .rang-slider .amount-wrapper input {
  min-height: auto;
  padding: 0;
  border: none;
  width: 100px;
  text-align: right;
  font-size: 16px;
  color: #ffffff;
}

.contest-wrapper .action-btn-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px -10px;
}

.contest-wrapper .action-btn {
  width: 30px;
  height: 30px;
  background-color: #5a4bcc;
  font-size: 20px;
  color: #aeaef1;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 5px 10px;
}

/* contest slider section css start */
.contest-slider-section {
  margin-top: -200px;
  position: relative;
  z-index: 2;
}

.contest-slider .slick-list {
  margin: 0 -15px;
}

.contest-slider .contest-card {
  margin: 0 15px;
}

.contest-slider .slick-arrow {
  position: absolute;
  top: -50px;
  right: 0;
  z-index: 2;
  color: #ffffff;
  width: 50px;
  height: 26px;
  font-size: 24px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
}

.contest-slider .slick-arrow.prev {
  right: 65px;
}

.contest-slider .slick-arrow.slick-disabled {
  background-image: none;
  background-color: #7b6fd6;
  box-shadow: none;
}

.contest-slider.style--two .slick-arrow {
  top: 50%;
  right: auto;
  left: -25px;
}

.contest-slider.style--two .slick-arrow.next {
  right: -25px;
  left: auto;
}

/* contest feature section css start */
.icon-item2 {
  text-align: center;
}

.icon-item2__content {
  margin-top: 30px;
}

.icon-item2__content .title {
  color: #ffffff;
  text-transform: capitalize;
}

.icon-item2__content p {
  margin-top: 10px;
}

/* winner section start */
.winner-wrapper {
  background-image: -moz-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  background-image: -webkit-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  /*background-image: -ms-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
  padding-right: 30px;
  padding-top: 30px;
  margin-bottom: 75px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.winner-wrapper .left {
  width: 75%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
}

.winner-wrapper .left .winner-prize-thumb {
  margin-left: -65px;
  margin-bottom: -100px;
  -webkit-transform: translateY(25px);
  -ms-transform: translateY(25px);
  transform: translateY(25px);
}

@media (max-width: 1350px) {
  .winner-wrapper .left .winner-prize-thumb {
    margin-left: -25px;
  }
}

.winner-wrapper .right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
  justify-content: center;
  width: 25%;
}

.winner-wrapper .right .winner-slider {
  width: 100%;
}

.winner-slide-item {
  position: relative;
}

.winner-slide-item .winner-thumb {
  text-align: center;
}

.winner-slide-item .winner-thumb img {
  display: inline-block;
}

.winner-slide-item .winner-content {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 15px 30px;
  text-align: center;
  border-radius: 20px 20px 0 0;
  -webkit-border-radius: 20px 20px 0 0;
  -moz-border-radius: 20px 20px 0 0;
  -ms-border-radius: 20px 20px 0 0;
  -o-border-radius: 20px 20px 0 0;
}

.winner-slide-item .winner-content .winner-name {
  font-size: 18px;
  text-transform: capitalize;
}

.winner-slide-item .winner-content p {
  font-size: 15px;
  color: #0f0233;
}

.winner-slide-item .winner-content .draw-date {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.4;
}

.winner-slider .slick-arrow {
  position: absolute;
  bottom: -70px;
  right: -30px;
  cursor: pointer;
}

.winner-slider .slick-arrow:hover i {
  color: #e89c31;
}

.winner-slider .slick-arrow i {
  color: #57538e;
  font-size: 70px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.winner-slider .slick-arrow.prev {
  right: 75px;
}

/* winner details section css start */
.winner-details-wrapper {
  padding: 50px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: flex-end;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.winner-details-wrapper .left {
  width: 25%;
}

.winner-details-wrapper .body {
  width: 50%;
  text-align: center;
  padding: 0 20px;
}

.winner-details-wrapper .body p,
.winner-details-wrapper .body span {
  font-size: 16px;
  color: #ffffff;
}

.winner-details-wrapper .body .contest-date span {
  color: #27ff5a;
}

.winner-details-wrapper .body .line {
  width: 215px;
  height: 3px;
  background-color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 25px auto;
}

.winner-details-wrapper .body .title {
  font-weight: 400;
  margin-bottom: 15px;
}

.winner-details-wrapper .body .numbers {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px;
}

.winner-details-wrapper .body .numbers li {
  padding: 10px 10px;
  width: calc((100% / 7) - 10px);
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(243, 42, 126, 0.35);
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  font-size: 38px;
  color: #ffffff;
  line-height: 1;
}

.winner-details-wrapper .body .btn-border {
  color: #ffffff;
  padding: 10px 35px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.winner-details-wrapper .body .btn-border:hover {
  background-color: #ffb200;
}

.winner-details-wrapper .right {
  width: 25%;
}

/* latest winner section css start */
.latest-winner-section .el-1,
.latest-winner-section .el-2,
.latest-winner-section .el-3 {
  position: absolute;
}

.latest-winner-section .el-1 {
  top: 0;
  left: 10%;
}

.latest-winner-section .el-2 {
  top: 4%;
  left: 39%;
}

.latest-winner-section .el-3 {
  top: 0;
  right: 12%;
}

.number-check-wrapper {
  padding: 30px;
  background-color: #4939c1;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.check-number-form .number-list-wrapper p {
  color: #ffffff;
}

.check-number-form .number-list-wrapper .number-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.check-number-form .number-list-wrapper .number-list input {
  width: 36px;
  min-height: 36px;
  background-image: -moz-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  background-image: -webkit-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  /*background-image: -ms-linear-gradient(135deg, #b122e6 0%, #ff63de 100%); */
  box-shadow: 0px 19px 56px 0px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 18px;
  border: none;
  padding: 5px;
  text-align: center;
  margin-bottom: 10px;
  color: #ffffff;
}

.check-number-form .form-control {
  min-height: 48px;
  background-color: #392ba6;
  border: none;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.check-number-form .form-control::-webkit-input-placeholder {
  color: #897edb;
}

.check-number-form .form-control::-moz-placeholder {
  color: #897edb;
}

.check-number-form .form-control:-ms-input-placeholder {
  color: #897edb;
}

.check-number-form .form-control:-moz-placeholder {
  color: #897edb;
}

.check-number-form .nice-select {
  min-height: 48px;
  background-color: #392ba6;
  border: none;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  padding: 0 30px;
}

.check-number-form .nice-select::after {
  border-bottom: 1px solid #9fa2e4;
  border-right: 1px solid #9fa2e4;
  width: 10px;
  height: 10px;
  right: 30px;
  top: 43%;
}

.check-number-form .nice-select .current {
  color: #897edb;
}

.check-number-form .nice-select .list {
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
}

.check-number-form .nice-select .option {
  font-weight: 400;
}

.check-number-form .nice-select .option.selected,
.check-number-form .nice-select .option.focus,
.check-number-form .nice-select .option:hover {
  background-color: rgba(0, 0, 0, 0.1);
  font-weight: 600;
  color: #ffffff;
}

.winner-card {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  /*background-image: -ms-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);*/
  background-image: -moz-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);
}

.winner-card__thumb {
  padding: 30px 10px;
  width: 30%;
  background-image: -moz-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  background-image: -webkit-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  /*background-image: -ms-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.winner-card__content {
  width: 70%;
  position: relative;
}

.winner-card__content .winner-thumb {
  position: absolute;
  top: 50%;
  left: -46px;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  overflow: hidden;
  margin-top: -43px;
}

.winner-card__content .content-top {
  padding: 30px 30px 30px 50px;
  border-bottom: 1px solid #c4a1f8;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.winner-card__content .content-top .left {
  width: 50%;
  padding-right: 50px;
}

.winner-card__content .content-top .right {
  width: 50%;
  text-align: center;
}

.winner-card__content .content-top .right span {
  color: #27ff5a;
}

.winner-card__content .content-bottom {
  padding: 30px 30px 30px 50px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.winner-card__content .content-bottom .number-list-wrapper {
  width: 75%;
}

.winner-card__content .content-bottom .number-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding-right: 30px;
}

.winner-card__content .content-bottom .number-list li,
.winner-card__content .content-bottom .number-list input {
  width: 36px;
  min-height: 36px;
  height: 36px;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(243, 42, 126, 0.35);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  font-size: 20px;
  color: #ffffff;
  border: none;
  padding: 2px;
  text-align: center;
  margin-top: 10px;
}

.winner-card__content .content-bottom .right {
  width: 25%;
  text-align: center;
}

.winner-card__content .content-bottom .right p {
  color: #ffffff;
}

.winner-card__content .content-bottom .right .contest-num {
  color: #ffffff;
  font-size: 28px;
}

.winner-tab-nav {
  border-bottom: 1px solid #2d246d;
}

.winner-tab-nav li {
  width: calc(100% / 5);
  text-align: center;
}

.winner-tab-nav li a {
  background-color: transparent;
  border-color: transparent !important;
  border-bottom: 4px solid transparent;
}

.winner-tab-nav li a.active,
.winner-tab-nav li a:hover {
  background-color: transparent !important;
  border-color: transparent !important;
  border-bottom: 4px solid #ff5800 !important;
}

.winner-tab-nav li a .icon-thumb {
  height: 75px;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.winner-tab-nav li a .icon-thumb::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  width: 80px;
  height: 80px;
  background-color: rgba(75, 61, 186, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin-top: -40px;
  margin-left: -40px;
}

.winner-tab-nav li a span {
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  margin-top: 20px;
  color: #ffffff;
  padding-bottom: 10px;
}

/* overview section css start */
.overview-section {
  position: relative;
}

.overview-section::before {
  position: absolute;
  content: "";
  bottom: 65px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #083248;
}

.overview-section .map-el {
  position: absolute;
  bottom: 65px;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
}
.overview-section [class*="obj-"] {
  position: absolute;
}

.overview-section .obj-1 {
  top: 0;
  left: 0;
}

.overview-section .obj-2 {
  top: 2%;
  right: 3%;
}

.overview-section .obj-3 {
  top: 5%;
  right: 30%;
}

.overview-section .obj-4 {
  top: 5%;
  right: 5%;
}

.map-pointer {
  height: 285px;
  position: relative;
}

.map-pointer .pointer {
  position: absolute;
}

.map-pointer .pointer.num-1 {
  top: 48%;
  left: 13%;
}

.map-pointer .pointer.num-2 {
  top: -30px;
  left: 23%;
}

.map-pointer .pointer.num-3 {
  top: 55%;
  left: 25%;
}

.map-pointer .pointer.num-4 {
  top: 5%;
  left: 38%;
}

.map-pointer .pointer.num-5 {
  top: 55%;
  left: 48%;
}

.map-pointer .pointer.num-6 {
  top: 35%;
  left: 53%;
}

.map-pointer .pointer.num-7 {
  top: 7%;
  left: 71%;
}

.map-pointer .pointer.num-8 {
  top: 27%;
  left: 81%;
}

.map-pointer .pointer.num-9 {
  top: 60%;
  right: 10%;
}

.pointer {
  width: 16px;
  height: 16px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: inline-block;
  background-color: #04aecd;
  animation: pulse 2000ms linear infinite;
  -webkit-animation: pulse 2000ms linear infinite;
  -moz-animation: pulse 2000ms linear infinite;
  z-index: 1;
}

.pointer::before,
.pointer::after {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  border-radius: 74px;
  background-color: #04aecd;
  opacity: 0.15;
}

.pointer::before {
  animation: inner-ripple 2000ms linear infinite;
  -webkit-animation: inner-ripple 2000ms linear infinite;
  -moz-animation: inner-ripple 2000ms linear infinite;
}

.pointer::after {
  animation: outer-ripple 2000ms linear infinite;
  -webkit-animation: outer-ripple 2000ms linear infinite;
  -moz-animation: outer-ripple 2000ms linear infinite;
}

@-webkit-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@-moz-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@-ms-keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@keyframes outer-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.2;
  }

  50% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0.5;
  }

  100% {
    -webkit-transform: scale(2.5);
    -ms-transform: scale(2.5);
    transform: scale(2.5);
    opacity: 0;
  }
}

@-webkit-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-moz-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@-ms-keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

@keyframes inner-ripple {
  0% {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    opacity: 0.3;
  }

  50% {
    -webkit-transform: scale(1.2);
    -ms-transform: scale(1.2);
    transform: scale(1.2);
    opacity: 0.7;
  }

  100% {
    -webkit-transform: scale(1.5);
    -ms-transform: scale(1.5);
    transform: scale(1.5);
    opacity: 0;
  }
}

.overview-card {
  padding: 30px 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  /* background-image: -moz-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%); */
  /*background-image: -ms-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);*/
  background-image: -moz-linear-gradient(28deg, #e89c31 0%, #ab6b10 100%);
  background-image: -webkit-linear-gradient(28deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 0px 27px 0px #032435;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.overview-card__icon {
  width: 82px;
}

.overview-card__content {
  width: calc(100% - 82px);
  padding-left: 30px;
}

.overview-card__content .number {
  font-size: 36px;
  font-weight: 600;
  color: #ffffff;
  line-height: 1.3;
}

.overview-card__content p {
  font-size: 18px;
}

/* features section css start */
.feature-card {
  padding: 30px 30px;
  text-align: center;
  /* background-image: -moz-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);
  background-image: -webkit-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%); */
  /*background-image: -ms-linear-gradient(45deg, #d73df5 0%, #8f3df5 100%);*/
  /* background-image: -moz-linear-gradient(28deg, #083248 0%, #083248 100%);
  background-image: -webkit-linear-gradient(28deg, #083248 0%, #083248 100%); */
  /* box-shadow: 0px 0px 27px 0px #032435; */
  background: #041f2d;
  border: 1px solid #052434;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.feature-card__icon {
  margin-bottom: 25px;
}

.feature-card__content p {
  color: #f4f5ff;
  margin-top: 8px;
  font-size: 17px;
}

.feature-card.style--two {
  padding: 0 30px;
  background: transparent;
}

.feature-card.style--two:hover .feature-card__icon .inner {
  box-shadow: inset 0 0px 50px 2px #ec1379a3;
}

.feature-card.style--two .feature-card__icon {
  width: 165px;
  height: 165px;
  background-color: #170543;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.feature-card.style--two .feature-card__icon .inner {
  width: 135px;
  height: 135px;
  background-image: -moz-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  /*background-image: -ms-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);*/
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0px 19px 2px #ec137954;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.feature-car {
  position: absolute;
  top: 35%;
  right: 0;
  text-align: right;
}

@media (max-width: 1650px) {
  .feature-car img {
    width: 80%;
  }
}

@media (max-width: 1450px) {
  .feature-car img {
    width: 70%;
  }
}

/* team section css start */
.team-card {
  text-align: center;
}

.team-card:hover .team-card__thumb .obj {
  width: 100px;
  height: 91px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.team-card__thumb {
  width: 167px;
  height: 167px;
  border-radius: 100%;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  -ms-border-radius: 100%;
  -o-border-radius: 100%;
  display: inline-block;
  position: relative;
}

.team-card__thumb .obj {
  position: absolute;
  top: 0;
  left: -23px;
  width: 200px;
  height: 182px;
  z-index: -1;
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.team-card__content {
  margin-top: 50px;
}

.team-card__content .name {
  text-transform: capitalize;
}

.team-card__content .designation {
  color: #e89c31;
}

/* testimonial section css start */
.has-bg--shape {
  position: relative;
}

.has-bg--shape .bg-shape {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 690px;
  background-image: -moz-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  background-image: -webkit-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  /*background-image: -ms-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-end;
  z-index: -1;
  overflow: hidden;
}

.has-bg--shape .bg-shape .round-shape {
  margin-bottom: -1px;
  width: 100%;
}

.has-bg--shape .bg-shape .round-shape img {
  width: 100%;
}
.has-bg--shape .bg-shape .shape-1,
.has-bg--shape .bg-shape .shape-2,
.has-bg--shape .bg-shape .shape-3,
.has-bg--shape .bg-shape .shape-4,
.has-bg--shape .bg-shape .shape-5,
.has-bg--shape .bg-shape .shape-6 {
  position: absolute;
  z-index: -2;
  background-color: #ffffff;
  opacity: 0.102;
}

.has-bg--shape .bg-shape .shape-1 {
  bottom: -14%;
  left: -1%;
  width: 420px;
  height: 420px;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.has-bg--shape .bg-shape .shape-2 {
  top: 14%;
  left: 25%;
  width: 75px;
  height: 75px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.has-bg--shape .bg-shape .shape-3 {
  top: 3%;
  right: 38%;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.has-bg--shape .bg-shape .shape-4 {
  top: 13%;
  right: 27%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.has-bg--shape .bg-shape .shape-5 {
  top: 47%;
  right: 14%;
  width: 155px;
  height: 145px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  -webkit-transform: rotate(15deg);
  -ms-transform: rotate(15deg);
  transform: rotate(15deg);
}

.has-bg--shape .bg-shape .shape-6 {
  top: -18%;
  right: -10%;
  width: 400px;
  height: 400px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.testimonial-area {
  padding: 50px 50px;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
}

.testimonial-single {
  text-align: center;
}

.testimonial-single__thumb {
  width: 90px;
  height: 90px;
  display: inline-block;
}

.testimonial-single__thumb img {
  display: inline-block;
  width: 90px;
  height: 90px;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.testimonial-single__content {
  padding: 0 50px;
  margin-top: 30px;
}

.testimonial-single__content .client-name {
  margin-bottom: 10px;
}

.testimonial-single__content p {
  color: #f1f3ff;
}

.testimonial-single__content .ratings {
  background-color: rgba(38, 56, 169, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: inline-block;
  padding: 6px 15px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  margin-top: 15px;
}

.testimonial-single__content .ratings i {
  font-size: 17px;
  color: #ffffff;
  margin: 0px 4px;
}

/* support section css start */
.support-card {
  background-image: -moz-linear-gradient(28deg, #ef5823 0%, #f3981e 100%);
  background-image: -webkit-linear-gradient(28deg, #ef5823 0%, #f3981e 100%);
  box-shadow: 0px 0px 27px 0px #032435;
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  padding: 20px 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.support-card__thumb {
  width: 140px;
  height: 190px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}
.support-card__thumb img {
  width: 80%;
}
.support-card__content {
  width: calc(100% - 140px);
  padding-left: 30px;
}

.support-card__content p {
  color: #f1f3ff;
}

.support-card__title {
  margin-bottom: 10px;
}

/* counter section css start */
.counter-section {
  margin-top: -140px;
}

.counter-wrapper {
  padding: 45px 40px 10px 40px;
  background-color: #072b3f;
  box-shadow: inset 0 -3px 8px 3px #dc3681, 0px 10px 21px 0px rgb(7 43 63);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  position: relative;
  z-index: 3;
}
.how-to-play-sec {
  margin: 0px auto;
  padding-top: 80px;
}
.how-to-play-sec .section-header {
  margin: 0;
  padding-bottom: 40px;
}
.how-to-play-sec .section-title {
  color: #f28020;
}
.how-to-play-sec .section-sub-title {
  color: #ffffff;
  margin: 0px;
}
.how-to-play-sec .play-card__content {
  margin-top: 20px;
}
.how-to-play-sec .play-card.bg_img {
  background: #4b3f81;
  background-image: -webkit-linear-gradient(28deg, #4b3f81 20%, #303368 75%);
}
.how-to-play-sec .play-card.bg_img:hover {
  /* transform: translateY(-5px) !important; */
  transform: unset;
}
.how-to-play-sec .play-card__title {
  color: #ffb200;
}
.how-to-play-sec .play-card__icon::after {
  content: none;
}
.how-to-play-sec .play-card__icon {
  background-color: unset;
  box-shadow: none;
}
.update-home-page .contest-card {
  border: 1px solid #292b39 !important;
}
.update-home-page .contest-card__thumb {
  /* background: #051c2a !important; */
  /* background: #072c47!important; */
  background: rgb(255, 255, 255);
  background: linear-gradient(
    310deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(5, 31, 46, 1) 0%,
    rgba(5, 31, 46, 1) 0%,
    rgba(7, 44, 73, 1) 100%,
    rgba(7, 44, 73, 1) 100%
  ) !important;
}
.update-home-page .contest-card__content {
  border-top: 1px solid transparent !important;
  background: #04202e00 !important;
  flex-direction: column;
  align-items: flex-start;
  padding: 15px;
  padding-top: 40px;
  gap: 5px;
}
.update-home-page .contest-card__footer {
  background: #04202e00 !important;
  padding-bottom: 20px;
  padding-top: 0;
}
.home-page .contest-card.gradient {
  border-radius: 10px !important;
}
.home-our-feature.section-header {
  margin-bottom: 0px;
  padding-bottom: 40px;
}
.update-home-page .contest-card__name {
  color: #fff;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.36px;
  text-align: left;
}
.update-home-page .contest-card__price {
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: -0.36px;
  text-align: left;
}

.home-our-feature .section-title {
  color: #f28020;
  font-size: 48px;
}
.home-our-feature .section-sub-title {
  color: #ffffff;
  margin: 0px;
}
.home-our-feature p {
  max-width: 720px;
  margin: 6px auto 26px auto;
}
.home-our-features-section .feature-card {
  background: #3e4174;
  background-image: -webkit-linear-gradient(28deg, #3e4174 20%, #303368 75%);
  padding: 30px 15px;
  /* padding-top: 40px; */
  min-height: 304px;
}

.home-our-features-section .btn-style {
  padding: 25px 30px !important;
}
.update-home-page .overview-section::before {
  content: unset;
}
.mystery-box-section {
  position: relative;
}
.mystery-box-section:before {
  content: "";
  position: absolute;
  max-width: 115px;
  width: 100%;
  height: 100%;
  background: url("../images/newhome/bg-mystery-1.png") no-repeat;
  left: 5%;
  top: 23%;
  background-size: 65%;
}
.mystery-box-section:after {
  content: "";
  position: absolute;
  width: 100px;
  height: 100%;
  background: url("../images/newhome/bg-mystery-2.png") no-repeat;
  right: 5%;
  top: 23%;
}
.mystery-box-grid {
  position: relative;
  padding-top: 80px;
}
.mystery-box-grid .contest-card__thumb {
  height: 180px;
  padding-top: 45px;
}
.mystery-box-grid .contest-card__thumb img {
  max-height: 175px;
}

.mystery-box-grid .contest-card__thumb .contest-num {
  min-width: 25px;
  height: 25px;
  line-height: 25px;
  top: 15px;
  left: 15px;
  bottom: unset;
  transform: unset;
  border-radius: 50px;
}
.mystery-box-grid .contest-card__thumb .contest-num .number {
  font-size: 14px;
  line-height: 15px;
}
.unboxing-page .innerunboxingtitle {
  background-color: #0000 !important;
  border: 0 !important;
}
.show-all-grid a {
  font-size: 24px;
  text-decoration: underline;
  text-decoration-color: #ffb200;
}
.home-our-support {
  padding: 80px 0px 30px 0px;
}
.home-our-support .section-header {
  margin-bottom: 0px;
  padding-bottom: 40px;
}
.feature-card__icon img {
  width: 96px;
  height: 96px;
  object-fit: contain;
}
.home-our-support .section-header h2 {
  max-width: 600px;
  margin: 0px auto;
  width: 100%;
  font-size: 48px;
}
.home-our-support .section-header span {
  font-size: 50px;
  text-transform: uppercase;
  color: #ffb200;
  display: none;
}
.bg-welcome {
  position: absolute;
  top: 21%;
  left: 5%;
  text-align: left;
  width: 38%;
}
.icon-item2__icon img {
  width: 61px;
}
.counter-wrapper.style--two {
  padding: 30px 30px;
  margin: -80px 60px 0 60px;
}

.counter-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.counter-item__icon {
  width: 95px;
}

.counter-item__content {
  width: calc(100% - 95px);
  padding-left: 15px;
}
.counter-grid .counter-item__content {
  padding-left: 0px;
  width: unset;
}
.counter-grid .counter-item__icon {
  width: unset;
  padding-right: 15px;
}

.counter-item__content span {
  font-family: "Biko";
  font-weight: 600;
  font-size: 60px;
  line-height: 1;
  color: #ffffff;
}

.counter-item__content p {
  color: #e89c31;
}

.counter-item.style--two .counter-item__content {
  width: 100%;
}

.counter-item.style--three {
  padding: 25px;
  background-color: #0f0233;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.counter-item.style--three .counter-item__content {
  width: 100%;
}

/* contest-details-section css start */
.clock-wrapper {
  background-color: rgba(255, 255, 255, 0.2);
  padding: 15px 30px;
  border-radius: 25px 25px 0 0;
  -webkit-border-radius: 25px 25px 0 0;
  -moz-border-radius: 25px 25px 0 0;
  -ms-border-radius: 25px 25px 0 0;
  -o-border-radius: 25px 25px 0 0;
  text-align: center;
}

.clock-wrapper > p {
  color: #eceafe;
}

.clock-wrapper .clock {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
}

.clock-wrapper .clock div {
  width: 25%;
}

.clock-wrapper .clock div span {
  font-size: 40px;
  line-height: 1;
  font-weight: 700;
  color: #ffb200;
  text-shadow: 0px 7px 4px rgba(87, 74, 179, 0.65);
}

.clock-wrapper .clock div p {
  font-size: 14px;
  text-transform: capitalize;
}

.contest-cart {
  background-color: #12023e;
  padding: 30px 30px 120px 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.contest-cart__left {
  width: 50%;
  padding-right: 15px;
}

.contest-cart__thumb-slider {
  height: 500px;
  background-color: #5a4bcc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.contest-cart__thumb-slider .single-slide {
  text-align: center;
}

.contest-cart__thumb-slider .single-slide img {
  width: 100%;
}

.contest-cart__nav-slider {
  margin-top: 30px;
  padding: 0 60px;
}

.contest-cart__nav-slider .slick-list {
  margin: 0 -10px;
}

.contest-cart__nav-slider .single-slide {
  height: 120px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #5a4bcc;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  text-align: center;
  margin: 0 10px;
}

.contest-cart__nav-slider .single-slide img {
  display: inline-block;
}

.contest-cart__nav-slider .slick-arrow {
  width: 40px;
  height: 40px;
  background-color: #5a4bcc;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  position: absolute;
  top: 60px;
  left: 0;
  margin-top: -20px;
  cursor: pointer;
}

.contest-cart__nav-slider .slick-arrow:hover {
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
}

.contest-cart__nav-slider .slick-arrow.next {
  left: auto;
  right: 0;
}

.contest-cart__right {
  width: 50%;
  padding-left: 15px;
}

.contest-cart__right .subtitle {
  color: #ffb200;
  margin-bottom: 15px;
}

.contest-cart__right .contest-name {
  font-size: 36px;
  margin-bottom: 45px;
}

.contest-cart__right .contest-num {
  color: #ffffff;
  text-transform: capitalize;
  margin-top: 15px;
  margin-bottom: 30px;
}

.contest-cart__right .contest-num span {
  color: #ec1379;
}

.contest-cart__right .ticket-amount {
  margin-top: 20px;
}

.contest-cart__right .ticket-amount .left {
  font-weight: 600;
  color: #ffffff;
}

.contest-cart__right .ticket-amount .right {
  float: right;
  font-weight: 600;
  color: #ffffff;
}

.contest-cart__right .ticket-amount p {
  font-size: 14px;
  margin-top: 10px;
}

.contest-cart__right .progressbar {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  background-color: #200e54;
  margin-top: 10px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.contest-cart__right .progressbar .bar {
  position: absolute;
  width: 0px;
  height: 100%;
  top: 0;
  left: 0;
  background: #ec6624;
  overflow: hidden;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.contest-cart__right .ticket-price {
  margin-top: 25px;
  margin-bottom: 25px;
}

.contest-cart__right .ticket-price .amount {
  color: #ffffff;
  font-weight: 600;
  font-size: 36px;
}

.contest-cart__right .ticket-price small {
  font-size: 14px;
  margin-left: 15px;
  color: #e9e9e9;
  text-transform: capitalize;
}

.contest-cart__right .select-quantity {
  width: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
}

.contest-cart__right .caption {
  color: #ffffff;
  margin-right: 15px;
}

.contest-cart__right .quantity {
  position: relative;
  width: 170px;
  height: 60px;
}

.contest-cart__right .quantity input {
  border: none;
  text-align: center;
  padding: 0;
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  height: 50px;
  padding-left: 15px;
}

.contest-cart__right .quantity-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.contest-cart__right .quantity-nav .quantity-button {
  width: 40px;
  height: 40px;
  background-color: #20115a;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #e9e9e9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.contest-cart__right .quantity-nav .quantity-button.quantity-up {
  background-color: #5a4bcc;
}

.contest-description {
  margin-top: -35px;
}

.contest-description .nav-tabs .nav-item .cmn-btn {
  background-color: #5a4bcc;
  background-image: none;
  box-shadow: none;
}

.contest-description .nav-tabs .nav-item .cmn-btn.active {
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
}

.content-block .title {
  margin-bottom: 20px;
  font-size: 24px;
}

/********************* Style for live icon *********************/
.live-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.live-bg {
  padding-right: 10px;
  font-size: 24px;
  color: red;
}
.live_bg {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  border-radius: 100px;
}
.drop {
  margin-bottom: -5px;
}

span.live-icon {
  display: inline-block;
  position: relative;
  top: calc(50% - 5px);
  background-color: red;
  width: 10px;
  height: 10px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
  right: 8px;
}
span.live_icon {
  display: inline-block;
  position: relative;
  background-color: red;
  width: 9px;
  height: 9px;
  margin-left: 20px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  z-index: 1;
  right: 0;
  top: 0px;
}
span.live {
  font-size: 12px;
  margin-left: 5px;
  font-weight: 500;
  color: #fff;
}
span.live-icon:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: live 2s ease-in-out infinite;
  z-index: -1;
}
span.live_icon:before {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 0, 0, 0.6);
  width: 100%;
  height: 100%;
  border-radius: 50%;
  animation: live 2s ease-in-out infinite;
  z-index: -1;
}
@keyframes live {
  0% {
    transform: scale(1, 1);
  }
  100% {
    transform: scale(3.5, 3.5);
    background-color: rgba(255, 0, 0, 0);
  }
}

.contest-details-meta .desc {
  line-height: 0;
}

.game-page .contest-card__thumb {
  position: relative;
  height: 148px;
}
.game-page .contest-card__thumb .percentage {
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 12px;
}

.game-page .heading-button {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.game-page .chances {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-transform: capitalize;
}
.game-page .chances label {
  font-size: 15px;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 20px;
  margin-right: 10px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: -2px;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #e89c31;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/******************** Style for live icon ********************/

.content-block + .content-block {
  margin-top: 45px;
}

.icon-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  background-color: #170943;
}

.icon-item__thumb {
  width: 65px;
}

.icon-item__content {
  padding-left: 25px;
  width: calc(100% - 65px);
}

.icon-item__content p {
  color: #e89c31;
}

.icon-item__content span {
  color: #ffffff;
  font-size: 24px;
  line-height: 1.5;
}

.contest-details__thumb-slider {
  background-color: #5a4bcc;
  min-height: 585px;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.contest-details__thumb-slider .single-slide {
  text-align: center;
}

.contest-details__thumb-slider .single-slide img {
  display: inline-block;
}

.contest-details__nav-slider {
  margin-top: 30px;
  padding: 0 60px;
}

.contest-details__nav-slider .slick-list {
  margin: 0 -10px;
}

.contest-details__nav-slider .single-slide {
  height: 120px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #5a4bcc;
  justify-content: center;
  align-items: center;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  text-align: center;
  margin: 0 10px;
}

.contest-details__nav-slider .single-slide img {
  display: inline-block;
}

.contest-details__nav-slider .slick-arrow {
  width: 40px;
  height: 40px;
  background-color: #5a4bcc;
  color: #ffffff;
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  position: absolute;
  top: 60px;
  left: 0;
  margin-top: -20px;
  cursor: pointer;
}

.contest-details__nav-slider .slick-arrow:hover {
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
}

.contest-details__nav-slider .slick-arrow.next {
  left: auto;
  right: 0;
}

.contest-details-content .subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #ffb200;
  margin-bottom: 10px;
}

.contest-details-content .contest-name {
  font-size: 36px;
}

.contest-details-content .contest-details-meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 0px;
}

.contest-details-content .contest-details-meta ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -15px;
}

.contest-details-content .contest-details-meta ul li {
  padding: 10px 15px;
  position: relative;
}

.contest-details-content .contest-details-meta ul li:first-child::before {
  display: none;
}

.contest-details-content .contest-details-meta ul li::before {
  position: absolute;
  content: "";
  top: 50%;
  left: -1px;
  width: 2px;
  height: 18px;
  background-color: #ada8f7;
  margin-top: -11px;
}

.contest-details-content .contest-details-meta span {
  color: #ec1379;
}

.contest-details-content .contest-description {
  /* border-top: 1px solid #082432; */
  margin-top: 30px;
  padding-top: 30px;
}

.contest-sidebar__cart {
  padding: 30px 30px;
  background-color: #4939c0;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
}

.contest-sidebar__cart .clock-wrapper {
  padding: 0;
  background-color: transparent;
  margin-bottom: 30px;
}

.contest-sidebar__cart .clock-wrapper p {
  color: #e9e9e9;
}

.contest-sidebar__cart .clock-wrapper .clock div span {
  font-weight: 600;
  color: #ec6624;
}

.contest-sidebar .title {
  color: #eceafe;
}

.contest-sidebar .ticket-amount {
  margin-top: 10px;
}

.contest-sidebar .ticket-amount .left {
  font-weight: 600;
  color: #ffffff;
}

.contest-sidebar .ticket-amount .right {
  float: right;
  font-weight: 600;
  color: #ffffff;
}

.contest-sidebar .ticket-amount p {
  font-size: 14px;
  margin-top: 10px;
}

.contest-sidebar .progressbar {
  position: relative;
  display: block;
  width: 100%;
  height: 10px;
  background-color: #3b2bb1;
  margin-top: 5px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.contest-sidebar .progressbar .bar {
  position: absolute;
  width: 0px;
  height: 100%;
  top: 0;
  left: 0;
  background: #ec6624;
  overflow: hidden;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.contest-sidebar .ticket-price {
  margin-top: 25px;
  margin-bottom: 25px;
}

.contest-sidebar .ticket-price .amount {
  color: #ffffff;
  font-weight: 600;
  font-size: 36px;
}

.contest-sidebar .ticket-price small {
  font-size: 14px;
  margin-left: 15px;
  color: #e9e9e9;
  text-transform: capitalize;
}

.contest-sidebar .select-quantity {
  text-align: center;
}

.contest-sidebar .caption {
  color: #ffffff;
  margin-right: 15px;
}

.contest-sidebar .quantity {
  position: relative;
  width: 170px;
  height: 60px;
  display: inline-block;
}

.contest-sidebar .quantity input {
  border: none;
  text-align: center;
  padding: 0;
  font-size: 28px;
  font-weight: 600;
  color: #ffffff;
  height: 60px;
  padding-left: 15px;
}

.contest-sidebar .quantity-nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.contest-sidebar .quantity-nav .quantity-button {
  width: 40px;
  height: 40px;
  background-color: rgba(90, 75, 204, 0.3);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #e9e9e9;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  cursor: pointer;
}

.contest-sidebar .quantity-nav .quantity-button.quantity-up {
  background-color: #5a4bcc;
}

.contest-sidebar .bottom {
  text-align: center;
  margin-bottom: -50px;
  margin-top: 30px;
}

.contest-sidebar .social-links {
  margin-top: 50px;
}

/* lottery-details css start */
.lottery-wrapper {
  padding: 100px 30px 50px 30px;
  background-color: #12023e;
}

.lottery-wrapper__title {
  font-size: 60px;
  text-transform: uppercase;
  font-weight: 700;
  color: #ffffff;
  text-align: center;
  margin-bottom: 5px;
}

.lottery-wrapper p {
  text-align: center;
}

.lottery-wrapper__btn {
  text-align: center;
  margin-top: 45px;
}

.lottery-wrapper.style--two {
  padding: 0;
  background-color: transparent;
}

.lottery-wrapper .action-header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  padding: 50px 40px;
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.lottery-wrapper .action-header .left {
  width: 50%;
}

.lottery-wrapper .action-header .left ul {
  margin: -5px -10px;
}

.lottery-wrapper .action-header .left ul li {
  display: inline-block;
  margin: 5px 10px;
}

.lottery-wrapper .action-header .right {
  width: 50%;
}

.lottery-wrapper .action-header .right ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -10px;
  justify-content: flex-end;
}

.lottery-wrapper .action-header .right ul li {
  display: flex;
  margin: 5px 10px;
}

.lottery-wrapper .action-header .right ul li i {
  color: #ffffff;
  font-size: 28px;
  padding-right: 10px;
}

.lottery-wrapper .action-header .right ul li button {
  background-color: transparent;
  display: flex;
  align-items: center;
}

.lottery-wrapper .action-header .right ul li button span {
  color: #ffffff;
}

.clock2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin: -5px -8px;
}

.clock2 div {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin: 5px 8px;
}

.clock2 div span {
  color: #ffffff;
}

.clock2 div p {
  color: #ffffff;
}

.lottery-single {
  background-color: #5a4bcc;
  position: relative;
}

.lottery-single:hover .lottery-single__close {
  opacity: 1;
  visibility: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.lottery-single__close {
  position: absolute;
  width: 30px;
  height: 30px;
  background-color: #5a4bcc;
  color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -15px;
  right: -15px;
  opacity: 0;
  visibility: 0;
  -webkit-transform: scale(0.5);
  -ms-transform: scale(0.5);
  transform: scale(0.5);
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.lottery-single__header {
  background-color: #4939c1;
  padding: 20px 20px;
}

.lottery-single__header .titcket-number {
  color: #ffffff;
  margin-bottom: 10px;
}

.lottery-single__header .top {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
  margin-bottom: 20px;
  border-bottom: 1px solid #6d62c7;
}

.lottery-single__header .top .titcket-number {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 0;
}

.lottery-single__header .top ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.lottery-single__header .top ul li button {
  background-color: transparent;
  display: flex;
  align-items: center;
}

.lottery-single__header .top ul li button span {
  color: #ffffff;
}

.lottery-single__header .top ul li button i {
  font-size: 28px;
  color: #ffffff;
}

.lottery-single__selected-number {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -3px;
}

.lottery-single__selected-number li {
  width: 36px;
  height: 36px;
  background-image: -moz-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  background-image: -webkit-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  /*background-image: -ms-linear-gradient(135deg, #b122e6 0%, #ff63de 100%); */
  box-shadow: 0px 19px 56px 0px rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.4);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  margin: 3px;
}

.lottery-single__selected-number li.active {
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
  border: none;
  color: #ffffff;
}

.lottery-single__number {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -3px;
  justify-content: center;
}

.lottery-single__number li {
  width: 36px;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  cursor: pointer;
  margin: 4px 3px;
}

.lottery-single__number li.active {
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
  border: none;
}

.lottery-single__body {
  padding: 20px 20px;
}

.lottery-single__footer {
  margin-left: 20px;
  margin-right: 20px;
  padding: 20px 0 30px 0;
  border-top: 1px solid #7d73cd;
}

.lottery-single.style--two .lottery-single__number {
  justify-content: flex-start;
  margin: -4px;
}

.lottery-single.style--two .lottery-single__number li {
  margin: 4px 4px;
}

.buy-lottery-ticket {
  border: 1px solid #201456;
  padding: 30px 30px;
  border-radius: 25px;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.buy-lottery-ticket .left {
  width: 35%;
}

.buy-lottery-ticket .right {
  width: 65%;
  text-align: right;
}

.buy-lottery-ticket .sub-total-price,
.buy-lottery-ticket .total-price {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.buy-lottery-ticket .sub-total-price span,
.buy-lottery-ticket .total-price span {
  font-size: 18px;
}

.buy-lottery-ticket .sub-total-price {
  padding-bottom: 20px;
}

.buy-lottery-ticket .total-price {
  padding-top: 20px;
  border-top: 1px solid #201456;
}

.buy-lottery-ticket .total-price p,
.buy-lottery-ticket .total-price span {
  color: #ffffff;
}

.pick-lottery-package .title {
  color: #ffffff;
  font-weight: 700;
  font-size: 60px;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.lottery-package-list {
  text-align: center;
  margin: -10px -15px;
}

.lottery-package-list a {
  padding: 20px 20px;
  background-image: -ms-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  background-image: -moz-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);

  color: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 10px 15px;
}

.lottery-package-list a:hover {
  background: #e89c31;
  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

/* cart section css start */
.cart-wrapper {
  padding: 20px 30px 60px 30px;
  background-color: transparent;
}

.cart-wrapper__title {
  font-weight: 700;
  font-size: 60px;
  text-align: center;
  margin-bottom: 50px;
}

.ticket-wrapper {
  border: 1px solid #052434;
  border-radius: 10px;
}

.ticket-wrapper__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  background-color: rgb(4 31 45 / 70%);
  padding: 30px 20px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.ticket-wrapper__header button {
  font-size: 14px;
  text-transform: uppercase;
  background-color: transparent;
  color: #ffffff;
}

.cart-page .ticket-wrapper__body {
  padding: 20px 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: rgb(4 31 45 / 80%);
}

.ticket-wrapper__body .single-row {
  border-bottom: 1px solid #083248;
}

.ticket-wrapper__body .single-row:first-child {
  padding-top: 0;
}

.ticket-wrapper__body .single-row:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.ticket-wrapper__body .single-row .numbers li {
  margin: 3px;
  width: 36px;
  height: 36px;
  background-image: -ms-linear-gradient(
    86deg,
    #ab6b10 0%,
    #d68e28 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ab6b10 0%,
    #d68e28 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ab6b10 0%,
    #d68e28 44%,
    #fbb936 100%
  );

  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
}

.ticket-wrapper__body .single-row .action-btns {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -10px;
}

.ticket-wrapper__body .single-row .action-btns button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 22px;
  margin: 5px 10px;
}

.ticket-wrapper__body .single-row .action-btns button:hover {
  background-position: 120% 50%;
}

.ticket-wrapper__body .single-row .action-btns .edit-btn {
  background-image: -moz-linear-gradient(7deg, #e0962d 0%, #ab6b10 100%);
  background-image: -webkit-linear-gradient(7deg, #e0962d 0%, #ab6b10 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
}

.ticket-wrapper__body .single-row .action-btns .del-btn {
  background-image: -ms-linear-gradient(-45deg, #e0962d 0%, #ab6b10 100%);
  background-image: -moz-linear-gradient(-45deg, #e0962d 0%, #ab6b10 100%);
  background-image: -webkit-linear-gradient(-45deg, #e0962d 0%, #ab6b10 100%);
}

.checkout-wrapper__header {
  padding: 30px 20px 30px 20px;
  background-color: rgb(4 31 45 / 80%);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.checkout-wrapper__body {
  padding: 20px;
  background-color: rgb(4 31 45 / 80%);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.checkout-wrapper__body .price li {
  padding: 30px 0;
  border-bottom: 1px solid #083248;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.checkout-wrapper__body .price li:first-child {
  padding-top: 0;
}

.checkout-wrapper__body .price li:last-child {
  padding-bottom: 0;
  border-bottom: none;
}

.checkout-wrapper__body .price li .caption + span {
  color: #f3af33;
  font-size: 15px;
}

.checkout-wrapper__body .price li .price {
  font-size: 22px;
  color: #ffffff;
}

.checkout-wrapper__btn {
  text-align: center;
  margin-bottom: -40px;
  margin-top: 50px;
}

/* checkout section css start */
.checkout-area {
  padding: 50px 0px;
  background-color: transparent;
}

.checkout-form-area .top {
  padding: 30px;
  background-color: #031b28;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #052434;
  border-radius: 10px;
}

.checkout-form-area .top .right a {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.checkout-form-area .top .right a i {
  width: 40px;
  height: 40px;
  color: #ffffff;
  font-size: 28px;
  background-image: -moz-linear-gradient(7deg, #e4992f 0%, #ae6e12 100%);
  background-image: -webkit-linear-gradient(7deg, #e4992f 0%, #ae6e12 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.checkout-form-area .top .right a span {
  color: #ffffff;
  padding-left: 8px;
  font-size: 16px;
}

.checkout-form-area .personal-details {
  padding: 30px;
  background-color: #031b28;
  border: 1px solid #052434;
  border-radius: 10px;
}

.checkout-form-area .personal-details .title {
  padding-bottom: 15px;
  border-bottom: 1px dashed #083248;
  margin-bottom: 30px;
}

.checkout-form-area .payment-details {
  padding: 30px;
  background-color: rgb(4 31 45 / 80%);
  border: 1px solid #052434;
  border-radius: 10px;
}

.checkout-form-area .payment-details .title {
  padding-bottom: 15px;
  margin-bottom: 30px;
  border-bottom: 1px solid #083248;
}

.checkout-form-area .info-text {
  font-size: 14px;
  margin-top: 10px;
}

.checkout-form-area .info-text a {
  color: #07ff7b;
}

.personal-details-form .form-row {
  margin: -10px -15px;
}

.personal-details-form .form-group {
  padding: 10px 15px;
}

.personal-details-form input {
  padding-top: 0;
  padding-left: 0;
  padding-bottom: 5px;
  min-height: auto;
  font-size: 14px;
  border: none;
  border-bottom: 1px solid #083248;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  color: #ffffff;
}

.personal-details-form input::-webkit-input-placeholder {
  color: #9aace5;
}

.personal-details-form input::-moz-placeholder {
  color: #9aace5;
}

.personal-details-form input:-ms-input-placeholder {
  color: #9aace5;
}

.personal-details-form input:-moz-placeholder {
  color: #9aace5;
}

.personal-details-form button {
  text-transform: capitalize;
  padding: 9px 30px;
  width: 160px;
  font-size: 16px;
}

.payment-form .payment-methods {
  margin: -15px;
}

.payment-form .payment-methods button {
  background-color: transparent;
  padding: 5px 15px;
  border: 1px solid #083248;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin: 15px;
}

.payment-form .payment-methods button.checked {
  border-color: #e1962d;
  position: relative;
}

.payment-form .payment-methods button.checked::before {
  position: absolute;
  content: "\f00c";
  font-family: "Line Awesome Free";
  font-weight: 900;
  width: 33px;
  height: 33px;
  background-color: #df952c;
  color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  top: -15px;
  right: -15px;
  border: 3px solid #031b28;
}

.payment-form .payment-methods button i {
  color: #b2c8ff;
  font-size: 36px;
  line-height: 1;
}

.payment-form .payment-methods button span {
  display: block;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 12px;
}

.payment-form__title {
  font-size: 18px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.payment-form label {
  font-size: 16px;
}

.payment-form input {
  height: 60px;
  font-size: 15px;
}

.payment-form button {
  font-size: 16px;
  text-transform: capitalize;
  padding: 10px 35px;
  margin-top: 20px;
}

.payment-form .form-row {
  margin-left: -15px;
  margin-right: -15px;
}

.payment-form .form-group {
  padding-left: 15px;
  padding-right: 15px;
}
.checkout-area .payment-details.address {
  margin-bottom: 20px;
}

/* affiliate single section css start */
.affiliate-single-wrapper {
  position: relative;
  padding-left: 65px;
  padding-right: 65px;
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.affiliate-single-wrapper__obj {
  position: absolute;
  right: 0;
  bottom: 0;
  text-align: right;
}

/* how it work section css start */
.work-card {
  padding: 0 30px;
  background: transparent;
}

.work-card:hover .work-card__icon .inner {
  box-shadow: inset 0 0px 50px 2px #ec1379a3;
}

.work-card__icon {
  width: 165px;
  height: 165px;
  background-color: #170543;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
}

.work-card__icon .inner {
  width: 135px;
  height: 135px;
  background-image: -moz-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);
  /*background-image: -ms-linear-gradient(-45deg, #c165dd 0%, #5c27fe 100%);*/
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  box-shadow: inset 0 0px 19px 2px #ec137954;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.work-card__content {
  margin-top: 30px;
}

.work-card__content p {
  margin-top: 10px;
}

.work-card__title {
  text-transform: uppercase;
}

/* affiliate partner section css start */
.affiliate-card {
  padding: 40px 30px;
  background-image: -moz-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);
  background-image: -webkit-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);
  /*background-image: -ms-linear-gradient(90deg, #c165dd 0%, #5c27fe 100%);*/
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  text-align: center;
  overflow: hidden;
}

.affiliate-card:hover {
  background-image: -moz-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  background-image: -webkit-linear-gradient(90deg, #e82a7a 0%, #360354 100%);
  /*background-image: -ms-linear-gradient(90deg, #e82a7a 0%, #360354 100%); */
}

.affiliate-card__content {
  margin-top: 25px;
}

.affiliate-card__content p {
  margin-top: 8px;
  color: #ffffff;
}

.affiliate-card__title {
  text-transform: capitalize;
  color: #ffffff;
}

/* top affiliate section css start */
.top-affiliate-card {
  text-align: center;
}

.top-affiliate-card:hover .top-affiliate-card__thumb::before {
  top: 0;
}

.top-affiliate-card__thumb {
  width: 267px;
  height: 328px;
  position: relative;
  z-index: 1;
  display: inline-block;
}

.top-affiliate-card__thumb::before {
  position: absolute;
  content: "";
  left: 0;
  top: 12px;
  width: 100%;
  height: 100%;
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  background-color: #e22979;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.top-affiliate-card__thumb .inner {
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.top-affiliate-card__thumb img {
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.top-affiliate-card__content {
  margin-top: 50px;
}

.top-affiliate-card__content .name {
  text-transform: capitalize;
}

.top-affiliate-card__content .amount {
  color: #e89c31;
  margin-top: 8px;
}

/* video section css start */
.video-wrapper {
  padding: 30px 30px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  min-height: 490px;
  position: relative;
}

.video-wrapper .cmn-btn {
  padding: 25px 45px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* buy ticket section css start */
.buy-ticket-section {
  position: relative;
  padding-top: 310px;
  padding-bottom: 140px;
}

@media (max-width: 1450px) {
  .buy-ticket-section {
    padding-top: 215px;
  }
}

.winner-obj {
  position: absolute;
  top: 60px;
  right: 0;
  width: 54%;
}

@media (max-width: 1650px) {
  .winner-obj {
    width: 40%;
  }
}

.buy-btn-wrapper {
  position: relative;
  display: inline-block;
}

.buy-btn-wrapper .arrow {
  position: absolute;
  top: 10%;
  left: 240px;
}

.buy-btn-wrapper span {
  display: block;
  font-weight: 600;
  color: #ffffff;
  margin-bottom: 20px;
}

.winner-stat-wrapper {
  margin-top: 265px;
}

@media (max-width: 1650px) {
  .winner-stat-wrapper {
    margin-top: 185px;
  }
}

.winner-stat-wrapper__title {
  margin-bottom: 30px;
  color: #e9e9e9;
}

/* client section css start */
.client-wrapper__title {
  font-weight: 600;
  font-size: 36px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 45px;
  color: #dfe2fa;
}

.client-slider {
  padding: 50px 80px;
  background-image: -ms-linear-gradient(0deg, #0f5ae0 0%, #7400ba 100%);
  background-image: -moz-linear-gradient(0deg, #0f5ae0 0%, #7400ba 100%);
  background-image: -webkit-linear-gradient(0deg, #0f5ae0 0%, #7400ba 100%);

  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.client-slider .slick-track {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.client-slider .client-single {
  text-align: center;
}

.client-slider .client-single img {
  display: inline-block;
}

/* faq section css start */
.faq-el {
  position: absolute;
  top: 0;
  left: 0;
}

@media (max-width: 1550px) {
  .faq-el {
    width: 40%;
    top: 20%;
  }
}

.faq-top-wrapper {
  padding-left: 50px;
  padding-right: 50px;
  padding-bottom: 30px;
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.faq-body-wrapper {
  margin-top: 40px;
}

/* blog section css start */
.blog-card__thumb {
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  overflow: hidden;
}

.blog-card__thumb img {
  width: 100%;
}

.blog-card__content {
  padding: 40px 60px;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: -105px;
  background-color: #683df5;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: relative;
  z-index: 1;
}

.blog-card__content p {
  color: #ffffff;
  margin-top: 20px;
}

.blog-card__title {
  font-size: 36px;
  margin-bottom: 10px;
}

.blog-card__meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -15px;
}

.blog-card__meta li {
  margin: 5px 15px;
}

.blog-card__meta li i {
  font-size: 14px;
  color: #e89c31;
  margin-right: 3px;
}

.blog-card__meta li span {
  font-size: 14px;
}

.blog-card__footer {
  border-top: 1px solid #8380f7;
  margin-top: 30px;
  padding-top: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.blog-card__footer .left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.blog-card__footer .left span {
  font-size: 14px;
}

.blog-card__footer .left .name {
  color: #e89c31;
  padding-left: 10px;
}

.blog-card__footer .left .post-author {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 20px;
}

.blog-card__footer .left .post-author img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.blog-card__footer .right .read-btn {
  color: #e89c31;
}

.blog-card__footer .right .read-btn i {
  font-size: 24px;
}

.blog-card.style--two {
  background-color: #683df5;
  padding: 10px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.blog-card.style--two .blog-card__thumb {
  width: 36%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.blog-card.style--two .blog-card__thumb img {
  height: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.blog-card.style--two .blog-card__content {
  width: 64%;
  padding: 20px 25px;
  margin: 0;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.blog-card.style--two .blog-card__title {
  font-size: 28px;
}

/* blog single section css start */
.blog-single__title {
  font-size: 50px;
}

.blog-single__meta {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;
}

.blog-single__meta .left {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.blog-single__meta .left span {
  font-size: 14px;
  color: #ffffff;
}

.blog-single__meta .left .name {
  padding-left: 10px;
}

.blog-single__meta .left .post-author {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin-left: 20px;
}

.blog-single__meta .left .post-author img {
  width: 30px;
  height: 30px;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.blog-single__meta .right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.blog-single__meta .right > span {
  margin-right: 15px;
  color: #ffffff;
}

.blog-single__meta .right .social-link-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -7px;
}

.blog-single__meta .right .social-link-list li {
  margin: 5px 7px;
}

.blog-single__meta .right .social-link-list li a {
  width: 40px;
  height: 40px;
  border: 1px solid rgba(255, 255, 255, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.blog-single__meta .right .social-link-list li a:hover {
  border: none;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

.blog-single__body {
  margin-top: 30px;
  padding: 50px;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  background-color: #0f0233;
  box-shadow: 0px 9px 50px 0px rgba(49, 65, 156, 0.15);
}

.blog-single__body .title {
  font-size: 40px;
  margin-bottom: 20px;
}

.blog-single__body .title ~ .title {
  margin-top: 20px;
}

.blog-single__body img {
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
  width: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  margin: 30px 0;
}

/* contact section css start */
.contact-wrapper {
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.contact-form-wrapper {
  padding: 50px 0 50px 50px;
}

.contact-form-wrapper .title {
  font-size: 28px;
  margin-bottom: 30px;
}

.contact-form .form-group {
  margin-bottom: 0;
}

.contact-form .form-group + .form-group {
  margin-top: 30px;
}

.contact-form label {
  font-size: 16px;
  text-transform: capitalize;
}

.contact-form label sup {
  color: #f1481f;
  font-size: 100%;
  top: 0;
  right: 3px;
}

.contact-form input,
.contact-form textarea {
  height: 60px;
  background-color: rgba(0, 18, 50, 0.102);
  font-size: 16px;
  color: #ffffff;
  border-color: #7279cd;
  padding: 20px;
}

.contact-form input::-webkit-input-placeholder,
.contact-form textarea::-webkit-input-placeholder {
  color: #8b99d7;
}

.contact-form input::-moz-placeholder,
.contact-form textarea::-moz-placeholder {
  color: #8b99d7;
}

.contact-form input:-ms-input-placeholder,
.contact-form textarea:-ms-input-placeholder {
  color: #8b99d7;
}

.contact-form input:-moz-placeholder,
.contact-form textarea:-moz-placeholder {
  color: #8b99d7;
}

.contact-form input:focus,
.contact-form input:checked,
.contact-form textarea:focus,
.contact-form textarea:checked {
  border-color: #989dda;
}

.contact-form button {
  padding: 14px 30px;
  font-size: 16px;
}

.contact-info-wrapper {
  padding-top: 150px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  height: 100%;
}

.contact-info {
  width: calc(50% - 15px);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  position: relative;
}

.contact-info:last-child::after {
  display: none;
}

.contact-info::after {
  position: absolute;
  content: "";
  top: 6px;
  right: 0;
  width: 1px;
  height: 48px;
  background-color: #516184;
}

.contact-info__icon {
  width: 60px;
}

.contact-info__content {
  width: calc(100% - 60px);
  padding-left: 15px;
}

.contact-info__content p {
  color: #ffffff;
  text-transform: uppercase;
}

.contact-info__content span {
  font-size: 16px;
  color: #e89c31;
}

.contact-thumb {
  align-self: flex-end;
  width: 100%;
  text-align: center;
}

.contact-thumb img {
  width: 70%;
}

.social-card {
  text-align: center;
  background-color: #0f0233;
  padding: 20px;
  border: 1px solid rgba(84, 67, 160, 0.59);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
}

.social-card:hover {
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
}

.social-card__icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  color: #ffffff;
  font-size: 28px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

.social-card__content {
  margin-top: 25px;
}

.social-card__content span {
  font-size: 16px;
  text-transform: capitalize;
  color: #e89c31;
  margin-top: 5px;
}

/* login css start */
.modal {
  background-color: rgba(0, 0, 0, 0.5);
}

.modal .modal-content {
  background-color: transparent;
}

.modal .modal-body {
  padding: 0;
}

.account-form-area {
  padding: 30px 20px 20px 20px;
  background-color: #1b1c25 ;
  box-shadow: 0px 0px 29.4px 0.6px rgba(0, 0, 0, 0.5);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  position: relative;
}

.account-form-area .close-btn {
  position: absolute;
  top: 27px;
  right: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: 20px;
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.account-form-area .title {
  font-size: 28px;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 20px;
}

.account-form-area .account-form-wrapper {
  background-color: #22232d;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 30px;
}
.account-form-area .account-form-wrapper .btn-style span {
  z-index: unset !important;
}
.account-form-area .account-form-wrapper .btn-style span {
  z-index: unset !important;
}

.account-form-area form .form-group {
  margin-bottom: 0;
}
.registration-popup .account-form-area form .form-group + .form-group {
  margin-top: 20px;
}

.account-form-area form .form-group + .form-group {
  margin-top: 30px;
}

.account-form-area form label {
  text-transform: uppercase;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.7);
}

.account-form-area form label sup {
  color: #f1481f;
  font-size: 100%;
  top: 0;
  right: 3px;
}

.account-form-area form input {
  padding: 0;
  padding-bottom: 10px;
  min-height: auto;
  font-size: 16px;
  color: #ffffff;
  border: none;
  border-bottom: 1px solid #ffffff2d;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.account-form-area form input::-webkit-input-placeholder {
  color: #ffffff;
}

.account-form-area form input::-moz-placeholder {
  color: #ffffff;
}

.account-form-area form input:-ms-input-placeholder {
  color: #ffffff;
}

.account-form-area form input:-moz-placeholder {
  color: #ffffff;
}

.account-form-area form .link {
  font-size: 14px;
  margin-top: 3px;
  color: #e89c31;
}

.account-form-area form .cmn-btn {
  width: 200px;
  display: inline-block;
}

.account-form-area p a {
  color: #e89c31;
  font-size: 16px;
}

.account-form-area .divider {
  position: relative;
  z-index: 1;
  margin-top: 10px;
  text-align: center;
}

.account-form-area .divider::after {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  border-radius: 50%;
  background-color: #ffffff4b;
  z-index: -1;
}

.account-form-area .divider span {
  font-size: 16px;
  text-transform: uppercase;
  color: #ffffff;
  font-weight: 600;
  padding: 5px 30px;
  background-color: #47484e;
}

.account-form-area .social-link-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  gap: 20px;
}

/* .account-form-area .social-link-list li {
  margin: 0 10px;
} */

.account-form-area .social-link-list li a,
.account-form-area button .my-facebook-button-class.metro,
/* .account-form-area button, */
.account-form-wrapper ul.social-link-list button,
.account-form-area button {
  width: 50px;
  height: 50px;
  background-color: rgba(12, 18, 32, 0) !important;
  border: 1px solid #ffffff4d !important;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex !important;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 18px !important;
  color: #fff !important;
}
.fairnessBtn button {
  width: fit-content;
}
.loginpage button {
  border-radius: 50% !important;
}
.registerpage button {
  border-radius: 50px !important;
}
.account-form-area .social-link-list li a:hover,
.account-form-area button.my-facebook-button-class.metro:hover,
.account-form-wrapper ul.social-link-list button:hover {
  color: #ffffff !important;
  background-image: -webkit-linear-gradient(
    86deg,
    #e2972e 0%,
    #d78e28 44%,
    #e0952d 100%
  ) !important;
  box-shadow: 0px 9px 4px 0px rgb(124 78 25 / 9%) !important;
  border: 1px solid #dd932b !important;
}
.account-form-wrapper ul.social-link-list button div {
  margin: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  border-radius: unset !important;
}
.account-form-wrapper ul.social-link-list button span {
  padding: 0 !important;
}
.account-form-wrapper ul.social-link-list button div svg g path {
  fill: #fff;
}
.account-form-wrapper ul.social-link-list button div svg g path:last-child {
  fill: none;
}

/* user section css start */
.user-card {
  padding: 50px 30px;
  background-image: -ms-linear-gradient(120deg, #7b19cb 0%, #3b26db 99%);
  background-image: -moz-linear-gradient(120deg, #7b19cb 0%, #3b26db 99%);
  background-image: -webkit-linear-gradient(120deg, #7b19cb 0%, #3b26db 99%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  text-align: center;
}

.user-card__id {
  color: #e89c31;
  font-size: 18px;
  margin-top: 3px;
}

.avatar-upload {
  position: relative;
  max-width: 160px;
  margin: auto;
}

.avatar-upload .obj-el {
  position: absolute;
  top: 0;
  left: 0;
  width: 124%;
  height: 124%;
  margin-left: -14%;
  margin-top: -5%;
  border-radius: 50%;
}

.obj-el img {
  width: 124px;
  height: 124px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #e89b32;
  padding: 3px;
}

.avatar-upload .avatar-edit {
  position: absolute;
  right: 6px;
  z-index: 1;
  bottom: 0;
}

.avatar-upload .avatar-edit input {
  display: none;
}

.avatar-upload .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 50%;
  background-image: -moz-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  background-image: -webkit-linear-gradient(135deg, #b122e6 0%, #ff63de 100%);
  /*background-image: -ms-linear-gradient(135deg, #b122e6 0%, #ff63de 100%); */
  box-shadow: 0px 19px 56px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  font-weight: normal;
  transition: all 0.2s ease-in-out;
}

.avatar-upload .avatar-edit input + label:after {
  content: "\f030";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #ffffff;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  text-align: center;
  margin: auto;
}

.avatar-upload .avatar-preview {
  width: 160px;
  height: 160px;
  position: relative;
  border-radius: 100%;
}

.avatar-upload .avatar-preview > div {
  width: 100%;
  height: 100%;
  border-radius: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.user-action-card {
  padding: 10px 0;
  margin-top: 20px;
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.user-action-list li.active a {
  background: #3b26db;
  background: -webkit-linear-gradient(
    to right,
    #ad1f83,
    rgba(108, 0, 146, 0.25),
    rgba(59, 38, 219, 0.001)
  );
  background: linear-gradient(
    to right,
    #ad1f83,
    rgba(108, 0, 146, 0.25),
    rgba(59, 38, 219, 0.001)
  );
  border-color: #ff002a;
}

.user-action-list li a {
  font-size: 18px;
  text-transform: capitalize;
  padding: 12px 30px;
  display: block;
  border-left: 2px solid transparent;
}

.user-action-list li a .badge {
  float: right;
  padding: 4px 8px;
  color: #ffffff;
  background-color: #7368f5;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.upcoming-draw-wrapper {
  background-color: #5a4bcc;
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.upcoming-draw-wrapper .title {
  font-size: 28px;
  margin-bottom: 25px;
}

.draw-single-ticket {
  border: 2px solid rgba(255, 255, 255, 0.2);
  padding: 20px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative;
  background-origin: #5a4bcc;
}

.draw-single-ticket::before {
  position: absolute;
  content: "";
  top: 39%;
  left: -19px;
  width: 34px;
  height: 34px;
  background-color: #5a4bcc;
  /* border: 2px solid #7b6fd6; */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.draw-single-ticket::after {
  position: absolute;
  content: "";
  top: 39%;
  right: -19px;
  width: 34px;
  height: 34px;
  background-color: #5a4bcc;
  /* border: 2px solid #7b6fd6; */
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-left-color: transparent;
  border-bottom-color: transparent;
  -webkit-transform: rotate(-135deg);
  -ms-transform: rotate(-135deg);
  transform: rotate(-135deg);
}

.draw-single-ticket__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.draw-single-ticket__header .left {
  font-size: 22px;
  color: #ffffff;
}

.draw-single-ticket__header .right {
  font-size: 14px;
  color: #ffffff;
}

.draw-single-ticket .circle-divider {
  text-align: center;
  margin: 10px 0;
}

.draw-single-ticket .circle-divider img {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.draw-single-ticket .ticket-numbers-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -2px;
}

.draw-single-ticket .ticket-numbers-list.active li {
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
  border: none;
}

.draw-single-ticket .ticket-numbers-list li {
  width: 36px;
  height: 36px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  background-color: #5a4bcc;
  border-radius: 50px;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  -ms-border-radius: 50px;
  -o-border-radius: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  color: #ffffff;
  margin: 2px;
}

.draw-ticket-slider .slick-list {
  margin: 0 -5px;
}

.draw-ticket-slider .draw-single-ticket {
  margin: 0 5px;
}

.draw-ticket-slider .slick-arrow {
  position: absolute;
  top: -50px;
  right: 0;
  width: 50px;
  height: 26px;
  color: #ffffff;
  background-image: -moz-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  background-image: -webkit-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);
  /*background-image: -ms-linear-gradient(7deg, #ec1379 0%, #6c0092 100%);*/
  box-shadow: 0px 3px 7px 0px rgba(0, 0, 0, 0.35);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  text-align: center;
  line-height: 26px;
  font-size: 24px;
  cursor: pointer;
}

.draw-ticket-slider .slick-arrow.prev {
  right: 65px;
}

.draw-ticket-slider .slick-arrow.slick-disabled {
  background-color: #7368f5;
  background-image: none;
  box-shadow: none;
}

.past-draw-wrapper {
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 30px;
  padding-top: 30px;
}

.past-draw-wrapper .title {
  font-size: 28px;
  margin-bottom: 15px;
  padding-left: 30px;
}

.past-draw-wrapper table {
  width: 100%;
}

.past-draw-wrapper table th {
  font-size: 14px;
  font-weight: 600;
  color: #b0bbde;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 30px;
  border-bottom: 1px solid #9186e6;
}

.past-draw-wrapper table th:first-child {
  text-align: left;
}

.past-draw-wrapper table th:last-child {
  text-align: right;
}

.past-draw-wrapper table tbody {
  background-color: #4c3eb9;
}

.past-draw-wrapper table tbody tr + tr td {
  border-top: 1px solid #6a5ec3;
}

.past-draw-wrapper table td {
  padding: 15px;
  text-align: center;
}

.past-draw-wrapper table td:first-child {
  text-align: left;
  padding-left: 30px;
}

.past-draw-wrapper table td:last-child {
  text-align: right;
  padding-right: 30px;
}

.past-draw-wrapper table td span {
  color: #ffffff;
  font-size: 16px;
  text-transform: uppercase;
}

.past-draw-wrapper table td .number-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  margin: -4px;
}

.past-draw-wrapper table td .number-list.win-list li {
  border: none;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

.past-draw-wrapper table td .number-list li {
  width: 35px;
  height: 35px;
  font-size: 18px;
  color: #ffffff;
  background-color: #5a4bcc;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 4px;
}

.past-draw-wrapper table td .win {
  color: #e89c31;
}

.past-draw-wrapper .load-more {
  border-top: 2px solid #6a5ec3;
}

.past-draw-wrapper .load-more button {
  background-color: transparent;
  color: #b2acf2;
  width: 100%;
  font-size: 16px;
  padding: 25px;
}

.user-info-card + .user-info-card {
  margin-top: 30px;
}

.user-info-card {
  background-color: #5a4bcc;
  padding: 30px 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.user-info-card__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
}

.user-info-card__header button {
  background-color: transparent;
  color: #ada3f7;
}

.user-info-card__header button i {
  font-size: 22px;
}

.user-info-card__list {
  margin-top: 30px;
}

.user-info-card__list li + li {
  margin-top: 8px;
}
.user-info-card .upd_btn {
  margin-top: 40px;
}

.user-info-card__list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.user-info-card__list li span {
  color: #ffffff;
  font-size: 18px;
  /* top: 24px; */
}

.user-info-card__list li .caption {
  width: 20%;
  position: relative;
  text-align: left;
  padding-right: 20px;
}

.user-info-card__list li .caption::after {
  position: absolute;
  content: ":";
  right: 25px;
  top: -3%;
}

.user-info-card__list li .value {
  width: 70%;
  padding-left: 25px;
}

.user-info-card__list li .value.user-password {
  padding-top: 10px;
}

.user-info-card__list li .value.status-active {
  font-size: 14px;
  position: relative;
  padding-left: 45px;
}

.user-info-card__list li .value.status-active::before {
  position: absolute;
  content: "\f058";
  top: 0;
  left: 25px;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  color: #22b455;
}

.transaction-balance-wrapper {
  padding: 65px 30px;
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.transaction-balance-wrapper .left {
  width: 50%;
}

.transaction-balance-wrapper .transaction-balance {
  padding: 30px 50px;
  background-color: #4c3eb9;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.transaction-balance-wrapper .transaction-balance .balance {
  font-size: 28px;
}

.transaction-balance-wrapper .transaction-balance span {
  font-size: 18px;
  color: #e89c31;
  margin-top: 5px;
}

.transaction-balance-wrapper .right {
  width: 50%;
  display: flex;
  justify-content: space-evenly;
}

.transaction-balance-wrapper .transaction-action-btn {
  width: 120px;
  height: 115px;
  background-color: #4c3eb9;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 15px 0;
}

.transaction-balance-wrapper .transaction-action-btn span {
  width: 100%;
  font-size: 18px;
  color: #e89c31;
}

.all-transaction {
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 30px;
  padding-top: 30px;
}

.all-transaction__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 20px;
}

.all-transaction__header .date-range {
  width: 320px;
  position: relative;
}

.all-transaction tr {
  /* background: #041f2d; */
  background: #2527335e;
}

.all-transaction__header .date-range i {
  position: absolute;
  top: 10px;
  right: 30px;
  color: #e5e2ff;
  font-size: 32px;
}

.all-transaction__header .date-range input {
  border-color: #9c93e0;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  padding: 10px 30px;
  font-size: 14px;
  color: #ffffff;
}

.all-transaction__header .title {
  font-size: 28px;
  width: calc(100% - 320px);
}

.all-transaction table {
  width: 100%;
}

.all-transaction table th {
  font-size: 14px;
  font-weight: 600;
  color: #b0bbde;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 30px;
  border-bottom: 1px solid #9186e6;
  white-space: nowrap;
}

.all-transaction table th:first-child {
  text-align: center;
}

.all-transaction table tbody {
  background-color: #4c3eb9;
}

.all-transaction table tbody tr + tr td {
  border-top: 1px solid #6a5ec3;
}

.all-transaction table td {
  padding: 20px;
  text-align: center;
  white-space: nowrap;
  border-bottom: none;
  vertical-align: middle;
}

.all-transaction table td:first-child {
  text-align: left;
  padding-left: 30px;
  vertical-align: middle;
}

.all-transaction table td:last-child {
  text-align: right;
  padding-right: 30px;
  display: flex;
  justify-content: center;
  border-top: none;
  vertical-align: middle;
}

.all-transaction table td span {
  font-size: 12px;
}

.all-transaction table p {
  font-size: 16px;
  color: #ffffff;
  line-height: 1.4;
  text-transform: uppercase;
}

.all-transaction table .date span {
  display: block;
  text-align: center;
  font-size: 14px;
  text-transform: uppercase;
  color: #d5daf1;
}

.all-transaction table .amount {
  font-size: 16px;
}

.all-transaction table .amount.minus-amount {
  color: #eb6623;
}

.all-transaction table .amount.plus-amount {
  color: #e89c31;
}

.all-transaction table .status-pending i {
  color: #ffc107;
}
.all-transaction table .status-pending .fa-times-circle {
  color: red !important;
}

.all-transaction table .status-success i {
  color: #e89c31;
}

.all-transaction .load-more {
  border-top: 2px solid #6a5ec3;
}

.all-transaction .load-more button {
  background-color: transparent;
  color: #b2acf2;
  width: 100%;
  font-size: 16px;
  /* padding: 25px; */
  padding: 10px;
}

.referral-link-wrapper {
  padding: 30px;
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.referral-link-wrapper .title {
  font-size: 28px;
  margin-bottom: 30px;
}

.copy-link {
  border: 1px solid #7b6fd6;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  position: relative;
  height: 60px;
  background-color: #4c3eb9;
  overflow: hidden;
}

.copy-link .copy-link-icon {
  position: absolute;
  top: 4px;
  left: 5px;
  width: 50px;
  height: 50px;
  color: #ffffff;
  background-color: #5351fb;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 26px;
}

.copy-link .label {
  font-size: 18px;
  color: #ffffff;
  position: absolute;
  left: 75px;
  top: 15px;
}

.copy-link input {
  border: none;
}

.copy-link input[data-click-select-all] {
  padding-right: 160px;
  padding-left: 200px;
  height: 60px;
  color: #00cca2;
  font-size: 16px;
}

.copy-link input[type="submit"] {
  position: absolute;
  top: 4px;
  right: 5px;
  width: auto;
  color: #ffffff;
  padding: 10px 35px;
  background-image: -ms-linear-gradient(
    -103deg,
    #ef774c 0%,
    #ed684f 35%,
    #e84351 76%,
    #e73351 100%
  );
  background-image: -moz-linear-gradient(
    -103deg,
    #ef774c 0%,
    #ed684f 35%,
    #e84351 76%,
    #e73351 100%
  );
  background-image: -webkit-linear-gradient(
    -103deg,
    #ef774c 0%,
    #ed684f 35%,
    #e84351 76%,
    #e73351 100%
  );

  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.referral-overview {
  padding: 30px;
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.referral-crad {
  padding: 30px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  background-color: #20212be6 !important;
  border: 1px solid #292b39;
  box-shadow: 6px 11px 14px 1px #181d254f !important;
}

.referral-crad__icon {
  width: 60px;
}

.referral-crad__content {
  width: calc(100% - 60px);
  padding-left: 15px;
}

.referral-crad__content .number {
  font-size: 28px;
}

.referral-crad__content span {
  color: #e89c31;
}

.referral-transaction {
  background-color: #5a4bcc;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  margin-top: 30px;
  padding-top: 30px;
}

.referral-transaction__header {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 0 30px;
  margin-bottom: 20px;
}

.referral-transaction__header .date-range {
  width: 320px;
  position: relative;
}

.referral-transaction__header .date-range i {
  position: absolute;
  top: 10px;
  right: 30px;
  color: #e5e2ff;
  font-size: 32px;
}

.referral-transaction__header .date-range input {
  border-color: #9c93e0;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  padding: 10px 30px;
  font-size: 14px;
  color: #ffffff;
}

.referral-transaction__header .title {
  font-size: 28px;
  width: calc(100% - 320px);
}

.referral-transaction table {
  width: 100%;
}

.referral-transaction tr {
  background: #2527335e;
}

.referral-transaction table th {
  font-size: 14px;
  font-weight: 600;
  color: #b0bbde;
  text-transform: uppercase;
  text-align: center;
  padding: 15px 30px;
  border-bottom: 1px solid #9186e6;
}

.referral-transaction table th:first-child {
  text-align: center;
}

.referral-transaction table th:last-child {
  text-align: right;
}

.referral-transaction table tbody {
  background-color: #4c3eb9;
}

.referral-transaction table tbody tr + tr td {
  border-top: 1px solid #6a5ec3;
}

.referral-transaction table td {
  padding: 15px 20px;
  text-align: center;
  font-size: 16px;
  color: #ffffff;
  border-bottom: none;
}

.referral-transaction table td:first-child {
  text-align: left;
}

.referral-transaction table td:last-child {
  /* text-align: right; */
  padding-right: 30px;
}

.referral-transaction table .date span {
  display: block;
  text-align: center;
  font-size: 16px;
  text-transform: uppercase;
  color: #d5daf1;
}

.referral-transaction table .date .month {
  font-size: 12px;
}

.referral-transaction .load-more {
  border-top: 2px solid #6a5ec3;
}

.referral-transaction .load-more button {
  background-color: transparent;
  color: #b2acf2;
  width: 100%;
  font-size: 16px;
  padding: 25px;
}

/* error-section start */
.error-wrapper {
  padding-top: 100px;
  padding-bottom: 100px;
  min-height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.error-wrapper__inner {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 1;
}

.error-wrapper__inner::after {
  position: absolute;
  content: "";
  bottom: 9px;
  left: 0;
  width: 100%;
  height: 80%;
  background-image: -ms-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -moz-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);
  background-image: -webkit-linear-gradient(120deg, #3b26db 1%, #7b19cb 100%);

  z-index: -1;
  animation: colorFlow 5s infinite linear;
}

.error-wrapper__content {
  width: 100%;
  text-align: center;
  margin-top: 50px;
}

.error-wrapper__content .cmn-btn {
  margin-left: auto;
  margin-right: auto;
  width: fit-content;
  margin-top: 20px;
}

.error-wrapper__content .cmn-btn i {
  margin-left: 6px;
  font-size: 32px;
}

/* sidebar css start */

.sidebar .widget + .widget {
  margin-top: 40px;
}

.sidebar .widget {
  padding: 30px 30px;
  background-color: #683df5;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.sidebar .widget__title {
  text-transform: capitalize;
  margin-bottom: 30px;
}

.sidebar .sidebar-search input {
  border: none;
  border-bottom: 1px solid #8380f7;
  padding-left: 0;
  padding-top: 0;
  font-size: 14px;
  color: #ffffff;
}

.sidebar .sidebar-search input::-webkit-input-placeholder {
  color: #9face8;
}

.sidebar .sidebar-search input::-moz-placeholder {
  color: #9face8;
}

.sidebar .sidebar-search input:-ms-input-placeholder {
  color: #9face8;
}

.sidebar .sidebar-search input:-moz-placeholder {
  color: #9face8;
}

.sidebar .sidebar-search button {
  margin-top: 30px;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  text-transform: uppercase;
  color: #ffffff;
  font-size: 16px;
  padding: 10px 40px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
}

.sidebar .sidebar-search button i {
  margin-right: 5px;
  color: rgba(255, 255, 255, 0.7);
}

.sidebar .social-link-list {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -7px;
}

.sidebar .social-link-list li {
  margin: 5px 7px;
}

.sidebar .social-link-list li a {
  width: 40px;
  height: 40px;
  border: 1px solid #7b98e0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.sidebar .social-link-list li a:hover {
  border: none;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

.sidebar .category-list li:first-child a {
  padding-top: 0;
}

.sidebar .category-list li:last-child a {
  padding-bottom: 0;
  border-bottom: none;
}

.sidebar .category-list li a {
  display: block;
  color: #e9e9e9;
  padding: 10px 0;
  border-bottom: 1px dashed #213a68;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar .category-list li a:hover {
  color: #ffffff;
  padding-left: 10px;
}

.sidebar .category-list li a:hover span {
  color: #ffffff;
}

.sidebar .category-list li a span {
  float: right;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar .tags {
  margin: -5px -4px;
}

.sidebar .tags a {
  margin: 5px 4px;
  font-size: 14px;
  color: #e9e9e9;
  padding: 11px 30px;
  border: 1px solid rgba(234, 234, 234, 0.1);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.sidebar .tags a:hover {
  border-color: #fb6468;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
}

.small-post__thumb img {
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  height: 200px;
  width: 100%;
  object-fit: cover;
  -o-object-fit: cover;
  object-position: center;
  -o-object-position: center;
}

.small-post__content {
  margin-top: 30px;
}

.small-post__title {
  margin-bottom: 10px;
}

.small-post-slider .slick-arrow {
  position: absolute;
  top: -57px;
  right: 0;
  width: 20px;
  height: 20px;
  box-shadow: 0px 2px 5px 0px rgba(0, 18, 50, 0.2);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-image: -ms-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -moz-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
  background-image: -webkit-linear-gradient(
    86deg,
    #ec038b 0%,
    #fb6468 44%,
    #fbb936 100%
  );
}

.small-post-slider .slick-arrow.slick-disabled {
  background-color: #7b6fd6;
  background-image: none;
}

.small-post-slider .slick-arrow.prev {
  right: 30px;
}

/* footer section css start */
.footer-section {
  position: relative;
  /* background-color: #072b3f; */
}
.footer-section .bg-shape--top {
  display: none;
}

.footer-section hr {
  margin: 0;
  border-color: rgba(104, 111, 197, 0.4) !important;
}

.bg-shape--top {
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  margin-top: -3px;
  width: 100%;
}
.bg-shape--top img {
  width: 100%;
}
.subscribe-area {
  padding: 50px 50px;
  /* background-image: -ms-linear-gradient(135deg, #031b28 0%, #083248 100%);
  background-image: -moz-linear-gradient(135deg, #031b28 0%, #083248 100%);
  background-image: -webkit-linear-gradient(135deg, #031b28 0%, #083248 100%); */
  background-color: #041f2d;
  box-shadow: 0px 19px 56px 0px rgba(0, 0, 0, 0.2);
  border-radius: 30px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
}

.subscribe-area .left {
  width: 50%;
}

.subscribe-area .left .subtitle {
  font-size: 24px;
  font-weight: 600;
  color: #ffb200;
  margin-bottom: 10px;
}

.subscribe-area .left .title {
  color: #ffffff;
  font-size: 32px;
}

.subscribe-area .right {
  width: 50%;
}

.subscribe-area .subscribe-form {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -45px;
}

.subscribe-area .subscribe-form input {
  background-color: rgba(255, 255, 255, 0.102);
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  width: calc(100% - 160px);
  min-height: 60px;
  padding-left: 25px;
  padding-right: 40px;
}

.subscribe-area .subscribe-form button {
  background-image: -webkit-linear-gradient(86deg, #af6f12 0%, #fbb936 100%);
  background-image: -moz-linear-gradient(86deg, #af6f12 0%, #fbb936 100%);
  background-image: -webkit-linear-gradient(86deg, #af6f12 0%, #fbb936 100%);

  box-shadow: 0px 17px 40px 0px rgba(124, 78, 25, 0.35);
  color: #ffffff;
  width: 160px;
  border-radius: 999px;
  -webkit-border-radius: 999px;
  -moz-border-radius: 999px;
  -ms-border-radius: 999px;
  -o-border-radius: 999px;
  margin-left: -45px;
}

.social-links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px;
}

.social-links li {
  margin: 5px;
}

.social-links li a,
.social-links li span {
  width: 40px;
  height: 40px;
  background-color: #0a1e5e00;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  color: #ffffff;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  -webkit-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.social-links li a.tiktok {
  line-height: 35px;
}
.social-links li.mob-view-url {
  display: none;
}

.social-links li a:hover,
.social-links li span:hover {
  background-color: #ffb200;
  /* box-shadow: 0px 5px 9px 0px rgba(238, 71, 48, 0.54); */
}

.app-btn {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: -5px -7px;
}

.app-btn li {
  margin: 5px 7px;
}

.short-links {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: -5px;
}

.short-links li:first-child a {
  padding-left: 0;
}

.short-links li:first-child a::before {
  display: none;
}

.short-links li:last-child a {
  padding-right: 0;
}

.short-links li a {
  padding: 5px 18px;
  color: #d2d5fc;
  position: relative;
  font-size: 16px;
}

.short-links li a::before {
  position: absolute;
  content: "";
  top: 50%;
  left: 0;
  width: 3px;
  height: 18px;
  background-color: #686fc5;
  margin-top: -9px;
}

/*** new home css *******/
.play-card.bg_img {
  background-image: -webkit-linear-gradient(28deg, #e89c31 0%, #ab6b10 100%);
  border: 1px solid #083248;
  /* box-shadow: 0px 0px 27px 0px #032435; */
  /* background: url("../../assets/images/elements/card-bg-1.jpg"); */
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
}

/* Verify Message */

.verify-email {
  display: flex;
  align-items: center;
  min-height: 100vh;
}
.verify-email .now-login-btn {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.verify-message-inner {
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  text-align: center;
}

.user-profile-section .share-buttons {
  text-align: left;
  margin-top: 10px;
  margin-right: 10px;
}
.user-profile-section .email-share {
  margin-left: 15px;
}
.user-profile-section .fb-share {
  margin-left: 10px;
}
.user-profile-section .social-txt {
  margin-left: 5px;
}
button.react-share__ShareButton.email-share {
  margin-left: 15px;
  margin-bottom: 10px;
}

/************ style for mystery boxes ***********/
.unboxing-page .unboxing_header {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-top: 0px solid #052434;
  border-left: 0px solid #052434;
  border-right: 0px solid #052434;
}

.unboxing-page .contest-card__footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.unboxing-page .contest-card__thumb {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #041d2a;
}
.unboxing-page .contest-card {
  /* background-color: unset; */
  background: linear-gradient(
    310deg,
    #191919,
    #1b1c25  0,
    #1b1c25  0,
    #1b1c25  100%,
    #191919 0
  ) !important;
  border-radius: 10px;
  position: relative;
  background-image: unset;
  border: 1px solid #292b39 !important;
}

.home-page .contest-card__footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.home-page .contest-card__thumb {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #041d2a;
}
.home-page .contest-card {
  /* background-color: unset; */
  background: linear-gradient(
    310deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(5, 31, 46, 1) 0%,
    rgba(5, 31, 46, 1) 0%,
    rgba(7, 44, 73, 1) 100%,
    rgba(7, 44, 73, 1) 100%
  ) !important;
  border-radius: 10px;
  position: relative;
  /* background-image: url(../../assets/images/bg/boxcard.svg); */
  background-image: unset;
  background-repeat: no-repeat;
  border: 1px solid #052434;
}

.home-page .contest-card .action-icon i.far.fa-heart {
  position: relative;
  right: 0;
  top: 3px;
}

.user-profile-section .contest-card__footer {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.user-profile-section .contest-card__thumb {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #041d2a;
}
.user-profile-section .contest-card {
  background-color: unset;
  border-radius: 10px;
  position: relative;
  background-image: unset;
  /* border: 1px solid #0b3c56; */
  border: 1px solid #052434;
}
/************ style for mystery boxes ***********/

/************ style for game popup ***********/
.modal_contents .pop_btn {
  padding: 10px 30px;
}
.modal_contents .pop_btns {
  margin-right: 30px;
  background: transparent;
  /* outline: 2px solid #e89c31; */
  padding: 9px 25px;
}
.modal_contents .pop_btns:hover {
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  outline: 2px solid #e89c31;
}
.modal_contents .pop_head {
  margin-bottom: 30px;
}
.modal_contents .popup__buttons {
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
}
.modal_contents .popup__buttons button {
  margin: 0px 10px;
  min-width: 120px;
}
.modal_contents .bor_btn:hover {
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
}
/************ style for game popup ***********/

/************ style for user profile ***********/
.user-profile-section .user-info-card__list li input {
  margin-right: 5px;
  border: 1px solid #292b39;
}
.user-profile-section .shipping-card-body input:disabled,
.shipping-card-body select:disabled {
  border: 1px solid #292b39 !important;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.user-profile-section .shipping-card-body select {
  margin-bottom: 10px;
  background: #22232d;
}
.user-profile-section .user-info-card__header button {
  background-color: transparent;
  color: #ffffff;
}
.user-profile-section .all-transaction__header .date-range input {
  border-color: #ffffff;
}
.user-profile-section .all-transaction table th {
  border-bottom: 1px solid #292b39;
}
.user-profile-section .table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #292b39 !important;
}
.user-profile-section .all-transaction table tbody tr + tr td {
  border-top: 1px solid #1c4860;
}
.user-profile-section .all-transaction .load-more {
  /* border-top: 2px solid #1c4860; */
  border: none;
}
.user-profile-section .all-transaction .load-more button {
  color: #ffffff;
}
.user-profile-section .referral-transaction table tbody tr + tr td {
  border-top: 1px solid #1c4860;
}
.user-profile-section .referral-transaction table th {
  border-bottom: 1px solid #292b39;
}
.user-profile-section .referral-transaction .load-more {
  border-top: 2px solid #1c4860;
}
.user-profile-section .referral-transaction .load-more button {
  background-color: transparent;
  color: #ffffff;
}
.user-profile-section .all-transaction input[type="date"]:before {
  background: none;
  display: block !important;
  font-family: "FontAwesome";
  content: "\f073";
  width: 15px;
  height: 20px;
  position: absolute;
  top: 11px;
  right: 29px;
  font-size: 17px;
  color: #ffffff;
  cursor: pointer;
}
.user-profile-section .all-transaction__header .date-range i {
  position: absolute;
  top: 13px;
  right: 23px;
  color: #ffffff;
  font-size: 25px;
}
.all-transaction__header .date-range input::placeholder {
  color: #ffffff;
}
.user-profile-section .referral-transaction table th:last-child {
  text-align: center;
}
/************ style for user profile ***********/

/************ style for mysteryboxes tab scrollbar ***********/
.unboxing-page .filter-tablist::-webkit-scrollbar {
  width: 3px;
  height: 8px;
}
.unboxing-page .filter-tablist::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: none;
  background-color: #073148;
}

/************ style for responsive referral link  ***********/
.referral-link-wrapper .label_link {
  margin-left: 20px;
}
.referral-link-wrapper .copy-link-inner input[type="text"] {
  padding-right: 120px;
  padding-left: 70px;
  height: 60px;
  color: #e89c31;
  font-size: 16px;
  background-color: #20212be6;
}

/************ Style for live drops boxes  ***********/
.last-live-drops-section .price-name {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 0;
}
.last-live-drops-section span.live_price {
  font-size: 12px;
  font-weight: 600;
  color: #e79933;
}
.last-live-drops-section span.user_name {
  font-size: 12px;
  font-weight: 400;
}
.last-live-drops-section .recent-item {
  padding-bottom: 5px;
  height: 100%;
}
.last-live-drops-section .recent-item-content p {
  color: #f1f1f1;
  font-size: 11px;
  text-transform: capitalize;
}

/************ Style for my profile inventory tab page **********/
.inventory__page {
  margin-top: 0;
}
.inventory__page .box__img {
  width: 90px;
  margin: 0 auto;
}
.inventory__page .total_price {
  color: red;
}
.inventory__page span.quantity {
  font-size: 16px !important;
  color: #ffffff;
}

/************ Style for my profile past game tab **********/
.past_game .profit {
  color: #ffa01f;
}

.past_game .loss {
  color: #ffa01f;
}

.past_game tr {
  /* background: #041f2d; */
  background: #2527335e;
}

.past_game .cmn-btn {
  padding: 10px 20px;
  font-size: 16px;
}

.past_game table th:last-child {
  text-align: center;
}

.past_game .table-responsive-xl {
  overflow: hidden;
  overflow-x: scroll;
  /* overflow-x: auto; */
}

.past_game .table-responsive-xl::-webkit-scrollbar {
  background-color: #1B1C25  ;
  border-radius: 16px;
  width: 16px;
}
.past_game .table-responsive-xl::-webkit-scrollbar-track {
  background-color: #1B1C25  ;
  border-radius: 16px;
}
.past_game .table-responsive-xl::-webkit-scrollbar-track:hover {
  background-color: #1B1C25a3;
}
.past_game .table-responsive-xl::-webkit-scrollbar-thumb {
  background-color: #000000 ;
  border-radius: 16px;
  border: 5px solid #22232d;
}
.past_game .table-responsive-xl::-webkit-scrollbar-thumb:hover {
  background-color: #073148;
}
.past_game .table-responsive-xl::-webkit-scrollbar-button {
  display: none;
}

/************ Style for add to cart popup ***********/
.header__top .right .product__cart {
  position: relative;
}
/************ Style for add to cart popup ***********/

img {
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.last-live-drops-section button.slick-arrow.slick-prev {
  display: none !important; /*** remove slick pre button ***/
}
.contest-description .chances-slider span.caption {
  font-size: 15px;
}
.contest-description .chances-slider .chances {
  margin-left: -15px;
}
.contest-description .chances-slider .rang-slider {
  margin-left: 20px;
  margin-top: -25px;
}
.contest-description .chances-slider .range {
  margin-top: -15px;
}
.contest-description .chances-slider span.price {
  color: #e89b32;
  font-size: 15px;
  font-weight: 600;
}
.contest-description .chances-slider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.confirmation-popup .modal-lg {
  max-width: 500px;
}
.confirmation-popup button.close-btn {
  background: transparent !important;
  top: 10px;
  right: 10px;
}
.confirmation-popup button.close-btn img {
  height: 20px;
}
.confirmation-popup .account-form-area {
  padding: 10px;
}
.home-our-features-content {
  padding-top: 80px;
}
@media (max-width: 1440px) {
  .bg-welcome {
    top: 25%;
    left: 2%;
    width: 42%;
  }
  .update-home-page .hero.style--two .hero-box-main {
    right: 0%;
  }
}

@media (max-width: 1199px) {
  .login-form-responce{
    display: flex;
    flex-wrap: wrap;
  }
  .left-nav-bottm .product__cart {
    width: 100%;
  }
  .left-nav-bottm .product__cart a {
    width: 100%;
  }
  .left-nav-bottm .product__cart a .total__amount,
  .left-nav-bottm .product__cart a .wallet-btn {
    width: 50%;
  }
  .curve-shape::after {
    display: none;
  }
  .slice-shape {
    clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
  }
  .section-title {
    font-size: 56px;
  }
  .header.menu-fixed .header__bottom .main-menu li a {
    padding: 10px 0;
  }
  .header__top {
    /* padding: 8px 0; */
  }

  .header__top .left a {
    font-size: 14px;
  }
  .header__top .left .language i {
    font-size: 15px;
  }
  .header__top .left .language .nice-select .current {
    font-size: 14px;
  }
  .header__top .right .total__amount {
    padding: 4px 10px;
    font-size: 14px;
  }
  .header__top .right .amount__btn i {
    font-size: 24px;
  }
  .header__top .right .user__btn {
    width: 30px;
    height: 30px;
    line-height: 36px;
  }
  .header__top .right .user__btn i {
    font-size: 20px;
  }
  .header__bottom {
    padding: 10px 0;
  }
  .header .site-logo img {
    max-width: 100px;
  }
  .header .main-menu {
    padding: 15px 0;
  }
  .header .main-menu li.menu_has_children > a {
    display: block;
  }
  .header .main-menu li.menu_has_children > a::before {
    display: block;
    top: 9px;
  }
  .header .main-menu li a {
    /* padding: 8px 0; */
    display: flex;
    align-items: center;
    gap: 7px;
    /* border-bottom: 1px solid rgba(255, 255, 255, 0.1); */
  }
  .header .main-menu li .sub-menu {
    opacity: 1;
    visibility: visible;
    display: none;
    position: static;
    -webkit-transition: none;
    -o-transition: none;
    transition: none;
    width: 100%;
  }
  .header .main-menu li .sub-menu li a {
    font-size: 16px;
  }
  .header .main-menu li + li {
    margin-left: 0;
  }
  .header .nav-right {
    margin-left: 0;
    flex-wrap: wrap;
  }
  .header .nav-right .cmn-btn {
    display: inline-block;
    margin-bottom: 15px;
  }
  .navbar-collapse {
    padding: 0 20px;
    background-color: #070b28;
    /* max-height: 320px; */
    max-height: 550px;
    /* overflow: auto; */
    /* overflow-y: auto; */
    /* border: 1px solid red; */
  }

  .menu-toggle {
    color: #ffffff;
  }
  .menu-toggle:before,
  .menu-toggle:after {
    background-color: #ffffff;
  }
  span.menu-toggle:hover {
    color: #ffffff;
  }
  .hero {
    padding-top: 285px;
    padding-bottom: 215px;
  }
  .hero__content p {
    font-size: 18px;
  }
  .hero__subtitle {
    font-size: 22px;
  }
  .hero__title {
    font-size: 90px;
  }
  .hero__car img {
    max-width: 40%;
    left: 78%;
  }
  .hero.style--two .hero__title {
    font-size: 62px;
  }
  .hero.style--two .hero__content p {
    font-size: 20px;
  }
  .hero.style--two .hero-car {
    width: 715px;
    height: 425px;
    right: -18%;
    top: 20%;
  }
  .hero.style--two .hero-car div[class*="el"] img {
    width: 60%;
  }
  .hero.style--two .hero-car .el-girl {
    right: 22%;
  }
  .hero.style--two .hero-car .el-girl img {
    width: 55% !important;
  }
  .hero.style--two .hero-car-main img {
    width: 100%;
  }
  .hero.style--three .hero__bike img {
    width: 50%;
  }
  .hero.style--three .hero__car-right img {
    width: 45%;
  }
  .hero.style--three .hero__car-left img {
    width: 50%;
  }
  .inner-hero-section .inner-page-content .title {
    font-size: 56px;
  }
  .about-wrapper__title {
    font-size: 56px;
  }
  .next-draw-area .left {
    width: 32%;
  }
  .next-draw-area {
    padding: 30px;
  }
  .next-draw-area .middle {
    width: 45%;
  }
  .next-draw-area .right {
    width: 20%;
  }
  .play-elements img {
    width: 60%;
  }
  .contest-wrapper__title {
    font-size: 56px;
  }
  .winner-wrapper .left {
    width: 65%;
  }
  .winner-wrapper .left .winner-prize-thumb {
    margin-bottom: 0;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  .winner-details-wrapper .left {
    width: 20%;
  }
  .winner-details-wrapper .body {
    width: 60%;
  }
  .check-number-form button.cmn-btn {
    padding: 12px 30px;
  }
  .winner-details-wrapper .right {
    width: 20%;
  }
  .winner-card__content .content-bottom .number-list {
    padding-right: 0;
  }
  .overview-section .map-el {
    bottom: 16%;
  }
  .map-pointer .pointer.num-2 {
    top: 55px;
  }
  .feature-car {
    display: none;
  }
  .support-card__thumb {
    margin-left: auto;
    margin-right: auto;
  }
  .support-card__content {
    width: 100%;
    padding-left: 0;
    margin-top: 25px;
    text-align: center;
  }
  .counter-item__icon {
    width: 65px;
  }
  .counter-item__content {
    width: calc(100% - 65px);
  }
  .lottery-wrapper .action-header .left {
    width: 41%;
  }
  .lottery-wrapper .action-header .right {
    width: 59%;
  }
  .cart-wrapper__title {
    font-size: 56px;
  }
  .counter-item__content span {
    font-size: 42px;
  }
  .buy-ticket-section {
    padding-top: 110px;
  }
  .winner-stat-wrapper {
    margin-top: 110px;
  }
  .blog-card__content {
    margin-left: 30px;
    margin-right: 30px;
    padding: 30px;
  }
  .blog-card__title {
    font-size: 32px;
  }
  .blog-single__title {
    font-size: 42px;
  }
  .blog-single__body .title {
    font-size: 36px;
  }
  .draw-single-ticket .ticket-numbers-list {
    justify-content: flex-start;
  }
  .past-draw-wrapper table td .number-list {
    justify-content: flex-start;
  }
  .referral-crad__icon {
    width: 100%;
    text-align: center;
  }
  .referral-crad__content {
    width: 100%;
    text-align: center;
    padding-left: 0;
    margin-top: 15px;
  }
}

@media screen and (max-width: 1024px) {
  .update-home-page .hero.style--two .hero-box-main {
    right: 5%;
    top: -18%;
  }
  .bg-welcome {
    top: 22%;
    left: 3%;
    width: 45%;
  }
  .home-our-support .support-card {
    min-height: 350px;
    padding: 15px;
    padding-bottom: 0;
  }
  .home-our-support .support-card__thumb {
    width: 100px;
    height: 100px;
  }
  .home-our-support .support-card__title {
    font-size: 20px;
    margin-bottom: 5px;
  }
  .home-our-support .support-card__content p {
    font-size: 16px;
  }
  /* .update-home-page .hero.style--two {
    padding-top: 100px;
    padding-bottom: 100px;
  } */
  .bg-seprator:before {
    left: 5%;
  }
  .bg-seprator:after {
    right: 5%;
  }
  .update-home-page .hero.style--two .hero__title,
  .update-home-page .hero.style--two .hero__title span {
    font-size: 60px;
  }
  .update-home-page .hero.style--two .hero__title img {
    max-width: 60px;
  }
  .mystery-box-header .section-title {
    font-size: 48px;
  }
  .home-our-support {
    padding: 40px 0px;
  }
  .home-our-features-section .feature-card {
    padding: 15px;
  }
  .home-our-features-section .feature-card__icon {
    margin-bottom: 15px;
  }
  .home-our-features-section .feature-title {
    font-size: 18px;
  }
  .home-our-features-section .feature-card__content p {
    font-size: 16px;
    line-height: 1.5;
  }
}
@media screen and (max-width: 991px) {
  h2 {
    font-size: 36px;
  }
  .pb-150 {
    padding-bottom: 90px;
  }
  .pt-150 {
    padding-top: 90px;
  }
  .pb-120 {
    padding-bottom: 50px;
  }
  .pt-120 {
    padding-top: 90px;
  }
  .cmn-btn {
    font-size: 16px;
  }
  .hero {
    padding-top: 240px;
    padding-bottom: 450px;
  }
  .hero__content {
    text-align: center;
  }
  .hero__subtitle {
    font-size: 20px;
  }
  .hero__title {
    font-size: 72px;
  }
  .hero__car {
    height: 475px;
    top: auto;
    bottom: 0;
  }
  .hero__car img {
    left: 65%;
  }
  .hero.style--two .hero-car {
    display: none;
  }
  .hero.style--two .hero-car-main {
    display: none;
  }
  .hero.style--three {
    padding-bottom: 200px;
  }
  .hero.style--three .hero__bike {
    display: none;
  }
  .hero.style--three .hero__car-left {
    display: none;
  }
  .hero.style--three .hero__car-right {
    display: none;
  }
  .inner-hero-section .inner-page-content .title {
    font-size: 48px;
  }
  .about-wrapper {
    padding-bottom: 150px;
  }
  .about-wrapper__title {
    font-size: 48px;
  }
  .next-draw-area .left {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }
  .next-draw-area .middle {
    width: 60%;
  }
  .next-draw-area .right {
    width: 30%;
    text-align: center;
  }
  .contest-wrapper__title {
    font-size: 48px;
  }
  .winner-wrapper .right {
    width: 30%;
  }
  .winner-details-wrapper .left {
    display: none;
  }
  .winner-details-wrapper .body {
    width: 100%;
  }
  .winner-details-wrapper .right {
    display: none;
  }
  .check-number-form .number-list-wrapper .number-list {
    justify-content: flex-start;
  }
  .check-number-form .number-list-wrapper .number-list input {
    margin-right: 15px;
  }
  .winner-tab-nav li a span {
    font-size: 16px;
  }
  .overview-section .map-el {
    bottom: 37%;
  }
  .counter-wrapper {
    border-radius: 25px;
    -webkit-border-radius: 25px;
    -moz-border-radius: 25px;
    -ms-border-radius: 25px;
    -o-border-radius: 25px;
  }
  .contest-cart__left {
    width: 100%;
    padding-right: 0;
  }
  .contest-cart__right {
    width: 100%;
    padding-left: 0;
  }
  .lottery-wrapper {
    padding: 90px 30px 50px 30px;
  }
  .lottery-wrapper__title {
    font-size: 48px;
  }
  .lottery-wrapper .action-header {
    padding: 30px;
  }
  .lottery-wrapper .action-header .left {
    width: 100%;
  }
  .lottery-wrapper .action-header .right {
    width: 100%;
    margin-top: 15px;
  }
  .lottery-wrapper .action-header .right ul {
    justify-content: flex-start;
  }
  .buy-lottery-ticket .left {
    width: 60%;
  }
  .buy-lottery-ticket .right {
    width: 40%;
  }
  .pick-lottery-package .title {
    font-size: 48px;
  }
  .cart-wrapper {
    padding-top: 90px;
  }
  .cart-wrapper__title {
    font-size: 48px;
  }
  .affiliate-single-wrapper {
    padding-left: 50px;
    padding-right: 50px;
  }
  .affiliate-single-wrapper__obj img {
    width: 70%;
  }
  .video-wrapper {
    min-height: 400px;
  }
  .winner-obj {
    display: none;
  }
  .faq-el {
    display: none;
  }
  .blog-card__title {
    font-size: 28px;
  }
  .blog-single__title {
    font-size: 36px;
  }
  .blog-single__body .title {
    font-size: 32px;
  }
  .contact-wrapper {
    padding: 50px 30px 0 30px;
  }
  .contact-form-wrapper {
    padding: 0;
  }
  .contact-info-wrapper {
    padding-top: 100px;
  }
  .contact-thumb {
    margin-top: 50px;
  }
  .user-info-card__list li .caption {
    width: 25%;
  }
  .user-info-card__list li .value {
    width: 60%;
  }
  .sidebar {
    margin-top: 50px;
  }
  .all-transaction table th:first-child {
    text-align: center;
  }
  .subscribe-area .left {
    width: 100%;
    text-align: center;
  }
  .subscribe-area .right {
    width: 100%;
    margin-top: 30px;
  }
  .app-btn {
    justify-content: center;
    margin-bottom: 20px;
  }

  .section-header p {
    padding-bottom: 50px;
    padding-right: 20px;
    padding-left: 20px;
  }
  .section-header {
    margin-bottom: 60px;
    padding-top: 30px;
  }
  .slice-shape {
    clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
  }
  .contest-cart__right .contest-name {
    font-size: 30px;
    margin-bottom: 30px;
  }
  .social-links {
    justify-content: center;
  }
  .contest-card__thumb img {
    max-height: 180px;
  }
  .contest-card__thumb .contest-num {
    bottom: -50px;
    width: 75px;
    height: 75px;
  }
  .contest-card__content {
    padding: 20px 20px 10px;
  }
  .contest-card__price {
    font-size: 24px;
  }
  .icon-item2__icon img {
    width: 80px;
  }
  .icon-item2__content p {
    font-size: 18px;
    line-height: 1.5;
  }
  .section-title {
    font-size: 48px;
  }
  .update-home-page .hero.style--two {
    padding-top: 80px;
    padding-bottom: 40px;
  }
  .update-home-page .hero.style--two .hero__title {
    max-width: 400px;
    margin: 0px auto;
    font-size: 40px;
  }
  .update-home-page .hero.style--two .hero__title span {
    font-size: 40px;
  }
  .update-home-page .hero.style--two .hero__title img {
    margin-top: -20px;
  }
  .hero__btn .btn-style {
    height: 50px !important;
    max-width: 200px;
  }
  .hero__btn .btn-style span {
    font-size: 25px;
  }
  .hero__btn img {
    max-width: 35px;
    margin-top: -10px;
  }
  .bg-welcome {
    top: 25%;
    left: 4%;
    width: 44%;
  }
  .hero__btn {
    margin-top: 35px;
    display: flex;
    justify-content: center;
  }
  .update-home-page .hero.style--two .hero-box-main {
    right: 0;
    text-align: center;
    position: inherit;
    top: auto;
  }
  .update-home-page .hero.style--two .hero-box-main img {
    max-width: 450px;
    width: 100%;
  }
  .counter-grid .counter-item__content span {
    font-size: 30px;
  }
  .how-to-play-sec {
    padding-top: 0px !important;
    text-align: center;
  }
  .how-to-play-sec .play-card.bg_img {
    margin-bottom: 20px;
  }
  .bg-seprator {
    padding-top: 10px;
  }
  .bg-seprator:before,
  .bg-seprator:after {
    width: 30%;
  }
  .bg-seprator img {
    max-width: 130px;
    height: 130px;
  }
  .mystery-box-header {
    margin-top: 30px;
    padding: 0px !important;
    margin-bottom: 30px !important;
  }
  .mystery-box-header p {
    margin-top: 30px;
  }
  .home-our-features-content {
    padding-top: 80px !important;
  }
  .home-our-features-section .feature-card {
    margin-bottom: 20px;
    padding: 15px;
    min-height: 290px;
  }
  .home-our-features-section .feature-card__icon {
    margin-bottom: 15px;
  }
  .home-our-features-section .feature-title {
    font-size: 18px;
  }
  .update-counter-section {
    padding-bottom: 40px;
    padding-top: 0px;
  }
  .home-our-feature p {
    padding-bottom: 0;
    margin: 0;
  }
  .transaction-balance-wrapper .right {
    justify-content: space-evenly;
  }
  .contest-details-content .contest-description {
    padding-top: 0;
  }
}

@media (max-width: 767px) {
  .social-links li.mob-view-url {
    display: block;
  }
  .social-links li.desk-view-url {
    display: none;
  }
  h3 {
    font-size: 22px;
  }
  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 18px;
  }

  .section-title,
  .home-our-feature .section-title {
    font-size: 36px;
  }
  .cmn-accordion .card-header .btn-link {
    font-size: 18px;
  }
  .cmn-btn {
    font-size: 14px;
  }

  .hero {
    padding-bottom: 385px;
  }
  .hero__title {
    font-size: 56px;
  }
  .hero__car {
    height: 345px;
  }
  .hero__car img {
    max-width: 30%;
    left: 70%;
  }
  .hero.style--three {
    padding-bottom: 150px;
  }
  .hero.style--three {
    padding-bottom: 30px;
  }
  .inner-hero-section .inner-page-content .title {
    font-size: 36px;
  }
  .about-wrapper {
    padding: 30px 30px 120px 30px;
  }
  .about-wrapper__title {
    font-size: 36px;
  }
  .next-draw-area .middle {
    width: 100%;
  }
  .next-draw-area .right {
    width: 100%;
    margin-top: 15px;
  }
  .play-elements img {
    width: 45%;
  }
  .contest-wrapper__title {
    font-size: 42px;
  }

  .game-page .contest-card__thumb img {
    height: unset !important;
    max-height: 135px !important;
  }
  .winner-wrapper .left {
    width: 60%;
  }
  .winner-wrapper .right {
    width: 35%;
  }
  .winner-details-wrapper .body .numbers {
    justify-content: center;
  }
  .winner-details-wrapper .body .numbers li {
    font-size: 32px;
    width: auto;
    margin: 3px;
  }
  .winner-card__thumb {
    width: 100%;
    justify-content: center;
  }
  .winner-card__content {
    width: 100%;
  }
  .winner-card__content .winner-thumb {
    top: 0;
    left: 50%;
    margin-left: -43px;
  }
  .winner-card__content .content-top {
    padding: 65px 20px 20px 20px;
  }
  .winner-card__content .content-top .left {
    width: 100%;
    text-align: center;
    padding-right: 0;
  }
  .winner-card__content .content-top .right {
    width: 100%;
    text-align: center;
    margin-top: 15px;
  }
  .winner-card__content .content-bottom {
    text-align: center;
    padding: 20px;
  }
  .winner-card__content .content-bottom .number-list-wrapper {
    width: 100%;
  }
  .winner-card__content .content-bottom .number-list {
    justify-content: center;
  }
  .winner-card__content .content-bottom .number-list li,
  .winner-card__content .content-bottom .number-list input {
    margin-right: 15px;
  }
  .winner-card__content .content-bottom .right {
    width: 100%;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
  }
  .winner-card__content .content-bottom .right .contest-num {
    margin-left: 15px;
  }
  .overview-section .map-el {
    bottom: 49%;
  }
  .overview-card {
    text-align: center;
  }
  .overview-card__icon {
    margin-left: auto;
    margin-right: auto;
  }
  .overview-card__content {
    width: 100%;
    padding-left: 0;
    margin-top: 20px;
  }
  .testimonial-single__content {
    padding: 0;
  }
  .counter-section {
    margin-top: -185px;
  }
  .counter-item__icon {
    width: 100%;
    text-align: center;
  }
  .counter-item__content {
    width: 100%;
    text-align: center;
    padding-left: 0;
    margin-top: 15px;
  }
  .contest-cart__right .select-quantity {
    width: 58%;
  }
  .contest-details__thumb-slider {
    min-height: 440px;
  }
  .contest-details-content .contest-name {
    font-size: 32px;
  }
  .lottery-wrapper__title {
    font-size: 36px;
  }
  .buy-lottery-ticket .left {
    width: 100%;
  }
  .buy-lottery-ticket .right {
    width: 100%;
    margin-top: 30px;
    text-align: center;
  }
  .pick-lottery-package .title {
    font-size: 36px;
  }
  .cart-wrapper__title {
    font-size: 36px;
    margin-bottom: 10x;
  }
  .blog-card.style--two .blog-card__thumb {
    width: 100%;
  }
  .blog-card.style--two .blog-card__content {
    width: 100%;
  }
  .blog-single__meta .right {
    margin-top: 15px;
  }
  .user-info-card__list li .caption {
    width: 30%;
  }
  .transaction-balance-wrapper {
    padding: 20px 30px;
    flex-direction: column;
  }
  .transaction-balance-wrapper .left {
    width: 100%;
    text-align: center;
  }
  .transaction-balance-wrapper .right {
    width: 100%;
    justify-content: space-between;
    margin-top: 20px;
  }
  .all-transaction__header .date-range {
    margin-top: 20px;
  }
  .all-transaction__header .title {
    width: 100%;
    font-size: 24px;
  }
  .ar-profile-row .slick-prev {
    left: 10px;
  }
  .user-profile-section .email-share {
    margin-left: 15px;
    margin-bottom: 10px;
  }
  .user-profile-section .fb-share {
    margin-left: 15px !important;
  }
  .unboxing-page .unboxing_header {
    border-radius: 8px;
    border: 1px solid #052434;
  }
  .unboxing-page .unboxing__header {
    border-radius: 8px;
  }
  .user-profile-section .user-info-card__list li .value {
    width: 100%;
    padding-left: 0px;
  }
  .user-profile-section .user-info-card__list li .caption {
    text-align: left;
    width: 75%;
  }
  .user-profile-section .user-info-card__list li .caption {
    text-align: left;
    margin-left: 0px;
  }
  .user-profile-section .user-info-card__list input:disabled {
    border: 1px solid #014467 !important;
    background: #c7c8cb3b;
  }
  .user-info-card__list li .caption::after {
    background-blend-mode: unset;
    position: absolute;
    content: ":";
    right: unset;
    margin-left: 5px;
    top: -3%;
  }
  .referral-link-wrapper .label_link {
    margin-left: 15px;
  }
  .referral-link-wrapper .copy-link-inner input[type="text"] {
    padding-right: 91px;
    padding-left: 54px;
    height: 50px;
  }
  .referral-link-wrapper .clipboard-copy {
    position: absolute;
    top: 4px;
    right: 5px;
    width: auto;
    padding: 6px 20px;
  }
  .referral-link-wrapper .copy-link .copy-link-icon {
    position: absolute;
    top: 4px;
    left: 5px;
    width: 40px;
    height: 40px;
  }
  .referral-link-wrapper .copy-link {
    height: 50px;
  }
  .referral-link-wrapper .fb-share {
    margin-left: 7px !important;
  }
  .referral-link-wrapper .email-share {
    margin-left: 7px !important;
  }
  .section-header p {
    padding-bottom: 50px;
  }
  .section-sub-title {
    font-size: 18px;
  }
  .game-page .chances {
    display: flex;
    flex-direction: row;
    text-transform: capitalize;
    justify-content: flex-end;
  }
  .contest-description .chances-slider {
    flex-direction: column;
    width: 40%;
  }
  .contest-description .chances-slider .rang-slider {
    margin-top: 0px;
  }
  .contest-cart__left {
    width: 100%;
    padding-right: 0;
  }
  .contest-cart__right {
    width: 100%;
    padding-left: 0;
  }
  .short-links li a {
    padding: 5px 15px;
    font-size: 14px;
  }
  .icon-item2__icon img {
    width: 60px;
  }
  .contest-cart__right .contest-name {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .contest-cart__right .ticket-price .amount {
    font-size: 30px;
  }
  .contest-cart__right .quantity {
    height: 50px;
  }
  .product-details-page .contest-cart__right .quantity-nav .quantity-button {
    width: 30px;
    height: 30px;
  }
  .contest-card__thumb .contest-num {
    bottom: -45px;
    width: 65px;
    height: 65px;
  }
  .contest-card__thumb .contest-num .number {
    font-size: 16px;
  }
  .contest-card__thumb img {
    max-height: 175px;
  }
  .user-info-card__header button i {
    font-size: 20px;
  }
  h3.user-info-card__title {
    font-size: 18px;
    max-width: 200px;
    width: 100%;
  }
  .ar-profile-row .user-info-card__list {
    padding-bottom: 20px;
  }
  .user-info-card__list {
    margin-top: 20px;
  }
  .referral-link-wrapper .title {
    font-size: 24px;
    margin-bottom: 15px;
  }
  .referral-overview {
    padding: 15px;
  }
  .referral-crad__icon img {
    max-width: 45px;
    width: 100%;
  }
  .referral-crad__content {
    margin-top: 8px;
  }
  .bg-welcome {
    top: 16%;
    left: 20%;
    width: 56%;
  }
  .update-home-page .hero.style--two .hero-box-main {
    padding-top: 20px;
  }
  .update-home-page .hero.style--two .hero-box-main img {
    max-width: 330px;
  }
  .update-counter-section {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .counter-grid {
    gap: 15px;
  }
  .counter-box {
    height: 140px;
    padding: 0px 5px;
  }
  .counter-grid .counter-item__content span {
    font-size: 25px;
  }
  .counter-grid .counter-item__content p {
    font-size: 16px;
  }
  .counter-grid .counter-item__content {
    margin-top: 10px;
  }
  .counter-grid .counter-item__icon {
    padding: 0;
  }
  .counter-item {
    justify-content: center;
  }
  .counter-grid .counter-box:nth-child(2) {
    height: 160px;
  }
  .how-to-play-sec .section-header {
    margin-bottom: 30px;
    padding-top: 0;
  }
  .how-to-play-sec .section-title,
  .mystery-box-header .section-title {
    font-size: 30px;
  }
  .bg-seprator {
    padding: 0;
  }
  .bg-seprator img {
    max-width: 100px;
    height: 100px;
  }
  .mystery-box-header {
    margin-bottom: 0 !important;
  }
  .mystery-box-header p {
    margin-top: 15px;
    padding-bottom: 30px;
  }
  .home-our-features-content {
    padding-top: 20px !important;
  }
  .home-our-feature p {
    padding: 0;
  }
  .home-our-support .section-header span {
    font-size: 30px;
  }
  .home-our-support .section-header {
    margin-bottom: 30px;
    padding-top: 20px;
  }
  .home-our-support .support-card {
    min-height: unset;
    padding: 15px;
    max-width: 325px;
    margin: 0px auto;
    border-radius: 10px;
  }
  .home-our-support .support-card .support-card__thumb {
    width: 70px;
    height: 80px;
  }
  .home-our-features-content .section-header {
    margin-bottom: 30px;
  }
  .how-to-play-sec .play-card {
    padding: 15px 25px;
  }
  .how-to-play-sec {
    padding-top: 20px !important;
  }
  .how-to-play-sec .play-card.bg_img {
    max-width: 325px;
    margin: 0px auto;
    margin-bottom: 20px;
    min-height: unset;
    padding: 15px;
  }
  .home-our-features-section .feature-card {
    max-width: 325px;
    margin: 0px auto;
    margin-bottom: 20px;
    padding: 15px;
    min-height: unset;
  }
  .home-our-features-section .mb-50 {
    margin-bottom: 20px;
  }
  .home-our-features-section .feature-card__content p {
    font-size: 14px;
  }
  .user-info-card + .user-info-card {
    margin-top: 20px;
  }
  .contest-details-content {
    padding: 0px 10px;
  }
  .home-our-support .support-card__title {
    font-size: 18px;
  }
  .home-our-support .support-card__content p {
    font-size: 14px;
    line-height: 1.5;
  }
  .footer-section .pt-120 {
    padding-top: 50px;
  }
  .footer-section .pb-5 {
    padding-bottom: 1rem !important;
  }
  .footer-section .py-5 {
    padding-top: 1rem !important;
  }
  .home-our-features-section .feature-card__icon {
    max-width: 60px;
    margin: 0px auto;
    margin-bottom: 15px;
  }
  .transaction-balance-wrapper .transaction-balance {
    padding: 15px;
  }
  .transaction-balance-wrapper .transaction-action-btn {
    padding: 10px 0;
    height: 100px;
  }
  .transaction-balance-wrapper img {
    width: 30px;
  }
}

@media (max-width: 575px) {
  h2 {
    font-size: 28px;
  }
  .pt-120 {
    padding-top: 80px;
  }
  .pb-120 {
    padding-bottom: 50px;
  }
  .pt-150 {
    padding-top: 80px;
  }
  .pb-150 {
    padding-bottom: 80px;
  }
  .section-title,
  .home-our-feature .section-title,
  .home-our-support .section-header h2,
  .home-our-support .section-header span {
    font-size: 24px;
  }
  .cmn-accordion .card-header .btn-link {
    font-size: 16px;
  }
  .cmn-accordion .card-body p {
    font-size: 16px;
  }
  .cmn-tabs li a {
    padding: 12px 25px;
    font-size: 16px;
  }
  .header__top .left {
    justify-content: center;
  }
  .header__top .right {
    justify-content: center;
  }
  .hero {
    padding-top: 250px;
  }
  .hero__content p {
    font-size: 16px;
  }
  .hero__subtitle {
    font-size: 16px;
  }
  .hero__title {
    font-size: 48px;
  }
  .hero__car {
    bottom: 3%;
  }
  .hero__car img {
    left: 80%;
  }
  .hero.style--two .hero__title {
    font-size: 48px;
  }
  .inner-hero-section .inner-page-content .title {
    font-size: 32px;
  }
  .page-list {
    padding-left: 15px;
  }
  .about-wrapper__header {
    width: 100%;
  }
  .about-wrapper__title {
    font-size: 32px;
  }
  .next-draw-area .left .title {
    font-size: 32px;
  }
  .play-elements {
    display: none;
  }
  .contest-card__content {
    padding-top: 65px;
  }
  .contest-card__content .left {
    padding-right: 0;
    width: 100%;
    text-align: center;
  }
  .contest-card__content .right {
    padding-left: 0;
    width: 100%;
    text-align: center;
  }
  .contest-wrapper__title {
    font-size: 36px;
  }
  .subscribe-area .subscribe-form button {
    margin-left: 0;
    min-height: 55px;
    width: 100%;
    margin-top: 15px;
  }
  .winner-wrapper {
    padding-left: 30px;
  }
  .winner-wrapper .left {
    display: none;
  }
  .winner-wrapper .right {
    width: 100%;
  }
  .winner-details-wrapper {
    padding: 30px 20px;
  }
  .winner-tab-nav {
    justify-content: center;
  }
  .winner-tab-nav li {
    width: auto;
  }
  .winner-tab-nav li a .icon-thumb {
    width: 50px;
    height: 50px;
    margin: auto;
  }
  .winner-tab-nav li a .icon-thumb::before {
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
  }
  .winner-tab-nav li a span {
    font-size: 14px;
  }
  .overview-section .map-el {
    bottom: 65%;
  }
  .testimonial-area {
    padding: 30px 30px;
  }
  .counter-section {
    margin-top: -230px;
  }
  .clock-wrapper .clock div span {
    font-size: 32px;
  }
  .contest-cart__thumb-slider {
    height: 285px;
  }
  .contest-cart__right .select-quantity {
    width: 100%;
  }
  .contest-details-content .contest-name {
    font-size: 28px;
  }
  .lottery-wrapper {
    padding: 70px 30px 50px 30px;
  }
  .lottery-wrapper__title {
    font-size: 32px;
  }
  .lottery-single.style--two .lottery-single__number {
    justify-content: center;
  }
  .pick-lottery-package .title {
    font-size: 32px;
  }
  .cart-wrapper {
    padding-top: 80px;
  }
  .ticket-wrapper__body .single-row .numbers {
    width: 100%;
    justify-content: center;
  }
  .ticket-wrapper__body .single-row .action-btns {
    width: 100%;
    justify-content: center;
  }
  .checkout-area {
    padding: 30px 15px;
  }
  .affiliate-single-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  .checkout-form-area .top .right {
    width: 100%;
    margin-top: 15px;
  }
  .video-wrapper .cmn-btn {
    padding: 15px 35px;
    display: inline-block;
    white-space: nowrap;
  }
  .faq-top-wrapper {
    padding-left: 30px;
    padding-right: 30px;
  }
  .blog-card__content {
    margin-left: 0;
    margin-right: 0;
    margin-top: -30px;
  }
  .blog-card__footer .left .post-author {
    margin-left: 8px;
  }
  .blog-card.style--two .blog-card__content {
    padding: 20px 15px;
  }
  .blog-card.style--two .blog-card__title {
    font-size: 24px;
  }
  .blog-single__title {
    font-size: 32px;
  }
  .blog-single__body {
    padding: 15px;
  }
  .contact-info {
    width: 100%;
    margin-top: 20px;
  }
  .contact-info::after {
    display: none;
  }
  .draw-ticket-slider {
    padding-bottom: 50px;
  }
  .draw-ticket-slider .slick-arrow {
    top: auto;
    bottom: 0;
  }
  .user-info-card {
    padding: 20px 15px;
  }
  .user-info-card__list li span {
    font-size: 14px;
  }
  .user-info-card__list li .caption {
    text-align: left;
    width: 42%;
  }
  .user-info-card__list li .value {
    width: 58%;
    padding-left: 15px;
  }
  .subscribe-area .subscribe-form {
    margin-right: 0;
  }
  .subscribe-area .subscribe-form input {
    width: 100%;
  }
}

@media (max-width: 530px) {
  .user-info-card__list li + li {
    margin-top: 15px;
  }
  .counter-item {
    flex-direction: column;
  }
}

@media (max-width: 480px) {
  .subscribe-area {
    padding: 30px;
  }
  .contest-details__thumb-slider {
    min-height: 350px;
  }
  .counter-wrapper.style--two {
    margin: -80px 30px 0 30px;
  }
  .hero__btn .video-btn {
    margin: 0 auto;
  }
  .hero__btn {
    display: inline-grid;
    display: -moz-inline-grid;
    display: -ms-inline-grid;
  }
  .hero__car img {
    max-width: 25%;
    left: 85%;
  }
  .update-home-page .hero.style--two .hero__title {
    font-size: 32px;
  }
}

@media (max-width: 460px) {
  .subscribe-area .left .subtitle {
    font-size: 18px;
  }
  .subscribe-area .left .title {
    font-size: 24px;
  }
}

@media (max-width: 440px) {
  .next-draw-area .middle .clock2 div::after {
    height: 25px;
    top: 5px;
  }
  .next-draw-area .middle .clock2 span,
  .next-draw-area .middle .clock2 p {
    font-size: 24px;
  }
}

@media (max-width: 380px) {
  .hero__title {
    font-size: 36px;
  }
  .hero.style--two .hero__title {
    font-size: 36px;
  }
  .next-draw-area .middle .clock2 span,
  .next-draw-area .middle .clock2 p {
    font-size: 22px;
  }
  .counter-grid .counter-item__icon img {
    width: 45px;
  }
  .counter-grid .counter-item__content span {
    font-size: 20px;
  }
  .counter-grid .counter-item__content p {
    font-size: 14px;
  }
  .mystery-box-grid .contest-card__thumb {
    height: 200px;
  }
  .mystery-box-grid .contest-card__thumb img {
    max-height: 140px;
  }
  .update-home-page .contest-card__footer {
    padding-top: 0;
  }
  .how-to-play-sec .play-card__icon {
    width: 50px;
    height: 75px;
    line-height: 60px;
  }
  .how-to-play-sec .play-card__content {
    margin-top: 0;
  }
}

@media (max-width: 340px) {
  .contest-card__meta li:last-child {
    width: 70%;
    padding-left: 15px;
  }
}
