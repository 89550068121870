.addtocart_page {
  position: absolute;
  top: 54px;
  left: 0;
  z-index: 999;
  min-width: 20rem;
  width: 100%;
  max-width: 320px;
  /* background: #06202d;
  border: 2px solid #073148; */
  background: #1b1c25;
  border: 2px solid #292B39;

  padding: 5px 10px;
  box-shadow: 0 11px 15px -7px #0003, 0 24px 38px 3px #00000024,
    0 9px 46px 8px #0000001f;
}
/* .triangle-up {
	width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-bottom: 50px solid #555;
} */
.shopping_cart {
  height: 30vh;
  overflow: hidden;
  color: #ffffff;
  list-style-type: none;
  overflow-y: scroll;
}
.shopping_cart img.mr-3.media {
  width: 80px;
}
.shopping_cart .media {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  padding: 5px 0;
}
.shopping_cart span.pro_name {
  font-size: 15px;
  padding-top: 5px;
  text-transform: capitalize;
  width: 110px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.shopping_cart .media_body h4 {
  font-size: 18px;
  font-weight: 100;
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  text-align: right;
}
.addtocart_page .total {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 19px;
  width: 90%;
  margin: 10px auto;
  padding: 10px 0px;
  border-top: 1px solid #0a3e58;
  border-bottom: 1px solid #0a3e58;
  align-items: center;
}
.addtocart_page .total h5 {
  margin: 0;
  font-size: 22px;
  text-transform: capitalize;
}
.addtocart_page .total span {
  font-size: 22px;
  font-weight: 600;
  line-height: initial;
}
.addtocart_page .view-cart {
  text-align: center;
}
.addtocart_page .view-cart a {
  margin: 0px auto;
  width: 85%;
  padding: 5px 20px;
  font-size: 14px;
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
.shopping_cart .view-cart a:hover {
  background-size: 300%;
}
.shopping_cart .view-cart a {
  text-decoration: none;
  color: #ffffff;
  font-size: 15px;
  text-transform: uppercase;
}
.addtocart_page .view_btn {
  margin-top: 30px;
  margin-bottom: 15px;
}
span.pro_name {
  font-size: 15px;
  padding-top: 5px;
}
.shopping_cart span.quantity {
  color: #e89c31;
}
.addtocart_page span.total_price {
  color: #ffffff;
}

.shopping_cart::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}
.shopping_cart::-webkit-scrollbar-track {
  /* background-color: #06202d; */
  background-color: #19191d;
}
.shopping_cart::-webkit-scrollbar-track:hover {
  /* background-color: #06202d; */
  background-color: #19191d;
}
.shopping_cart::-webkit-scrollbar-thumb {
  /* background-color: #073148;
  border-radius: 16px;
  border: 5px solid #06202d; */
  background-color: #754503;
  border-radius: 16px;
  border: 5px solid #292b39;
}
.shopping_cart::-webkit-scrollbar-thumb:hover {
  /* background-color: #073148; */
  background-color: #f3991e;
}
.shopping_cart::-webkit-scrollbar-button {
  display: none;
}


/*****************/


.animate-inventory {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.animate-inventory.toggle-cart {
  opacity: 1;
}

.animate-inventory .fly-cart img {
  width: 250px;
  height: 250px;
  max-width: 100%;
  max-height: 100%;
  transition: all 0.5s;
  opacity: 0;
}


.animate-inventory.toggle-cart img {
  animation: flyinventory 0.5s linear;
  /* animation-fill-mode: forwards; */
  opacity: 0;
}

@keyframes flyinventory {
  0% {
    transform: translate3d(0px, 0px, 16px) scale(1);
    opacity: 1;
  }

  50% {
    transform: translate3d(200px, -280px, 16px) scale(0.5);
    opacity: 1;
  }

  100% {
    transform: translate3d(400px, -580px, 16px) scale(0.2);
    opacity: 1;
  }
}

