/* Logged In Menu */
.loggedin-menu {
  position: relative;
  z-index: 9999;
}

.loggedin {
  margin-left: 40px;
}

.loggedin img {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  object-fit: cover;
}

.loggedin span {
  font-size: 14px;
  text-align: center;
  line-height: 14px !important;
  font-weight: normal;
  color: #fff;
  cursor: pointer;
}

@media (max-width: 767px) {
  .loggedin span {
    display: none;
  }
}
.new_header .nav-right {
  margin-left: 0px !important;
}

.loggedin-list {
  /* background-color: #083248; */
  background-color: #292b38;
  box-sizing: border-box;
  margin-top: 4px;
  opacity: 0;
  overflow: hidden;
  padding: 5px 22px;
  pointer-events: none;
  position: absolute;
  width: 140px;
  top: 100%;
  border-radius: 5px;
  left: 15px;
  -webkit-transform-origin: 50% 0;
  -ms-transform-origin: 50% 0;
  transform-origin: 50% 0;
  -webkit-transform: scale(0.75) translateY(-21px);
  -ms-transform: scale(0.75) translateY(-21px);
  transform: scale(0.75) translateY(-21px);
  -webkit-transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25),
    opacity 0.15s ease-out;
  transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
  /* z-index: 9; */
}

.loggedin-list li {
  text-align: left;
  cursor: pointer;
}

.loggedin-list li i.las.la-user {
  margin-left: -2px;
}

.loggedin-list li a {
  color: #fafafa;
  cursor: pointer;
  font-weight: 400;
  line-height: 40px;
  list-style: none;
  min-height: 40px;
  outline: none;
  padding-left: 18px;
  padding-right: 29px;
  text-align: left;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.loggedin-list li a:hover,
.loggedin-list li a:focus,
.loggedin-list li a:active {
  color: #e89c31;
}

.loggedin-menu.open ul.loggedin-list {
  opacity: 1;
  pointer-events: auto;
  -webkit-transform: scale(1) translateY(0);
  -ms-transform: scale(1) translateY(0);
  transform: scale(1) translateY(0);
  box-shadow: 0 0 10px 0 #031b28;
  padding: 5px 22px;
  /* z-index: 999; */
}

.loggedin-menu.open i.fa-caret-down:before {
  content: "\f0d8" !important;
}

.close-menu {
  color: #fff;
  background: transparent;
  position: absolute;
  right: 0;
  width: 25px;
  height: 25px;
}

@media (max-width: 1199px) {
  .collapse:not(.show) {
    display: block !important;
    left: -300px;
  }

  .navbar-collapse {
    background-color: #1B1C25 !important;
    max-height: 100% !important;
    overflow: inherit;
    position: fixed;
    left: 0;
    width: 300px;
    top: 0;
    bottom: 0;
    transition: all 0.5s;
    /* z-index: 99; */
  }

  .header.menu-fixed .navbar-collapse {
    top: 50px;
  }

  .header:not(.menu-fixed) .navbar-collapse {
    box-shadow: 0 0 20px 0 #111117ef;
  }

  .new_header .new-main-menu {
    margin-left: 0px !important;
    padding-left: 0px !important;
    border-left: 0px !important;
  }
  .new_header .new-main-menu li{
    /* padding-left: 15px; */
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .new_header .new-main-menu svg {
    /* width: 20px;
    height: 20px; */
    /* position: absolute;
    left: -15px;
    top: 15px; */
  }
  
}

@media (max-width: 480px) {
  .no-display-xl {
    display: none !important;
  }

  .navbar-collapse {
    max-width: 250px;
  }
}

/********** Start style for language drowpdown ********/
.header__top .right .language {
  background: #083248;
  margin-left: 35px;
  border-radius: 8px;
}

.header__top .right .language button {
  color: #ffffff;
  margin-bottom: -3px;
  font-size: 14px;
}

.header__top .right .language .btn:focus {
  outline: none;
  box-shadow: none;
}

.language .dropdown-menu.show {
  background: #083248;
  /* color: #fff; */
  box-shadow: 0 0 10px 0 #031b28;
  border: none;
}

.language .dropdown-item:focus,
.dropdown-item:hover {
  color: #ffffff;
  text-decoration: none;
  background-color: #073148;
}

.language a.dropdown-item {
  color: #fff;
}

.chat-btn {
  cursor: pointer;
}

/***** cart css *********/
.cart-box {
  position: fixed;
  right: 10px;
  transform: translate(-50%, -50%);
  top: 50%;
  /* z-index: 999999999; */
  min-width: 100px;
  height: 100px;
  display: flex;
  justify-content: flex-end;
  text-align: center;
}

.chat-box-btn {
  width: 24px;
  height: 24px;
  border: none;
  color: transparent !important;
}

.cart-box .cart {
  background: #db922a;
  height: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: relative;
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  cursor: pointer;
  transition: all ease 1s;
  margin-left: auto;
}

.cart-box .cart img {
  height: 25px;
}

.cart-box .cart span {
  position: absolute;
  top: -2px;
  right: -5px;
  height: 20px;
  width: 20px;
  background: #f8fdff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  font-size: 11px;
  color: black;
  line-height: 31px;
}

.new_header {
  /* position: unset !important; */
  background: #1b1c25 !important;
  padding: 12px 0px;
}
.new_header .site-logo img {
  width: 114px;
}

.new_header .new-main-menu {
  margin-left: 35px;
  padding-left: 35px;
  padding-right: 20px;
  border-left: 1px solid #292b39cc;
}


.new_header .new-main-menu svg {
  /* width: 20px;
  height: 20px; */
  /* position: absolute;
  left: -12px;
  top: 15px; */
}
.header-wallet {
  height: 44px;
  justify-content: center;
  background-color: #292b39;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.total__amount {
  width: 80px;
  text-align: center;
}

.wallet-btn {
  width: 100px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  background: linear-gradient(90deg, #f06723 0.05%, #f4a21d 99.85%);
}

.new-loggedin {
  width: 64px;
  height: 44px;
  border-radius: 10px;
  margin-left: 0;
  background: #292b39;
  box-shadow: 0px 3px 10px 0px rgba(29, 32, 39, 0.5) inset;
}
.notificationIcon {
  width: 44px;
  height: 44px;
  border-radius: 10px;
  background: #292b39;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 10px 0px rgba(29, 32, 39, 0.5) inset;
  position: relative;
}

.notificationIcon span {
  position: absolute;
  top: -10px;
  right: 0px;
  width: 19px;
  height: 17.733px;
  border-radius: 2px;
  background: #15161e;
  font-size: 12px;
  text-align: center;
}

@media (max-width: 480px) {
  .header__top .right .language {
    background: #083248;
    height: 30px;
    border-radius: 8px;
    margin-left: 5px !important;
  }

  .header__top .right .language button {
    color: #ffffff;
    margin-top: 0;
  }
}

/********** End style for language drowpdown ********/

.cart-box button,
/* .cart-box button:hover, */
.cart-box button:focus {
  opacity: 0;
  background: transparent;
  border: 1px solid #db922a;
  border-radius: 50px;
  margin: 10px 0px;
  color: #db922a;
  padding: 5px 15px;
  font-size: 11px;
  box-shadow: none !important;
}

.cart-box button:hover {
  background-color: #db922a !important;
  color: #fff !important;
  border: 1px solid #db922a !important;
}

.cart-box button:active {
  background-color: #db922a !important;
  color: #fff !important;
  border: 1px solid #db922a !important;
}

.cart-box:hover button {
  opacity: 1;
}

.cart-box:hover .cart {
  /* margin:0px  auto; */
  transition: all ease 1s;
}

button.login-signup-btn,
button.login-signup-btn:hover,
button.login-signup-btn:focus,
button.login-signup-btn:not(:disabled):not(.disabled):active {
  border: 1px solid #e89c31 !important;
  background: #e89c31 !important;
  background-image: -webkit-linear-gradient(7deg, #e89c31 0%, #ab6b10 100%);
  box-shadow: 0px 3px 7px 0px rgb(0 0 0 / 35%);
  border-radius: 50px;
  padding: 5px 20px;
  outline: none !important;
  box-shadow: none !important;
}

.header__top span.total__amount {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header__top span.total__amount img {
  margin-right: 10px;
  height: 18px;
}

.header__top .right .amount__btn img {
  height: 34px;
  margin-top: -9px;
}

.loggedin-list li i {
  margin-right: 5px;
}

.loggedin-list li {
  font-size: 13px;
  padding: 6px 0px;
}

.sticky-header-btn {
  display: none;
}

.header .menu-fixed .sticky-header-btn {
  display: block;
  padding-left: 20px;
}

@media screen and (max-width: 991px) {
  .cart-box button,
  .cart-box button:hover,
  .cart-box button:focus {
    opacity: 1;
    background: transparent;
    border: 1px solid #db922a;
    border-radius: 50px;
    margin: 10px 0px;
    color: #db922a;
    padding: 5px 15px;
    font-size: 11px;
  }

  .cart-box:hover button {
    opacity: 1;
  }

  .cart-box:hover .cart {
    /* margin:0px  auto; */
    transition: all ease 1s;
  }

  .header .menu-fixed .sticky-header-btn {
    display: none;
  }
}
